import { CurrencyType } from "../../types/common";
import { INR, USD } from "../../utils/constants";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const CurrencyIcon = ({ currency, color = "#000" }: { currency: CurrencyType, color?: string }) => {
    if (currency === INR) {
        return <CurrencyRupeeIcon sx={{ color: color }} />;
    }
    else if (currency === USD) {
        return <AttachMoneyIcon sx={{ color: color }} />
    }
    return null;
};

export default CurrencyIcon;