import api from "./apiService"; // Assuming `api` is your Axios instance

export const fetchPatients = (
  start?: string | null,
  end?: string | null,
  search?: string
) => {
  let params;
  if (start && end) {
    params = {
      start,
      end,
      search,
    };
  }
  params = {
    ...params,
    search,
  };
  return api.get(`/api/patients`, { params });
};

export const updateData = () => {
  return api.get(`/api/patients/updateData`);
};

export const addPatientInBulk = (formdata: FormData) => {
  return api.post(`/api/patients/upload/bulk-patients`, formdata);
};