import React, { useMemo, useRef } from "react";

// third-party imports
import { useReactToPrint } from "react-to-print";
import {
  Box,
  Typography,
  Card,
  CardContent,
  useMediaQuery,
} from "@mui/material";
import Button from "../../ui/Button";
import QRCode from "react-qr-code";
import Logo from "../../shared/Logo";
import { capitalizeFirstLetter } from "../../../utils/common";
import {
  // calculateAge,
  calculateAgeInYearsOrMonths,
  specializationMapping,
} from "../../../utils/appointments";
import { getFormattedDateTime } from "../../../utils/appointments";
import UserAvatar from "../../shared/UserAvatar";
import { AppointmentSummaryPropTypes } from "../../../types/appointments";
import useAppStore from "../../../store/appStore";

const AppointmentSummary: React.FC<AppointmentSummaryPropTypes> = ({
  appointmentDetails,
  appointmentIdForMeet,
}) => {
  // props & state values
  const { appointmentDate, doctor, patient, appointmentCode, appointmentId } =
    appointmentDetails;

  const dateTimeObj = useMemo(
    () => getFormattedDateTime(appointmentDate),
    [appointmentDate]
  );

  const { selectedPatientInList } = useAppStore();

  const isBelowLg = useMediaQuery("(max-width:75rem)");
  const printRef = useRef<HTMLDivElement>(null);

  // callbacks & functions
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Appointment Summary",
  });

  const getGender = (gender: string) => {
    switch (gender) {
      case "MALE":
        return "M";
      case "FEMALE":
        return "F";
      default:
        return "O";
    }
  };

  return (
    <Card
      sx={{
        marginTop: isBelowLg
          ? 0
          : appointmentIdForMeet
            ? 0
            : selectedPatientInList
              ? "1rem"
              : "4.063rem",
        padding: "0rem 1.813rem 2.313rem 1.688rem",
        textAlign: "center",
        borderRadius: "1.25rem",
        boxShadow: "none",
        border: "1px solid var(--neuro-secondary_border)",
      }}
    >
      <CardContent sx={{ padding: "0rem !important" }}>
        <Box
          ref={printRef}
          sx={{
            "@media print": {
              // Print-specific styles
              padding: "1.25rem",
              margin: "0 auto",
              border: "1px solid var(--neuro-secondary_border)",
              borderRadius: "0.625rem",
              width: "100%",
            },
          }}
        >
          {/* doctor card */}
          <Box
            sx={{
              marginBlock: "1rem",
              padding: "0.625rem",
              border: "1px solid var(--neuro-secondary_border)",
              borderRadius: "0.625rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <Box>
                    <UserAvatar
                      src={doctor?.user?.profileImageUrl || null}
                      alt={
                        `${capitalizeFirstLetter(doctor?.user?.firstName || "")}` ||
                        "Doctor-profile"
                      }
                    />
                  </Box>

                  <Box>
                    <Typography
                      sx={{
                        fontSize: "var(--neuro-font-size-micro)",
                        fontWeight: "var(--neuro-font-weight-semibold)",
                        fontFamily: "var(--neuro-font-family-inter)",
                        color: "var(--neuro-bg-darkblue-primary)",
                        lineHeight: "120%",
                        textAlign: "left",
                      }}
                    >
                      {/* {`Dr. ${capitalizeFirstLetter(doctor?.user?.firstName) || ""}
                       ${capitalizeFirstLetter(doctor?.user?.lastName) || ""}`} */}
                      {`Dr. ${capitalizeFirstLetter(doctor?.user?.firstName) || ""}`}
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "var(--neuro-font-size-x-small)",
                        fontFamily: "var(--neuro-font-family-roboto-slab)",
                        color: "var(--neuro-button-bg-primary)",
                        lineHeight: "120%",
                        textAlign: "left",
                      }}
                    >
                      {doctor?.specializations &&
                      doctor?.specializations.length > 0
                        ? doctor?.specializations
                            .map(
                              (specialization: string) =>
                                specializationMapping[specialization] ||
                                specialization
                            )
                            .join(", ")
                        : ""}
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "var(--neuro-font-size-x-small)",
                        fontFamily: "var(--neuro-font-family-roboto-slab)",
                        color: "var(--neuro-black-text)",
                        lineHeight: "120%",
                        marginTop: "0.75rem",
                        textAlign: "left",
                      }}
                    >
                      {doctor?.yearsOfExperience
                        ? `${doctor?.yearsOfExperience} Years Experience`
                        : ""}
                    </Typography>
                  </Box>
                </Box>

                <Logo width="4.375rem" />
              </Box>

              <Box
                sx={{
                  borderTop: 1,
                  borderColor: "var(--neuro-secondary_border)",
                }}
              />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "var(--neuro-font-family-inter)",
                    color: "var(--neuro-bg-darkblue-primary)",
                    lineHeight: "120%",
                    textAlign: "left",
                  }}
                >
                  {/* <b>{`${capitalizeFirstLetter(patient?.user?.firstName) || ""}
                    ${capitalizeFirstLetter(patient?.user?.lastName) || ""}`}</b>{" "} */}
                  <b>{`${capitalizeFirstLetter(patient?.user?.firstName) || ""}`}</b>{" "}
                </Typography>

                <Typography
                  sx={{
                    fontFamily: "var(--neuro-font-family-inter)",
                    color: "var(--neuro-bg-darkblue-primary)",
                    lineHeight: "120%",
                    textAlign: "right",
                  }}
                >
                  {`(${getGender(patient?.gender)},` || ""}{" "}
                  {patient?.age && patient?.age !== ""
                    ? `${patient?.age} yrs)`
                    : `${calculateAgeInYearsOrMonths(patient?.dateOfBirth || "")})` ||
                      ""}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Typography
            sx={{
              fontSize: "var(--neuro-font-size-huge)",
              lineHeight: "var(--neuro-line-height-huge-tiny)",
              fontWeight: "var(--neuro-font-weight-semibold)",
              mt: "1rem",
              mb: "1rem",
              color: "var(--neuro-button-bg-primary)",
              "@media print": {
                textAlign: "center",
                width: "100%",
              },
            }}
          >
            {dateTimeObj
              ? dateTimeObj.hours +
                ":" +
                dateTimeObj.minutes +
                " " +
                dateTimeObj.amOrPm
              : ""}
          </Typography>

          <Typography
            sx={{
              marginBottom: "1rem",
              fontSize: "var(--neuro-font-size-tiny)",
              fontWeight: "var(--neuro-font-weight-bold)",
              fontFamily: "var(--neuro-font-family-roboto-condensed)",
              color: "var(--neuro-black-text)",
              "@media print": {
                textAlign: "center",
                width: "100%",
              },
            }}
          >
            {dateTimeObj
              ? dateTimeObj.day +
                " " +
                dateTimeObj.month +
                " " +
                dateTimeObj.year
              : ""}
          </Typography>

          <Box
            sx={{
              borderTop: 1,
              borderColor: "var(--neuro-secondary_border)",
              marginBottom: "1rem",
            }}
          />

          <Typography
            sx={{
              marginBottom: "1rem",
              fontSize: "var(--neuro-font-size-medium-small)",
              fontWeight: "var(--neuro-font-weight-bold)",
              fontFamily: "var(--neuro-font-family-roboto-condensed)",
              color: "var(--neuro-black-text)",
              lineHeight: "120%",
              wordWrap: "break-word",
              textAlign: "center",
            }}
          >
            {appointmentCode || ""}
          </Typography>

          <QRCode
            size={256}
            style={{
              height: "12.5rem",
              maxWidth: "100%",
              width: "100%",
              marginBottom: "1rem",
            }}
            value={`${window.location.origin}/appointment/${appointmentId}`}
            viewBox="0 0 256 256"
          />
        </Box>

        <Button
          variant="contained"
          className="primary-button"
          fullWidth
          sx={{ mb: "1rem" }}
          onClick={handlePrint}
        >
          Print Appointment
        </Button>
      </CardContent>
    </Card>
  );
};

export default AppointmentSummary;
