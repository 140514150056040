import React, { useCallback, useState } from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Doctor } from "../../types/common";
import ComponentHeader from "../shared/ComponentHeader";
import { DoctorsListProps } from "../../types/doctors";
import { capitalizeFirstLetter, getFormattedDate } from "../../utils/common";
import Button from "../ui/Button";
import LoadingScreen from "../shared/LoadingScreen";
import ContentCenter from "../shared/ContentCenter";
import useAppStore from "../../store/appStore";
import { TableWrapper } from "../shared/TableWrapper";
import CardTableToggle from "../shared/CardTableToggle";
import ComponentFilter from "../shared/ComponentFilter";
import { debounce } from "lodash";
// import config from "../../config";
import { DOCTORS } from "../../utils/constants";
import DoctorCard from "./DoctorCard";
import { useNavigate } from "react-router-dom";
import config from "../../config";

const DoctorsList: React.FC<DoctorsListProps> = ({
  loading,
  doctors,
  loadDoctors,
}) => {
  // props & state values
  const isMobile = useMediaQuery("(max-width:50rem)");

  const navigate = useNavigate();

  // Initialize view from localStorage or default to "table"
  const [view, setView] = useState(
    () => localStorage.getItem("doctorsView") || config.DEFAULT_DOCTORS_VIEW
  );

  const {
    setFilter,
    doctorsFilter,
    setShowDoctorSubmenus,
    setSelectedDoctorInList,
    setDrawerOpen,
    setIsMenuCollapsed,
    drawerOpen,
    isMenuCollapsed,
    setIsMenuClosedBySystem
  } = useAppStore();

  // callbacks & functions
  const TableRowClick = (row: any) => {
    setShowDoctorSubmenus(true);
    setSelectedDoctorInList(row || null);
    if (isMobile) {
      if (drawerOpen) {
        setDrawerOpen(false);
        setIsMenuClosedBySystem(true);
      }
    } else {
      if (!isMenuCollapsed) {
        setIsMenuCollapsed(true);
        setIsMenuClosedBySystem(true);
      }
    }
    navigate(`/doctors/personal-profile`);
  };

  // search filter handlers start
  const handleInputChange = (value: string, filter: any) => {
    loadDoctors(DOCTORS, filter.customStartDate, filter.customEndDate, value);
  };

  const debouncedSearch = useCallback(
    debounce((value: string, filter: any) => {
      // if (value.length >= config?.SEARCH_FILTER_LETTER) {
      //   handleInputChange(value, filter);
      // }
      handleInputChange(value, filter);
    }, 1000),
    []
  );

  const handleStoreSearchValueChange = (value: string) => {
    setFilter("doctorsFilter", {
      searchValue: value,
    });

    debouncedSearch(value, doctorsFilter);

    // if (value === "") {
    //   // If the input is cleared, fetch all doctors
    //   handleInputChange("", doctorsFilter);
    // }

    // if (
    //   value.length >= config?.SEARCH_FILTER_LETTER
    //   // value.length % config?.SEARCH_FILTER_LETTER === 0
    // ) {
    //   debouncedSearch(value, doctorsFilter);
    // }
  };
  // search filter handlers end

  const handleAddNew = () => {
    setShowDoctorSubmenus(true);
    if (isMobile) {
      if (drawerOpen) {
        setDrawerOpen(false);
        setIsMenuClosedBySystem(true);
      }
    } else {
      if (!isMenuCollapsed) {
        setIsMenuCollapsed(true);
        setIsMenuClosedBySystem(true);
      }
    }
    navigate(`/doctors/personal-profile`);
  };

  const handleEdit = (doctor: Doctor) => {
    setShowDoctorSubmenus(true);
    setSelectedDoctorInList(doctor);

    if (isMobile) {
      if (drawerOpen) {
        setDrawerOpen(false);
        setIsMenuClosedBySystem(true);
      }
    } else {
      if (!isMenuCollapsed) {
        setIsMenuCollapsed(true);
        setIsMenuClosedBySystem(true);
      }
    }
    navigate(`/doctors/personal-profile`);
  };

  const updateDoctorsRangeFilter = (value: string) => {
    setFilter("doctorsFilter", {
      range: value,
    });
  };

  const updateDoctorsCustomDateFilter = (start: string, end: string) => {
    setFilter("doctorsFilter", {
      customStartDate: start,
      customEndDate: end,
    });
  };

  // table columns
  const columns = [
    {
      id: "firstName",
      label: "Name(Years)",
      sortable: true,
      render: (value: string, row: Doctor) =>
        `${row?.prefix || "Dr."} ${capitalizeFirstLetter(value || "")}(${row?.yearsOfExperience || 0} yrs)`,
    },
    {
      id: "specializations",
      label: "Specializations",
      sortable: true,
      render: (value: any) => value?.join(", ") || "-",
    },
    {
      id: "spokenLanguages",
      label: "Languages",
      render: (value: any) => value?.join(", ") || "-",
      sortable: true,
    },
    {
      id: "role",
      label: "Role",
      sortComparator: (a: Doctor, b: Doctor) => {
        const aRole = a?.isNetwork ? "Network" : "Consultation";
        const bRole = b?.isNetwork ? "Network" : "Consultation";

        // Move undefined or null values to the end
        if (aRole === null || aRole === undefined) return 1;
        if (bRole === null || bRole === undefined) return -1;

        // Boolean comparison: "Network" before "Consultation"
        if (aRole === "Network" && bRole === "Consultation") return -1;
        if (aRole === "Consultation" && bRole === "Network") return 1;

        // If roles are equal, return 0
        return 0;
      },
      render: (_: any, row: Doctor) =>
        row?.isNetwork ? "Network" : "Consultation",
      sortable: true,
    },
    {
      id: "hospital.name",
      label: "Clinic",
      sortable: true,
      sortComparator: (a: any, b: any) => {
        const aName = a?.hospital?.name || "";
        const bName = b?.hospital?.name || "";

        // Check if names start with a number
        const aStartsWithNumber = /^\d/.test(aName);
        const bStartsWithNumber = /^\d/.test(bName);

        // Move names starting with a number to the end
        if (aStartsWithNumber && !bStartsWithNumber) return 1;
        if (bStartsWithNumber && !aStartsWithNumber) return -1;

        // Move empty or null values to the end
        if (aName === "") return 1;
        if (bName === "") return -1;

        // Normal locale comparison for non-empty, non-numeric values
        return aName.localeCompare(bName);
      },
      render: (_: any, row: any) => row?.hospital?.name || "-",
    },
    {
      id: "createdAt",
      label: "Registered On",
      sortable: true,
      render: (value: string) => getFormattedDate(value || "") || "-",
    },
  ];

  const handleToggleView = useCallback((newView: string) => {
    setView((prevView) => {
      const updatedView = prevView !== newView ? newView : prevView;
      localStorage.setItem("doctorsView", updatedView); // Store updated view in localStorage
      return updatedView;
    });
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "var(--neuro-bg-light-grey-secondary)",
          minHeight: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <ComponentHeader title="Doctor List" />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1.5rem",
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              className="outlined-secondary-button"
              startIcon={<AddIcon />}
              onClick={handleAddNew}
            >
              Add New
            </Button>
          </Box>
        </Box>

        <Box sx={{ marginBottom: "1rem" }}>
          <ComponentFilter
            updateRange={updateDoctorsRangeFilter}
            updateDates={updateDoctorsCustomDateFilter}
            range={doctorsFilter.range}
            isEmpty
            viewComponent={
              <CardTableToggle view={view} setView={handleToggleView} />
            }
            hideStatusFilter
            handleStoreSearchValueChange={handleStoreSearchValueChange}
            searchValue={doctorsFilter.searchValue || ""}
            searchFilterPlaceholder="Name, Mobile, Qualifications, Email"
            searchFilterWidth="20rem"
          />
        </Box>

        {loading ? (
          <Box
            sx={{
              height: "calc(100% - 8rem)",
            }}
          >
            <LoadingScreen />
          </Box>
        ) : doctors && doctors?.length > 0 ? (
          view === "card" ? (
            <Grid
              container
              spacing={2}
              sx={{
                maxHeight: "calc(100% - 7rem)",
                overflow: "auto",
              }}
            >
              {doctors?.map((doctor) => (
                <Grid item xs={12} key={doctor.id}>
                  <DoctorCard
                    doctor={doctor}
                    handleEdit={handleEdit}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <TableWrapper
              columns={columns}
              rows={doctors}
              stickyHeader={true}
              maxHeight="calc(100% - 8rem)"
              onRowClick={TableRowClick}
              rowSx={{
                cursor: "pointer",
              }}
            />
          )
        ) : (
          <Box
            sx={{
              height: "calc(100% - 8rem)",
            }}
          >
            <ContentCenter>
              <Typography className="no-data">No doctors found</Typography>
            </ContentCenter>
          </Box>
        )}
      </Box>
    </>
  );
};

export default DoctorsList;
