import React from "react";

// third-party imports
import { Box, ListItem, ListItemText, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAppStore from "../../store/appStore";
import { SidebarMenuItemProps } from "../../types/ui";
import Tooltip from "./Tooltip";

const SidebarSubmenuItem: React.FC<SidebarMenuItemProps> = ({
  text,
  route,
  selected = false,
  sx = {},
  hasSubmenus = false,
  disabled = false,
  //   submenus = [],
  isSubMenu = false,
  //   initiallyOpen = false,
  showIconsOnly = false,
  usedInSubMenu = false,
}) => {
  // props & state values
  const navigate = useNavigate();

  const { setDrawerOpen, drawerOpen } = useAppStore();

  const isMobile = useMediaQuery("(max-width:50rem)");

  // callbacks & functions
  const handleListItemClick = (route: string) => {
    navigate(`/${route}`);
    if (isMobile) {
      setDrawerOpen(!drawerOpen);
    }
  };

  return (
    <>
      <Tooltip title={showIconsOnly ? text || "" : ""} placement="right">
        <ListItem
          // button
          onClick={() => {
            if (!disabled) {
              handleListItemClick(route);
            }
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            color: selected
              ? "var(--neuro-white-text)"
              : "var(--neuro-black-text)",
            background: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
              "& .MuiTypography-root": {
                color: disabled
                  ? "var(--neuro-darkgrey_border)"
                  : "var(--neuro-black-text)",
              },
            },
            padding: showIconsOnly
              ? "0.313rem 0.375rem 0.313rem 0.3rem"
              : usedInSubMenu
                ? "0rem 0.375rem"
                : "0.313rem 0.375rem",
            width: "auto",
            marginBottom: "0.563rem",
            marginInline: "0.625rem",
            cursor: disabled ? "default" : "pointer",
            position: "relative",
            borderLeft: `3px solid ${selected ? "var(--neuro-button-bg-success)" : "transparent"}`,
            marginRight: selected && hasSubmenus ? 0 : "0.625rem",
            "&:first-of-type": {
              marginTop: isSubMenu ? 0 : usedInSubMenu ? "0.5rem" : "1rem",
            },
            minHeight: "1.5rem",
            // maxHeight: "1.5rem",
            ...sx,
          }}
        >
          <Box display="flex" alignItems="center" sx={{ height: "100%" }}>
            <ListItemText
              primary={text}
              sx={{
                "& .MuiTypography-root": {
                  fontSize: usedInSubMenu
                    ? "var(--neuro-font-size-extra-small)"
                    : "var(--neuro-font-size-smaller-plus)",
                  color: selected
                    ? "var(--neuro-black-text)"
                    : "var(--neuro-darkgrey_border)",
                  lineHeight: "120%",
                },

                "& .MuiTypography-root:hover": {
                  color: disabled
                    ? "var(--neuro-darkgrey_border)"
                    : "var(--neuro-black-text)",
                },
              }}
            />
          </Box>
        </ListItem>
      </Tooltip>
    </>
  );
};

export default SidebarSubmenuItem;
