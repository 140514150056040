import React, { useEffect, useState } from "react";

// third-party imports
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Avatar,
  IconButton,
  Typography,
  Box,
  Popper,
  useMediaQuery,
  ClickAwayListener,
} from "@mui/material";
// import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
// import EmailIcon from "@mui/icons-material/Email";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import AddIcon from "@mui/icons-material/Add";

// project imports
import Button from "../ui/Button";
import DoYouKnowDisorderModal from "../appointments/NewAppointment/DoYouKnowDisorderModal";
// import HeaderButton from "../ui/HeaderButton";
import useAuthStore from "../../store/authStore";
import {
  // capitalizeFirstLetter,
  capitalizeWordsPreservingSpaces,
} from "../../utils/common";
// import HelpSupportModal from "../shared/HelpSupportModal";
import Tooltip from "../ui/Tooltip";
import MenuIcon from "../svg/MenuIcon";
import useProfileStore from "../../store/profileStore";
import {
  isAdmin,
  isClinicAdmin,
  isClinicAdminOrAdmin,
  isDoctor,
  isDoctorOrAdminOrClinicAdmin,
  isDoctorOrNetwork,
  isNetwork,
  isPatient,
} from "../../utils/auth";
import useAppStore from "../../store/appStore";
import { logOutUser } from "../../services/authService";
import Logo from "../shared/Logo";
import useResetStore from "../../store/resetStore";
// import Badge from "../ui/Badge";
// import config from "../../config";

const Header = ({ showOnlyLogoInHeader = false }: { showOnlyLogoInHeader?: boolean; }) => {
  // props & state values
  const {
    profileDetails,
    getProfileDetails,
    userProfile,
    userFirstName,
    // userLastName,
  } = useProfileStore();

  const location = useLocation();
  console.log("location in header", location);
  const isDashboard = location?.pathname === "/dashboard";

  console.log("profileDetails in header", profileDetails);

  const { user, userRole } = useAuthStore();

  const { resetAllStores } = useResetStore();

  const doctorId = user?.doctorId;

  const loggedInUserId = isDoctorOrAdminOrClinicAdmin(userRole)
    ? doctorId
    : user?.patientId;

  console.log("loggedInUserId", loggedInUserId);

  const { isMenuCollapsed, setIsMenuCollapsed, setDrawerOpen, drawerOpen, setSelectedDoctorInList, setShowDoctorSubmenus, setDrawerOpenByClick, setIsMenuOpenedByClick, drawerOpenByClick, isMenuOpenedByClick, setHighlightDiscard, setIsMenuClosedBySystem } =
    useAppStore();

  const isMobile = useMediaQuery("(max-width:50rem)");

  // const [searchValue, setSearchValue] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [disorderModalOpen, setDisorderModalOpen] = useState<boolean>(false);

  console.log("user", user);

  const isAppointmentPath = /^\/appointment\/[a-zA-Z0-9]{22,}$/.test(
    window?.location?.pathname
  );

  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const profilePopperItems = [
    {
      text: "Personal Info",
      id: 1,
      lastItem: false,
    },
    // {
    //   text: "Payment History",
    //   id: 2,
    //   lastItem: false,
    // },
    {
      text: "Logout",
      id: 4,
      lastItem: true,
    },
  ];

  // callbacks & function values
  useEffect(() => {
    if (doctorId && isClinicAdminOrAdmin(userRole)) {
      if (isDashboard) {
        getProfileDetails(doctorId, "ADMIN", "DASHBOARD");
      } else {
        getProfileDetails(doctorId, "ADMIN");
      }
    } else if (doctorId && isDoctor(userRole)) {
      if (isDashboard) {
        getProfileDetails(doctorId, "DOCTOR", "DASHBOARD");
      } else {
        getProfileDetails(doctorId, "DOCTOR");
      }
    } else {
      if (isDashboard) {
        getProfileDetails("", "", "DASHBOARD");
      } else {
        getProfileDetails();
      }
    }
  }, [doctorId]);

  // useEffect(() => {
  //   console.log("useEF 2");

  //   if (profileDetails && profileDetails?.id === loggedInUserId) {
  //     console.log("useEF 2 in");

  //     console.log("profileDetails", profileDetails);
  //     const fetchedProfileImageUrl = profileDetails?.user?.profileImageUrl;
  //     if (fetchedProfileImageUrl) {
  //       setProfileUrl(fetchedProfileImageUrl);
  //       localStorage.setItem("profile_image", fetchedProfileImageUrl);
  //     }
  //   }
  // }, [profileDetails]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setHighlightDiscard(true);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleLogOut = async () => {
    try {
      await logOutUser(user?.userId || "");
    } catch (error) {
      console.error("error in log out", error);
    }
  };

  const handleProfilePopperItemClick = (item: string) => {
    if (item === "Logout") {
      handleLogOut();
      setAnchorEl(null);
      // logout();
      resetAllStores();
      navigate("/");
    }
    if (item === "Personal Info") {
      setAnchorEl(null);
      if (isDoctorOrNetwork(userRole)) {
        setSelectedDoctorInList(profileDetails || null);
        setShowDoctorSubmenus(true);
        if (isMobile) {
          if (drawerOpen) {
            setDrawerOpen(false);
            setIsMenuClosedBySystem(true);
          }
        } else {
          if (!isMenuCollapsed) {
            setIsMenuCollapsed(true);
            setIsMenuClosedBySystem(true);
          }
        }
        navigate(`/settings/personal-profile`);
      }
      else {
        if (isMobile) {
          if (drawerOpen) {
            setDrawerOpen(false);
            setIsMenuClosedBySystem(true);
          }
        } else {
          if (!isMenuCollapsed) {
            setIsMenuCollapsed(true);
            setIsMenuClosedBySystem(true);
          }
        }
        navigate(`/settings/personal-info`);
      }
    }
  };

  // const handleNewAppointment = () => {
  //   // setDisorderModalOpen(true);
  //   navigate("/specialties");
  // };

  const handleCloseDisorderModal = () => {
    setDisorderModalOpen(false);
  };

  const handleMenuIconClick = () => {
    setHighlightDiscard(true);
    setIsMenuClosedBySystem(false);
    if (isMobile) {
      // setDrawerOpen(true);
      setDrawerOpen(!drawerOpen);
      setDrawerOpenByClick(!drawerOpenByClick);
    } else {
      setIsMenuCollapsed(!isMenuCollapsed);
      setIsMenuOpenedByClick(!isMenuOpenedByClick);
    }
  };

  const handleBack = () => {
    if (isAppointmentPath) {
      navigate("/appointments");
    } else {
      // if (!isMobile) {
      //   setIsMenuCollapsed((prev) => !prev);
      // }
      navigate("/documents");
    }
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        borderBottom: "0.063rem solid var(--neuro-secondary_border)",
        height: isMobile ? "3.75rem" : "5rem",
        // width: "100vw",
        // position: "sticky",
        // top: 0,
        // right: 0,
        background: "var(--neuro-white-text)",
        // background: isMobile
        //   ? "linear-gradient(to right, rgba(98, 181, 90, 1), rgba(84, 184, 149, 1))"
        //   : "var(--neuro-white-text)",
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: isMobile ? "3.75rem" : "5rem",
          maxWidth: "1200px",
          marginInline: "auto",
          padding: "1.063rem 1.25rem 1rem 1.25rem",
          position: "relative",
          zIndex: 1,
          "@media (max-width: 85.375rem)": {
            width: "100%",
            maxWidth:
              "100%" /* Ensure it takes the entire width of the screen */,
            marginInline: 0 /* Remove the auto margin */,
          },
        }}
      >
        {
          showOnlyLogoInHeader ?
            <Box
              sx={{
                width: "4.75rem",
                height: "3.375rem",
                marginLeft: "2rem",
              }}
            >
              <Logo width="4.75rem" height="3.375rem" />
            </Box> :

            <>
              {/* {!isMobile && <Badge label={config?.VERSION || ""} />} */}

              <IconButton
                disableRipple
                sx={{
                  // marginRight: isMobile ? "0.625rem" : "0.875rem",
                  padding: 0,
                  backgroundColor: "transparent",
                  height: "1.25rem",
                  width: "1.25rem",
                  "&:hover": { backgroundColor: "transparent" },
                }}
                onClick={handleMenuIconClick}
              >
                <MenuIcon
                  color={
                    "var(--neuro-black-text)"
                    // isMobile ? "var(--neuro-white-text)" : "var(--neuro-black-text)"
                  }
                />
              </IconButton>

              <Box
                sx={{
                  width: "4.75rem",
                  height: "3.375rem",
                  marginLeft: "2rem",
                }}
              >
                <Logo width="4.75rem" height="3.375rem" />
              </Box>

              {/* <Tooltip title="Expand sidebar" placement="top">
            <IconButton
              disableRipple
              sx={{
                marginRight: isMobile ? "0.625rem" : "0.875rem",
                padding: 0,
                backgroundColor: "transparent",
                "&:hover": { backgroundColor: "transparent" },
              }}
              onClick={handleMenuIconClick}
            >
              {/* {isMenuCollapsed && !drawerOpen ? (
              <MenuIcon
                color={
                  isMobile
                    ? "var(--neuro-white-text)"
                    : "var(--neuro-black-text)"
                }
              />
            ) : null} */}

              {/* {isMenuCollapsed && !isMobile ? (
            <MenuIcon
              color={
                isMobile ? "var(--neuro-white-text)" : "var(--neuro-black-text)"
              }
            />
          ) : !drawerOpen && isMobile ? (
            <MenuIcon
              color={
                isMobile ? "var(--neuro-white-text)" : "var(--neuro-black-text)"
              }
            />
          ) : null} */}
              {/* </IconButton> */}
              {/* </Tooltip> */}

              {(window?.location?.pathname === "/documents/view" ||
                isAppointmentPath) && (
                  <Tooltip
                    title={`Back to ${isAppointmentPath ? "appointments" : "documents"}`}
                    placement="top"
                  >
                    <IconButton
                      onClick={handleBack}
                      disableRipple
                      size="small"
                      sx={{
                        marginInline: isMobile ? "0.625rem" : "0.875rem",
                        padding: 0,
                        backgroundColor: "transparent",
                        "&:hover": { backgroundColor: "transparent" },
                      }}
                    >
                      <ArrowBackIcon
                        sx={{
                          color: "var(--neuro-black-text)",
                          // color: isMobile
                          //   ? "var(--neuro-white-text)"
                          //   : "var(--neuro-black-text)",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}

              {/* <SearchInput
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search"
          endIcon={<SearchIcon />}
        /> */}

              <Box flexGrow={1} />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  marginRight: "2.438rem",
                }}
              >
                {/*<HeaderButton startIcon={<LocalHospitalIcon />}> 
             Near by Clinics
          </HeaderButton>*/}
                {/* {userRole === "PATIENT" && (
            <HeaderButton
              onClick={handleNewAppointment}
              startIcon={<CalendarTodayIcon />}
            >
              New Appointment
            </HeaderButton>
          )} */}

                {/* <HelpSupportModal isButton={true} /> */}

                {/* Do you know your disorder modal */}
                <DoYouKnowDisorderModal
                  open={disorderModalOpen}
                  onClose={handleCloseDisorderModal}
                // width={700}
                />

                {/* <HeaderButton startIcon={<EmailIcon />}>Subscribe</HeaderButton> */}
              </Box>

              {/* will use later - commented for now */}
              {/* {isMobile && (
          <IconButton
            color="inherit"
            disableRipple
            sx={{
              marginRight: isMobile ? "22px" : "16px",
              padding: 0,
              backgroundColor: "transparent",
              "&:hover": { backgroundColor: "transparent" },
            }}
          >
            <AddIcon
              sx={{
                fontSize: "20px",
                color: isMobile
                  ? "var(--neuro-white-text)"
                  : "var(--neuro-black-text)",
              }}
            />
          </IconButton>
        )} */}

              {/* popper for add icon - have to make changes here */}
              {/* <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
          sx={{
            marginTop: isMobile ? "11px !important" : "16px !important",
            boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
            borderRadius: "20px 0px 20px 20px",
            zIndex: 199,
          }}
        >
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box
              sx={{
                padding: "14px 18px 30px 16px",
                bgcolor: "rgba(255, 255, 255, 1)",
                borderRadius: "20px 0px 20px 20px",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              {profilePopperItems &&
                profilePopperItems.length > 0 &&
                profilePopperItems.map((item) => {
                  return (
                    <Box
                      key={item.id}
                      sx={{
                        paddingBottom: item?.lastItem ? 0 : "11px",
                        borderBottom: item?.lastItem
                          ? "none"
                          : "1px solid var(--neuro-secondary_border)",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "var(--neuro-darkblue-text)",
                          fontWeight: 400,
                          fontSize: "16px",
                          lineHeight: "19.2px",
                          fontFamily: "Inter",
                          paddingLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleProfilePopperItemClick(item.text)}
                      >
                        {item.text}
                      </Typography>
                    </Box>
                  );
                })}
            </Box>
          </ClickAwayListener>
        </Popper> */}

              {/* will enable it later */}
              {/* <IconButton
          color="inherit"
          disableRipple
          sx={{
            marginRight: isMobile ? "22px" : "16px",
            padding: 0,
            backgroundColor: "transparent",
            "&:hover": { backgroundColor: "transparent" },
          }}
        >
          <NotificationsIcon
            sx={{
              fontSize: "20px",
              color: isMobile
                ? "var(--neuro-white-text)"
                : "var(--neuro-black-text)",
            }}
          />
        </IconButton> */}

              {!isMobile && (
                <Typography
                  sx={{
                    fontWeight: "var(--neuro-font-weight-bold)",
                    color: isMobile
                      ? "var(--neuro-white-text)"
                      : "var(--neuro-black-text)",
                  }}
                >
                  {isDoctor(userRole) && "Dr."}{" "}
                  {capitalizeWordsPreservingSpaces(userFirstName || "") || ""}{" "}
                  {isAdmin(userRole) && "(Admin)"}
                  {isPatient(userRole) && "(Patient)"}
                  {isDoctor(userRole) && "(Doctor)"}
                  {isNetwork(userRole) && "(Network)"}
                  {isClinicAdmin(userRole) && "(Clinic Admin)"}
                </Typography>
              )}

              {!isMobile ? (
                <Button
                  variant="outlined"
                  className="outlined-secondary-button"
                  sx={{
                    width: "5rem",
                    height: "2.875rem",
                    marginLeft: isMobile ? 0 : "0.625rem",
                  }}
                  onClick={handleClick}
                  aria-describedby={id}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.75rem",
                    }}
                  >
                    <Avatar
                      alt={
                        `${capitalizeWordsPreservingSpaces(user?.name || "")}` ||
                        "user-profile"
                      }
                      src={userProfile || undefined}
                      sx={{
                        width: "2.375rem",
                        height: "2.375rem",
                      }}
                    />
                    <ExpandMoreIcon sx={{ color: "var(--neuro-darkgrey_border)" }} />
                  </Box>
                </Button>
              ) : (
                <Box
                  sx={{
                    height: "2.25rem",
                    width: "2.25rem",
                    borderRadius: "50%",
                    background: "var(--neuro-white-text)",
                    border: "0.063rem solid var(--neuro-button-bg-success)",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={handleClick}
                  aria-describedby={id}
                >
                  <Avatar
                    alt={
                      `${capitalizeWordsPreservingSpaces(user?.name || "")}` ||
                      "user-profile"
                    }
                    src={userProfile || undefined}
                    sx={{ width: "2.25rem", height: "2.25rem" }}
                  />
                </Box>
              )}

              <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-end"
                sx={{
                  marginTop: isMobile ? "0.688rem !important" : "1rem !important",
                  boxShadow: "0rem 0.25rem 0.625rem 0rem rgba(0, 0, 0, 0.25)",
                  borderRadius: "1.25rem 0rem 1.25rem 1.25rem",
                  zIndex: 199,
                }}
              >
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Box
                    sx={{
                      padding: "0.875rem 1.125rem 1.875rem 1rem",
                      bgcolor: "rgba(255, 255, 255, 1)",
                      borderRadius: "1.25rem 0rem 1.25rem 1.25rem",
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.75rem",
                    }}
                  >
                    {profilePopperItems &&
                      profilePopperItems.length > 0 &&
                      profilePopperItems.map((item) => {
                        return (
                          <Box
                            key={item.id}
                            sx={{
                              paddingBottom: item?.lastItem ? 0 : "0.688rem",
                              borderBottom: item?.lastItem
                                ? "none"
                                : "0.063rem solid var(--neuro-secondary_border)",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "var(--neuro-darkblue-text)",
                                lineHeight: "120%",
                                fontFamily: "var(--neuro-font-family-inter)",
                                paddingLeft: "0.625rem",
                                cursor: "pointer",
                              }}
                              onClick={() => handleProfilePopperItemClick(item.text)}
                            >
                              {item.text}
                            </Typography>
                          </Box>
                        );
                      })}
                  </Box>
                </ClickAwayListener>
              </Popper>
            </>


        }

      </Box>
    </Box>
  );
};

export default Header;
