import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import LoadingScreen from "../shared/LoadingScreen";
import ContentCenter from "../shared/ContentCenter";
import { format } from "date-fns";
import { getAllAuditLogsOfPatient } from "../../services/auditLogsService";
import { TableWrapper } from "../shared/TableWrapper";
import useAppStore from "../../store/appStore";
import CustomBreadcrumbs from "../shared/CustomBreadcrumbs";
import { capitalizeFirstLetter } from "../../utils/common";
import useAuthStore from "../../store/authStore";
import { isDoctorOrAdminOrClinicAdmin } from "../../utils/auth";
import { useNavigate } from "react-router-dom";

const NewPatientAuditLogList = () => {
  // props & state values
  const [auditLogs, setAuditLogs] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { selectedPatientInList } = useAppStore();

  const navigate = useNavigate();

  const { userRole } = useAuthStore();

  const breadcrumbItems = [
    { label: "Patients", href: "/patients" },
    {
      label: `${capitalizeFirstLetter(selectedPatientInList?.user?.firstName || "")}`,
    },
    { label: "Audit Logs" },
  ];

  // callbacks & functions
  const formattedDate = (dateString: string | null) => {
    if (!dateString || dateString === "") {
      return "";
    }
    const formatedDate = format(new Date(dateString), "d MMM yyyy, hh:mm a");

    return formatedDate;
  };

  const loadAuidtLogs = async (id: string) => {
    try {
      const response = await getAllAuditLogsOfPatient(id);
      setAuditLogs(response?.data || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching referrals:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedPatientInList) {
      loadAuidtLogs(selectedPatientInList?.id);
    } else {
      if (isDoctorOrAdminOrClinicAdmin(userRole)) {
        navigate("/patients");
      } else {
        navigate("/appointments");
      }
    }
  }, [selectedPatientInList]);

  // table columns
  const columns = [
    {
      id: "timestamp",
      label: "Timestamp",
      sortable: true,
      render: (value: any) => `${formattedDate(value || "") || "-"}`,
    },
    {
      id: "user",
      label: "User",
      sortable: true,
      sortComparator: (a: any, b: any) => {
        const userA = `${a?.userName || "-"} (${a?.userType || "-"})`;
        const userB = `${b?.userName || "-"} (${b?.userType || "-"})`;

        return userA.localeCompare(userB);
      },
      render: (_: any, row: any) =>
        `${row?.userName || "-"} (${row?.userType || "-"})`,
    },
    {
      id: "eventType",
      label: "Action Type",
      render: (value: string) => `${value || "-"}`,
      sortable: true,
    },
    {
      id: "description",
      label: "Description",
      render: (value: string) => `${value || "-"}`,
      sortable: true,
    },
    {
      id: "success",
      label: "Status",
      sortable: true,
      render: (value: boolean) => `${value ? "SUCCESS" : "FAIL"}`,
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        paddingBlock: selectedPatientInList ? "1rem" : 0,
        paddingInline: selectedPatientInList ? "0.875rem" : 0,
      }}
    >
      {selectedPatientInList && <CustomBreadcrumbs items={breadcrumbItems} />}

      {loading && <LoadingScreen />}

      {!loading && auditLogs && auditLogs.length === 0 && (
        <ContentCenter>
          <Typography className="no-data">Audit Logs not found</Typography>
        </ContentCenter>
      )}

      {!loading && auditLogs && auditLogs.length > 0 && (
        <TableWrapper
          columns={columns}
          rows={auditLogs}
          stickyHeader={true}
          maxHeight="100%"
        />
      )}
    </Box>
  );
};

export default NewPatientAuditLogList;
