import React from "react";

// third-party imports
import {
  Box,
  //  Typography,
  //  Switch
} from "@mui/material";

// project imports
import DoctorGrid from "../NewAppointment/DoctorGrid";
import YourAvailability from "../NewAppointment/YourAvailability";
// import SearchContainer from "./SearchContainer";
import AppointmentDrawer from "./AppointmentDrawer";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeader from "../../shared/ComponentHeader";
import useAppointmentStore from "../../../store/appointmentsStore";
import { PatientAppointmentsProps } from "../../../types/common";
import useAppStore from "../../../store/appStore";
import { capitalizeFirstLetter } from "../../../utils/common";
import CustomBreadcrumbs from "../../shared/CustomBreadcrumbs";

const NewFlow: React.FC<PatientAppointmentsProps> = ({
  patientId,
  patientName,
  patientEmail,
  patientContact,
  from = "",
}) => {
  // props & state values
  const query = useParams();
  const navigate = useNavigate();

  const { selectedPatientInList } = useAppStore();

  const { yourAvailabilitySelected, setYourAvailabilitySelected } =
    useAppointmentStore();

  const [isNeurologistSelected, setIsNeurologistSelected] =
    React.useState(false);

  const breadcrumbItems = [
    { label: "Patients", href: "/patients" },
    {
      label: `${capitalizeFirstLetter(selectedPatientInList?.user?.firstName || "")}`,
    },
    { label: "Appointments" },
    { label: "Book" },
  ];

  console.log("setIsNeurologistSelected", setIsNeurologistSelected);
  console.log("setYourAvailabilitySelected", setYourAvailabilitySelected);

  // callbacks & functions
  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setYourAvailabilitySelected(event.target.checked);
  // };

  const handleBack = () => {
    navigate("/specialties");
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "var(--neuro-bg-light-grey-secondary)",
          // padding: patientId ? 0 : "1.625rem 1.25rem",
          height: "100%",

          paddingBlock: "1rem",
          paddingInline: "0.875rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            height: "100%",
          }}
        >
          {patientId && <CustomBreadcrumbs items={breadcrumbItems} />}

          {!patientId && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <ComponentHeader
                title="New Appointment"
                page="specialties"
                handleBack={handleBack}
              />
              {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: yourAvailabilitySelected
                    ? "var(--neuro-darkgrey_border)"
                    : "var(--neuro-black-text)",
                  textAlign: "right",
                  alignSelf: "stretch",
                  flexGrow: 1,
                  margin: "auto 0",
                  fontWeight: yourAvailabilitySelected ? 400 : 700,
                  lineHeight: "120%",
                  fontFamily:
                    "Roboto, -apple-system, Roboto, Helvetica, sans-serif",
                }}
              >
                Doctor availability
              </Typography>

              <Switch
                checked={yourAvailabilitySelected}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />

              <Typography
                sx={{
                  color: yourAvailabilitySelected
                    ? "var(--neuro-black-text)"
                    : "var(--neuro-darkgrey_border)",
                  alignSelf: "stretch",
                  flexGrow: 1,
                  margin: "auto 0",
                  fontWeight: yourAvailabilitySelected ? 700 : 400,
                  lineHeight: "120%",
                  fontFamily:
                    "Roboto, -apple-system, Roboto, Helvetica, sans-serif",
                }}
              >
                Your Availability
              </Typography>
            </Box> */}
            </Box>
          )}

          {/* filter header */}
          {/* <SearchContainer /> */}

          {!yourAvailabilitySelected && (
            <Box
              sx={{
                height: from === "patient-list" ? "calc(100% - 2rem)" : "100%",
                overflowY: "auto",
              }}
            >
              <DoctorGrid
                isNeurologistSelected={isNeurologistSelected}
                patientId={patientId || selectedPatientInList?.id}
                selectedSpeciality={query.specialisation || ""}
                from={from}
              />
            </Box>
          )}

          {yourAvailabilitySelected && (
            <YourAvailability
            // yourAvailabilitySelected={yourAvailabilitySelected}
            // isNeurologistSelected={isNeurologistSelected}
            />
          )}
        </Box>
      </Box>
      <AppointmentDrawer
        headerText="Schedule Appointment"
        patientId={patientId || selectedPatientInList?.id}
        patientName={
          patientName ||
          `${capitalizeFirstLetter(selectedPatientInList?.user?.firstName || "")}`
        }
        patientEmail={patientEmail || selectedPatientInList?.user?.email}
        patientContact={patientContact || selectedPatientInList?.user?.mobile}
        from={from}
      />
    </>
  );
};

export default NewFlow;
