import React from "react";
import { Button, Typography, Box } from "@mui/material";

const OtpTypeSelect: React.FC<{ otpTypes:string[], onSelect: (type: string) => void }> = ({ onSelect }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Typography variant="h6">Select Verification Type</Typography>
      <Button variant="contained" onClick={() => onSelect("aadhaar")}>
        Aadhaar Verification
      </Button>
      <Button variant="contained" onClick={() => onSelect("mobile")}>
        Mobile Verification
      </Button>
    </Box>
  );
};

export default OtpTypeSelect;
