import React, { useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import Modal from "../ui/Modal";
import StepOne from "./AadharModal";
import StepTwo from "./AadharOtpModal";
import StepThree from "./AbhaOtpModal";
import StepFour from "./AbhaAddressModal";
import useAppStore from "../../store/appStore";
import { sendAadharOtp ,verifyAadharOtp ,sendAbhaNewNumberOtp ,
verifyAbhaNewNumberOtp,getAbhaAddressSuggestions , submitAbhaAddress} from "../../services/authService";
import useAuthStore from "../../store/authStore";
import { useNavigate } from "react-router-dom";
const AbhaSignUp: React.FC<{
  open: boolean;
  onClose: () => void;
  userId?:string;
}> = ({ open, onClose ,userId }) => {
  const [currentStep, setCurrentStep] = useState<number>(1); // Current step
  const [stepData, setStepData] = useState<{ [key: number|string]: any }>({}); // Data for each step
  const isMobile = useMediaQuery("(max-width:37.5rem)");
  const {setIsRedirectedFromSignUp, showSnackbar } = useAppStore();
  const navigate = useNavigate();
  const login = useAuthStore((state) => state.login);
  const [loading, setLoading] = useState(false);
  const [reloading, setReloading] = useState(false);
  // Step-specific API methods


  const resendAadhaarOtp = async () => {
    setReloading(true);
    try {
      console.log("step-1 body",stepData["step1Body"])
			const response : any= await sendAadharOtp(stepData["step1Body"]);
      const responseData =await response.data;
      console.log("send aadhar otp result",response)
      showSnackbar(response.message, "success");
      setStepData((prev) => ({ ...prev, 2: {...responseData}}));
			console.log("result in re-send aahar otp", responseData);
		} catch (error: any) {
			console.log("error", error);
			showSnackbar(
				error?.response?.data?.message ||
					"Could not resend otp, please try again",
				"error"
			);
			console.error("Could not send otp");
		}finally {
      setReloading(false);
    }
  };

  const resendAbhaOtp = async () => {
    setReloading(true);
    try {
      const abhaResponse : any  = await sendAbhaNewNumberOtp(stepData["step3Body"].txnId ,stepData["step3Body"].mobile);
       const abhaResponseData = abhaResponse.data;
       console.log("send abha otp result",abhaResponse)
       showSnackbar(abhaResponse.message, "success");
       setStepData((prev) => ({ ...prev, 100: {...abhaResponseData} }));
		} catch (error: any) {
			console.log("error", error);
			showSnackbar(
				error?.response?.data?.message ||
					"Could not resend otp, please try again",
				"error"
			);
			console.error("Could not send otp");
		}finally {
      setReloading(false);
    }
  };

  const fetchStepTwoData = async (data: any) => {
    setLoading(true);
    try {
			const response : any= await sendAadharOtp(data);
      const responseData =await response.data;
      console.log("send aadhar otp result",response)
      showSnackbar(response.message, "success");
      setStepData((prev) => ({ ...prev, 2: {...responseData},step1Body:{...data},aadhaar:data.aadharNumber }));
      setCurrentStep((prev) => prev + 1)
			console.log("result in send aahar otp", responseData);
		} catch (error: any) {
			console.log("error", error);
			showSnackbar(
				error?.response?.data?.message ||
					"Could not send otp, please try again",
				"error"
			);
			console.error("Could not send otp");
		}finally {
      setLoading(false);
    }
  };

  const fetchStepThreeData = async (data: any) => {
    setLoading(true);
    try {
      const aadharResponse : any= await verifyAadharOtp(
        data.otp,
        stepData[2].txnId,
        data.mobile,
        stepData["aadhaar"],
        userId
      );
      const aadhaarResponseData =await aadharResponse.data;
      console.log("verify aadhar otp result",aadharResponse)
      showSnackbar(aadharResponse.message, "success");
      if(aadhaarResponseData.verifyAbhaCommunicationNumber){
       const abhaResponse : any  = await sendAbhaNewNumberOtp(aadhaarResponseData.txnId ,data.mobile);
       const abhaResponseData = abhaResponse.data;
       console.log("send abha otp result",abhaResponse)
       setStepData((prev) => ({ ...prev, 100: {...abhaResponseData},step3Body:{txnId :aadhaarResponseData.txnId ,mobile :data.mobile} }));
       showSnackbar(abhaResponse.message, "success");
       setCurrentStep((prev) => prev + 1)
      }else{
        const addressResponse : any = await getAbhaAddressSuggestions(aadhaarResponseData.txnId);
        const abhaResponseData = addressResponse.data;
        console.log("get abha address suggestions result",addressResponse)
        setStepData((prev) => ({ ...prev, 101: {...abhaResponseData} }));
        showSnackbar(addressResponse.message, "success");
        setCurrentStep((prev) => prev + 2)
      }
    } catch (error: any) {
      console.log("error in verify otp", error);
      showSnackbar(
        error?.response?.data?.message ||
          "Could not create abha account, please try again",
        "error"
      );
    }finally {
      setLoading(false);
    }
  };

  const fetchStepFourData = async (data : any) => {
    setLoading(true);
    try {
      // let userId;
      const verifiedResponse : any = await verifyAbhaNewNumberOtp(
        stepData[100].txnId,
        data.otp,
        stepData["aadhaar"],
        stepData["step3Body"].mobile,
      );
      const verifiedResponseData =await verifiedResponse.data;
      console.log("abha verified otp result",verifiedResponseData)
      showSnackbar(verifiedResponse.message, "success");
      if(verifiedResponseData?.authResult==="success"){
        const addressResponse : any = await getAbhaAddressSuggestions(verifiedResponseData?.txnId);
        const abhaResponseData =await addressResponse.data;
        console.log("get abha address suggestions result",addressResponse)
        setStepData((prev) => ({ ...prev, 101: {...abhaResponseData} }));
        showSnackbar(addressResponse.message, "success");
        setCurrentStep((prev) => prev + 1)
       }else{
        setStepData((prev) => ({ ...prev, 3: {...verifiedResponseData} }));
        setCurrentStep((prev) => prev + 1)
       }
    } catch (error: any) {
      console.log("error in verify otp", error);
      showSnackbar(
        error?.response?.data?.message ||
          "Could not verify abha communication number",
        "error"
      );
    }finally {
      setLoading(false);
    }
  };

  const fetchStepLastStepData = async (data : any) => {
    setLoading(true);
    try {
      const addressSubmitResponse : any = await submitAbhaAddress(
        stepData[101].txnId,
        data.abhaAddress,
        stepData["aadhaar"],
        userId as string,
      );
      const addressSubmitResponseData =await addressSubmitResponse.data;
      console.log("sumit abha address suggestion result",addressSubmitResponse)
      showSnackbar(addressSubmitResponse.message, "success");
      if (addressSubmitResponseData && addressSubmitResponseData?.accessToken && addressSubmitResponseData?.refreshToken) {
        login(addressSubmitResponseData.accessToken, addressSubmitResponseData.refreshToken);
        navigate("/dashboard");
        setIsRedirectedFromSignUp(true);
      }else{
        setLoading(false);
        setStepData({});
        onClose();
        window.location.reload();
      }

    } catch (error: any) {
      console.log("error in verify otp", error);
      showSnackbar(
        error?.response?.data?.message ||
          "Could not submit abha address",
        "error"
      );
    }finally {
      setLoading(false);
    }
  };

  // Dynamically render step content
  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <StepOne sendOtp = {fetchStepTwoData} loader={loading} />;
      case 2:
        return <StepTwo resendOtp = {resendAadhaarOtp} verifyAadhaarOtp = {fetchStepThreeData} message ={stepData[2]?.message} loader={loading} reloader={reloading}  />;
      case 3:
        return <StepThree  resendOtp={resendAbhaOtp} verifyAbhaCommunicationOtp = {fetchStepFourData} loader={loading} reloader={reloading} />;
      case 4:
        return <StepFour  suggestions={stepData[101]?.abhaAddressList} verifySelectedAbhaAddress ={fetchStepLastStepData} loader={loading} />;
      default:
        return <Typography>Invalid Step</Typography>;
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeIcon
      width={isMobile ? "20rem" : "31.25rem"}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {renderStepContent()}
        <Box
          sx={{
            mt: "2rem",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
        </Box>
      </Box>
    </Modal>
  );
};

export default AbhaSignUp;
