import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';

interface RadioGroupProps {
  question: {
    type: string;
    name: string;
    title: string;
    isRequired: boolean;
    choices: { value: string; text: string; score: number }[];
    correctAnswer: string[];
    showScore: boolean;
  };
  index: number;
  toggleRequired: (index: number) => void;
  updateQuestion: (index: number, updatedQuestion: any) => void;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
  isonclick?: boolean;
  showHighlighted: boolean;
}

const DynamicRanking: React.FC<RadioGroupProps> = ({
  question,
  index,
  updateQuestion,
  handleDuplicateQuestion,
  handleRemoveQuestion,
  toggleRequired,
  showHighlighted = false,
  isonclick = false,
}) => {
  // props & state values
  const [localQuestion, setLocalQuestion] = useState(question);

  // callbacks & functions
  useEffect(() => {
    setLocalQuestion(question);
  }, [question])

  const updateLocalQuestion = (updatedQuestion: typeof question) => {
    setLocalQuestion(updatedQuestion);
    updateQuestion(index, updatedQuestion);
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    updateLocalQuestion({ ...localQuestion, title: newTitle });
  };

  const handleToggleRequired = () => {
    toggleRequired(index);
  };

  const handleAddChoice = () => {
    const number = parseInt(localQuestion?.choices[localQuestion.choices?.length - 1]?.value.replace("feature", ""), 10);

    const newChoice = {
      value: `feature${number ? number + 1 : localQuestion.choices.length + 1}`,
      text: `Feature ${number ? number + 1 : localQuestion.choices.length + 1}`,
      score: 0,
    };

    const updatedChoices = [...localQuestion.choices, newChoice];
    updateLocalQuestion({ ...localQuestion, choices: updatedChoices });
  };

  const handleRemoveChoice = (choiceIndex: number) => {
    const updatedChoices = localQuestion.choices.filter((_, idx) => idx !== choiceIndex);
    updateLocalQuestion({ ...localQuestion, choices: updatedChoices });
  };

  const handleEditChoice = (choiceIndex: number, newLabel: string) => {
    const updatedChoices = [...localQuestion.choices];
    updatedChoices[choiceIndex] = { ...updatedChoices[choiceIndex], text: newLabel };
    updateLocalQuestion({ ...localQuestion, choices: updatedChoices });

  };

  const handleEditScore = (choiceIndex: number, newScore: number) => {
    const updatedChoices = [...localQuestion.choices];
    updatedChoices[choiceIndex] = {
      ...updatedChoices[choiceIndex],
      score: newScore,
      value: `${newScore}` // Update `value` as a string of the new score
    };

    updateLocalQuestion({ ...localQuestion, choices: updatedChoices });
  };

  return (
    <Box sx={{
      margin: 0,
      padding: "1rem",
      borderRadius: "0.5rem",
      marginBottom: "1rem",
      cursor: "pointer",
      transition: "border-color 0.3s ease",
      background: "var(--neuro-white-text)",
      height: "auto",
      border: isonclick || showHighlighted ? "1px solid var(--neuro-light_red_border) !important" : "1px solid transparent !important",
      "&:hover": {
        border: "1px solid var(--neuro-light_red_border_light) !important"
      }
    }}>
      <div className="question-header">
        {localQuestion.name}
        <input
          type="text"
          value={localQuestion.title}
          onChange={handleTitleChange}
          className="question-name-input survey-js-gobal-input"
          aria-label="Question Title"
        />
        {localQuestion.isRequired && <span className="required">*</span>}
      </div>

      <div className="radio-group">
        {localQuestion.choices.map((choice, choiceIndex) => (
          <div key={choice.value} className="radio-item">
            <button
              className="remove-button"
              onClick={() => handleRemoveChoice(choiceIndex)}
              aria-label={`Remove ${choice.text}`}
            >
              -
            </button>

            <input
              type="text"
              value={choice.text}
              onChange={(e) => handleEditChoice(choiceIndex, e.target.value)}
              autoFocus
              className="edit-label-input survey-js-gobal-input"
              aria-label={`Edit ${choice.text}`}
            />

            {localQuestion.showScore && (
              <input
                type="number"
                value={choice.score}
                onChange={(e) => handleEditScore(choiceIndex, parseInt(e.target.value, 10))}
                className="edit-score-input survey-js-gobal-input"
                aria-label={`Score for ${choice.text}`}
              />
            )}
          </div>
        ))}
        <div className="radio-item">
          <button
            className="add-button"
            onClick={handleAddChoice}
            aria-label="Add Choice"
          >
            +
          </button>
        </div>
      </div>

      <div className="question-controls">
        <button
          className="duplicate-button button-spacing blue-bg"
          onClick={() => handleDuplicateQuestion(index)}
        >
          Duplicate
        </button>
        <button
          className="delete-button button-spacing red-bg required-button"
          onClick={() => handleRemoveQuestion(index)}
        >
          Delete
        </button>
        <button
          className={`required-button ${localQuestion.isRequired ? 'active1' : ''}`}
          onClick={handleToggleRequired}
          aria-label={`Mark as ${localQuestion.isRequired ? 'Not Required' : 'Required'}`}
        >
          {localQuestion.isRequired ? 'Required' : 'Not Required'}
        </button>
      </div>
    </Box>
  );
};

export default DynamicRanking;
