
import * as Yup from "yup";

export const PullAbhaRecordsFormValidationSchema = Yup.object().shape({
    purposeCode: Yup.string().required('Purpose is required'),
    refUri: Yup.string().nullable(), // Optional field allowing null
    hiTypes: Yup.array().of(Yup.string().required()).min(1, "At least one hi Type is required").required("hi Types are  required"),
    startDate: Yup.date()
    .required("Start date is required"),
    endDate: Yup.date()
        .required("End date is required")
        .when("startDate", (startDate, schema) =>startDate
        ? schema.min(startDate, "End date must be greater than or equal to the start date")
        : schema),
    dataEraseAt: Yup.date().required("Data Erase date is required")  
});