import { Box } from "@mui/material";
import DoctorPersonalInfoForm from "./DoctorPersonalInfoForm";
import useAppStore from "../../store/appStore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../store/authStore";
import { isDoctor, isDoctorOrNetwork } from "../../utils/auth";
import { getProfileApi } from "../../services/profileService";
import useProfileStore from "../../store/profileStore";
import LoadingScreen from "../shared/LoadingScreen";
import CustomBreadcrumbs from "../shared/CustomBreadcrumbs";
import { capitalizeFirstLetter } from "../../utils/common";
import Button from "../ui/Button";
import NewDoctorDetails from "./NewDoctorDetails";

const DoctorPersonalInfoFormPage = () => {
  // props & state values
  const {
    selectedDoctorInList,
    showDoctorSubmenus,
    setSelectedDoctorInList,
    setShowDoctorSubmenus,
  } = useAppStore();
  const navigate = useNavigate();

  const {
    profileDetails,
  } = useProfileStore();

  const { userRole, user } = useAuthStore();

  const [showEdit, setShowEdit] = useState(true);

  const breadcrumbItems = [
    {
      label: selectedDoctorInList ? `${capitalizeFirstLetter(selectedDoctorInList?.firstName || selectedDoctorInList?.user?.firstName || "")}` : "",
    },
    { label: "Personal Profile" },
  ];

  // callbacks & functions
  useEffect(() => {
    if (isDoctorOrNetwork(userRole)) {
      setShowEdit(false);
    }
    else {
      setShowEdit(true);
    }
  }, [userRole])

  useEffect(() => {
    const getProifileInfo = async () => {
      const doctorId = user?.doctorId;

      if (doctorId && isDoctor(userRole)) {
        try {
          const response = await getProfileApi(doctorId, "DOCTOR");
          console.log("profileDetails response inpersonal profile page for doctor", response);

          if (response?.data) {
            setShowDoctorSubmenus(true);
            setSelectedDoctorInList(response.data);
          }
        }
        catch (error) {
          navigate(`/dashboard`);

        }
      }
      else {
        try {
          const response = await getProfileApi();
          console.log("profileDetails response inpersonal profile page for network", response);

          if (response?.data) {
            setShowDoctorSubmenus(true);
            setSelectedDoctorInList(response.data);
          }
        }
        catch (error) {
          navigate(`/dashboard`);

        }
      }
    }

    if (!showDoctorSubmenus) {
      if (isDoctorOrNetwork(userRole)) {
        if (profileDetails) {
          console.log("profileDetails inpersonal profile page", profileDetails);
          setShowDoctorSubmenus(true);
          setSelectedDoctorInList(profileDetails);
        }
        else {
          getProifileInfo();
        }
      }
      else {
        navigate(`/doctors`);
      }
    }
  }, [showDoctorSubmenus]);

  console.log("selectedDoctorInList in personal profile", selectedDoctorInList);

  const hanleClose = () => {
    setSelectedDoctorInList(null);
    setShowDoctorSubmenus(false);
    if (isDoctorOrNetwork(userRole)) {
      navigate(`/dashboard`);
    }
    else {
      navigate(`/doctors`);
    }
  };

  const handleProfileViewEdit = () => {
    setShowEdit((prev) => !prev);
  };

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        height: "100%",
        paddingBlock: "1rem",
        paddingInline: "0.875rem",
      }}
    >
      {isDoctorOrNetwork(userRole) && <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "1.25rem",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <CustomBreadcrumbs items={breadcrumbItems} />
        <Button
          variant="outlined"
          color="secondary"
          className="outlined-secondary-button"
          onClick={handleProfileViewEdit}
        >
          {showEdit ? "View Profile" : "Edit Profile"}
        </Button>
      </Box>}

      {isDoctorOrNetwork(userRole) ?
        <>
          {!selectedDoctorInList ? <LoadingScreen /> :
            showEdit ?
              <DoctorPersonalInfoForm
                doctor={selectedDoctorInList}
                onClose={hanleClose}
                hideBreadCrumbs
              />
              :
              <NewDoctorDetails from="profile-page" doctorId={selectedDoctorInList?.id || ""} />
          }
        </>
        :
        <DoctorPersonalInfoForm
          doctor={selectedDoctorInList}
          onClose={hanleClose}
        />}
    </Box>
  );
};

export default DoctorPersonalInfoFormPage;
