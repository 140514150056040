import React from "react";
import { Box } from "@mui/material";
import SidebarFooter from "./SidebarFooter";
import { SidebarContentProps } from "../../types/common";
import useAuthStore from "../../store/authStore";
import { isClinicAdminOrAdmin } from "../../utils/auth";
import NewSidebarMenu from "./NewSidebarMenu";
import useAppStore from "../../store/appStore";

const NewSidebarContent: React.FC<SidebarContentProps> = ({
  menuItems,
  //   isMenuCollapsed,
  //   drawerOpen = false,
  showIconsOnly = false,
  isMobile = false,
}) => {
  // props & state values
  const { userRole } = useAuthStore();
  const { isMenuCollapsed, drawerOpen } = useAppStore();

  const showAssistantIconOnly = isMobile
    ? drawerOpen
      ? showIconsOnly
        ? true
        : false
      : false
    : isMenuCollapsed
      ? true
      : false;

  // Check if the selected menu has submenus
  //   const selectedMenu = menuItems.find((menu) => menu.selected);
  //   const hasSubmenus =
  //     selectedMenu && selectedMenu.submenus && selectedMenu.submenus.length > 0;

  return (
    <Box
      sx={{
        width: isMobile
          ? drawerOpen
            ? showIconsOnly
              ? "3.75rem"
              : "13.75rem"
            : 0
          : isMenuCollapsed
            ? "3.75rem"
            : "13.75rem",
        minWidth: isMobile
          ? drawerOpen
            ? showIconsOnly
              ? "3.75rem"
              : "13.75rem"
            : 0
          : isMenuCollapsed
            ? "3.75rem"
            : "13.75rem",
        transition: "width 0.3s ease-in-out, min-width 0.3s ease-in-out",
        backgroundColor: "var(--neuro-white-text)",
        // height: "100vh",
        height: "100%",
        color: "var(--neuro-black-text)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRight: "0.063rem solid var(--neuro-secondary_border)",
        // borderRight: hasSubmenus
        //   ? "0.125rem solid var(--neuro-black-text)" // Remove border if the menu has submenus
        //   : "0.063rem solid var(--neuro-secondary_border)",
        // overflow: "auto",
        overflow: "hidden",
        // position: "relative",
      }}
    >
      <Box
        sx={{
          height: isClinicAdminOrAdmin(userRole)
            ? "100%"
            : showAssistantIconOnly
              ? "calc(100% - 3.125rem)"
              : "calc(100% - 11.25rem)",
          // marginTop: "1rem",
        }}
      >
        <NewSidebarMenu menuItems={menuItems} showIconsOnly={showIconsOnly} />
      </Box>
      {!isClinicAdminOrAdmin(userRole) && (
        <SidebarFooter showIconOnly={showAssistantIconOnly} />
      )}
    </Box>
  );
};

export default NewSidebarContent;
