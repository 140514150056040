import api from "./apiService";

export const updateProfileApi = (id: string, data: any) => {
  return api.put(`/api/profiles/${id}`, data);
};

export const getProfileApi = (id?: string, role?: string, query?: string) => {
  let params;

  if (role === "DOCTOR") {
    params = {
      doctorId: id,
      page: query && query !== "" ? query : "",
    };
  } else if (role === "ADMIN") {
    params = {
      page: query && query !== "" ? query : "",
    };
  } else {
    params = {
      patientId: id,
      page: query && query !== "" ? query : "",
    };
  }

  if (id && id !== "") {
    return api.get(`/api/profiles`, { params });
  } else {
    if (query && query !== "") {
      const params = {
        page: query,
      };
      return api.get(`/api/profiles`, { params });
    }
    return api.get(`/api/profiles`);
  }
};

export const getAdminProfileApi = (id: string) => {
  return api.get(`/api/profiles/admin/${id}`);
};

export const getDoctorProfileApi = (id: string) => {
  return api.get(`/api/profiles/doctors/${id}`);
};

export const getPatientProfileApi = (id: string) => {
  return api.get(`/api/profiles/patients/${id}`);
};
