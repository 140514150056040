// third-party imports
import { Box } from "@mui/material";

// project imports
import DoctorGrid from "../NewAppointment/DoctorGrid";
import AppointmentDrawer from "./AppointmentDrawer";

const AdminAppointmentBooking = () => {
    return (
        <>
            <Box
                sx={{
                    backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                    height: "100%",
                    paddingBlock: "1rem",
                    paddingInline: "0.875rem",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                        height: "100%",
                    }}
                >
                    <Box
                        sx={{
                            height: "100%",
                            overflowY: "auto",
                        }}
                    >
                        <DoctorGrid
                            isNeurologistSelected={false}
                            patientId=""
                            selectedSpeciality={""}
                        />
                    </Box>
                </Box>
            </Box>
            <AppointmentDrawer
                headerText="Schedule Appointment"
                from="admin-appointments"
            />
        </>
    );
};

export default AdminAppointmentBooking;
