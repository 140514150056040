import React, { useMemo } from "react";
import { Box, Typography, Avatar, Divider } from "@mui/material";
import { capitalizeFirstLetter } from "../../utils/common";
import Button from "../ui/Button";
import { getFormattedDate } from "../../utils/common";
import { CardViewDoctorCardProps } from "../../types/doctors";

const DoctorCard: React.FC<CardViewDoctorCardProps> = ({
  doctor,
  handleEdit,
}) => {
  // props & state values
  const styles = {
    wrapperStyles: {
      display: "flex",
      alignItems: "center",
      gap: "0.2rem",
    },
    headingStyles: {
      fontWeight: "var(--neuro-font-weight-semibold)",
      color: "var(--neuro-button-bg-primary)",
      fontSize: "var(--neuro-font-size-smallest-x)",
      lineHeight: "120%",
    },
    subHeadingStyles: {
      fontWeight: "var(--neuro-font-weight-regular)",
      color: "var(--neuro-black-text)",
      fontSize: "var(--neuro-font-size-smallest-x)",
      lineHeight: "120%",
    },
  };

  const cardStyles = useMemo(() => {
    const background = "var(--neuro-white-text)";
    const borderLeft = "4px solid var(--neuro-button-bg-success)";

    return {
      background,
      borderLeft,
      opacity: 1,
    };
  }, [doctor]);

  // callbacks & functions
  const fullAddess = (city: any, state: any, country: any): string => {
    // `${hospital.city} ${hospital.state} ${hospital.country}`
    let address = "";

    if (city && city !== "") {
      address = `${city},`;
    }

    if (state && state !== "") {
      address = `${address} ${state},`;
    }

    if (country && country !== "") {
      address = `${address} ${country}`;
    }

    return address || "";
  };

  return (
    <Box
      sx={{
        borderRadius: "0.75rem",
        backgroundColor: cardStyles.background,
        display: "flex",
        gap: "0.75rem",
        padding: "0.625rem 1.188rem 0.625rem 1.188rem",
        marginBottom: "1rem",
        border: "0.063rem solid var(--neuro-secondary_border)",
        borderLeft: cardStyles.borderLeft,
        cursor: "pointer"
      }}
      onClick={() => handleEdit(doctor)}
    >
      <Box
        sx={{
          zIndex: 10,
          display: "flex",
          flexDirection: "column",
          lineHeight: "120%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: "1.25rem",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              gap: "0.438rem",
            }}
          >
            <Avatar
              sx={{
                width: "2.875rem",
                height: "2.875rem",
                bgcolor: "var(--neuro-light-grey_border)",
                mt: "0.188rem",
              }}
              src={undefined}
            />
            <Box
              sx={{
                display: "flex",
                marginTop: "0.563rem",
                flexDirection: "column",
                alignItems: "start",
                flexGrow: 1,
                flexBasis: 0,
                width: "fit-content",
              }}
            >
              <Typography
                sx={{
                  color: "var(--neuro-bg-primary-seagreen)",
                  fontWeight: "var(--neuro-font-weight-bold)",
                  lineHeight: "120%",
                  mb: "0.125rem",
                }}
              >
                {`${doctor?.prefix || "Dr."} ${capitalizeFirstLetter(doctor?.firstName || "") || "-"}`}
              </Typography>

              <Typography
                sx={{
                  color: "var(--neuro-bg-darkblue-primary)",
                  fontSize: "var(--neuro-font-size-smallest-x)",
                  lineHeight: "120%",
                  fontFamily: "var(--neuro-font-family-roboto-slab)",
                  mb: "0.375rem",
                  overflowWrap: "anywhere",
                }}
              >
                {`${doctor?.specializations?.join(", ") || ""} (${doctor?.yearsOfExperience || 0} Years of Experience)`}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "var(--neuro-font-family-roboto-slab)",
                  color: "var(--neuro-black-text)",
                  fontSize: "var(--neuro-font-size-smallest-x)",
                  lineHeight: "120%",
                  justifyContent: "space-between",
                  gap: "1.25rem",
                  flexWrap: "wrap",
                }}
              >
                <Box sx={styles.wrapperStyles}>
                  <Typography sx={styles.headingStyles}>Mobile:</Typography>
                  <Typography sx={styles.subHeadingStyles}>
                    {doctor?.mobile || "-"}
                  </Typography>
                </Box>
                <Box sx={styles.wrapperStyles}>
                  <Typography sx={styles.headingStyles}>Email:</Typography>
                  <Typography sx={styles.subHeadingStyles}>
                    {doctor?.email || "-"}
                  </Typography>
                </Box>
                <Box sx={styles.wrapperStyles}>
                  <Typography sx={styles.headingStyles}>Address:</Typography>
                  <Typography sx={styles.subHeadingStyles}>
                    {`${fullAddess(doctor?.hospital?.city, doctor?.hospital?.state, doctor?.hospital?.country) || "-"}`}
                  </Typography>
                </Box>
                <Box sx={styles.wrapperStyles}>
                  <Typography sx={styles.headingStyles}>Reg. Date:</Typography>
                  <Typography sx={styles.subHeadingStyles}>
                    {`${getFormattedDate(doctor?.createdAt || "") || "-"}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mt: "0.75rem" }} />
        <Box
          sx={{
            alignSelf: "center",
            display: "flex",
            marginTop: "0.75rem",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: "100%",
            flexWrap: "wrap",
            gap: "1.5rem",
            paddingLeft: "3.3rem",
          }}
        >
          <Box sx={styles.wrapperStyles}>
            <Typography sx={styles.headingStyles}>Language:</Typography>
            <Typography sx={styles.subHeadingStyles}>
              {doctor?.spokenLanguages?.join(", ") || "-"}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Button
              variant="contained"
              color="success"
              className="success-button"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                handleEdit(doctor);
              }
              }
              sx={{
                height: "2.25rem",
              }}
            >
              Edit
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DoctorCard;
