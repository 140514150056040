import React, { useMemo } from "react";
import { Box, Typography, Divider } from "@mui/material";
import { getFormattedDate } from "../../utils/common";
import Button from "../ui/Button";
import { DoctorLeaveSettingCardProps } from "../../types/doctors";

const LeaveSettingCard: React.FC<DoctorLeaveSettingCardProps> = ({
    leave,
    handleEdit,
    handleDelete,
}) => {
    // props & state values
    const cardStyles = useMemo(() => {
        const background = "var(--neuro-white-text)";
        const borderLeft = "4px solid var(--neuro-button-bg-success)";

        return {
            background,
            borderLeft,
            opacity: 1,
        };
    }, [leave]);

    return (
        <Box
            sx={{
                borderRadius: "0.75rem",
                backgroundColor: cardStyles.background,
                display: "flex",
                gap: "0.75rem",
                padding: "0.625rem 1.188rem 0.625rem 1.188rem",
                marginBottom: "1rem",
                border: "0.063rem solid var(--neuro-secondary_border)",
                borderLeft: cardStyles.borderLeft,
                cursor: "pointer"
            }}
            onClick={() => handleEdit(leave)}
        >
            <Box
                sx={{
                    zIndex: 10,
                    display: "flex",
                    flexDirection: "column",
                    lineHeight: "120%",
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        gap: "1.25rem",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "start",
                            gap: "0.438rem",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                marginTop: "0.563rem",
                                flexDirection: "column",
                                alignItems: "start",
                                flexGrow: 1,
                                flexBasis: 0,
                                width: "fit-content",
                            }}
                        >
                            <Typography
                                sx={{
                                    color: "var(--neuro-bg-primary-seagreen)",
                                    fontWeight: "var(--neuro-font-weight-bold)",
                                    lineHeight: "120%",
                                    mb: "0.125rem",
                                }}
                            >
                                {`${getFormattedDate(leave?.startDate || "")} - ${getFormattedDate(leave?.endDate || "")}`}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Divider sx={{ mt: "0.75rem" }} />
                <Box
                    sx={{
                        alignSelf: "center",
                        display: "flex",
                        marginTop: "0.75rem",
                        width: "100%",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        maxWidth: "100%",
                        flexWrap: "wrap",
                        gap: "1.5rem",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                        }}
                    >
                        <Button
                            variant="contained"
                            className="warning-button"
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                event.stopPropagation();
                                handleDelete(leave);
                            }
                            }
                            sx={{
                                height: "2.25rem",
                            }}
                        >
                            Delete
                        </Button>

                        <Button
                            variant="contained"
                            color="success"
                            className="success-button"
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                event.stopPropagation();
                                handleEdit(leave)
                            }
                            }
                            sx={{
                                height: "2.25rem",
                            }}
                        >
                            Edit
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default LeaveSettingCard;
