import React, { useEffect, useState } from "react";
import { Box, Chip, TextField, Typography } from "@mui/material";
import { postConsultationSummary } from "../../../services/appointmentsService";
import useAppointmentStore from "../../../store/appointmentsStore";
import { DoctorsNoteProps } from "../../../types/appointments";
import useAppStore from "../../../store/appStore";

const DoctorsNote: React.FC<DoctorsNoteProps> = ({
  appointmentId,
  doctorNotes,
}) => {
  // props & state values
  const { notes, setNotes } = useAppointmentStore();
  const { showSnackbar } = useAppStore();
  const [newNote, setNewNote] = useState("");
  // const [infoText, setInfotext] = useState<any>(null);

  // callbacks & functions
  useEffect(() => {
    if (notes && notes.length === 0) {
      setNotes(doctorNotes?.doctorNotes);
    }
  }, []);

  const handleDelete = (noteToDelete: string) => {
    // setInfotext("Deleting note...");
    setNotes(notes.filter((note) => note !== noteToDelete));
    const notesArray = notes.filter((note) => note !== noteToDelete);
    const data = {
      appointmentId: appointmentId || doctorNotes?.appointmentId,
      clinicalNotes: notesArray || [],
      // medications: medicineData,
    };
    const params = {
      saveType: "CLINICALNOTES",
    };
    try {
      postConsultationSummary(data, params);
      // setInfotext("Note deleted");
    } catch (error) {
      // setInfotext("Could not delete");
      showSnackbar("Could not update note, please try again", "error");
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && newNote.trim() !== "") {
      // setInfotext("Adding note...");
      setNotes([...notes, newNote.trim()]);
      const data = {
        appointmentId: appointmentId || doctorNotes?.appointmentId,
        clinicalNotes: [...notes, newNote.trim()],
        // medications: medicineData,
      };
      const params = {
        saveType: "CLINICALNOTES",
      };
      try {
        postConsultationSummary(data, params);
        // setInfotext("Note added");
      } catch (error) {
        // setInfotext("Could not add Note");
        showSnackbar("Could not update note, please try again", "error");
      }
      setNewNote("");
    }
  };

  const handleInput = (event: any) => {
    setNewNote(event.target.value || "");
  };

  return (
    <Box
      sx={{
        paddingBottom: "1.563rem",
        marginBottom: "1.188rem",
        borderBottom: "1px dashed var(--neuro-light-grey_border)",
      }}
    >
      <Box sx={{ width: "100%", display: "flex", alignItems: "center", gap: "1.25rem", mb: "1rem" }}>
        <Typography
          sx={{
            fontSize: "var(--neuro-font-size-tiny)",
            lineHeight: "120%",
            fontFamily: "var(--neuro-font-family-roboto-condensed)",
            color: "var(--neuro-button-bg-primary)",
          }}
        >
          Doctors note
        </Typography>

        {/* {infoText && <Typography
          sx={{
            fontSize: "var(--neuro-font-size-mini)",
            lineHeight: "120%",
            fontFamily: "var(--neuro-font-family-roboto-condensed)",
          }}
        >
          {infoText}
        </Typography>} */}
      </Box>

      <Box
        sx={{
          bgcolor: "var(--neuro-white-text)",
          border: "1px solid var(--neuro-light-grey_border)",
          paddingBlock: "1.313rem",
          paddingInline: "1.438rem",
          borderRadius: "1.25rem",
          minHeight: "6.25rem",
          display: "flex",
          flexDirection: "column",
          gap: "0.625rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "0.5rem",
          }}
        >
          {notes &&
            notes.length > 0 &&
            notes.map((note, i) => (
              <Chip
                key={i}
                label={note}
                onDelete={() => handleDelete(note)}
                sx={{
                  bgcolor: "var(--neuro-white-text)",
                  borderRadius: "0.625rem",
                  paddingBlock: "0.75rem",
                  paddingInline: "0.875rem",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.875rem",
                  border: "1px solid var(--neuro-light-grey_border)",
                  "& .MuiChip-label": {
                    paddingLeft: 0,
                  },
                }}
              />
            ))}
        </Box>
        <TextField
          onChange={handleInput}
          // disabled={
          //   createSummaryLoading && createSummaryLoading.includes("notes")
          //     ? true
          //     : false
          // }
          onKeyDown={handleKeyPress}
          value={newNote}
          placeholder="Type here..."
          sx={{
            // minWidth: "100px",
            minHeight: "6.25rem",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            alignItems: "flex-start",
            height: "6.25rem",
            width: "100%",
            "& .MuiInputBase-root": {
              //   paddingRight: "12px",
              alignItems: "flex-start",
              borderRadius: "3.125rem",
              height: "6.25rem",
              width: "100%",
            },
            "& .MuiInputBase-input": {
              alignItems: "flex-start",
              padding: "0rem !important",
              height: "6.25rem !important",
              width: "100%",
            },
            "& .MuiOutlinedInput-root": {
              alignItems: "flex-start",
              width: "100%",
              backgroundColor: "transparent",
              "& fieldset": {
                borderColor: "transparent", // default
              },
              "&:hover fieldset": {
                borderColor: "transparent", // hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "transparent", // focus
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default DoctorsNote;
