import { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import ComponentHeader from "../shared/ComponentHeader"; import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import { capitalizeFirstLetter } from "../../utils/common";
import Button from "../ui/Button";
import useAppStore from "../../store/appStore";
import { TableWrapper } from "../shared/TableWrapper";
import CardTableToggle from "../shared/CardTableToggle";
import useAuthStore from "../../store/authStore";
import { isClinicAdmin } from "../../utils/auth";
import ComponentFilter from "../shared/ComponentFilter";
import {
  getDisorders,
  // fetchFormRequestsApi,
  getFormResponseApisuryve,
  // submitFormResponseApisuryvegetcall
} from "../../services/formService";
import LoadingScreen from "../shared/LoadingScreen";
import ContentCenter from "../shared/ContentCenter";
import FormsTableActions from "./FormsTableActions";

const FormListEdit = () => {
  // props & state values
  const [apidata, setApidata] = useState<any[]>([]); // Use an empty array as initial state

  const [view, setView] = useState(
    () => localStorage.getItem("allFormsView") || "table"
  );

  const [anchorElByRow, setAnchorElByRow] = useState<{
    [key: string]: HTMLElement | null;
  }>({});

  const [loading, setLoading] = useState<boolean>(true);

  const { userRole } = useAuthStore();
  const { clinicsFilter, setSelectedFormInList } = useAppStore();

  const [disorders, setDisorders] = useState<any[]>([]);


  const navigate = useNavigate();

  const { setShowFormSubmenus } = useAppStore();

  // callbacks & functions
  const fetchDisorder = async () => {
    try {
      const response = await getDisorders();
      setDisorders(response?.data || []);
    }
    catch (error) {
      console.error("Error getting disorder", error);
    }
  }

  useEffect(() => {
    setShowFormSubmenus(false);
    fetchDisorder();
  }, [])

  const handleAddNew = () => {
    setShowFormSubmenus(true);
    localStorage.removeItem("formid");
    localStorage.setItem("isDisabled", "true")
    // navigate(`/form/add/?radio=`);
    navigate(`/form/add`);

  };

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getFormResponseApisuryve();
        const sortedData = response.data.data.sort(
          (a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setApidata(sortedData || []); // Store sorted data in apidata
        setLoading(false);
        console.log("Data fetched and sorted successfully:", sortedData);
      } catch (err) {
        setLoading(false);
        console.error("Error fetching data:", err);
      }
    };
    fetchData();
  }, []);

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    rowId: string
  ) => {
    setAnchorElByRow((prev) => ({ ...prev, [rowId]: event.currentTarget }));
  };

  const handleMenuClose = (rowId: string) => {
    setAnchorElByRow((prev) => ({ ...prev, [rowId]: null }));
  };


  const columns = [
    {
      id: "name",
      label: "Name",
      sortable: true,
      render: (_: any, row: any) =>
        `${capitalizeFirstLetter(row?.name || "") || "-"}`,
    },
    {
      id: "type",
      label: "Form Type",
      render: (value: string) => value || "-",
      sortable: true,
    },
    {
      id: "disorderId",
      label: "Disorder",
      render: (_: any, row: any) => (<Typography>
        {
          disorders && disorders?.length > 0 && disorders.filter((disorder) => disorder?.id === row?.disorderId).length > 0 ? disorders.filter((disorder) => disorder?.id === row?.disorderId)[0]?.name : "-"
        }
      </Typography>),
      sortable: true,
    },
    {
      id: "isScoreBased",
      label: "ScoreBased",
      render: (value: boolean) => (value ? "Yes" : "No"),
      sortable: true,
    },
    {
      id: "filledBy",
      label: "Filled By",
      sortable: true,
      render: (_: any, row: any) =>
        `${capitalizeFirstLetter(row?.filledBy || "") || "-"}`,
    },
    {
      id: "questions",
      label: "Form created",
      sortable: true,
      render: (value: boolean | null) =>
        value !== null ? (
          <CheckCircleIcon sx={{ color: "var(--neuro-button-bg-success)" }} />
        ) : (
          <PendingIcon sx={{ color: "var(--neuro-button-bg-primary)" }} />
        ),
    },
    {
      id: "notifyOnSubmit",
      label: "Notify On Submit",
      sortable: true,
      render: (value: boolean) =>
        value ? "Yes" : "No",
    },
    {
      id: "notificationEmail",
      label: "Notification Email",
      sortable: true,
      render: (value: boolean) =>
        value || "-",
    },
    {
      id: "isPublic",
      label: "Is Public",
      sortable: true,
      render: (value: boolean) =>
        value ? "Yes" : "No",
    },
    {
      id: "actions",
      label: "Actions",
      render: (_: any, row: any): any => (
        Boolean(row?.isPublic) && Boolean(row?.publicUrl) ?

          <Box>
            <FormsTableActions
              handleMenuClose={() => handleMenuClose(row.id)}
              handleMenuOpen={(event: any) =>
                handleMenuOpen(event, row.id)
              }
              anchorEl={anchorElByRow[row.id] || null}
              row={row}
              key={row?.id}
            />
          </Box>
          :
          <Box
            sx={{
              width: "55%",
              textAlign: "center",
            }}
          >
            -
          </Box>
      ),
    },
  ];

  // Handle table row click
  const handleRowClick = (row: any) => {
    const { id } = row;
    localStorage.setItem("isDisabled", "false")
    navigate(`/forms/${id}/edit`);
    // navigate(`/forms/${id}/settings`); // Navigate to the form details page with the selected ID
    localStorage.setItem("formid", id);
    setSelectedFormInList(row);
  };

  const handleToggleView = useCallback((newView: string) => {
    setView((prevView) => {
      const updatedView = prevView !== newView ? newView : prevView;
      localStorage.setItem("allFormsView", updatedView);
      return updatedView;
    });
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "var(--neuro-bg-light-grey-secondary)",
          height: "100%",
        }}
      >
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <ComponentHeader title="Form List" />

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1.5rem",
              }}
            >
              {!isClinicAdmin(userRole) && (
                <Button
                  variant="outlined"
                  color="secondary"
                  className="outlined-secondary-button"
                  startIcon={<AddIcon />}
                  onClick={handleAddNew}
                  sx={{
                    height: "2.25rem",
                  }}
                >
                  Add New
                </Button>
              )}
            </Box>
          </Box>
          <Box sx={{ marginBottom: "1rem" }}>
            <ComponentFilter
              updateRange={() => { }}
              updateDates={() => { }}
              range={clinicsFilter.range}
              isEmpty
              viewComponent={
                <CardTableToggle view={view} setView={handleToggleView} />
              }
              hideStatusFilter
              handleStoreSearchValueChange={() => { }}
              searchValue={clinicsFilter.searchValue || ""}
              searchFilterPlaceholder="Name , Filled By, ScoreBased"
              searchFilterWidth="17.3rem"
            />
          </Box>

          <Box sx={{ height: "calc(100% - 8rem)" }}>
            {loading && <LoadingScreen />}

            {!loading && apidata?.length > 0 &&
              <TableWrapper
                columns={columns}
                rows={apidata} // Pass the sorted data
                stickyHeader={true}
                maxHeight="100%"
                onRowClick={handleRowClick} // Add onRowClick handler
                rowSx={{ cursor: "pointer" }}
              />
            }

            {!loading && apidata?.length === 0 &&
              <ContentCenter>
                <Typography className="no-data">No forms found</Typography>
              </ContentCenter>
            }
          </Box>
        </>
      </Box>
    </>
  );
};

export default FormListEdit;
