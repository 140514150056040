import React, { useMemo } from 'react';
import { Box, Typography, IconButton, Divider, Avatar } from '@mui/material';
import { PrescriptionTypes } from '../../types/prescriptions';
import { getFormattedDateTime, specializationMapping } from '../../utils/appointments';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DescriptionIcon from '@mui/icons-material/Description';
import { capitalizeFirstLetter } from '../../utils/common';

export const PrescriptionCard: React.FC<PrescriptionTypes> = ({ prescription, onClick, selectedPrescription }) => {
    // props & state values
    const {
        // prescriptionId,
        appointmentId,
        appointmentDate,
        // medication,
        // dosageAmount,
        // dosageUnit,
        // medicationType,
        // frequency,
        // durationAmount,
        // durationUnit,
        // instructions,
        // createdAt,
        // updatedAt,
        // createdBy,
        // updatedBy,
        doctor,
    } = prescription;

    const formattedDate = useMemo(() => {
        if (!appointmentDate) return null;
        const DateTimeObject = getFormattedDateTime(appointmentDate);

        if (DateTimeObject && typeof DateTimeObject === "object") {
            const { day, month, year, hours, minutes, amOrPm } = DateTimeObject;
            return {
                date: `${day} ${month} ${year}`,
                time: `${hours}:${minutes}${amOrPm}`,
            };
        } else return "";
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                width: "100%",
                flexDirection: 'column',
                lineHeight: "120%",
                cursor: "pointer",
                borderRadius: '0.75rem',
                borderLeft: selectedPrescription && selectedPrescription?.appointmentId === appointmentId ? "4px solid var(--neuro-button-bg-success)" : "4px solid var(--neuro-button-bg-primary)",
                padding: "0.75rem 1rem 0.75rem 1.25rem",
                backgroundColor: "var(--neuro-white-text)"
            }}
            onClick={() => onClick(prescription)}
        >
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    gap: '1.25rem',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        gap: '1.25rem',
                    }}
                >
                    <Avatar
                        sx={{
                            width: "2.875rem",
                            height: "2.875rem",
                            bgcolor: "var(--neuro-white-text)",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid",
                            borderColor: selectedPrescription && selectedPrescription?.appointmentId === appointmentId ? "var(--neuro-button-bg-success)" : "var(--neuro-secondary_border)"
                        }}
                    >
                        <DescriptionIcon sx={{ color: selectedPrescription && selectedPrescription?.appointmentId === appointmentId ? "var(--neuro-button-bg-success)" : "var(--neuro-black-text)" }} />
                    </Avatar>

                    <Divider
                        orientation='vertical'
                        sx={{
                            width: '1px',
                            backgroundColor: "var(--neuro-secondary_border)",
                            color: "var(--neuro-secondary_border)",
                            height: "auto",
                            marginBlock: "-0.75rem"
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: 'auto 0',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '6px',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: selectedPrescription && selectedPrescription?.appointmentId === appointmentId ? "var(--neuro-black-text)" : "var(--neuro-darkgrey_border)",
                                    // flexGrow: 1,
                                    fontWeight: "var(--neuro-font-weight-bold)",
                                    lineHeight: "120%"
                                }}
                            >
                                {doctor?.firstname && doctor?.firstname !== "" ? `Dr. ${capitalizeFirstLetter(doctor?.firstname || "")}` : ""}
                            </Typography>
                            <Typography
                                sx={{
                                    color: "var(--neuro-darkgrey_border)",
                                    fontSize: "var(--neuro-font-size-x-small)",
                                    fontFamily: "var(--neuro-font-family-roboto-slab)",
                                    lineHeight: "120%"
                                }}
                            >
                                - {doctor?.specializations &&
                                    doctor?.specializations.length > 0
                                    ? doctor?.specializations
                                        .map(
                                            (specialization: string) =>
                                                specializationMapping[specialization] || specialization
                                        )
                                        .join(", ")
                                    : ""}
                            </Typography>
                        </Box>
                        <Typography
                            sx={{
                                color: "var(--neuro-darkgrey_border)",
                                fontSize: "var(--neuro-font-size-x-small)",
                                fontFamily: "var(--neuro-font-family-roboto-slab)",
                                lineHeight: "120%",
                                alignSelf: 'flex-start',
                                mt: '5px',
                            }}
                        >
                            {doctor.defaultClinicName || "-"} | {formattedDate ? formattedDate.date : ""} - {formattedDate ? formattedDate.time : ""}
                        </Typography>
                    </Box>
                </Box>

                {selectedPrescription && selectedPrescription?.appointmentId === appointmentId && <IconButton
                    disableRipple
                    sx={{
                        padding: 0,
                        height: 36,
                        width: 36,
                        backgroundColor: "transparent",
                        "& >svg": {
                            width: 36,
                            height: 36,
                        },
                        "&:hover": {
                            backgroundColor: "transparent",
                        }
                    }}
                >
                    <KeyboardArrowRightIcon sx={{ color: "var(--neuro-button-bg-success)", fontSize: "var(--neuro-font-size-tiny-plus)" }} />
                </IconButton>}
            </Box>
        </Box >
    );
};