import React, { useState, useEffect } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Button from "../ui/Button";
import Spinner from "../ui/Spinner";
import useAppStore from "../../store/appStore";
import Input from "../ui/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";

// Define validation schema for Yup
const schema = yup.object().shape({
  mobile: yup
    .string()
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
    .required("Mobile number is required"),
  otp: yup
    .string()
    .matches(/^[0-9]{6}$/, "OTP must be 6 digits")
    .required("OTP is required"),
});

interface FormInputs {
  mobile: string;
  otp: string;
}

interface AadharOtpModalPropTypes {
  verifyAadhaarOtp: (data: any) => void;
  resendOtp: () => void;
  message: string;
  loader: boolean;
  reloader: boolean;
}

const AadharOtpModal: React.FC<AadharOtpModalPropTypes> = ({ verifyAadhaarOtp, message, loader, reloader, resendOtp }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const [timer, setTimer] = useState(60); // Timer state
  const [isTimerActive, setIsTimerActive] = useState(true); // Timer activation state
  const [resendAttempts, setResendAttempts] = useState(0);
  const { showSnackbar } = useAppStore();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if the screen is small (mobile)

  // Timer Effect
  useEffect(() => {
    if (isTimerActive) {
      const interval = setInterval(() => {
        setTimer((prev) => {
          if (prev > 0) return prev - 1;
          clearInterval(interval);
          setIsTimerActive(false);
          return 0;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isTimerActive]);

  // Handle Resend OTP
  const handleResendOtp = () => {
    if (resendAttempts >= 2) {
      showSnackbar("Resend OTP limit exceeded. Please try again later.", "error");
      return;
    }
    resendOtp();
    setTimer(60);
    setIsTimerActive(true);
    setResendAttempts((prev) => prev + 1);
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    verifyAadhaarOtp(data)
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "1.25rem",
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography
          className="form-heading"
          sx={{
            fontWeight: "var(--neuro-font-weight-bold)",
            fontSize: "var(--neuro-font-size-large-medium)",
            fontFamily: "var(--neuro-font-family-roboto-condensed)",
            lineHeight: "120%",
          }}
        >
          Aadhaar
        </Typography>

        <Typography
          sx={{
            fontSize: isMobile
              ? "var(--neuro-font-size-extra-small)"
              : "var(--neuro-font-size-smaller-plus)",
            fontWeight: "var(--neuro-font-weight-bold)",
            marginTop: "0.75rem",
            marginBottom: isMobile ? "1.5rem" : "0.625rem",
            textAlign: "center",
          }}
        >
          {message}
        </Typography>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            marginBlock: "1rem",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Input
            type="text"
            fullWidth
            // className="input-secondary"
            className="input-primary"
            sx={{
              "& >.MuiInputBase-root": {
                height: "2.5rem",
              },
            }}
            label="Enter OTP*"
            {...register("otp")}
            error={!!errors.otp}
            helperText={errors.otp?.message}
          />
          <Input
            type="text"
            fullWidth
            // className="input-secondary"
            className="input-primary"
            sx={{
              "& >.MuiInputBase-root": {
                height: "2.5rem",
              },
            }}
            label="Enter Mobile Number For Abha communication*"
            {...register("mobile")}
            error={!!errors.mobile}
            helperText={errors.mobile?.message}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            mt: "2rem",
            mb: "1rem",
            alignItems: "center",
            gap: "1.25rem",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="success"
            disabled={loader || reloader}
            className="success-button"
            // onClick={handleSubmitNew}
            sx={{
              opacity: loader || reloader ? 0.7 : 1,
              textTransform: "none",
              width: isMobile ? "6.25rem" : "9.375rem",
              height: isMobile ? "2.5rem" : "3rem",
              color: "var(--neuro-white-text)",
            }}
          >
            {loader ? <Spinner size={"1.25rem"} /> : "Verify OTP"}
          </Button>

          <Button
            variant="text"
            color="secondary"
            disableRipple
            className="secondary-text-button"
            onClick={handleResendOtp}
            disabled={reloader || isTimerActive} // Disable while timer is active
            sx={{
              textTransform: "none",
              fontSize: isMobile
                ? "var(--neuro-font-size-smallest-x)"
                : "var(--neuro-font-size-extra-small)",
            }}
          >
            {reloader ? (
              <Spinner size={"1.25rem"} />
            ) : (
              `Resend OTP ${isTimerActive ? `(${timer})` : ""}`
            )}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AadharOtpModal;
