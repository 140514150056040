import { Box } from "@mui/material";
import DoctorAvailabilityForm from "./DoctorAvailabilityForm";
import { useNavigate } from "react-router-dom";
import useAppStore from "../../store/appStore";
import { useEffect } from "react";
import useAuthStore from "../../store/authStore";
import { isDoctorOrNetwork } from "../../utils/auth";

const DoctorAvailabilityFormPage = () => {
  // props & state values
  const {
    showDoctorSubmenus,
    selectedDoctorInList,
    setSelectedDoctorInList,
    setShowDoctorSubmenus,
  } = useAppStore();

  const navigate = useNavigate();

  const { userRole } = useAuthStore();

  // callbacks & functions
  useEffect(() => {
    if (!showDoctorSubmenus || !selectedDoctorInList) {
      if (isDoctorOrNetwork(userRole)) {
        navigate(`/dashboard`);
      }
      else {
        navigate(`/doctors`);
      }
    }
  }, [showDoctorSubmenus, selectedDoctorInList]);

  const hanleClose = () => {
    setSelectedDoctorInList(null);
    setShowDoctorSubmenus(false);
    if (isDoctorOrNetwork(userRole)) {
      navigate(`/dashboard`);
    }
    else {
      navigate(`/doctors`);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        height: "100%",
        paddingBlock: "1rem",
        paddingInline: "0.875rem",
      }}
    >
      <DoctorAvailabilityForm
        id={selectedDoctorInList?.doctorId || selectedDoctorInList?.id || ""}
        doctor={selectedDoctorInList}
        onClose={hanleClose}
      />
    </Box>
  );
};

export default DoctorAvailabilityFormPage;
