import { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid } from "@mui/material";
import Input from "../ui/Input";

import useAppStore from "../../store/appStore";
import Button from "../ui/Button";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { doctorPaymentSettinsFormValidationSchema } from "../../validations/doctorValidation";
import Checkbox from "../ui/Checkbox";
import { DoctorPaymentSettingsFormInputs } from "../../types/doctors";
import { useNavigate } from "react-router-dom";
import CustomBreadcrumbs from "../shared/CustomBreadcrumbs";
import { capitalizeFirstLetter } from "../../utils/common";
import { updateDoctorPayemntSettings } from "../../services/doctorService";

const DoctorPaymentSettinngsForm = ({
  onClose,
  id = "",
  doctor = null,
}: {
  onClose: any;
  id?: string;
  doctor?: any;
}) => {
  // props & state values
  const supportedServices = doctor?.supportedServices || [];

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch,
  } = useForm<DoctorPaymentSettingsFormInputs>({
    resolver: yupResolver(doctorPaymentSettinsFormValidationSchema),
    context: { supportedServices },
    defaultValues: {
      isPayrollEmployee: doctor ? doctor?.isPayrollEmployee || false : false,
      onlineDoctorFee: "",
      inPersonConsultationFee: "",
      inPersonDoctorFee: "",
      onlineConsultationFee: ""
    },
  });

  console.log("doctor prop in personla info", doctor);
  console.log("errors in personla info", errors);

  const { showSnackbar, selectedDoctorInList, setSelectedDoctorInList } = useAppStore();

  const naviage = useNavigate();

  const isPayrollEmployeeChecked = watch("isPayrollEmployee");

  const breadcrumbItems = [
    { label: "Doctors", href: "/doctors" },
    {
      label: `${capitalizeFirstLetter(selectedDoctorInList?.firstName || "")}`,
    },
    { label: "Payment Settings" },
  ];

  // callbacks & functions
  useEffect(() => {
    if (doctor) {
      console.log("doctor in form", doctor);
      const defaultValues = {
        isPayrollEmployee: doctor.isPayrollEmployee || false,
        onlineConsultationFee: String(doctor.onlineConsultationFee || "") || "",
        onlineDoctorFee: String(doctor.onlineDoctorFee || "") || "",
        inPersonConsultationFee: String(doctor?.inPersonConsultationFee || "") || "",
        inPersonDoctorFee: String(doctor?.inPersonDoctorFee || "") || "",
      };
      reset(defaultValues);
    }
  }, [doctor, reset]);

  const onSubmit: SubmitHandler<DoctorPaymentSettingsFormInputs> = async (
    values
  ) => {
    console.log("values on submit payment settings in form", values);
    const data = {
      doctorId: id,
      onlineConsultationFee: supportedServices.includes("ONLINE") ? values?.onlineConsultationFee ? Number(values?.onlineConsultationFee) : undefined : undefined,
      onlineDoctorFee: supportedServices.includes("ONLINE") ? values?.onlineDoctorFee ? Number(values?.onlineDoctorFee) : undefined : undefined,
      inPersonConsultationFee: supportedServices.includes("IN_PERSON") ? values?.inPersonConsultationFee ? Number(values?.inPersonConsultationFee) : undefined : undefined,
      inPersonDoctorFee: supportedServices.includes("IN_PERSON") ? values?.inPersonDoctorFee ? Number(values?.inPersonDoctorFee) : undefined : undefined,
      isPayrollEmployee: values?.isPayrollEmployee,
    };

    try {
      if (doctor) {
        console.log("doctor edit", doctor);
        try {

          const response = await updateDoctorPayemntSettings(data);
          setSelectedDoctorInList({
            ...selectedDoctorInList,
            ...response?.data?.data
          })
          showSnackbar("Payment Settings Updated Successfully", "success");
        } catch (error: any) {
          showSnackbar(`${error.response?.data.message}`, "error");
          return;
        }
      } else {
        try {
          const response = await updateDoctorPayemntSettings(data);
          setSelectedDoctorInList({
            ...selectedDoctorInList,
            ...response?.data?.data
          })
          showSnackbar("Payment Settings Added Successfully", "success");
          naviage(`/doctors/${selectedDoctorInList?.id || selectedDoctorInList?.doctorId}/consultation-settings`);
        } catch (error: any) {
          showSnackbar(`${error.response?.data.message}`, "error");
          return;
        }
      }
    } catch (error) {
      const message = doctor
        ? "Could not update payment settings, please try again"
        : "Could not add payment settings, please try again";
      showSnackbar(message, "error");
      console.error("Error saving hospital:", error);
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <CustomBreadcrumbs items={breadcrumbItems} />
          <Box
            sx={{
              display: "flex",
              gap: "1.25rem",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              className="outlined-secondary-button"
              // startIcon={<AddIcon />}
              onClick={onClose}
              sx={{
                height: "2.25rem",
              }}
            >
              Cancel
            </Button>
            <Button
              startIcon={<SaveOutlinedIcon />}
              variant="contained"
              className="success-button"
              type="submit"
              sx={{
                height: "2.25rem",
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            p: "1.75rem",
            backgroundColor: "var(--neuro-white-text)",
            borderRadius: "0.625rem",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            maxHeight: "calc(100% - 3.25rem)",
            overflow: "auto",
          }}
        >
          <Grid container spacing={2.5} alignItems="flex-start">
            <Grid item xs={12} md={4}>
              <Checkbox
                label="Payroll Employee"
                defaultChecked={
                  doctor ? doctor?.isPayrollEmployee || false : false
                }
                error={!!errors.isPayrollEmployee}
                helperText={errors?.isPayrollEmployee?.message}
                {...register("isPayrollEmployee")}
              />
            </Grid>

            {supportedServices.includes("ONLINE") && <Grid item xs={12} md={4}>
              <Input
                type="text"
                className="input-primary"
                label="Service Provider Online Fee *"
                {...register("onlineConsultationFee")}
                error={!!errors.onlineConsultationFee}
                helperText={errors.onlineConsultationFee?.message}
              />
            </Grid>}

            {supportedServices.includes("ONLINE") && <Grid item xs={12} md={4}>
              <Input
                type="text"
                className="input-primary"
                label="Doctor Online Fee *"
                {...register("onlineDoctorFee")}
                disabled={isPayrollEmployeeChecked}
                error={!!errors.onlineDoctorFee}
                helperText={errors.onlineDoctorFee?.message}
              />
            </Grid>}

            {supportedServices.includes("IN_PERSON") && <Grid item xs={12} md={4}>
              <Input
                type="text"
                className="input-primary"
                label="Service Provider In Person Fee *"
                {...register("inPersonConsultationFee")}
                error={!!errors.inPersonConsultationFee}
                helperText={errors.inPersonConsultationFee?.message}
              />
            </Grid>}

            {supportedServices.includes("IN_PERSON") && <Grid item xs={12} md={4}>
              <Input
                type="text"
                className="input-primary"
                label="Doctor In Person Fee *"
                {...register("inPersonDoctorFee")}
                disabled={Boolean(isPayrollEmployeeChecked)}
                error={!!errors.inPersonDoctorFee}
                helperText={errors.inPersonDoctorFee?.message}
              />
            </Grid>}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default DoctorPaymentSettinngsForm;
