import { Box, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAppStore from "../../store/appStore";
import { useCallback, useEffect, useState } from "react";
import DoctorWorkExperienceForm from "./DoctorWorkExperienceForm";
import Button from "../ui/Button";
import AddIcon from "@mui/icons-material/Add";
import ComponentFilter from "../shared/ComponentFilter";
import CardTableToggle from "../shared/CardTableToggle";
import ContentCenter from "../shared/ContentCenter";
import LoadingScreen from "../shared/LoadingScreen";
import { TableWrapper } from "../shared/TableWrapper";
import { capitalizeFirstLetter, getFormattedDate } from "../../utils/common";
import ConfirmModal from "../shared/ConfirmModal";
import { deleteDoctorWorkExperience, fetchDoctorWorkExperiences } from "../../services/doctorService";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import WorkExperienceCard from "./WorkExperienceCard";
import CustomBreadcrumbs from "../shared/CustomBreadcrumbs";
import useAuthStore from "../../store/authStore";
import { isDoctor, isDoctorOrNetwork } from "../../utils/auth";
import useProfileStore from "../../store/profileStore";

const DoctorWorkExperienceFormPage = () => {
    // props & state values
    const {
        showDoctorSubmenus,
        selectedDoctorInList,
        showSnackbar
    } = useAppStore();

    const { userRole, user } = useAuthStore();
    const { getProfileDetails } = useProfileStore();

    const navigate = useNavigate();

    const [showList, setShowList] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [workExperiences, setWorkExperiences] = useState<any[]>([]);
    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
    const [selectedExperience, setSelectedExperience] = useState<any>(null);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    // Initialize view from localStorage or default to "table"
    const [view, setView] = useState(
        () => localStorage.getItem("workExperienceView") || "table"
    );

    const breadcrumbItems = [
        ...(!isDoctorOrNetwork(userRole)
            ? [{ label: "Doctors", href: "/doctors" }]
            : []),
        {
            label: `${capitalizeFirstLetter(selectedDoctorInList?.firstName || selectedDoctorInList?.user?.firstName || "")}`,
        },
        { label: "Work Experience" },
    ];


    // callbacks & functions
    useEffect(() => {
        if (!showDoctorSubmenus || !selectedDoctorInList) {
            if (isDoctorOrNetwork(userRole)) {
                navigate(`/dashboard`);
            }
            else {
                navigate(`/doctors`);
            }
        }
    }, [showDoctorSubmenus, selectedDoctorInList]);

    const loadWorkExperiences = async (id: string) => {
        try {
            const response = await fetchDoctorWorkExperiences(id);
            setWorkExperiences(response?.data?.data || [])
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadWorkExperiences(selectedDoctorInList?.id || selectedDoctorInList?.doctorId || "");
    }, [selectedDoctorInList])

    const handleToggleView = useCallback((newView: string) => {
        setView((prevView) => {
            const updatedView = prevView !== newView ? newView : prevView;
            localStorage.setItem("workExperienceView", updatedView); // Store updated view in localStorage
            return updatedView;
        });
    }, []);

    const handleAddNew = () => {
        setShowList(false);
        setIsEdit(false);
        setSelectedExperience(null);
    };

    const handleEdit = (qualification: any) => {
        setSelectedExperience(qualification);
        setIsEdit(true);
        setShowList(false);
    };

    const handleDelete = async (row: any) => {
        setSelectedExperience(row);
        setConfirmOpen(true);
    };

    const handleConfirm = async () => {
        if (selectedExperience?.id) {
            try {
                await deleteDoctorWorkExperience(selectedExperience?.id || "");
                showSnackbar("Work expereince deleted", "success");
                loadWorkExperiences(selectedDoctorInList?.id || selectedDoctorInList?.doctorId || "");
                setSelectedExperience(null);
                setConfirmOpen(false);
            }
            catch (error) {
                showSnackbar("Work expereince  could not be deleted, please try again", "error");
            }

        } else {
            showSnackbar("Work expereince  could not be deleted, please try again", "error");
        }
    }

    const handleCancel = () => {
        setConfirmOpen(false);
        setSelectedExperience(null);
    };

    const getProifileInfo = async () => {
        const doctorId = user?.doctorId;

        if (doctorId && isDoctor(userRole)) {
            await getProfileDetails(doctorId, "DOCTOR");
        }
        else {
            await getProfileDetails();
        }
    }

    const handleAfterChanges = () => {
        setSelectedExperience(null);
        loadWorkExperiences(selectedDoctorInList?.id || selectedDoctorInList?.doctorId || "");
        if (isDoctorOrNetwork(userRole)) {
            getProifileInfo();
        }
        setShowList(true);
    }

    const handleCancelAddEdit = () => {
        setSelectedExperience(null);
        setShowList(true);
    }

    const columns = [
        {
            id: "clinicName",
            label: "Clinic Name",
            sortable: true,
            render: (value: any) => `${capitalizeFirstLetter(value || "-")}`,
        },
        {
            id: "location",
            label: "Location",
            sortable: true,
            render: (value: any) => `${capitalizeFirstLetter(value || "-")}`,
        },
        {
            id: "clinicDetails",
            label: "Clinic Details",
            sortable: false,
            render: (value: any) => `${value || "-"}`,
        },
        {
            id: "designation",
            label: "Designation",
            sortable: true,
            render: (value: any) => `${capitalizeFirstLetter(value || "-")}`,
        },
        {
            id: "specialization",
            label: "Specialization",
            sortable: true,
            render: (value: any) => `${capitalizeFirstLetter(value || "-")}`,
        },
        {
            id: "startDate",
            label: "Start Date",
            sortable: true,
            render: (value: any) => `${getFormattedDate(value) || "-"}`,
            sortComparator: (a: any, b: any) => new Date(a).getTime() - new Date(b).getTime(),
        },
        {
            id: "endDate",
            label: "End Date",
            sortable: true,
            render: (value: any) => `${getFormattedDate(value) || "-"}`,
            sortComparator: (a: any, b: any) => new Date(a).getTime() - new Date(b).getTime(),
        },
        {
            id: "isCurrent",
            label: "Current?",
            sortable: true,
            render: (value: any) => (value ? "Yes" : "No"),
        },
        {
            id: "actions",
            label: "Actions",
            render: (_: any, row: any) => (
                <Box display="flex" justifyContent="space-around">
                    <IconButton
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            event.stopPropagation();
                            handleEdit(row)
                        }
                        }
                        color="secondary"
                        disableRipple
                        sx={{
                            p: 0,
                            "&:hover": { backgroundColor: "transparent" },
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            event.stopPropagation();
                            handleDelete(row);
                        }}
                        color="primary"
                        disableRipple
                        sx={{
                            p: 0,
                            "&:hover": { backgroundColor: "transparent" },
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            ),
        },
    ]

    return (
        <Box
            sx={{
                backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                height: "100%",
                paddingBlock: "1rem",
                paddingInline: "0.875rem",
            }}
        >
            {showList && (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "1rem",
                        }}
                    >
                        <CustomBreadcrumbs items={breadcrumbItems} />

                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1.5rem",
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="secondary"
                                className="outlined-secondary-button"
                                startIcon={<AddIcon />}
                                onClick={handleAddNew}
                            >
                                Add New
                            </Button>
                        </Box>
                    </Box>

                    <Box sx={{ marginBottom: "1rem" }}>
                        <ComponentFilter
                            isEmpty
                            hideSearchFilter
                            hideStatusFilter
                            hideRangeFilter
                            viewComponent={
                                <CardTableToggle view={view} setView={handleToggleView} />
                            }
                        />
                    </Box>

                    <Box
                        sx={{
                            height: "calc(100% - 7rem)",
                        }}
                    >
                        {loading ? (
                            <ContentCenter>
                                <LoadingScreen />
                            </ContentCenter>
                        ) :

                            workExperiences && workExperiences?.length > 0 ? (
                                view === "card" ? (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "1rem",
                                            maxHeight: "100%",
                                            overflow: "auto",
                                        }}
                                    >
                                        {workExperiences?.map((workExperience) => (
                                            <WorkExperienceCard
                                                handleEdit={handleEdit}
                                                expereicne={workExperience}
                                                handleDelete={handleDelete}
                                            />
                                        ))}
                                    </Box>
                                ) : (
                                    <TableWrapper
                                        columns={columns}
                                        rows={workExperiences}
                                        stickyHeader={true}
                                        maxHeight="100%"
                                        onRowClick={handleEdit}
                                        rowSx={{
                                            cursor: "pointer",
                                        }}
                                    />
                                )
                            )
                                : (
                                    <ContentCenter>
                                        <Typography className="no-data">No work experience found</Typography>
                                    </ContentCenter>
                                )}
                    </Box>

                    <ConfirmModal
                        open={confirmOpen}
                        description="Are you sure, you want to delete this experience?"
                        handleConfirm={handleConfirm}
                        handleCancel={handleCancel}
                    />
                </>
            )}

            {!showList &&
                <DoctorWorkExperienceForm
                    id={selectedDoctorInList?.doctorId || selectedDoctorInList?.id || ""}
                    selectedExperience={isEdit ? selectedExperience : null}
                    handleAfterChanges={handleAfterChanges}
                    onClose={handleCancelAddEdit}
                />
            }
        </Box>
    );
};

export default DoctorWorkExperienceFormPage;
