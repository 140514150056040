// third-party imports
import { Box, Typography } from "@mui/material";

// project imports
import AfternoonIcon from "../svg/AfternoonIcon";
import InjectionIcon from "../svg/InjectionIcon";
import MorningIcon from "../svg/MorningIcon";
import NightIcon from "../svg/NightIcon";
import TabletIcon from "../svg/TabletIcon";
import MedicationLiquidIcon from "@mui/icons-material/MedicationLiquid";
import OintmentsIcon from "../svg/OintmentsIcon";
import CapsuleIcon from "../svg/CapsuleIcon";
import Brightness6Icon from "@mui/icons-material/Brightness6";

const MedicationCard = ({ data }: { data: any }) => {
    //props & state values
    const {
        // ppointmentId,
        // createdAt,
        // createdBy,
        // dosageAmount,
        // dosageUnit,
        durationAmount,
        durationUnit,
        frequency,
        // IntegrationInstructions,
        medication,
        medicationType,
        // prescriptionId,
        // updateData,
        // updatedBy
    } = data;

    // callbacks & functions
    const doseTimePerDay = (doseQuantityPerDay: string): string => {
        if (doseQuantityPerDay.includes("ONCE_A_DAY")) {
            return "a day";
        } else if (doseQuantityPerDay === "TWICE_A_DAY") {
            return "twice a day";
        } else if (doseQuantityPerDay === "FOUR_TIMES_A_DAY") {
            return "four times a day";
        } else {
            return `${doseQuantityPerDay?.split("_")[0] ? doseQuantityPerDay?.split("_")[0] === "ONCE" ? "one" : doseQuantityPerDay?.split("_")[0] : ""} times a day`;
        }
    };

    const medicationIcon = (type: string) => {
        if (type === "TABLET") {
            return <TabletIcon />
        }
        if (type === "INJECTION") {
            return <InjectionIcon />
        }
        if (type === "SYRUP") {
            return <MedicationLiquidIcon sx={{ fontSize: "36px", color: "var(--neuro-bg-primary-seagreen)" }} />
        }
        if (type === "OINTMENT") {
            return <OintmentsIcon />
        }
        if (type === "CAPSULE") {
            return <CapsuleIcon height={36} width={36} />
        }
        else {
            return <TabletIcon />
        }
    }

    const medicationFrequencyIcons = (type: string) => {
        if (type === "ONCE_A_DAY_MORNING") {
            return <MorningIcon />
        }
        if (type === "ONCE_A_DAY_AFTERNOON") {
            return <AfternoonIcon />
        }
        if (type === "ONCE_A_DAY_NIGHT") {
            return <NightIcon />
        }
        if (type === "TWICE_A_DAY") {
            return (
                <>
                    <MorningIcon />
                    <NightIcon />
                </>
            )
        }
        if (type === "THREE_TIMES_A_DAY") {
            return (
                <>
                    <MorningIcon />
                    <AfternoonIcon />
                    <NightIcon />
                </>
            )
        }
        if (type === "FOUR_TIMES_A_DAY") {
            return (
                <>
                    <MorningIcon />
                    <AfternoonIcon />
                    <Brightness6Icon
                        sx={{
                            fontSize: "var(--neuro-font-size-smallest)",
                            color: "var(--neuro-button-bg-success)",
                        }}
                    />
                    <NightIcon />
                </>
            )
        }
        else {
            return "As Needed"
        }
    }

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                background:
                    "linear-gradient(to right, rgba(244, 244, 244, 1), rgba(255, 255, 255, 1))",
                borderRadius: "0.75rem",
                padding: "0.75rem 1.125rem 0.75rem 1.125rem",
            }}
        >
            <Box sx={{ display: "flex", gap: "0.938rem" }}>
                {medicationIcon(medicationType)}
                <Box sx={{ display: "flex", flexDirection: "column", gap: "0.063rem" }}>
                    <Typography
                        sx={{
                            fontWeight: "var(--neuro-font-weight-bold)",
                            color: "var(--neuro-black-text)",
                            lineHeight: "120%",
                        }}
                    >
                        {medication || ""}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: "var(--neuro-font-size-x-small)",
                            color: "var(--neuro-darkgrey_border)",
                            lineHeight: "120%",
                            fontFamily: "var(--neuro-font-family-roboto-slab)",
                        }}
                    >
                        1 {medicationType ? medicationType.toLowerCase() : ""} {doseTimePerDay(frequency)} {`(${durationAmount || ""} ${durationUnit ? durationUnit?.toLowerCase() : ""})`}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", width: "max-content", gap: "0.938rem" }}>
                {medicationFrequencyIcons(frequency)}
            </Box>
        </Box>
    );
};

export default MedicationCard;
