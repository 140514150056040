import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Documents from "../documents/list";
// import ReportViewer from "../documents/view/ReportViewer";
import SingleReportViewer from "../documents/view/SingleReportViewer";
import useAuthStore from "../../store/authStore";
import { isDoctorOrAdminOrClinicAdmin } from "../../utils/auth";
import useAppStore from "../../store/appStore";
import NewReportViewer from "../documents/view/NewReportViewer";

const NewPatientDocuments = () => {
  // props & state values
  const navigate = useNavigate();

  const location = useLocation();

  console.log("location in new patient documents", location)

  const { userRole } = useAuthStore();

  const { selectedPatientInList } = useAppStore();

  console.log("selectedPatientInList in new patient documents", selectedPatientInList);

  // callbacks & functions
  useEffect(() => {
    if (!selectedPatientInList) {
      if (isDoctorOrAdminOrClinicAdmin(userRole)) {
        navigate("/patients");
      } else {
        navigate("/appointments");
      }
    }
  }, [selectedPatientInList]);

  return (
    <>
      {location?.pathname.includes("view") ? (
        <NewReportViewer patientId={selectedPatientInList?.id} />
      ) : location?.pathname.includes("details") ? (
        <SingleReportViewer />
      ) : (
        <Documents patientId={selectedPatientInList?.id} from="patient-list" />
      )}
    </>
  );
};

export default NewPatientDocuments;
