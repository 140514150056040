import React from "react";
import { Box, } from "@mui/material";

interface ContentModalProps {
    open: boolean;
    children: React.ReactNode;
}

const ContentModal: React.FC<ContentModalProps> = ({
    open,
    children,
}) => {
    if (!open) return null; // Render nothing if modal is not open

    return (
        <Box
            sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "var(--neuro-white-text)",
                boxShadow: 3,
                zIndex: 1200,
                overflow: "hidden",
                height: "100%"
            }}
        >
            {/* Modal Content */}
            <Box sx={{
                height: "100%"
            }}>{children}</Box>
        </Box>
    );
};

export default ContentModal;
