import React, { MouseEvent, useState } from "react";

import { Box, IconButton, Menu, MenuItem, useMediaQuery } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "../ui/Button";
import UploadFileModal from "../documents/view/UploadFileModal";
import { documentCategories } from "../../utils/common";
import Drawer from "../shared/Drawer";
import AllFormsList from "./AllFormsList";
import { assignFormApi } from "../../services/formService";
import useAppStore from "../../store/appStore";

import { useNavigate } from "react-router-dom";
import { isClinicAdminOrAdmin } from "../../utils/auth";
import useAuthStore from "../../store/authStore";

interface ReferralsTableActionsProps {
    handleMenuOpen: any;
    anchorEl: any;
    handleMenuClose: any;
    row: any;
}

const PatientTableActions: React.FC<ReferralsTableActionsProps> = ({
    handleMenuOpen,
    anchorEl,
    handleMenuClose,
    row,
}) => {
    // props & state values
    const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
    const [selectedPatientId, setSelectedPatientId] = useState<any>(null);
    const [formDrawerOpen, setFormDrawerOpen] = useState<boolean>(false);
    const [selectedForm, setSelectedForm] = useState<any>(null);

    const navigate = useNavigate();

    const { showSnackbar, setSelectedPatientInList, setDrawerOpen, setIsMenuCollapsed } = useAppStore();

    const isMobile = useMediaQuery("(max-width:37.5rem)");

    const { userRole } = useAuthStore();

    // callbacks & functions
    const handleCancel = () => {
        setSelectedPatientId(null);
        setOpenUploadModal(false);
        handleMenuClose();
    };

    const handleFormAssign = async (data: any) => {
        try {
            const response = await assignFormApi(data);
            if (response?.data) {
                showSnackbar("Form assigned successfully", "success");
                assignFormApi(data);
                setSelectedPatientId(null);
                setSelectedForm(null);
                setFormDrawerOpen(false);
                handleMenuClose();
            }
        } catch (error) {
            showSnackbar("Could not assign form, please try again", "error");
        }
    }

    return (
        <div>
            <IconButton
                onClick={(event) => {
                    event.stopPropagation();
                    handleMenuOpen(event);
                }}
            >
                <MoreVertIcon sx={{ color: "var(--neuro-black-text)" }} />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={(event: any) => {
                    event.stopPropagation();
                    handleMenuClose();
                }}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <MenuItem
                    onClick={(event: any) => {
                        event.stopPropagation();
                        setSelectedPatientId(row?.id);
                        setOpenUploadModal(true);
                    }}
                >
                    Upload Document
                </MenuItem>

                {isClinicAdminOrAdmin(userRole) && <MenuItem
                    onClick={(event: any) => {
                        event.stopPropagation();
                        setSelectedPatientInList(row);
                        if (isMobile) {
                            setDrawerOpen(false);
                        } else {
                            setIsMenuCollapsed(true);
                        }
                        navigate(`/patients/${row?.id}/appointments/book`);
                    }}
                >
                    Book Appointment
                </MenuItem>}

                <MenuItem
                    onClick={(event: any) => {
                        event.stopPropagation();
                        setSelectedPatientId(row?.id);
                        setFormDrawerOpen(true);
                        handleMenuClose();
                    }}
                >
                    Assign Form
                </MenuItem>
            </Menu>

            {selectedPatientId === row?.id && <UploadFileModal
                open={openUploadModal}
                onClose={handleCancel}
                categories={documentCategories}
                closeIcon
                patientId={row?.id}
                from="patient-table"
            />}

            {selectedPatientId === row?.id && <Drawer
                open={formDrawerOpen}
                width={isMobile ? "100vw" : "45vw"}
                headerText={row?.user?.firstName || ""}
                onClose={(event: any) => {
                    event.stopPropagation();
                    setFormDrawerOpen(false);
                    setSelectedForm(null);
                    setSelectedPatientId(null);
                }}
            >
                <Box
                    sx={{
                        paddingInline: "1rem",
                        paddingTop: "1.25rem",
                        height: "calc(100% - 3.75rem)",
                        overflowY: "hidden",
                    }}
                    onClick={(event: MouseEvent<HTMLDivElement>) => event.stopPropagation()}
                >
                    <Box sx={{ height: "100%" }}>
                        <AllFormsList
                            selectedForm={selectedForm}
                            setSelectedForm={setSelectedForm}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                my: "1rem"
                            }}
                        >
                            <Button
                                color="success"
                                variant="contained"
                                className="success-button"
                                disabled={!selectedForm}
                                sx={{ marginRight: "0.625rem", height: "2.25rem" }}
                                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                    event.stopPropagation();
                                    const data = {
                                        patientId: row?.id,
                                        formId: selectedForm?.id,
                                    };
                                    handleFormAssign(data);
                                }}
                            >
                                Assign Form
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Drawer>}
        </div>
    );
};

export default PatientTableActions;
