import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface BreadcrumbItem {
  label: string;
  href?: string;
}

interface CustomBreadcrumbsProps {
  items: BreadcrumbItem[] | any[];
}

const CustomBreadcrumbs: React.FC<CustomBreadcrumbsProps> = ({ items }) => {
  // props & state values
  const navigate = useNavigate();

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {items && items.length > 0 && items.filter((item) => item !== null).map((item, index) =>
        item.href ? (
          <Link
            underline="none"
            className="page-heading"
            // href={item.href}
            key={index}
            sx={{ cursor: "pointer" }}
            onClick={() => navigate(item?.href || "/dashboard")}
          >
            {item.label}
          </Link>
        ) : (
          <Typography
            className="page-heading"
            key={index}
            sx={{ color: "var(--neuro-darkgrey_border) !important" }}
          >
            {item.label}
          </Typography>
        )
      )}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
