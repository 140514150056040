import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { inputLabelStyles } from "../../../utils/styles";

const DocumentFeedback = ({
  feedback,
  feebackUpdateHandler,
  id,
}: {
  feedback: any;
  feebackUpdateHandler: any;
  id: string;
}) => {
  // props & state values
  const [feedbackValue, setFeedbackValue] = useState<string>(
    feedback ? feedback : ""
  );

  // callbacks & functions
  useEffect(() => {
    setFeedbackValue(feedback ? feedback : "");
  }, [feedback]);

  const handleFeedbackChange = (event: any) => {
    setFeedbackValue(event.target.value);
  };

  // feedback onBlur
  const handleFeebackBlur = () => {
    const checkvalue = feedback ? feedback : "";
    if (feedbackValue.trim() !== checkvalue) {
      const value = feedbackValue.trim();
      feebackUpdateHandler(id, value);
    }
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem", width: "100%", marginBlock: "1em" }}>
      <Typography sx={inputLabelStyles}>Feedback on the summary to enhance its quality</Typography>
      <textarea onChange={handleFeedbackChange} onBlur={handleFeebackBlur} value={feedbackValue} className="styled-textarea" rows={3}></textarea>
    </Box>
  );
};

export default DocumentFeedback;
