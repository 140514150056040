import { useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import Button from "../ui/Button";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { erroMessageStyles, inputLabelStyles } from "../../utils/styles";
import { DateTime } from "luxon";
import { addDoctorLeaveSetting, confirmLeaveSetting, updateDoctorLeaveSetting } from "../../services/doctorService";
import { doctorLeaveSettingsFormValidationSchema } from "../../validations/doctorValidation";
import { DoctorLeaveSettingsFormInputs } from "../../types/doctors";
import useAppStore from "../../store/appStore";
import CustomBreadcrumbs from "../shared/CustomBreadcrumbs";
import { capitalizeFirstLetter } from "../../utils/common";
import LeaveSettingConfirmModal from "./LeaveSettingConfirmModal";
import useAuthStore from "../../store/authStore";
import { isDoctorOrNetwork } from "../../utils/auth";

const DoctorLeaveSettingsForm = ({
    onClose,
    handleAfterChanges,
    id = "",
    selectedLeave = null,
}: {
    onClose: () => void;
    selectedLeave?: any;
    id?: string;
    handleAfterChanges?: any;
}) => {
    // props & state values
    const {
        handleSubmit,
        control,
        reset,
    } = useForm<DoctorLeaveSettingsFormInputs>({
        resolver: yupResolver(doctorLeaveSettingsFormValidationSchema),
        defaultValues: {
            startDate: null,
            endDate: null,
        },
    });

    const { showSnackbar, selectedDoctorInList } = useAppStore();

    const { userRole } = useAuthStore();

    const [leaveSettingModalOpen, setLeaveSettingModalOpen] = useState<boolean>(false);
    const [appointmentCount, setAppointmentCount] = useState<number>(0)
    const [appointmentsInLeave, setAppointmentsInLeave] = useState<any[]>([]);
    const [startDateUtc, setStartDateUtc] = useState<any>(null);
    const [endDateUtc, setEndDateUtc] = useState<any>(null);

    console.log("appointmentsInLeave", appointmentsInLeave);

    const handleCloseLeaveSettingModal = () => {
        setLeaveSettingModalOpen(false);
    }

    const breadcrumbItems = [
        ...(!isDoctorOrNetwork(userRole)
            ? [{ label: "Doctors", href: "/doctors" }]
            : []),
        {
            label: `${capitalizeFirstLetter(selectedDoctorInList?.firstName || selectedDoctorInList?.user?.firstName || "")}`,
        },
        { label: "Leave Settings" },
    ];

    const newBreadcrumbItem =
        !selectedLeave
            ? { label: "New" }
            : null;

    const updatedBreadcrumbItems = [
        ...breadcrumbItems.slice(0, 2), // get the first 2 items
        newBreadcrumbItem, // add the new item
        ...breadcrumbItems.slice(2), // get the rest of the items
    ].filter((item) => item !== null);

    // callbacks & functions
    useEffect(() => {
        if (selectedLeave) {
            const startDate = selectedLeave?.startDate
                ? DateTime.fromISO(selectedLeave?.startDate)
                : null;
            const endDate = selectedLeave?.endDate
                ? DateTime.fromISO(selectedLeave?.endDate)
                : null;
            const defaultValues = {
                startDate: startDate,
                endDate: endDate
            };
            reset(defaultValues);
        }
    }, [reset, selectedLeave]);

    useEffect(() => {
        if (appointmentCount > 0) {
            setLeaveSettingModalOpen(true);
        }
    }, [appointmentCount])

    const onSubmit: SubmitHandler<DoctorLeaveSettingsFormInputs> = async (values) => {
        console.log("values on submit personal", values);
        const startDateValue = new Date(values.startDate);
        const endDateValue = new Date(values.endDate);

        // Add 5 hours and 30 minutes
        startDateValue.setHours(startDateValue.getHours() + 5);
        startDateValue.setMinutes(startDateValue.getMinutes() + 30);
        endDateValue.setHours(endDateValue.getHours() + 5);
        endDateValue.setMinutes(endDateValue.getMinutes() + 30);

        // Convert back to ISO string
        const updatedStartDateValue = startDateValue.toISOString();
        const updatedEndDateValue = endDateValue.toISOString();
        setStartDateUtc(updatedStartDateValue);
        setEndDateUtc(updatedEndDateValue);

        const data = {
            ...values,
            doctorId: id,
            startDate: updatedStartDateValue,
            endDate: updatedEndDateValue,
        };


        try {
            if (selectedLeave) {
                console.log("selectedLeave edit", selectedLeave);
                try {
                    await updateDoctorLeaveSetting(selectedLeave?.id, data);
                    showSnackbar("Leave Settings Updated Successfully", "success");
                    if (handleAfterChanges) {
                        handleAfterChanges();
                    }
                } catch (error: any) {
                    showSnackbar(`${error.response?.data.message}`, "error");
                    console.log("error in leave", error);
                    if (error?.response?.status && error?.response?.status === 409) {
                        const count = error?.response?.data?.conflicts?.totalConflicts || 0;
                        const appointments = error?.response?.data?.conflicts?.details || [];
                        setAppointmentCount(count);
                        setAppointmentsInLeave(appointments);
                    }
                    return;
                }
            } else {
                try {
                    await addDoctorLeaveSetting(data);
                    showSnackbar("Leave Settings Added Successfully", "success");
                    if (handleAfterChanges) {
                        handleAfterChanges();
                    }
                } catch (error: any) {
                    showSnackbar(`${error?.response?.data?.message || "Something went wrong"}`, "error");
                    console.log("error in leave", error);
                    if (error?.response?.status && error?.response?.status === 409) {
                        const count = error?.response?.data?.appointments?.totalCount || 0;
                        const appointments = error?.response?.data?.appointments?.details || [];
                        setAppointmentCount(count);
                        setAppointmentsInLeave(appointments);
                    }
                    return;
                }
            }
        } catch (error) {
            const message = selectedLeave
                ? "Could not update leave settings, please try again"
                : "Could not add leave settings, please try again";
            showSnackbar(message, "error");
            console.error("Error saving hospital:", error);
        }
    };

    const handleConfirmLeaveSetting = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: any) => {
        event.stopPropagation();
        try {
            await confirmLeaveSetting(data);
            showSnackbar(`Leave Settings ${selectedLeave ? "Updated" : "Added"} Successfully`, "success");
            handleCloseLeaveSettingModal();
            setAppointmentCount(0);
            setAppointmentsInLeave([]);
            if (handleAfterChanges) {
                handleAfterChanges();
            }
        }
        catch (error: any) {
            showSnackbar(`${error?.response?.data?.message || "Something went wrong"}`, "error");
        }
    }

    return (
        <Box
            sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
            }}
        >
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    height: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <CustomBreadcrumbs items={updatedBreadcrumbItems} />

                    <Box sx={{ display: "flex", gap: "1rem" }}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            className="outlined-secondary-button"
                            onClick={onClose}
                            sx={{
                                height: "2.25rem",
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            startIcon={<SaveOutlinedIcon />}
                            variant="contained"
                            className="success-button"
                            type="submit"
                            sx={{
                                height: "2.25rem",
                            }}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>

                <Box
                    sx={{
                        p: "1.5rem",
                        backgroundColor: "var(--neuro-white-text)",
                        borderRadius: "0.625rem",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1.5rem",
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="startDate"
                                control={control}
                                defaultValue={undefined}
                                render={({ field, fieldState: { error } }) => (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "0.375rem",
                                            width: "100%",
                                        }}
                                    >
                                        <Typography sx={inputLabelStyles}>Start Date *</Typography>
                                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                                            <DatePicker
                                                value={field.value || null}
                                                onChange={(date) => {
                                                    field.onChange(date);
                                                }}
                                                format="dd/MM/yyyy"
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        placeholder: "From (DD/MM/YYYY)",
                                                        sx: {
                                                            "& .MuiOutlinedInput-root": {
                                                                height: "2.5rem",
                                                                borderRadius: "0.625rem",
                                                                backgroundColor:
                                                                    "var(--neuro-bg-light-grey-secondary)",
                                                                borderColor:
                                                                    "var(--neuro-bg-light-grey-secondary)",
                                                                "& >fieldset": {
                                                                    borderColor:
                                                                        "var(--neuro-bg-light-grey-secondary)",
                                                                },
                                                                "& >fieldset:hover": {
                                                                    borderColor:
                                                                        "var(--neuro-bg-light-grey-secondary)",
                                                                },
                                                                "& >fieldset:active": {
                                                                    borderColor:
                                                                        "var(--neuro-bg-light-grey-secondary)",
                                                                },
                                                                "& >fieldset:focus": {
                                                                    borderColor:
                                                                        "var(--neuro-bg-light-grey-secondary)",
                                                                },
                                                                "& >.Mui-focoused.MuiOutlinedInput-notchedOutline":
                                                                {
                                                                    border:
                                                                        "1px solid var(--neuro-bg-light-grey-secondary)",
                                                                },
                                                                "& .MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root>.MuiOutlinedInput-notchedOutline":
                                                                {
                                                                    border:
                                                                        "1px solid var(--neuro-bg-light-grey-secondary)",
                                                                },
                                                            },
                                                        },
                                                    },
                                                }}
                                                sx={{
                                                    "& .MuiFormControl-root": {
                                                        width: "100%",
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>

                                        {error && (
                                            <Typography
                                                sx={erroMessageStyles}
                                            >
                                                {error.message}
                                            </Typography>
                                        )}
                                    </Box>
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Controller
                                name="endDate"
                                control={control}
                                defaultValue={undefined}
                                render={({ field, fieldState: { error } }) => (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "0.375rem",
                                            width: "100%",
                                        }}
                                    >
                                        <Typography sx={inputLabelStyles}>End Date *</Typography>
                                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                                            <DatePicker
                                                value={field.value || null}
                                                onChange={(date) => {
                                                    field.onChange(date);
                                                }}
                                                format="dd/MM/yyyy"
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        placeholder: "To (DD/MM/YYYY)",
                                                        sx: {
                                                            "& .MuiOutlinedInput-root": {
                                                                height: "2.5rem",
                                                                borderRadius: "0.625rem",
                                                                backgroundColor:
                                                                    "var(--neuro-bg-light-grey-secondary)",
                                                                borderColor:
                                                                    "var(--neuro-bg-light-grey-secondary)",
                                                                "& >fieldset": {
                                                                    borderColor:
                                                                        "var(--neuro-bg-light-grey-secondary)",
                                                                },
                                                                "& >fieldset:hover": {
                                                                    borderColor:
                                                                        "var(--neuro-bg-light-grey-secondary)",
                                                                },
                                                                "& >fieldset:active": {
                                                                    borderColor:
                                                                        "var(--neuro-bg-light-grey-secondary)",
                                                                },
                                                                "& >fieldset:focus": {
                                                                    borderColor:
                                                                        "var(--neuro-bg-light-grey-secondary)",
                                                                },
                                                                "& >.Mui-focoused.MuiOutlinedInput-notchedOutline":
                                                                {
                                                                    border:
                                                                        "1px solid var(--neuro-bg-light-grey-secondary)",
                                                                },
                                                                "& .MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root>.MuiOutlinedInput-notchedOutline":
                                                                {
                                                                    border:
                                                                        "1px solid var(--neuro-bg-light-grey-secondary)",
                                                                },
                                                            },
                                                        },
                                                    },
                                                }}
                                                sx={{
                                                    "& .MuiFormControl-root": {
                                                        width: "100%",
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>

                                        {error && (
                                            <Typography
                                                sx={erroMessageStyles}
                                            >
                                                {error.message}
                                            </Typography>
                                        )}
                                    </Box>
                                )}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <LeaveSettingConfirmModal open={leaveSettingModalOpen} handleCancel={handleCloseLeaveSettingModal} description={`There are ${appointmentCount} appointments in this date range, are you sure to take leave?`} width="35rem">
                <Box sx={{ display: "flex", alignItems: "center", gap: "0.938rem", flexWrap: "wrap", justifyContent: "center" }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        className="secondary-button"
                        onClick={(
                            event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                        ) => {
                            event.stopPropagation();
                            handleCloseLeaveSettingModal();
                            setAppointmentCount(0);
                        }}
                        sx={{
                            textTransform: "none",
                        }}
                    >
                        Discard
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        className="primary-button"
                        onClick={(
                            event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                        ) => {
                            // api call to confirm appointments
                            const appointmentsIdArray = appointmentsInLeave?.map((appointment) => appointment?.appointmentId);
                            const data = {
                                cancel: false,
                                doctorId: id,
                                startDate: startDateUtc,
                                endDate: endDateUtc,
                                appointmentIds: appointmentsIdArray
                            }
                            handleConfirmLeaveSetting(event, data);
                        }}
                        sx={{
                            textTransform: "none",
                        }}
                    >
                        {`Accept ${appointmentCount > 1 ? "Appointments" : "Appointment"} And Take Leave`}
                    </Button>

                    <Button
                        variant="contained"
                        className="warning-button"
                        onClick={(
                            event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                        ) => {
                            const appointmentsIdArray = appointmentsInLeave?.map((appointment) => appointment?.appointmentId);
                            const data = {
                                cancel: true,
                                doctorId: id,
                                startDate: startDateUtc,
                                endDate: endDateUtc,
                                appointmentIds: appointmentsIdArray
                            }
                            handleConfirmLeaveSetting(event, data);
                        }}
                        sx={{
                            textTransform: "none",
                        }}
                    >
                        {`Cancel ${appointmentCount > 1 ? "Appointments" : "Appointment"} And Take Leave`}
                    </Button>
                </Box>
            </LeaveSettingConfirmModal>
        </Box>
    );
};

export default DoctorLeaveSettingsForm;
