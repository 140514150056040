import { ADMIN, CLINIC_ADMIN, DOCTOR, NETWORK, PATIENT } from "./constants";

export const isDoctorOrAdmin = (role: string | null): boolean => {
  return role === DOCTOR || role === ADMIN;
};

export const isDoctorOrAdminOrClinicAdmin = (role: string | null): boolean => {
  return role === DOCTOR || role === ADMIN || role === CLINIC_ADMIN;
};

export const isDoctorOrAdminOrClinicAdminOrNetwork = (
  role: string | null
): boolean => {
  return (
    role === DOCTOR ||
    role === ADMIN ||
    role === CLINIC_ADMIN ||
    role === NETWORK
  );
};

export const isAdminOrClinicAdminOrNetwork = (role: string | null): boolean => {
  return role === ADMIN || role === CLINIC_ADMIN || role === NETWORK;
};

export const isDoctor = (role: string | null): boolean => {
  return role === DOCTOR;
};

export const isAdmin = (role: string | null): boolean => {
  return role === ADMIN;
};

export const isPatient = (role: string | null): boolean => {
  return role === PATIENT;
};

export const isNetwork = (role: string | null): boolean => {
  return role === NETWORK;
};

export const isClinicAdmin = (role: string | null): boolean => {
  return role === CLINIC_ADMIN;
};

export const isClinicAdminOrAdmin = (role: string | null): boolean => {
  return role === CLINIC_ADMIN || role === ADMIN;
};

export const isPatientOrClinicAdminOrAdmin = (role: string | null): boolean => {
  return role === CLINIC_ADMIN || role === ADMIN || role === PATIENT;
};

export const isDoctorOrNetwork = (role: string | null): boolean => {
  return role === NETWORK || role === DOCTOR;

}
