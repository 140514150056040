import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import Modal from "../../ui/Modal";
import Input from "../../ui/Input";
import Spinner from "../../ui/Spinner";
// import useAppStore from "../../../store/appStore";
// import { sendOtp } from "../../../services/authService";
// import axios from "axios";
// import OtpModal from "../../auth/OtpModal";
// import useAuthStore from "../../../store/authStore";

// Define validation schema for Yup
const schema = yup.object().shape({
  aadharNumber: yup.string().required("Aadhaar number is required"),
});

interface FormInputs {
  aadharNumber: string;
}

const AddAbhaAccountModal: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose, ...props }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const isMobile = useMediaQuery("(max-width:37.5rem)");
  // const { showSnackbar } = useAppStore();
  const [loading, setLoading] = useState(false);
  // const [otpToken, setOtpToken] = useState("");

  // const [txnId, setTxnId] = useState(null);
  // const [message, setMessage] = useState("");
  // const [email, setEmail] = useState("");
  // const [otpOpen, setOtpOpen] = useState(false);
  // const { user } = useAuthStore();

  // callbacks & functions
  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    setLoading(true)
    console.log(data);
    // setLoading(true);
    // setTxnId(null);
    // try {
    //   const payloadData = {
    //     emailOrMobileOrAadhaarOrAbhaNumber: data.aadharNumber || "",
    //   };
    //   const result = await sendOtp(payloadData);

    //   if (result?.message) {
    //     setMessage(result?.message);
    //   }

    //   setEmail(data.aadharNumber);
    //   setOtpToken(result.otpToken);
    //   if (result.txnId) {
    //     setTxnId(result.txnId);
    //   }
    //   setOtpOpen(true);
    //   onClose();
    // } catch (error: any) {
    //   if (axios.isAxiosError(error) && error.response) {
    //     if (error?.response?.status === 403) {
    //       showSnackbar(
    //         "Your account has been deactivated. Please contact the administrator for assistance.",
    //         "error"
    //       );
    //     } else {
    //       showSnackbar("Could not send OTP, Please try again", "error");
    //     }
    //   } else {
    //     showSnackbar("Could not send OTP, Please try again", "error");
    //   }
    // }
    // setLoading(false);
  };

  // const onCloseOtp = () => {
  //   setOtpOpen(false);
  // };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        closeIcon
        width={isMobile ? "20rem" : "31.25rem"} // Adjust width
        //   height={isMobile ? undefined : 450} // Adjust height
        {...props}
      >
        <Box
          sx={{
            paddingInline: "1.25rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              mb: "2rem",
              fontWeight: "var(--neuro-font-weight-bold)",
              fontSize: "var(--neuro-font-size-large-medium)",
              fontFamily: "var(--neuro-font-family-roboto-condensed)",
              lineHeight: "var(--neuro-line-height-medium)",
            }}
          >
            Aadhaar
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ width: "100%" }}
          >
            <Input
              type="text"
              // className="input-secondary"
              className="input-primary"
              // placeholder="XXXX - XXXX - XXXX"
              sx={{
                "& >.MuiInputBase-root": {
                  height: "2.5rem",
                },
              }}
              label="Enter Aadhaar Number*"
              {...register("aadharNumber")}
              error={!!errors.aadharNumber}
              helperText={errors.aadharNumber?.message}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: "2rem",
                mb: "1rem",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                className="success-button"
                color="success"
                disabled={loading}
                sx={{
                  textTransform: "none",
                }}
              >
                {loading ? <Spinner /> : "Get OTP"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* <OtpModal
        email={email}
        otpToken={otpToken}
        open={otpOpen}
        onClose={onCloseOtp}
        width={650}
        txnId={txnId}
        message={message}
        loggedInUserId={user?.userId || null}
      /> */}
    </>
  );
};

export default AddAbhaAccountModal;
