import axios from "axios";
import { RegistrationDataProps } from "../types/auth";
import api from "./apiService";

export const completeRegistration = async (
  registrationData: RegistrationDataProps
) => {
  try {
    const response = await api.post(
      "/api/auth/complete-registration",
      registrationData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   throw new Error(
    //     error.response.data.message || "Unable to complete registration"
    //   );
    // }
    throw error;
  }
};
// Register a new user
export const register = async (userData: any) => {
  try {
    const response = await api.post("/api/auth/register", userData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || "Unable to register");
    }
    throw error;
  }
};

// Standard login with username and password (if needed)
export const login = async (userData: any) => {
  try {
    const response = await api.post("/api/auth/login", userData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || "Unable to login");
    }
    throw error;
  }
};

// Login by sending OTP to email or phone
export const sendOtp = async (data: any) => {
  try {
    const response = await api.post("/api/auth/send-otp", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   throw new Error(error.response.data.message || "Unable to send OTP");
    // }
    throw error;
  }
};

export const sendOtpViaAbhaCommunication = async (mobile: any) => {
  try {
    const response = await api.post("/api/abhaM1/send-otp/abha-communication-mobile", {
      mobileNumber: mobile
    }, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   throw new Error(error.response.data.message || "Unable to send OTP");
    // }
    throw error;
  }
};

export const verifyViaAbhaCommunication = async (txnId: string, otp: any) => {
  try {
    const response = await api.post("/api/abhaM1/verify-otp/abha-communication-mobile", {
      txnId: txnId,
      otp: otp
    }, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   throw new Error(error.response.data.message || "Unable to send OTP");
    // }
    throw error;
  }
};

export const verifyAbhaNumberViaAadhaar = async (txnId: string, otp: any,abhaNumber:string) => {
  try {
    const response = await api.post("/api/abhaM1/verify-otp/aadhaar-linked-mobile/via-abha-number", {
      txnId: txnId,
      otp: otp,
      abhaNumber
    }, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   throw new Error(error.response.data.message || "Unable to send OTP");
    // }
    throw error;
  }
};

export const verifyAbhaNumberViaAbhaOtp = async (txnId: string, otp: any,abhaNumber:string) => {
  try {
    const response = await api.post("/api/abhaM1/verify-otp/abha-communication-mobile/via-abha-number", {
      txnId: txnId,
      otp: otp,
      abhaNumber
    }, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   throw new Error(error.response.data.message || "Unable to send OTP");
    // }
    throw error;
  }
};

export const verifyAbhaAccount = async (txnId: string, abhaNumber: string, abhaTtoken: string) => {
  try {
    const response = await api.post("/api/abhaM1/verify-user/abha-communication-mobile", {
      txnId: txnId,
      abhaNumber: abhaNumber,
      abhaTtoken: abhaTtoken
    }, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   throw new Error(error.response.data.message || "Unable to send OTP");
    // }
    throw error;
  }
};


export const fetchOtpTypes = async (data: any) => {
  try {
    const response = await api.post("/api/auth/send-otp", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   throw new Error(error.response.data.message || "Unable to send OTP");
    // }
    throw error;
  }
};


// Verify OTP
export const verifyOtp = async (
  emailOrPhone: string,
  otp: string,
  otpToken: string,
  txnId?: any,
  userId?: any
) => {
  let data;

  if (txnId) {
    data = {
      emailOrMobileOrAadhaarOrAbhaNumber: emailOrPhone,
      otp,
      txnId,
      userId: userId,
    };
  } else {
    data = {
      emailOrMobileOrAadhaarOrAbhaNumber: emailOrPhone,
      otp,
      otpToken,
      userId: userId,
    };
  }
  const response = await api.post("/api/auth/verify-otp", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data; // Return the data from the response
};

// Resend OTP
export const resendOtp = async (emailOrPhone: string) => {
  try {
    const response = await api.post(
      "/api/auth/resend-otp",
      { emailOrMobileOrAadhaarOrAbhaNumber: emailOrPhone },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   throw new Error(error.response.data.message || "Unable to resend OTP");
    // }
    throw error;
  }
};

// Refresh Token
export const refreshTokenAPI = () => {
  const refreshToken = localStorage.getItem("refreshToken");

  return api.post(`/api/token/refresh`, { token: refreshToken });
};

// send aadhar otp
export const sendAadharOtp = async (data: any) => {
  try {
    const reqBody = { aadhaar: data.aadharNumber, agreeToTerms: data.agreeToTerms };
    const response = await api.post(
      "/api/abhaM1/send-otp/aadhaar-linked-mobile",
      reqBody,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   throw new Error(error.response.data.message || "Unable to send OTP");
    // }
    throw error;
  }
};

// verify aadhar otp
export const verifyAadharOtp = async (
  otp: string,
  txnId: string,
  mobileNumber: string,
  aadhaar: string,
  userId?: any
) => {
  const response = await api.post(
    "/api/abhaM1/verify-otp/aadhaar-linked-mobile",
    { otp, txnId, mobileNumber, aadhaar, userId },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data; // Return the data from the response
};

// send new-number otp
export const sendAbhaNewNumberOtp = async (txnId: string, mobileNumber: string) => {
  try {
    const response = await api.post(
      "/api/abhaM1/send-otp/new-abha-communication-mobile",
      { txnId, mobileNumber },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   throw new Error(error.response.data.message || "Unable to send OTP");
    // }
    throw error;
  }
};

// verify new-number otp
export const verifyAbhaNewNumberOtp = async (txnId: string, otp: string, aadhaar: string, mobile: string) => {
  const response = await api.post(
    "/api/abhaM1/verify-otp/new-abha-communication-mobile",
    { txnId, otp, mobile, aadhaar },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data; // Return the data from the response
};

// get abha-address suggesstions
export const getAbhaAddressSuggestions = async (txnId: string) => {
  const response = await api.get("/api/abhaM1/address-suggestions", {
    headers: {
      "Content-Type": "application/json",
      transaction_id: txnId,
    },
  });
  return response.data; // Return the data from the response
};

// post abha-address
export const submitAbhaAddress = async (txnId: string, abhaAddress: string, aadhaar: string, userId: string) => {
  const response = await api.post(
    "/api/abhaM1/selected-address",
    { txnId, abhaAddress, aadhaar, userId },
    {
      headers: {
        "Content-Type": "application/json",
        transaction_id: txnId,
      },
    }
  );
  return response.data; // Return the data from the response
};

// check abha for aadhar linked mobile
export const checkAbhaForMobile = async (mobile: string) => {
  const response = await api.post(
    "/api/abdm/check-abha/aadhar-linked-number",
    { mobile },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data; // Return the data from the response
};

// check abha for aadhar linked mobile
export const fetchAbhaDetails = async (userId: string) => {
  const response = await api.get(`/api/abdm/abhaDetails/${userId}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data; // Return the data from the response
};

// login with abha or mobile or aadhar - abha apis
export const loginForAbha = async (value: string) => {
  const response = await api.post(
    "/api/abdm/sendOtp",
    { mobileOrAadhaarOrAbhaNumber: value },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data; // Return the data from the response
};

// verify otp for login with abha or mobile or aadhar - abha apis
export const verifyOtpForLoginAbha = async (
  value: string,
  txnId: string,
  otp: string
) => {
  const response = await api.post(
    "/api/abdm/verifyOtp",
    { mobileOrAadhaarOrAbhaNumber: value, txnId, otp },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data; // Return the data from the response
};

// on logout
export const logOutUser = async (userId: string) => {
  const response = await api.post(
    "/api/auth/logout",
    { userId },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

// Download abha card
// export const downloadAbha = async (data: any) => {
//   try {
//     const response = await api.get("/api/abdm/download-abha-card", {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     return response.data;
//   } catch (error) {
//     // if (axios.isAxiosError(error) && error.response) {
//     //   throw new Error(error.response.data.message || "Unable to send OTP");
//     // }
//     throw error;
//   }
// };

// send aadhar otp
export const sendAbhaOtpviaAbhaMobile = async (data: any) => {
  const reqBody = { abhaNumber: data.abhaNumber };
  const response = await api.post(
    "/api/abhaM1/send-otp/abha-communication-mobile/via-abha-number",
    reqBody,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

export const sendAadharOtpviaAadhaar = async (data: any) => {
  const reqBody = { abhaNumber: data.abhaNumber };
  const response = await api.post(
    "/api/abhaM1/send-otp/aadhaar-linked-mobile/via-abha-number",
    reqBody,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};



export const sendAbhaMobileOtp = async (data: any) => {
  const reqBody = { abhaNumber: data.abhaNumber };
  const response = await api.post(
    "/api/abhaM1/send-otp/abha-communication-mobile/via-abha-number",
    reqBody,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};