import {
  Box,
  Dialog,
  IconButton,
  Typography,
  Slide,
  useMediaQuery,
  // TextField,
  // Autocomplete,
} from "@mui/material";
import React, { useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppointmentCalendar from "./AppointmentCalendar";
import AppointmentTimeSlots from "./AppointmentTimeSlots";
import SelectedDoctorCard from "./SelectedDoctorCard";
import AppointmentPayment from "./AppointmentPayment";
import { TransitionProps } from "@mui/material/transitions";
import Tooltip from "../../ui/Tooltip";
import PaymentFooter from "./PaymentFooter";
import useAppointmentStore from "../../../store/appointmentsStore";
import { AppointmentDrawerProps } from "../../../types/appointments";
import AppointmentInputs from "./AppointmentInputs";
import { capitalizeFirstLetter } from "../../../utils/common";
// import { Patient } from "../../../types/common";
// import { fetchPatients } from "../../../services/patientService";
// import { inputLabelStyles } from "../../../utils/styles";

// Slide transition for the dialog
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AppointmentDrawer: React.FC<AppointmentDrawerProps> = ({
  headerText,
  patientId,
  patientName,
  patientEmail,
  patientContact,
  onClose,
  from = "",
}) => {
  // props & state values
  const isMobile = useMediaQuery("(max-width:37.5rem)");
  const isBelow1100 = useMediaQuery("(max-width:68.75rem)");

  // const inputRef = useRef<HTMLInputElement | null>(null);

  // const [patients, setPatients] = useState<Patient[]>([]);

  const { setAppointmentDrawer,
    appointmentDrawerOpen,
    selectedDoctor,
    paymentCompleted,
    patientForAppointment,
    // setPatientForAppointment
  } = useAppointmentStore();

  console.log("selectedDoctor in booking drawer", selectedDoctor);

  const appointmentPatientName = patientForAppointment ? capitalizeFirstLetter(patientForAppointment?.user?.firstName || "") : null;
  const appointmentPatientEmail = patientForAppointment ? patientForAppointment?.user?.email || null : null;
  const appointmentPatientContact = patientForAppointment ? patientForAppointment?.user?.mobile || null : null;


  // callbacks & functions
  // const showPayButtons = () => {
  //   if (!selectedDoctor?.isNetwork) {
  //     return false;
  //   }
  //   if (from === "admin-appointments") {
  //     if (!patientForAppointment) {
  //       return false;
  //     }
  //   }
  //   return true;
  // }

  useEffect(() => {
    // close the drawer on unmount
    return () => {
      setAppointmentDrawer(false);
      if (onClose) {
        onClose();
      }
    };
  }, []);

  // const loadPatients = async () => {
  //   try {
  //     // setLoading(true);
  //     const response = await fetchPatients();
  //     setPatients(response.data || []);
  //     // setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching patients:", error);
  //     // setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   if (from === "admin-appointments") {
  //     loadPatients();
  //   }
  // }, [from]);

  return (
    <Dialog
      fullScreen
      open={appointmentDrawerOpen}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          width: isMobile ? "100vw" : isBelow1100 ? "50vw" : "45vw",
          height: "100vh",
          marginLeft: "auto", // Align to the right
          maxWidth: "none",
          boxShadow: "none",
          borderRadius: 0,
          backgroundColor: "var(--neuro-white-text)",
        },
      }}
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflowX: "hidden",
        }}
        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
          event.stopPropagation();
        }}
      >
        {/* drawer header */}
        <Box
          sx={{
            background:
              "linear-gradient(to right, rgba(98, 181, 90, 1), rgba(37, 129, 196, 1))",
            height: "3.75rem",
            minHeight: "3.75rem",
            // maxHeight: "60px",
            display: "flex",
            alignItems: "center",
            gap: "1.25rem",
            paddingLeft: "1.25rem",
          }}
        >
          <Tooltip title="Close this dialog" placement="right">
            <IconButton
              size="small"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                setAppointmentDrawer(false);
                if (onClose) {
                  onClose();
                }
              }}
            >
              <ArrowBackIcon sx={{ color: "var(--neuro-white-text)" }} />
            </IconButton>
          </Tooltip>
          <Typography
            sx={{
              fontSize: "var(--neuro-font-size-tiny)",
              lineHeight: "var(--neuro-line-height-micro)",
              color: "var(--neuro-white-text)",
              fontFamily: "var(--neuro-font-family-roboto-condensed)",
            }}
          >
            {headerText}
          </Typography>
        </Box>

        <Box sx={{ height: "calc(100% - 7.5rem)", overflowY: "auto" }}>

          {/* middle content */}
          <Box
            sx={{
              paddingInline: isMobile ? "0.938rem" : "1.25rem",
              paddingTop: "1.125rem",
              paddingBottom: "0.75rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <SelectedDoctorCard />

            <AppointmentCalendar />

            {!selectedDoctor?.isNetwork && <AppointmentTimeSlots />}

            {/* dashed line separator */}
            <Box
              sx={{
                width: "100%",
                height: "0.063rem",
                border: "0.063rem dashed var(--neuro-secondary_border)",
              }}
            />
          </Box>

          <AppointmentInputs />

          {/* Patient Select */}
          {/* <Box sx={{ display: "flex", gap: "2rem", mt: "1rem" }}>
            <Typography sx={inputLabelStyles}>
              Select Patient :
            </Typography>
            <Box sx={{ width: "100%" }}>
              <Autocomplete
                options={patients || []}
                getOptionLabel={(option) => option.user.firstName}
                value={patientForAppointment}
                onChange={(_, newValue) => {
                  setPatientForAppointment(newValue);

                  // Blur the input element after an option is selected
                  inputRef.current?.blur();
                }}
                renderInput={(params) => {
                  const combinedRef = (input: HTMLInputElement | null) => {
                    // Handle the ref from params.InputProps.ref
                    if (typeof params.InputProps.ref === "function") {
                      params.InputProps.ref(input);
                    } else if (params.InputProps.ref) {
                      (params.InputProps.ref as React.MutableRefObject<HTMLInputElement | null>).current = input;
                    }

                    // Set your custom ref
                    inputRef.current = input;
                  };

                  return (
                    <TextField
                      {...params}
                      placeholder="Select Patient"
                      variant="outlined"
                      fullWidth
                      inputRef={combinedRef}
                      sx={{
                        "& .MuiInputBase-input": {
                          textAlign: "center", // Center align the placeholder text
                        },
                        "& .MuiInputBase-root": {
                          height: "2.625rem !important",
                        },
                        "& .MuiInputLabel-root": {
                          textAlign: "center", // Center align the label text
                        },
                      }}
                    />
                  );
                }}
              />
            </Box>
          </Box> */}



          {/* payment section */}
          {!selectedDoctor?.isNetwork && !paymentCompleted && <AppointmentPayment />}
        </Box>

        {!selectedDoctor?.isNetwork && (
          <Box
            sx={{
              flexShrink: 0,
              position: "sticky",
              bottom: 0,
              zIndex: 1,
              minHeight: "3.75rem",
              width: "100%",
              height: "3.75rem",
            }}
          >
            <PaymentFooter
              patientId={patientId || patientForAppointment?.id || ""}
              patientName={patientName || appointmentPatientName || ""}
              patientEmail={patientEmail || appointmentPatientEmail || ""}
              patientContact={patientContact || appointmentPatientContact || ""}
              from={from}
            />
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default AppointmentDrawer;
