import { Box, Typography } from "@mui/material";
import MedicationCard from "./MedicationCard";
import MorningIcon from "../svg/MorningIcon"
import AfternoonIcon from "../svg/AfternoonIcon"
import NightIcon from "../svg/NightIcon"
import Brightness6Icon from "@mui/icons-material/Brightness6";

const SelectedPrescription = ({ selectedPrescription }: { selectedPrescription: any; }) => {
    // props & state values
    const styles = {
        iconsWrapper: {
            display: "flex",
            alignItems: "center",
            gap: "0.25rem"
        },
        time: {
            color: "var(--neuro-darkgrey_border)",
            fontFamily: "var(--neuro-font-family-inter)",
            lineHeight: "normal",
        }
    }
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: "column",
            gap: "1rem",
            borderRadius: '0.75rem',
            padding: "1rem",
            backgroundColor: "var(--neuro-white-text)",
            height: "100%",
            overflowY: "hidden",
        }}>
            <Box sx={{
                height: "calc(100% - 2rem)",
                overflowY: "auto",
                display: 'flex',
                flexDirection: "column",
                gap: "0.875rem",
            }}>
                {selectedPrescription && selectedPrescription?.prescriptions && selectedPrescription?.prescriptions.map((prescription: any) => (
                    <MedicationCard data={prescription} key={prescription?.prescriptionId} />
                ))}
            </Box>

            <Box sx={{ paddingInline: "1.25rem", paddingTop: "0.875rem", display: "flex", alignItems: "center", justifyContent: "space-between", height: "2rem", borderTop: "1px solid var(--neuro-secondary_border)", marginInline: "-1rem" }}>
                <Box sx={styles.iconsWrapper}>
                    <MorningIcon />
                    <Typography sx={styles.time}>
                        Morning
                    </Typography>
                </Box>
                <Box sx={styles.iconsWrapper}>
                    <AfternoonIcon />
                    <Typography sx={styles.time}>
                        Afternoon
                    </Typography>
                </Box>
                <Box sx={styles.iconsWrapper}>
                    <Brightness6Icon
                        sx={{
                            fontSize: "var(--neuro-font-size-smallest)",
                            color: "var(--neuro-button-bg-success)",
                        }}
                    />
                    <Typography sx={styles.time}>
                        Evening
                    </Typography>
                </Box>
                <Box sx={styles.iconsWrapper}>
                    <NightIcon />
                    <Typography sx={styles.time}>
                        Night
                    </Typography>

                </Box>
            </Box>
        </Box>
    )
}

export default SelectedPrescription;