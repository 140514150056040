import { createBrowserRouter, RouteObject } from "react-router-dom";
import RoleBasedRoute from "./RoleBasedRoute"; // Adjust the import based on your file structure

import Dashboard from "../../components/dashboard/Index";
import AuthRedirect from "./AuthRedirect";
import ReportViewer from "../documents/view/ReportViewer";
import AppointmentConfirmation from "../appointments/AppointmentConfirmation";
import Speciality from "../appointments/Speciality";
import NewAppointment from "../appointments/NewAppointment";
import MainLayout from "../layouts/MainLayout";
import PatientProfile from "../profile/patient";
import AuthLayout from "../layouts/AuthLayout";
import LoginForm from "../auth/LoginForm";
import NotFound from "./NotFound";
import AbhaTformsermsAndConditions from "./AbhaTermsAndConditions";
import Documents from "../documents/list";
import MedicalHistory from "../medical-history/MedicalHistory";
import Subscriptions from "../subscriptions/Subscriptions";
import Settings from "../settings/Settings";
import Appointments from "../appointments/Appointments";
import PatientDisorder from "../appointments/NewAppointment/PatientDisorder";
import GeneralForm from "../forms/GeneralForm";
import { dataDrivenFormSchema } from "../forms/GeneralFormSchema";
import DisorderForm from "../appointments/NewAppointment/DisorderForm";
// import AppointmentScheduler from "../appointments/AppointmentScheduler";
import Checkout from "../appointments/Checkout";
import Meet from "../appointments/Meet/Meet";
import Referrals from "../referrals/Referrals";
import Patients from "../patients/Patients";
import PatientDocuments from "../patients/PatientDocuments";
import PatientForms from "../patients/PatientForms";
import PatientAppointments from "../patients/PatientAppointments";
import Doctors from "../doctors/Doctors";
import Hospitals from "../hospitals/Hospitals";
import PrivateRoute from "./PrivateRoute";
import NewFlow from "../appointments/AppointmentBooking/NewFlow";
import PatientAbhaDetails from "../patients/PatientAbhaDetails";
import SingleReportViewer from "../documents/view/SingleReportViewer";
// import PatientAuditLogsList from "../patients/patientAuditLogsList";
import NewPatientDetailsPage from "../patients/NewPatientDetailsPage";
import { ADMIN, CLINIC_ADMIN, DOCTOR, NETWORK, PATIENT } from "../../utils/constants";
import NewPatientDocuments from "../patients/NewPatientDocuments";
import NewPatientAppointments from "../patients/NewPatientAppointments";
import NewPatinetForms from "../patients/NewPatinetForms";
import NewPatientAbhaDetails from "../patients/NewPatientAbhaDetails";
import NewPatientAuditLogList from "../patients/NewPatientAuditLogList";
import DoctorPersonalInfoFormPage from "../doctors/DoctorPersonalInfoFormPage";
import DoctorAvailabilityFormPage from "../doctors/DoctorAvailabilityFormPage";
import DoctorPaymentSettingsFormPage from "../doctors/DoctorPaymentSettingsFormPage";

import DoctorProfessionalProfileFormPage from "../doctors/DoctorProfessionalProfileFormPage";
import DoctorWorkExperienceFormPage from "../doctors/DoctorWorkExperienceFormPage";
import DoctorAcademicQualificationsFormPage from "../doctors/DoctorAcademicQualificationsFormPage";
import DoctorLeaveSettingsFormPage from "../doctors/DoctorLeaveSettingsFormPage";
import FormCreator from "../FormsCreator/FormCreator";
import MyForms from "../forms/MyForms";
import NewReportViewer from "../documents/view/NewReportViewer";
import NewPersonalInfoPage from "../profile/patient/NewPersonalInfoPage";
import NewAbhaDetailsPage from "../profile/patient/NewAbhaDetailsPage";
import Prescriptions from "../prescriptions/Prescriptions";
import Payments from "../payments/Payments";
import AddNewPatientPage from "../patients/AddNewPatientPage";
import PatientPayments from "../patients/PatientPayments";
import PatientPrescriptions from "../patients/PatientPrescriptions";
import NewDoctorDetails from "../doctors/NewDoctorDetails";
import AdminAppointmentBooking from "../appointments/AppointmentBooking/AdminAppointmentBooking";
import NewFormViewFullScreenEdit from "../FormsCreator/FormEdit/NewFormViewFullScreenEdit";
import PublicForm from "../FormsCreator/PublicForm/PublicForm";

const publicRoutes: RouteObject[] = [
  {
    path: "/",
    element: (
      <AuthRedirect
        element={
          <AuthLayout>
            <LoginForm />
          </AuthLayout>
        }
      />
    ),
  },
  {
    path: "/abha-terms-and-conditons",
    element: <AbhaTformsermsAndConditions />,
  },
  {
    path: "/public/:id",
    element: <PublicForm />,
  },
  {
    path: "/not-authorized",
    element: <NotFound />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const protectedRoutes: RouteObject[] = [
  {
    path: "/dashboard",
    element: <PrivateRoute element={<Dashboard />} />,
  },
  {
    path: "/documents",
    element: <PrivateRoute element={<Documents />} />,
  },
  {
    path: "/documents/view",
    element: <PrivateRoute element={<NewReportViewer />} />,
  },
  {
    path: "/documents/:id/details",
    element: <PrivateRoute element={<SingleReportViewer />} />,
  },
  {
    path: "/medical-history",
    element: <PrivateRoute element={<MedicalHistory />} />,
  },
  {
    path: "/subscriptions",
    element: <PrivateRoute element={<Subscriptions />} />,
  },
  {
    path: "/settings",
    element: <PrivateRoute element={<Settings />} />,
  },
  {
    path: "/settings/personal-info",
    element: <PrivateRoute element={<NewPersonalInfoPage />} />,
  },

  {
    path: "/settings/personal-profile",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<DoctorPersonalInfoFormPage />} />}
        allowedRoles={[DOCTOR, NETWORK]}
      />
    ),
  },

  {
    path: "/settings/consultation-settings",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<DoctorAvailabilityFormPage />} />}
        allowedRoles={[DOCTOR, NETWORK]}
      />
    ),
  },
  {
    path: "/settings/professional-profile",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<DoctorProfessionalProfileFormPage />} />}
        allowedRoles={[DOCTOR, NETWORK]}
      />
    ),
  },
  {
    path: "/settings/work-experience",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<DoctorWorkExperienceFormPage />} />}
        allowedRoles={[DOCTOR, NETWORK]}
      />
    ),
  },
  {
    path: "/settings/academic-qualifications",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<DoctorAcademicQualificationsFormPage />} />}
        allowedRoles={[DOCTOR, NETWORK]}
      />
    ),
  },

  {
    path: "/settings/leave-settings",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<DoctorLeaveSettingsFormPage />} />}
        allowedRoles={[DOCTOR, NETWORK]}
      />
    ),
  },

  {
    path: "/settings/abha-details",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<NewAbhaDetailsPage />} />}
        allowedRoles={[PATIENT, DOCTOR, ADMIN, CLINIC_ADMIN, NETWORK]}
      />
    ),
  },
  {
    path: "/prescriptions",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<Prescriptions />} />}
        allowedRoles={[PATIENT]}
      />
    ),
  },
  {
    path: "/payments",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<Payments />} />}
        allowedRoles={[PATIENT]}
      />
    ),
  },
  {
    path: "/doctors/personal-profile",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<DoctorPersonalInfoFormPage />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR, NETWORK]}
      />
    ),
  },
  {
    path: "/doctors/:id/consultation-settings",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<DoctorAvailabilityFormPage />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR, NETWORK]}
      />
    ),
  },
  {
    path: "/doctors/:id/payment-settings",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<DoctorPaymentSettingsFormPage />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN]}
      />
    ),
  },
  {
    path: "/doctors/:id/professional-profile",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<DoctorProfessionalProfileFormPage />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR, NETWORK]}
      />
    ),
  },
  {
    path: "/doctors/:id/work-experience",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<DoctorWorkExperienceFormPage />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR, NETWORK]}
      />
    ),
  },
  {
    path: "/doctors/:id/academic-qualifications",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<DoctorAcademicQualificationsFormPage />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR, NETWORK]}
      />
    ),
  },

  {
    path: "/doctors/:id/leave-settings",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<DoctorLeaveSettingsFormPage />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR, NETWORK]}
      />
    ),
  },
  {
    path: "/appointments",
    element: <PrivateRoute element={<Appointments />} />,
  },

  {
    path: "/appointments/book",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<AdminAppointmentBooking />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN]}
      />
    ),
  },

  {
    path: "/appointments/new/patient-disorder",
    element: <PrivateRoute element={<PatientDisorder />} />,
  },
  {
    path: "/appointments/new/forms/general-form",
    element: (
      <PrivateRoute
        element={<GeneralForm formSchema={dataDrivenFormSchema} />}
      />
    ),
  },
  {
    path: "/appointments/new/forms/:disorder",
    element: <PrivateRoute element={<DisorderForm />} />,
  },
  // {
  //   path: "/appointments/new-appointment/appointment-scheduler",
  //   element: <PrivateRoute element={<AppointmentScheduler />} />,
  // },
  {
    path: "/appointments/new-appointment/checkout",
    element: <PrivateRoute element={<Checkout />} />,
  },
  {
    path: "/appointment/:id",
    element: <PrivateRoute element={<AppointmentConfirmation />} />,
  },
  {
    path: "/specialties",
    element: <PrivateRoute element={<Speciality />} />,
  },
  {
    path: "/specialties/:id",
    element: <PrivateRoute element={<NewAppointment />} />,
  },
  {
    path: "/meet/:appointmentId",
    element: <PrivateRoute element={<Meet />} />,
    children: [
      {
        path: "profile",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<NewPatientDetailsPage />} />}
            allowedRoles={[DOCTOR]}
          />
        ),
      },
      {
        path: "documents",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<PatientDocuments />} />}
            allowedRoles={[DOCTOR]}
          />
        ),
        children: [
          {
            path: "view",
            element: (
              <RoleBasedRoute
                element={<PrivateRoute element={<ReportViewer />} />}
                allowedRoles={[DOCTOR]}
              />
            ),
          },
          {
            path: ":id/details",
            element: (
              <RoleBasedRoute
                element={<PrivateRoute element={<SingleReportViewer />} />}
                allowedRoles={["DOCTOR"]}
              />
            ),
          },
        ]
      },
      {
        path: "appointments",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<PatientAppointments />} />}
            allowedRoles={[DOCTOR]}
          />
        ),
        children: [
          {
            path: ":id/details",
            element: (
              <RoleBasedRoute
                element={<PrivateRoute element={<AppointmentConfirmation />} />}
                allowedRoles={[DOCTOR]}
              />
            ),
          },
        ]
      },
      {
        path: "forms",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<PatientForms />} />}
            allowedRoles={[DOCTOR]}
          />
        ),
      },
      {
        path: "abha-details",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<PatientAbhaDetails />} />}
            allowedRoles={[DOCTOR]}
          />
        ),
      },
      {
        path: "doctor-notes",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<PatientDocuments />} />}
            allowedRoles={[DOCTOR]}
          />
        ),
      },
      {
        path: "medication",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<PatientDocuments />} />}
            allowedRoles={[DOCTOR]}
          />
        ),
      },
      {
        path: "advices",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<PatientDocuments />} />}
            allowedRoles={[DOCTOR]}
          />
        ),
      },
      {
        path: "symptoms",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<PatientDocuments />} />}
            allowedRoles={[DOCTOR]}
          />
        ),
      },
      {
        path: "follow-up",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<PatientDocuments />} />}
            allowedRoles={[DOCTOR]}
          />
        ),
      },
    ]
  },
  {
    path: "/referrals",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<Referrals />} />}
        allowedRoles={["ADMIN", "NETWORK", "CLINIC_ADMIN"]}
      />
    ),
  },
  {
    path: "/patients",
    element: <PrivateRoute element={<Patients />} />,
  },
  {
    path: "/patients/new/profile",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<AddNewPatientPage />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
      />
    ),
  },
  {
    path: "/patients/:id/profile",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<AddNewPatientPage />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
      />
    ),
  },
  {
    path: "/patients/:id/appointments",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<NewPatientAppointments />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
      />
    ),
    children: [
      {
        path: "specialties",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<Speciality />} />}
            allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
          />
        ),
      },
      {
        path: "book",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<NewFlow />} />}
            allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
          />
        ),
      },
      {
        path: "specialties/:specialisation",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<NewFlow />} />}
            allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
          />
        ),
      },
      {
        path: ":id/details",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<AppointmentConfirmation />} />}
            allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
          />
        ),
      },
    ],
  },
  {
    path: "/patients/:id/documents",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<NewPatientDocuments />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
      />
    ),
    children: [
      {
        path: ":id/details",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<SingleReportViewer />} />}
            allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
          />
        ),
      },
      {
        path: "view",
        element: (
          <RoleBasedRoute
            element={<PrivateRoute element={<NewReportViewer />} />}
            allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
          />
        ),
      },
    ],
  },
  {
    path: "/patients/:id/forms",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<NewPatinetForms />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
      />
    ),
  },
  {
    path: "/patients/:id/abha-details",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<NewPatientAbhaDetails />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
      />
    ),
  },
  {
    path: "/patients/:id/prescriptions",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<PatientPrescriptions />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN, DOCTOR]}
      />
    ),
  },
  {
    path: "/patients/:id/payments",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<PatientPayments />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN]}
      />
    ),
  },
  {
    path: "/patients/:id/audit-logs",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<NewPatientAuditLogList />} />}
        allowedRoles={[ADMIN, CLINIC_ADMIN]}
      />
    ),
  },
  {
    path: "/doctors",
    element: <PrivateRoute element={<Doctors />} />,
  },
  {
    path: "/doctors/:id/details",
    element: <PrivateRoute element={<NewDoctorDetails />} />,
  },

  {
    path: "/my-forms",
    element: (
      <RoleBasedRoute
        element={<PrivateRoute element={<MyForms />} />}
        allowedRoles={[PATIENT]}
      />
    ),
  },

  {
    path: "/forms",
    element: <PrivateRoute element={<FormCreator />} />,
  },

  {
    path: "/form/add/",
    element: <PrivateRoute element={<NewFormViewFullScreenEdit />} />,
  },

  {
    path: "/forms/:id/edit",
    element: <PrivateRoute element={<NewFormViewFullScreenEdit />} />,
  },
  {
    path: "/clinics",
    element: <PrivateRoute element={<Hospitals />} />,
  },
  {
    path: "/profile/personal-info",
    element: <PrivateRoute element={<PatientProfile />} />,
  },
  {
    path: "/profile/forms",
    element: <PrivateRoute element={<PatientProfile />} />,
  },
  {
    path: "/profile/abha-details",
    element: <PrivateRoute element={<PatientProfile />} />,
  },
];

export const router = createBrowserRouter([
  {
    element: <MainLayout />,
    children: protectedRoutes,
  },
  ...publicRoutes,
]);
