import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface FileInputProps {
  question: {
    type: string;
    name: string;
    title: string;
    score: number;
    isRequired: boolean;
    showScore: boolean;
  };
  index: number;
  toggleRequired: (index: number) => void;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
  updateQuestion: (index: number, updatedQuestion: any) => void;
  updateQuestionTitle: (updatedTitle: string) => void; // For updating the title
  isonclick?: boolean;
  showHighlighted: boolean;
}

const FileInput: React.FC<FileInputProps> = ({
  question,
  index,
  handleDuplicateQuestion,
  handleRemoveQuestion,
  updateQuestion,
  toggleRequired,
  showHighlighted = false,
  isonclick = false,
}) => {
  // props & state values
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [localQuestion, setLocalQuestion] = useState(question);

  // callbacks & functions
  useEffect(() => {
    setLocalQuestion(question);
  }, [question])

  const updateLocalQuestion = (updatedQuestion: typeof question) => {
    setLocalQuestion(updatedQuestion);
    updateQuestion(index, updatedQuestion);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    updateLocalQuestion({ ...localQuestion, title: newTitle });
  };

  const handleScoreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newScore = parseInt(e.target.value, 10) || 0;
    updateLocalQuestion({ ...localQuestion, score: newScore });
  };

  const handleToggleRequired = () => {
    toggleRequired(index);
  };

  return (
    <Box sx={{
      margin: 0,
      padding: "1rem",
      borderRadius: "0.5rem",
      marginBottom: "1rem",
      cursor: "pointer",
      transition: "border-color 0.3s ease",
      background: "var(--neuro-white-text)",
      height: "auto",
      border: isonclick || showHighlighted ? "1px solid var(--neuro-light_red_border) !important" : "1px solid transparent !important",
      "&:hover": {
        border: "1px solid var(--neuro-light_red_border_light) !important"
      }
    }}>
      <div className="question-header">
        {localQuestion.name}
        <input
          type="text"
          value={localQuestion.title}
          onChange={handleTitleChange}
          className="question-name-input survey-js-gobal-input"
          aria-label="Question Title"
        />
        {localQuestion.isRequired && <span className="required">*</span>}
      </div>

      <div className="file-input-container">
        <label htmlFor={`file-${index}`} className="file-upload-label">
          {selectedFile ? (
            <span className="file-name">{selectedFile.name}</span>
          ) : (
            <span className="file-placeholder">
              Drag and drop a file here or click to select.
            </span>
          )}
        </label>
        <input
          type="file"
          id={`file-${index}`}
          onChange={handleFileChange}
          className="file-input"
        />
        {selectedFile && (
          <button className="remove-file-button" onClick={handleRemoveFile}>
            Remove File
          </button>
        )}
      </div>
      {localQuestion.showScore && (
        <div className="score-input-container">
          <label htmlFor={`score-${index}`} style={{ fontSize: '14px', marginRight: '10px' }}>
            Score:
          </label>
          <input
            type="number"
            id={`score-${index}`}
            value={localQuestion.score}
            onChange={handleScoreChange}
            className="score-input"
            aria-label="Score"
          />
        </div>
      )}

      <div className="question-controls">
        <button
          className="duplicate-button button-spacing blue-bg"
          onClick={() => handleDuplicateQuestion(index)}
        >
          Duplicate
        </button>
        <button
          className="delete-button button-spacing red-bg"
          onClick={() => handleRemoveQuestion(index)}
        >
          Delete
        </button>
        <button
          className={`required-button ${localQuestion.isRequired ? 'active' : ''}`}
          onClick={handleToggleRequired}
        >
          {localQuestion.isRequired ? 'Required' : 'Optional'}
        </button>
      </div>
    </Box>
  );
};

export default FileInput;
