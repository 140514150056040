import React from "react";
import { Box, Typography, Divider, Chip } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

interface DashboardCardcopyProps {
  icon: React.ReactNode;
  text: string;
  count: number | string;
  total?: number;
  navigateTo: string;
  action: (status: "Accepted" | "Rejected") => void; // action now expects a status
  subtext: string;
  subtext2: string;
  subtextcount: string | number;
  subtextcount2: string | number;
}

const DashboardCardcopy: React.FC<DashboardCardcopyProps> = ({
  icon,
  text,
  // count,
  total,
  navigateTo,
  action,
  subtext,
  subtext2,
  subtextcount,
  subtextcount2,
}) => {
  const navigate = useNavigate();

  console.log(
    "subtext",
    "subtext2",
    "subtextcount",
    "subtextcount2",
    subtext,
    subtext2,
    subtextcount,
    subtextcount2
  );

  return (
    <Box
      sx={{
        borderRadius: "12px",
        backgroundColor: "#fff",
        // maxWidth: "312px",
        padding: "1.1rem",
        fontFamily:
          '"Roboto Condensed", -apple-system, Roboto, Helvetica, sans-serif',
        boxShadow: "none",
        cursor: "pointer",
        border: "1px solid transparent",
        "&:hover": {
          border: "1px solid var(--neuro-secondary_border)"
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {/* Title and Count */}
        <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "14px"
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 400,
                color: "var(--Secondary-Neuro_black, #000)",
              }}
            >
              {text}
            </Typography>
            {icon}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "6px"
              // justifyContent: "space-between"
            }}
          >
            <Typography
              variant="h3"
              sx={{ color: "#000", fontSize: "40px", fontWeight: 300, lineHeight: "120%", fontFamily: "var(--neuro-font-family-roboto-condensed)" }}
              onClick={() => {
                if (total !== 0) {
                  // action();
                  if (navigateTo === "/referrals") {
                    navigate(navigateTo, {
                      state: { redirectedFrom: "dashboard" },
                    });
                  } else {
                    navigate(navigateTo);
                  }
                }
              }}
            >
              {total}
            </Typography>
            {text === "Total Referrals" && <Chip
              label={`New - ${total}`}
              onClick={() => {
                if (total !== 0) {
                  // action();
                  if (navigateTo === "/referrals") {
                    navigate(navigateTo, {
                      state: { redirectedFrom: "dashboard" },
                    });
                  } else {
                    navigate(navigateTo);
                  }
                }
              }}
              sx={{
                backgroundColor: "#62b55a",
                color: "#fff",
                fontSize: "14px",
                fontWeight: 400,
                padding: "4px 13px",
                borderRadius: "6px",
                height: "24px",
                "&:hover": {
                  backgroundColor: "#62b55a",
                },
                "& span:first-of-type": {
                  padding: "0px !important"
                }
              }}
            />}
          </Box>
        </Box>

        <Divider sx={{ my: "8px 0 9px", backgroundColor: "#dcdcdc" }} />

        {/* Clickable Arrow Icon and Text */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "13px",
            justifyContent: "space-between",
          }}
        >
          {subtext && (
            <Typography
              variant="body1"
              sx={{ color: "#292c67", fontSize: "16px", mr: 2 }}
              onClick={() => {
                if (subtextcount && subtextcount !== 0) {
                  action("Accepted");
                  if (navigateTo === "/referrals") {
                    navigate(navigateTo, {
                      state: { redirectedFrom: "dashboard" },
                    });
                  } else {
                    navigate(navigateTo);
                  }
                }
              }}
            >
              {subtext} {subtextcount || 0}
            </Typography>
          )}

          {/* Vertical Divider */}
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "#dcdcdc", mx: 3 }}
          />

          {subtext2 && (
            <Typography
              variant="body1"
              sx={{ color: "#292c67", fontSize: "16px", mr: 2 }}
              onClick={() => {
                if (subtextcount2 && subtextcount2 !== 0) {
                  action("Rejected");
                  if (navigateTo === "/referrals") {
                    navigate(navigateTo, {
                      state: { redirectedFrom: "dashboard" },
                    });
                  } else {
                    navigate(navigateTo);
                  }
                }
              }}
            >
              {subtext2} {subtextcount2 || 0}
            </Typography>
          )}

          {/* If subtext2 is empty, render a clickable arrow */}
          {subtext2 === "" && (
            <Box
              onClick={() => {
                action("Accepted");
                if (navigateTo === "/referrals") {
                  navigate(navigateTo, {
                    state: { redirectedFrom: "dashboard" },
                  });
                } else {
                  navigate(navigateTo);
                }
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: "#292c67",
                fontWeight: 700,
                "&:hover": {
                  color: "#0077ff",
                },
              }}
            >
              <ArrowForwardIcon sx={{ ml: 1 }} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardCardcopy;
