import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, useMediaQuery } from "@mui/material";

import OriginalReport from "./OriginalReport";
import ReportSummary from "./ReportSummary";
import useAuthStore from "../../../store/authStore";
import useDocumentStore from "../../../store/documentsStore";
import useAppStore from "../../../store/appStore";
import { NewReportViewerComponentProps } from "../../../types/documents";
import { isPatient } from "../../../utils/auth";
import Button from "../../ui/Button";
import CardsDropdown from "./CardsDropdown";
import config from "../../../config";

const NewReportViewerComponent: React.FC<NewReportViewerComponentProps> = ({ patientId, from = "", fullwidth = false }) => {
    const navigate = useNavigate();
    const { userRole } = useAuthStore();
    const isBelow800 = useMediaQuery("(max-width:50rem)");

    const {
        isMenuCollapsed,
        setIsMenuCollapsed,
        setDrawerOpen,
        appointmentIdForMeet,
        highlightDiscard,
        setHighlightDiscard
    } = useAppStore();

    console.log("highlightDiscard", highlightDiscard);

    const [previousSidebarCollpased, _] = useState<boolean>(
        isMenuCollapsed ? true : false
    );

    const [isPreviewCollapsed, setIsPreviewCollapsed] = useState<boolean>(false);
    const [isInitialCollapseState, setIsInitialCollapseState] = useState<boolean>(true);
    const [isSummaryCollapsed, setIsSummaryCollapsed] = useState<boolean>(false);

    const {
        documents,
        reGeneratedSummaryData,
        cleanUpReGenerateSummaryState,
        documentDetails,
        loadingDocumentDetails,
        errorDocumentDetails,
        fetchDocumentDetails,
        cleanUpDocumentDetailsStore,
        selectedDocumentId,
        setSelectedDocumentId,
    } = useDocumentStore();

    console.log("documentDetails", documentDetails);

    useEffect(() => {
        if (isBelow800) {
            setDrawerOpen(false);
        } else {
            setIsMenuCollapsed(true);
        }

        setHighlightDiscard(false);

        return () => {
            cleanUpDocumentDetailsStore();
            setIsMenuCollapsed(previousSidebarCollpased);
            setDrawerOpen(!previousSidebarCollpased);
            setHighlightDiscard(false);
        };
    }, []);

    useEffect(() => {
        let intervalId: ReturnType<typeof setTimeout>;

        if (highlightDiscard) {
            intervalId = setTimeout(() => {
                setHighlightDiscard(false);
            }, config.HIGHLIGHT_DURATION || 3000);
        }

        // Cleanup function to clear the interval
        return () => {
            clearInterval(intervalId);
        };
    }, [highlightDiscard])

    useEffect(() => {
        if (selectedDocumentId) {
            fetchDocumentDetails(selectedDocumentId);
            cleanUpReGenerateSummaryState();
        }
    }, [selectedDocumentId, reGeneratedSummaryData]);

    useEffect(() => {
        if (documents && documents.length > 0 && selectedDocumentId) {
            const document = documents.filter((doc) => doc.id === selectedDocumentId);
            if (document.length === 0) {
                setSelectedDocumentId(documents[0]?.id);
            }
        }
    }, [documents, selectedDocumentId]);

    // redirect to documents page if no documents
    useEffect(() => {
        if (documents && documents.length === 0) {
            if (patientId) {
                if (from === "meet") {
                    // setDocumentsConferenceView("List");
                    navigate(`/meet/${appointmentIdForMeet}/documents`);
                } else {
                    navigate(`/patients/${patientId}/documents`);
                }
            } else {
                navigate("/documents");
            }
        }
    }, [documents]);

    const handleDiscard = () => {
        if (from === "meet") {
            // navigate(`/meet/${appointmentIdForMeet}/documents`);
            navigate(-1);
        }
        if (patientId) {
            navigate(`/patients/${patientId}/documents`);
        }
        else {
            navigate("/documents");
        }
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", backgroundColor: "var(--neuro-white-text)" }}>
            <Box sx={{ p: "0.625rem 1.5rem", display: "flex", alignItems: "center", justifyContent: "space-between", height: "5rem", borderBottom: "0.063rem solid var(--neuro-secondary_border)", }}>
                <CardsDropdown
                    patientId={patientId}
                    from={from}
                />
                <Button variant="outlined" className="outlined-secondary-button" onClick={handleDiscard} sx={{
                    borderColor: highlightDiscard ? "var(--neuro-secondary-light-error) !important" : "var(--neuro-secondary_border) !important",
                    boxShadow: highlightDiscard ? "0 4px 8px rgba(255, 0, 0, 0.3) !important" : "none !important",
                }}>
                    Discard
                </Button>
            </Box>

            <Box
                sx={{
                    backgroundColor: "var(--neuro-white-text)",
                    height: patientId ? "calc(100% - 5rem)" : "calc(100% - 4rem)",
                    padding: 0,
                }}
            >
                <Grid
                    container
                    spacing={!isPatient(userRole) ? 0 : 2}
                    flexWrap="wrap"
                    sx={{ height: "100%", overflow: "auto" }}
                >
                    {isPatient(userRole) && (
                        <>
                            <Grid
                                item
                                xs={12}
                                md={
                                    fullwidth ? 12 :
                                        isPreviewCollapsed
                                            ? 0.75 :
                                            isInitialCollapseState ? 6 : 11.25
                                    // isSummaryCollapsed ? 11.25 : 6
                                }
                                sx={{
                                    height: "100%",
                                    overflowY: "hidden",
                                    transition: "all 0.3s ease-in-out",
                                }}
                            >
                                <OriginalReport
                                    key={documentDetails?.id}
                                    loading={loadingDocumentDetails}
                                    error={Boolean(errorDocumentDetails)}
                                    fileType={documentDetails?.fileType}
                                    filePath={documentDetails?.filePath}
                                    fileName={documentDetails?.name}
                                    id={documentDetails?.id}
                                    status={documentDetails?.status}
                                    patientId={patientId}
                                    from={from}
                                    isPreviewCollapsed={isPreviewCollapsed}
                                    showZoom={isInitialCollapseState}
                                    setIsSummaryCollapsed={setIsSummaryCollapsed}
                                    setIsPreviewCollapsed={setIsPreviewCollapsed}
                                    setIsInitialCollapseState={setIsInitialCollapseState}
                                    hideCollapse={fullwidth}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                md={
                                    fullwidth ? 12 :
                                        isSummaryCollapsed
                                            ? 0.75 :
                                            isInitialCollapseState ? 6 : 11.25
                                }
                                sx={{
                                    height: "100%",
                                    overflowY: "hidden",
                                    transition: "all 0.3s ease-in-out",
                                }}
                            >
                                <ReportSummary
                                    loading={loadingDocumentDetails}
                                    id={documentDetails?.id}
                                    metadata={documentDetails?.metadata}
                                    doctorSummary={documentDetails?.doctorSummary}
                                    patientSummary={documentDetails?.patientSummary}
                                    fileName={documentDetails?.name}
                                    fileType={documentDetails?.fileType}
                                    isDoctorApproved={documentDetails?.isDoctorApproved}
                                    feedback={documentDetails?.feedback}
                                    transcriptions={documentDetails?.transcriptions}
                                    patientNote={documentDetails?.patientNote}
                                    symptomAssessment={documentDetails?.symptomAssessment}
                                    isSummaryCollapsed={isSummaryCollapsed}
                                    setIsSummaryCollapsed={setIsSummaryCollapsed}
                                    setIsPreviewCollapsed={setIsPreviewCollapsed}
                                    setIsInitialCollapseState={setIsInitialCollapseState}
                                    showZoom={isInitialCollapseState}
                                    from={from}
                                    hideCollapse={fullwidth}
                                />
                            </Grid>
                        </>
                    )}

                    {!isPatient(userRole) && (
                        <>
                            <Grid
                                item
                                xs={12}
                                md={
                                    fullwidth ? 12 :
                                        isPreviewCollapsed
                                            ? 0.75 :
                                            isInitialCollapseState ? 6 : 11.25
                                    // isSummaryCollapsed ? 11.25 : 6
                                }
                                sx={{
                                    height: "100%",
                                    overflowY: "hidden",
                                    transition: "all 0.3s ease-in-out",
                                }}
                            >
                                <OriginalReport
                                    loading={loadingDocumentDetails && !documentDetails}
                                    error={Boolean(errorDocumentDetails) && !documentDetails}
                                    fileType={documentDetails?.fileType}
                                    filePath={documentDetails?.filePath}
                                    fileName={documentDetails?.name}
                                    id={documentDetails?.id}
                                    status={documentDetails?.status}
                                    patientId={patientId}
                                    isPreviewCollapsed={isPreviewCollapsed}
                                    showZoom={isInitialCollapseState}
                                    setIsSummaryCollapsed={setIsSummaryCollapsed}
                                    setIsPreviewCollapsed={setIsPreviewCollapsed}
                                    setIsInitialCollapseState={setIsInitialCollapseState}
                                    from={from}
                                    hideCollapse={fullwidth}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                md={
                                    fullwidth ? 12 :
                                        isSummaryCollapsed
                                            ? 0.75 :
                                            isInitialCollapseState ? 6 : 11.25
                                    //  isPreviewCollapsed ? 11.25 : 6
                                }
                                sx={{
                                    height: "100%",
                                    overflowY: "hidden",
                                    transition: "all 0.3s ease-in-out",
                                }}
                            >
                                <ReportSummary
                                    loading={loadingDocumentDetails}
                                    id={documentDetails?.id}
                                    metadata={documentDetails?.metadata}
                                    doctorSummary={documentDetails?.doctorSummary}
                                    patientSummary={documentDetails?.patientSummary}
                                    fileName={documentDetails?.name}
                                    fileType={documentDetails?.fileType}
                                    isDoctorApproved={documentDetails?.isDoctorApproved}
                                    feedback={documentDetails?.feedback}
                                    transcriptions={documentDetails?.transcriptions}
                                    patientNote={documentDetails?.patientNote}
                                    symptomAssessment={documentDetails?.symptomAssessment}
                                    isSummaryCollapsed={isSummaryCollapsed}
                                    setIsSummaryCollapsed={setIsSummaryCollapsed}
                                    setIsPreviewCollapsed={setIsPreviewCollapsed}
                                    setIsInitialCollapseState={setIsInitialCollapseState}
                                    showZoom={isInitialCollapseState}
                                    from={from}
                                    hideCollapse={fullwidth}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Box>
        </Box>
    );
};

export default NewReportViewerComponent;
