// third-party imports
import { Box, Typography, useMediaQuery } from "@mui/material";

// project imports
import Button from "../ui/Button";
import CheckedIcon from "../svg/CheckedIcon";
import useAuthStore from "../../store/authStore";
import { isPatient } from "../../utils/auth";
import { capitalizeWordsPreservingSpaces } from "../../utils/common";
import { FormFilledDetailsProps } from "../../types/forms";

const FormFilledDetails = ({
  score,
  patientName,
  onClose,
  maxScore,
  from = ""
}: FormFilledDetailsProps) => {
  // props & state values
  const isMobile = useMediaQuery("(max-width:37.5rem)");

  const { userRole } = useAuthStore();

  // callbacks & functions
  const getTextContent = () => {
    const styles = {
      baseStyle: {
        fontSize: "var(--neuro-font-size-micro)",
        fontWeight: "var(--neuro-font-weight-medium)",
      },
      thankYouText: {
        fontSize: "var(--neuro-font-size-regular)",
        fontFamily: "var(--neuro-font-family-inter)",
        fontWeight: "var(--neuro-font-weight-bold)",
      }
    }

    if (isPatient(userRole) || from === "form-design") {
      return (
        <>
          <Typography sx={styles.baseStyle}>
            Thank you for submitting your information. Your details have been
            successfully saved.
          </Typography>
          {from !== "form-design" && <Typography sx={styles.baseStyle}>
            Our team will review the information you provided, and if any
            further actions are required, we will contact you shortly.
          </Typography>}
        </>
      );
    }
    if (from === "public-form") {
      return (
        <Typography sx={styles.thankYouText}>
          Thank You!
        </Typography>
      );
    }
    return (
      <Typography sx={styles.baseStyle}>
        The form has been successfully submitted on behalf of{" "}
        <span style={{ fontWeight: 700 }}>
          {capitalizeWordsPreservingSpaces(patientName || "")}
        </span>
        . The patient's information has been saved.
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: isMobile ? "0.625rem 0.938rem" : "1.5rem",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: from === "public-form" ? "100%" : "auto",
          justifyContent: from === "public-form" ? "center" : "normal",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "var(--neuro-button-bg-secondary)",
            borderRadius: "50%",
            minWidth: isMobile ? "6.25rem" : "9.375rem",
            minHeight: isMobile ? "6.25rem" : "9.375rem",
            maxWidth: isMobile ? "6.25rem" : "9.375rem",
            maxHeight: isMobile ? "6.25rem" : "9.375rem",
            padding: "0.625rem",
          }}
        >
          <CheckedIcon
            width={isMobile ? "3.75rem" : "6.25rem"}
            height={isMobile ? "3.75rem" : "6.25rem"}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "1.875rem",
            alignItems: "center",
          }}
        >
          {getTextContent() || null}
        </Box>

        {score !== null && score !== undefined && from === "public-form" && (
          <Typography
            sx={{
              fontSize: "var(--neuro-font-size-micro)",
              fontFamily: "var(--neuro-font-family-inter)",
              marginTop: "1rem",
              textAlign: isMobile ? "start" : "center",
            }}
          >
            Your score is{" "}
            <span
              style={{
                fontSize: "var(--neuro-font-size-micro)",
                fontFamily: "var(--neuro-font-family-inter)",
              }}
            >
              {typeof score === "number" ? `${score}${maxScore !== null && maxScore !== undefined ? `/${maxScore}` : ""}` : ""}
            </span>
          </Typography>
        )}

        {score !== null && score !== undefined && !isPatient(userRole) && from !== "public-form" && (
          <Typography
            sx={{
              fontSize: "var(--neuro-font-size-medium-tiny)",
              fontFamily: "var(--neuro-font-family-inter)",
              marginTop: "2rem",
              textAlign: isMobile ? "start" : "center",
            }}
          >
            {`Based on the responses, ${from === "form-design" ? "" : "the patient's"} score is:`}{" "}
            <span
              style={{
                fontSize: "var(--neuro-font-size-medium-tiny)",
                fontFamily: "var(--neuro-font-family-inter)",
                fontWeight: "var(--neuro-font-weight-bold)",
              }}
            >
              {typeof score === "number" ? `${score}${maxScore !== null && maxScore !== undefined ? `/${maxScore}` : ""}` : ""}.
            </span>
          </Typography>
        )}

        {from !== "public-form" && <Box
          sx={{
            marginBlock: isMobile ? "1.875rem" : "3.375rem",
            width: "50%",
            borderBottom: "1px solid var(--neuro-button-bg-secondary)",
          }}
        ></Box>}

        {onClose && <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1.438rem",
          }}
        >
          <Button
            variant="outlined"
            className="outlined-secondary-button"
            color="secondary"
            size="large"
            onClick={onClose}
          >
            Close
          </Button>
        </Box>}

        {from !== "form-design" && from !== "public-form" && <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "2.75rem",
            marginBottom: "1.25rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "var(--neuro-font-size-medium-smallest)",
              fontFamily: "var(--neuro-font-family-inter)",
            }}
          >
            Any issue?{" "}
            <span>
              {" "}
              <a
                // href="#"
                style={{
                  color: "var(--neuro-black-text)",
                  marginInline: "0.375rem",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Click Here
              </a>
            </span>
            reach out to our technical team.
          </Typography>
        </Box>}
      </Box>
    </Box>
  );
};

export default FormFilledDetails;
