import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isDoctorOrAdminOrClinicAdmin } from "../../utils/auth";
import useAuthStore from "../../store/authStore";
import { Box } from "@mui/material";
import useAppStore from "../../store/appStore";
import CustomBreadcrumbs from "../shared/CustomBreadcrumbs";
import { capitalizeFirstLetter } from "../../utils/common";
import Payments from "../payments/Payments";

const PatientPayments = () => {
    // props & state values
    const navigate = useNavigate();
    const { userRole } = useAuthStore();
    const { selectedPatientInList } = useAppStore();

    const breadcrumbItems = [
        { label: "Patients", href: "/patients" },
        {
            label: `${capitalizeFirstLetter(selectedPatientInList?.user?.firstName || "")}`,
        },
        { label: "Payments" },
    ];

    // callbacks & functions
    useEffect(() => {
        if (!selectedPatientInList) {
            if (isDoctorOrAdminOrClinicAdmin(userRole)) {
                navigate("/patients");
            } else {
                navigate("/appointments");
            }
        }
    }, [selectedPatientInList]);

    return (
        <Box
            sx={{
                backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "100%",
                height: "100%",
                paddingBlock: selectedPatientInList ? "1rem" : 0,
                paddingInline: selectedPatientInList ? "0.875rem" : 0,
            }}
        >
            {selectedPatientInList && <CustomBreadcrumbs items={breadcrumbItems} />}
            <Payments patientId={selectedPatientInList?.id || ""} />
        </Box>
    );
};

export default PatientPayments;
