import React, { useRef, ChangeEvent, DragEvent, useState } from "react";
import { Box, Link, Typography } from "@mui/material";
import Modal from "../ui/Modal";
import Button from "../ui/Button";
import Spinner from "../ui/Spinner";
import UploadIcon from "../svg/UploadIcon";
import UploadDocumentIcon from "../svg/UploadDocumentIcon";
import useAppStore from "../../store/appStore";
import { addPatientInBulk } from "../../services/patientService";
import { PatientsBulkAddModalProps } from "../../types/patients";

const PatientsBulkAddModal: React.FC<PatientsBulkAddModalProps> = ({
    open,
    onClose,
    width,
    closeIcon,
    afterAdd,
    from = "",
    ...props
}) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const allowedExtensions = [".xls", ".xlsx", ".csv"];

    const [loadingAddPatients, setLoadingAddPatients] = useState(false);
    const [dragging, setDragging] = useState(false); // Track dragging state

    const { showSnackbar } = useAppStore();

    // callbacks & functions
    const isValidFile = (fileName: string) => {
        const fileExtension = fileName.slice(fileName.lastIndexOf(".")).toLowerCase();
        return allowedExtensions.includes(fileExtension);
    };

    // to reset files input
    const resetFileInput = () => {
        if (fileInputRef.current) fileInputRef.current.value = "";
        if (onClose) onClose();
    };

    // file uploading api handler
    const uploadFilesHandler = async (files: File[]) => {
        setLoadingAddPatients(true);
        const formData = new FormData();
        files.forEach((file) => formData.append("file", file));
        try {
            await addPatientInBulk(formData);
            showSnackbar("Patients added successfully", "success");
            setLoadingAddPatients(false);

            if (afterAdd) {
                afterAdd();
            }
            if (onClose) {
                onClose();
            }
            resetFileInput();
        }
        catch (error: any) {
            console.log("error in bulk", error);
            showSnackbar(`${error?.response?.data?.message || "Something went wrong"}`, "error");
            setLoadingAddPatients(false);
        }
    };

    // uplaod button handler
    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files) {
            const selectedFiles = Array.from(event.target.files || []);

            const invalidFiles = selectedFiles?.filter(file => !isValidFile(file.name));

            if (invalidFiles.length > 0) {
                showSnackbar("Invalid file format. Please upload .xls, .xlsx, or .csv files only.", "error");
                if (fileInputRef.current) {
                    fileInputRef.current.value = ""; // Reset the file input
                }
                return;
            }

            await uploadFilesHandler(selectedFiles);

            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        }
    };

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    // drag-drop handler
    const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
        if (event.dataTransfer.files) {
            event.preventDefault();
            setDragging(false);
            const files = Array.from(event.dataTransfer.files);

            const invalidFiles = files.filter(file => !isValidFile(file.name));

            if (invalidFiles.length > 0) {
                showSnackbar("Invalid file format. Please upload .xls, .xlsx, or .csv files only.", "error");
                if (fileInputRef.current) {
                    fileInputRef.current.value = ""; // Reset the file input
                }
                return;
            }

            if (files.length > 0) {
                await uploadFilesHandler(files);
            }
            else {
                showSnackbar("Please select a file", "error");
            }
        }
        else {
            showSnackbar("Please select a file", "error");
        }

    };

    // sample file download for bulk
    const handleDownloadSample = () => {
        const link = document.createElement("a");
        link.href = `/sample-bulk-upload-patients.csv`; // Path to the CSV file
        link.download = "sample.csv"; // Default file name for download
        link.click();
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            closeIcon={closeIcon}
            width={width ? width : "33.75rem"}
            {...props}
        >
            <Box
                sx={{
                    // width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingInline: "2.5rem",
                }}
                onClick={(event) => event.stopPropagation()}
            >
                <Typography
                    className="form-heading"
                    sx={{
                        textAlign: "center",
                        fontWeight: "var(--neuro-font-weight-semibold)",
                        fontSize: "var(--neuro-font-size-tiny)",
                    }}
                >
                    Add Patients
                </Typography>
                <Box sx={{ mt: "2rem" }}>
                    <UploadDocumentIcon />
                </Box>

                {/* Drag-and-Drop Zone */}
                <Box
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    sx={{
                        width: "100%",
                        mt: "1.5rem",
                        padding: "2rem",
                        border: "2px dashed var(--neuro-secondary_border)",
                        borderRadius: "8px",
                        textAlign: "center",
                        color: "var(--neuro-table-head-primary)",
                        backgroundColor: dragging ? "var(--neuro-bg-primary-seagreen)" : "var(--neuro-bg-light-grey-secondary)",
                        cursor: "pointer",
                        opacity: 1,
                    }}
                >
                    <Typography sx={{ color: "text.secondary" }}>
                        {dragging ? "Drop here" : "Drag and drop files here, or click Upload"}
                    </Typography>
                </Box>

                {/* Upload Button */}
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        mt: "1.5rem",
                    }}
                >
                    {/* {isMobileDevice && <MobileDocumentScanner />} */}
                    <input
                        type="file"
                        id="file-upload"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        accept=".xls,.xlsx,.csv"
                        disabled={loadingAddPatients}
                    />
                    <label htmlFor="file-upload">
                        <Button
                            variant="outlined"
                            className="outlined-secondary-button"
                            startIcon={<UploadIcon />}
                            disabled={loadingAddPatients}
                            onClick={() => fileInputRef.current?.click()}
                        >
                            {loadingAddPatients ? <Spinner /> : "Upload"}
                        </Button>
                    </label>
                </Box>

                <Box sx={{
                    mt: "1.5rem",
                    display: "flex",
                    alignItmes: "center",
                    flexDirection: "column",
                    gap: "1.25rem"
                }}>
                    <Typography sx={{ textAlign: "center" }}>To ensure a smooth upload process, please use our sample Excel template. </Typography>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <Link sx={{ textAlign: "center", cursor: "pointer", width: "max-content" }} onClick={handleDownloadSample}>Download Template</Link>
                    </Box>

                </Box>
            </Box>
        </Modal>
    );
};

export default PatientsBulkAddModal;
