import React from 'react';
import { Typography, Box } from '@mui/material';

interface HeadingProps {
    title: string;
    subtitle: string;
    questionsCount: number;
}

export const FormHeading: React.FC<HeadingProps> = ({
    title,
    subtitle,
    questionsCount,
}) => (
    <Box sx={{ textAlign: 'center' }}>
        <Typography
            sx={{
                fontSize: 'var(--neuro-font-size-large-small)',
                fontWeight: "var(--neuro-font-weight-bold)",
                marginTop: '1.688rem',
                fontFamily: 'var(--neuro-font-family-roboto-condensed)',
                lineHeight: "120%"
            }}
        >
            {title}
        </Typography>
        <Typography
            sx={{
                fontSize: 'var(--neuro-font-size-mini)',
                marginTop: '2.188rem',
                fontFamily: 'var(--neuro-font-family-roboto-condensed)',
                lineHeight: "120%",
                '@media (max-width: 991px)': {
                    maxWidth: '100%'
                }
            }}
        >
            {subtitle}
        </Typography>
        <Typography
            sx={{
                marginTop: '9px',
                lineHeight: "120%",
            }}
        >
            {questionsCount} Questions
        </Typography>
    </Box>
);