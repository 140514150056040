import React, { useState, useEffect } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
// import Modal from "../ui/Modal";
import OtpInput from "../ui/OtpInput";
import Button from "../ui/Button";
import Spinner from "../ui/Spinner";
// import { resendOtp, verifyOtp } from "../../services/authService";
// import useAuthStore from "../../store/authStore";
// import { useNavigate } from "react-router-dom";
// import Signup from "./Signup"; // Import the Signup component
import Logo from "../shared/Logo";
import { AbhaVerificationOtpModalProps } from "../../types/auth";
// import { isValidAadhar, isValidAbha } from "../../utils/common";
// import useAppStore from "../../store/appStore";
// import Input from "../ui/Input";
import {
  // SubmitHandler, 
  useForm
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Define validation schema for Yup
const schema = yup.object().shape({
  otp: yup
    .string()
    .matches(/^[0-9]{6}$/, "OTP must be 6 digits")
    .required("OTP is required"),
});

interface FormInputs {
  otp: string;
}

// export interface AbhaVerificationOtpModalProps {
//     type:string;
//     verifyOtp:(data:any)=>void;
//     resendOtp:()=>void;
//     loader:boolean;
//     reloader:boolean;
//   }

const AbhaVerificationOtpModal: React.FC<AbhaVerificationOtpModalProps> = ({ type, verifyOtp, resendOtp, loader, reloader }) => {
  const {
    // handleSubmit,
    // register,
    // formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  // const { showSnackbar } = useAppStore();
  const [otp, setOtp] = useState("");
  const [errorMessage] = useState<string | null>(null); // State to handle error message
  const [timer] = useState(30); // Timer state
  const [isTimerActive] = useState(false); // Timer activation state

  const numInputs = 6;

  console.log(type);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if the screen is small (mobile)
  ;

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isTimerActive]);

  useEffect(() => {
    if (otp.length === 6) {
      console.log("otp", otp)
    }
  }, [otp]);

  const onSubmit = async () => {
    console.log("verify-otp-data", otp)
    verifyOtp({ otp })
  };


  const onResend = async () => {
    console.log("verify-otp-data", otp)
    resendOtp({ otp })
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingInline: "1.25rem",
        margin: "1rem"
      }}
    >
      <>
        <Box sx={{ marginBottom: "1rem" }}>
          <Logo width={isMobile ? "5rem" : "6.25rem"} />
        </Box>
        <Typography
          className="form-heading"
          sx={{
            textAlign: "center",
            fontSize: isMobile ? "18px" : "24px",
            fontWeight: "var(--neuro-font-weight-bold)",
            fontFamily: "var(--neuro-font-family-roboto-condensed)",
            lineHeight: "120%",
          }}
        >
          Verify with OTP
        </Typography>
      </>

      <>
        <OtpInput value={otp} onChange={setOtp} numInputs={numInputs} />
        {/* Display error message */}
        {errorMessage && (
          <Typography
            color="error"
            sx={{
              marginTop: "1rem",
              marginBottom: "1rem",
              textAlign: "center",
              fontSize: isMobile
                ? "var(--neuro-font-size-smallest-x)"
                : "var(--neuro-font-size-extra-small)",
            }}
          >
            {errorMessage}
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            // justifyContent: "space-between",
            gap: "1.25rem",
            alignItems: "center",
            mt: "2rem",
            mb: "1rem",
          }}
        >
          <Button
            type="button"
            variant="contained"
            color="success"
            className="success-button"
            disabled={loader || reloader}
            onClick={onSubmit}
            sx={{
              opacity: loader || reloader ? 0.75 : 1,
              textTransform: "none",
              width: isMobile ? "6.25rem" : "9.375rem", // 100px or 150px
              height: isMobile ? "2.5rem" : "3rem", // 40px or 48px
              color: "var(--neuro-white-text)",
            }}
          >
            {loader ? <Spinner size={"1.25rem"} /> : "Submit"}
          </Button>
          <Button
            variant="text"
            color="secondary"
            disableRipple
            className="secondary-text-button"
            disabled={reloader || isTimerActive} // Disable while timer is active
            onClick={onResend}
            sx={{
              textTransform: "none",
              fontSize: isMobile
                ? "var(--neuro-font-size-smallest-x)"
                : "var(--neuro-font-size-extra-small)",
            }}
          >
            {reloader ? (
              <Spinner size={"1.25rem"} />
            ) : (
              `Resend OTP ${isTimerActive ? `(${timer})` : ""}`
            )}
          </Button>
        </Box>
      </>
    </Box>
  );
};

export default AbhaVerificationOtpModal;
