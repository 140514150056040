import React, { useState, useEffect } from "react";
import {
  Box,
  // CircularProgress,
  useMediaQuery
} from "@mui/material";
import Modal from "../ui/Modal";
import OtpTypeSelect from "./OtpTypeSelect";
import AbhaAccountSelect from "./AbhaAccountSelect";
import { sendOtpViaAbhaCommunication, 
         verifyViaAbhaCommunication, 
         verifyAbhaAccount, 
         sendAbhaOtpviaAbhaMobile,
         verifyAbhaNumberViaAbhaOtp,
         sendAadharOtpviaAadhaar,
         verifyAbhaNumberViaAadhaar
 } from "../../services/authService"; // Example API functions
import useAppStore from "../../store/appStore";
import useAuthStore from "../../store/authStore";
import { useNavigate } from "react-router-dom";
import AbhaVerificationOtpModal from "./AbhaVerificationOtpModal";
interface VerificationModalProps {
  input: string;
  step: any;
  setStep: any;
  open: boolean;
  onClose: () => void;
}

const VerificationModal: React.FC<VerificationModalProps> = ({ open, onClose, input, step, setStep }) => {
  console.log("step", step);
  const isMobile = useMediaQuery("(max-width:37.5rem)");
  const [loading, setLoading] = useState(true);
  const [reloading, setReloading] = useState(false);
  const [stepData, setStepData] = useState<{ [key: string]: any }>({}); // Data for each step
  const { showSnackbar } = useAppStore();
  const navigate = useNavigate();
  const login = useAuthStore((state) => state.login);


  // Fetch ABHA accounts before showing AbhaAccountSelect
  useEffect(() => {
    const fetchData = async () => {
      if (step === "mobileOtpModal") {
        setLoading(true);
        try {
          const result = await sendOtpViaAbhaCommunication(input);
          const resultData = await result.data;
          showSnackbar(result.message, "success");
          setStepData(prev => ({ ...prev, sendOtpViaAbhaCommunication: resultData }))
          console.log(result);
        } catch (error: any) {
          showSnackbar(error.response.data.message, "error");
          console.error("Error fetching ABHA accounts:", error);
          onClose();
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [step, input]);

  const verifyAbhaCommunicationOtp = async (data: any) => {
    setLoading(true);
    try {
      console.log("verify-abha-communication", data);
      const result = await verifyViaAbhaCommunication(stepData["sendOtpViaAbhaCommunication"].txnId, data.otp);
      const resultData = await result.data;
      showSnackbar(result.message, "success");
      setStepData(prev => ({ ...prev, verifyViaAbhaCommunication: resultData }))
      setStep("abhaAccountSelect")
    } catch (error: any) {
      showSnackbar(error.response.data.message, "error");
      console.error("Error fetching ABHA accounts:", error);
      onClose();
    } finally {
      setLoading(false);
    }
  }

  const verifyAbhaNumberViaAaadhaarOtp = async (data: any) => {
    setLoading(true);
    try {
      const result = await verifyAbhaNumberViaAadhaar(stepData["sendAadharOtpviaAadhaar"].txnId, data.otp, stepData["abhaNumber"]);
      const responseData = await result.data;
      showSnackbar(result?.message, "success");
      login(responseData?.accessToken, responseData?.refreshToken);
      navigate("/dashboard");
    } catch (error: any) {
      showSnackbar(error.response.data.message, "error");
      console.error("Error fetching ABHA accounts:", error);
      onClose();
    } finally {
      setLoading(false);
    }
  }

  const verifyAbhaNumberViaAbha = async (data: any) => {
    setLoading(true);
    try {
      const result = await verifyAbhaNumberViaAbhaOtp(stepData["sendAbhaOtpviaAbhaMobile"].txnId, data.otp, stepData["abhaNumber"]);
      const responseData = await result.data;
      showSnackbar(result?.message, "success");
      login(responseData?.accessToken, responseData?.refreshToken);
      navigate("/dashboard");
    } catch (error: any) {
      showSnackbar(error.response.data.message, "error");
      console.error("Error fetching ABHA accounts:", error);
      onClose();
    } finally {
      setLoading(false);
    }
  }

  const resendAbhaCommunicationOtp = async () => {
    setReloading(true);
    try {
      const result = await sendOtpViaAbhaCommunication(input);
      const resultData = await result.data;
      showSnackbar(result.message, "success");
      setStepData({ ...resultData })
    } catch (error: any) {
      showSnackbar(error.response.data.message, "error");
      console.error("Error fetching ABHA accounts:", error);
      onClose();
    } finally {
      setReloading(false);
    }
  }


  // const resendEmailOtp = async ()=>{
  //   setReloading(true);
  //   try {
  //     const result = await sendOtpViaAbhaCommunication(input);
  //     const resultData = await result.data;
  //     showSnackbar(result.message, "success");
  //     setStepData({...resultData});
  //   } catch (error:any) {
  //     showSnackbar(error.response.data.message, "error");
  //     console.error("Error fetching ABHA accounts:", error);
  //     onClose();
  //   } finally {
  //     setReloading(false);
  //   }
  // }


  const handleOtpTypeSelect = async (type: string) => {
    setLoading(true);
    if (type === "aadhaar") {
      try {
        // console.log("verify-abha-communication",data);
        const result = await sendAadharOtpviaAadhaar({ abhaNumber: input });
        const resultData = await result.data;
        console.log(resultData);
        showSnackbar(result.message, "success");
        setStepData(prev=>({...prev,sendAadharOtpviaAadhaar:resultData , abhaNumber:input}))
        setStep("abhaAadhaarOtpSelect")
      } catch (error: any) {
        showSnackbar(error.response.data.message, "error");
        console.error("Error fetching ABHA accounts:", error);
        onClose();
      } finally {
        setLoading(false);
      }
    } else {
      try {
        // console.log("verify-abha-communication",data);
        const result = await sendAbhaOtpviaAbhaMobile({ abhaNumber: input });
        const resultData = await result.data;
        console.log(resultData);
        showSnackbar(result.message, "success");
        setStepData(prev=>({...prev,sendAbhaOtpviaAbhaMobile:resultData , abhaNumber:input}))
        setStep("abhaMobileOtpSelect")
      } catch (error: any) {
        showSnackbar(error.response.data.message, "error");
        console.error("Error fetching ABHA accounts:", error);
        onClose();
      } finally {
        setLoading(false);
      }
    }


  };

  const handleAbhaAccountSelect = async (account: any) => {
    setReloading(true);
    try {
      // setSelectedAbhaAccount(account);
      const result = await verifyAbhaAccount(stepData["verifyViaAbhaCommunication"].txnId, account.ABHANumber, stepData["verifyViaAbhaCommunication"].token);
      const responseData = await result?.data
      showSnackbar(result?.message, "success");
      login(responseData?.accessToken, responseData?.refreshToken);
      navigate("/dashboard");
    } catch (error: any) {
      showSnackbar(error.response.data.message, "error");
      console.error("Error fetching ABHA accounts:", error);
      onClose();
    } finally {
      setReloading(false);
    }
  };

  const renderContent = () => {
    switch (step) {
      case "otpTypeSelect":
        return <OtpTypeSelect otpTypes={[]} onSelect={handleOtpTypeSelect} />;
      case "abhaAccountSelect":
        return <AbhaAccountSelect accounts={stepData["verifyViaAbhaCommunication"]?.accounts || []} onAccountSelect={handleAbhaAccountSelect} />;
      case "mobileOtpModal":
        return <AbhaVerificationOtpModal type="mobile" verifyOtp={verifyAbhaCommunicationOtp} resendOtp={resendAbhaCommunicationOtp} loader={loading} reloader={reloading} />;
      case "abhaAadhaarOtpSelect":
        return <AbhaVerificationOtpModal type="mobile" verifyOtp={verifyAbhaNumberViaAaadhaarOtp} resendOtp={verifyAbhaNumberViaAaadhaarOtp} loader={loading} reloader={reloading} />;
        case "abhaMobileOtpSelect":
          return <AbhaVerificationOtpModal type="mobile" verifyOtp={verifyAbhaNumberViaAbha} resendOtp={verifyAbhaNumberViaAbha} loader={loading} reloader={reloading} />;
      }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeIcon
      width={isMobile ? "20rem" : "31.25rem"}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1.5rem",
        }}
      >
        {renderContent()}
      </Box>
    </Modal>
  );
};

export default VerificationModal;
