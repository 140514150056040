import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Button from "../ui/Button";
import Drawer from "../shared/Drawer";
import LoadingScreen from "../shared/LoadingScreen";
import ContentCenter from "../shared/ContentCenter";
import { format } from "date-fns";
import { capitalizeFirstLetter } from "../../utils/common";
import useAuthStore from "../../store/authStore";
import { isPatient } from "../../utils/auth";
import assignFormStore from "../../store/formStore";
import { fetchFormRequestsApi } from "../../services/formService";
import useAppStore from "../../store/appStore";
import { PatientFormsListProps } from "../../types/patients";
import { TableWrapper } from "../shared/TableWrapper";
import ComponentFilter from "../shared/ComponentFilter";
import { debounce } from "lodash";
import NewSurveyComponent from "../FormsCreator/SurveyComponents/NewSurveyComponent";

const PatientFormsList: React.FC<PatientFormsListProps> = ({
  patientId,
  patientName,
  from = "",
}) => {
  // props & state values
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedForm, setSelectedForm] = useState<any>(null);
  console.log("selectedForm in patient forms table", selectedForm);
  const [formAction, setFormAction] = useState<string | null>(null);

  const [formRequests, setFormRequests] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const isMobile = useMediaQuery("(max-width:37.5rem)");

  const { showSnackbar, formsFilter, setFilter } = useAppStore();

  const { userRole } = useAuthStore();

  const {
    formSubmitted,
    formAssigned,
    // assignForm,
    submitLoading,
    submitError,
    submitFormResponse,
    cleanUpFormSubmittedState,
  } = assignFormStore();

  // callbacks & functions
  const loadFormRequests = async (id: string, search?: string) => {
    try {
      const response = await fetchFormRequestsApi(id, search);
      setFormRequests(response?.data || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching referrals:", error);
      setLoading(false);
    }
  };

  const onSubmitForm = (responses: any, score: number) => {
    const data = {
      responses,
      score,
    };
    submitFormResponse(selectedForm?.id, data);
  };

  const onCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedForm(null);
    setFormAction(null);
    cleanUpFormSubmittedState();
  };

  useEffect(() => {
    if (patientId) {
      loadFormRequests(patientId, formsFilter?.searchValue);
    }
  }, [patientId]);

  useEffect(() => {
    if (formSubmitted && patientId) {
      loadFormRequests(patientId, formsFilter?.searchValue);
      // onCloseDrawer();
    }
  }, [formSubmitted, patientId]);

  useEffect(() => {
    if (formAssigned && patientId) {
      loadFormRequests(patientId, formsFilter?.searchValue);
    }
  }, [formAssigned, patientId]);

  useEffect(() => {
    if (submitError) {
      showSnackbar("Could not submit form, please try again", "error");
      cleanUpFormSubmittedState();
    }
    if (submitLoading) {
      showSnackbar("Form is submitting, please try again", "info");
    }

    if (formSubmitted) {
      showSnackbar("Form is submittied", "success");
    }
  }, [formSubmitted, submitLoading, submitError]);



  const formattedDate = (dateString: string | null) => {
    if (!dateString || dateString === "") {
      return "";
    }
    const formatedDate = format(new Date(dateString), "d MMMM yyyy");

    return formatedDate;
  };

  const handleInputChange = (value: string) => {
    loadFormRequests(patientId || "", value);
  };

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      // if (value.length >= config?.SEARCH_FILTER_LETTER) {
      //   handleInputChange(value, formsFilter);
      // }
      handleInputChange(value);
    }, 1000),
    []
  );


  const handleStoreSearchValueChange = (value: string) => {
    setFilter("formsFilter", {
      searchValue: value,
    });

    debouncedSearch(value);

    // if (value === "") {
    //   // If the input is cleared, fetch all appointments
    //   handleInputChange("", appointmentsFilter);
    // }

    // if (
    //   value.length >= config?.SEARCH_FILTER_LETTER
    //   // value.length % config?.SEARCH_FILTER_LETTER === 0
    // ) {
    //   debouncedSearch(value, appointmentsFilter);
    // }
  };

  // table columns
  const columns = [
    {
      id: "form.name",
      label: "Form Name",
      sortable: true,
      sortComparator: (a: any, b: any) => {
        const nameA = a?.form?.name || "-"; // Default to "-" if name is undefined
        const nameB = b?.form?.name || "-"; // Default to "-" if name is undefined

        return nameA.localeCompare(nameB);
      },
      render: (_: any, row: any) => `${row?.form?.name || "-"}`,
    },
    {
      id: "assignedBy.firstName",
      label: "Assigned By",
      sortable: true,
      sortComparator: (a: any, b: any) => {
        const nameA =
          capitalizeFirstLetter(a?.assignedBy?.firstName || "") || "";
        const nameB =
          capitalizeFirstLetter(b?.assignedBy?.firstName || "") || "";

        return nameA.localeCompare(nameB);
      },
      render: (_: any, row: any) =>
        `${capitalizeFirstLetter(row?.assignedBy?.firstName || "") || "-"}`,
    },
    {
      id: "createdAt",
      label: "Assigned On",
      render: (value: any) => `${formattedDate(value || "") || "-"}`,
      sortable: true,
    },
    {
      id: "status",
      label: "Status",
      render: (value: string) => `${value || "-"}`,
      sortable: true,
    },
    // Conditionally add the score column if the userRole is not a patient
    ...(!isPatient(userRole)
      ? [
        {
          id: "score",
          label: "Score",
          sortable: true,
          render: (_: any, row: any) =>
            `${row?.form?.isScoreBased
              ? row?.score === 0
                ? 0
                : row?.score || "-"
              : "-"
            }`,
        },
      ]
      : []),
    {
      id: "actions",
      label: "Actions",
      render: (_: any, row: any) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <>
            {row?.status === "COMPLETED" && (
              <>
                <Button
                  variant="outlined"
                  size="small"
                  className="outlined-secondary-button"
                  onClick={() => {
                    setSelectedForm(row);
                    setFormAction("Edit");
                    setDrawerOpen(true);
                  }}
                  sx={{
                    minHeight: "1.375rem",
                    maxHeight: "1.375rem",
                  }}
                >
                  Edit
                </Button>

                <Button
                  variant="outlined"
                  size="small"
                  className="outlined-secondary-button"
                  onClick={() => {
                    setSelectedForm(row);
                    setFormAction("View");
                    setDrawerOpen(true);
                  }}
                  sx={{
                    minHeight: "1.375rem",
                    maxHeight: "1.375rem",
                  }}
                >
                  View
                </Button>
              </>
            )}

            {row?.status === "PENDING" && (
              <Button
                size="small"
                variant="outlined"
                className="outlined-secondary-button"
                onClick={() => {
                  setSelectedForm(row);
                  setFormAction("Fill");
                  setDrawerOpen(true);
                }}
                sx={{
                  minHeight: "1.375rem",
                  maxHeight: "1.375rem",
                }}
              >
                Fill
              </Button>
            )}
          </>
        </Box>
      ),
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: from === "patient-list" ? "calc(100% - 3.25rem)" : "100%",
      }}
    >
      <Box sx={{ marginBottom: "1rem" }}>
        <ComponentFilter
          isEmpty
          hideRangeFilter
          hideStatusFilter
          handleStoreSearchValueChange={handleStoreSearchValueChange}
          searchValue={formsFilter?.searchValue || ""}
          searchFilterPlaceholder="Form Name"
          searchFilterWidth="16rem"
        />
      </Box>

      <Box sx={{ height: "calc(100% - 4.75rem)", overflowY: "auto" }}>
        {loading && <LoadingScreen />}

        {!loading && formRequests && formRequests.length === 0 && (
          <ContentCenter>
            <Typography className="no-data">Forms not found</Typography>
          </ContentCenter>
        )}

        {!loading && formRequests && formRequests.length > 0 && (
          <TableWrapper
            columns={columns}
            rows={formRequests}
            stickyHeader={true}
            maxHeight="100%"
          />
        )}
      </Box>

      {/* view/edit form drawer */}
      <Drawer
        open={drawerOpen}
        width={isMobile ? "100vw" : "45vw"}
        headerText={`${patientName}`}
        onClose={() => {
          setSelectedForm(null);
          setFormAction(null);
          setDrawerOpen(false);
          cleanUpFormSubmittedState();
        }}
      >
        <Box
          sx={{
            paddingInline: "1rem",
            paddingTop: "1.25rem",
            height: "calc(100% - 3.75rem)",
            overflowY: "auto",
          }}
        >
          <>
            {selectedForm && (
              <>
                {selectedForm?.form && selectedForm?.form?.questions && selectedForm?.form?.questions?.pages && selectedForm?.form?.questions?.pages?.length > 0 ? (
                  <>
                    <NewSurveyComponent
                      questionData={{
                        isAutoSubmit: Boolean(selectedForm?.form.isAutoSubmit),
                        isScoreBased: Boolean(selectedForm?.form.isScoreBased),
                        formName: selectedForm?.form?.name || "",
                        pages: selectedForm?.responses ? [...selectedForm?.responses?.pages] : [...selectedForm?.form?.questions?.pages],
                      }}
                      from="patient-forms"
                      onFinish={onSubmitForm}
                      readonly={formAction === "View" ? true : false}
                      scoreForReadOnly={Boolean(selectedForm?.form?.isScoreBased) ? selectedForm?.score : null}
                      onClose={onCloseDrawer}
                      patientName={patientName}
                      showTooltip={false}
                    />
                  </>
                ) : (
                  <p>Loading or no data available...</p>
                )}
              </>
            )}
          </>
        </Box>
      </Drawer>
    </Box>
  );
};

export default PatientFormsList;
