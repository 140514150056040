export const tableStyles = {
  tableCell: {
    fontWeight: "var(--neuro-font-weight-bold)",
    color: "var(--neuro-table-head-primary)",
    fontFamily: "var(--neuro-font-family-roboto-condensed)",
    fontSize: "14px",
  },
  subTableCell: {
    fontWeight: "var(--neuro-font-weight-regular)",
    color: "var(--neuro-table-head-primary)",
    fontFamily: "var(--neuro-font-family-roboto-condensed)",
    fontSize: "14px",
  },
};

export const selectStyles = {
  withGreyBackground: {
    height: "2.625rem",
    backgroundColor: "var(--neuro-input-bg-primary) !important",
    borderRadius: "0.625rem !important",
    "& .MuiInputBase-root": {
      backgroundColor: "var(--neuro-input-bg-primary) !important",
      borderRadius: "0.625rem !important",
      border: "none !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
  countryCode: {
    maxWidth: "6.25rem",
    height: "2.625rem",
    backgroundColor: "var(--neuro-input-bg-primary) !important",
    borderRadius: "0.625rem !important",
    "& .MuiInputBase-root": {
      backgroundColor: "var(--neuro-input-bg-primary) !important",
      borderRadius: "0.625rem !important",
      border: "none !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
};

export const erroMessageStyles = {
  color: "#d32f2f",
  fontSize: "var(--neuro-font-size-smallest-x)",
  marginTop: "0.188rem",
  marginLeft: "0.875rem",
};

export const inputLabelStyles = {
  fontSize: "var(--neuro-font-size-smaller-plus) !important",
  fontFamily: "var(--neuro-font-family-roboto-condensed) !important",
  color: "var(--neuro-black-text) !important",
  lineHeight: "120% !important",
};

// export const checkboxLabelStyles = {
//   fontSize: "var(--neuro-font-size-smaller-plus)",
//   fontFamily: "var(--neuro-font-family-roboto-condensed)",
//   color: "var(--neuro-black-text)",
//   lineHeight: "120%",
// };
