import { Box, IconButton, Typography } from "@mui/material";
// import Button from "../ui/Button";
import HelpSupportModal from "../shared/HelpSupportModal";
import AssistantIcon from "../svg/AssistantIcon";
import HelpSupportModalContent from "../shared/HelpSupportModalContent";
import { useState } from "react";
import Tooltip from "../ui/Tooltip";
// import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// import useAppStore from "../../store/appStore";
// import Tooltip from "../ui/Tooltip";

const SidebarFooter = ({
  showIconOnly = false,
}: {
  showIconOnly?: boolean;
}) => {
  // props & state values
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  // const isMobile = useMediaQuery("(max-width:50rem)");
  // const { setIsMenuCollapsed, setDrawerOpen } = useAppStore();

  // callbacks & functions
  const handleHelpSupport = () => {
    setHelpModalOpen(true);
  };

  // const handleMenuIconClick = () => {
  //   if (isMobile) {
  //     setDrawerOpen(false);
  //   } else {
  //     setIsMenuCollapsed(true);
  //   }
  // };

  return (
    <>
      {showIconOnly ? (
        <>
          <Tooltip title="Need help?" placement="right">
            <IconButton
              disableRipple
              sx={{
                height: "3.125rem",
                width: "3.125rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                p: 0,
                marginInline: "auto",
                marginBottom: "1rem",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
              onClick={handleHelpSupport}
            >
              <AssistantIcon height="3.125rem" width="3.125rem" />
            </IconButton>
          </Tooltip>

          <HelpSupportModalContent
            open={helpModalOpen}
            handleClose={() => {
              setHelpModalOpen(false);
            }}
          />
        </>
      ) : (
        <Box
          bgcolor="var(--neuro-bg-darkblue-primary)"
          textAlign="center"
          sx={{
            borderRadius: "1.875rem 1.875rem 0 0", // 30px
            padding: "1.0625rem 1.25rem 1.875rem 1.25rem", // 17px 20px 30px 20px
            // height: "16.5625rem", // 265px
            height: "11.25rem", // 180px
            // maxHeight: "16.5625rem", // 265px
            maxHeight: "14.375rem", // 230px
          }}
        >
          <Box
            sx={{
              position: "relative",
              backgroundColor: "var(--neuro-white-text)",
              borderRadius: "1.25rem", // 20px
              // height: "9.375rem", // 150px
              height: "6.25rem", // 100px
              // marginBottom: "2.0625rem", // 33px
              marginBottom: "0.75rem", // 12px
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.625rem", // 10px
                alignItems: "center",
                zIndex: 1,
              }}
            >
              <AssistantIcon height="3.125rem" width="3.125rem" />
              <Typography
                sx={{
                  fontSize: "var(--neuro-font-size-medium-smallest)",
                  fontWeight: "var(--neuro-font-weight-bold)",
                  lineHeight: "120%",
                  fontFamily: "var(--neuro-font-family-roboto-condensed)",
                  textAlign: "center",
                  paddingLeft: "0.9375rem", // 15px
                  color: "var(--neuro-black-text)",
                  zIndex: 1,
                }}
              >
                Need Assistance?
              </Typography>
            </Box>

            <Box
              sx={{
                position: "absolute",
                border: "0.063rem dashed var(--neuro-grey_border)",
                backgroundColor: "var(--neuro-bg-light-grey)",
                // width: "9.75rem", // 156px
                // height: "9.75rem", // 156px
                width: "5rem", // 80px
                height: "5rem", // 80px
                // bottom: "-1.6875rem", // -27px
                // left: "-1.375rem", // -22px
                bottom: 0, // -27px
                left: 0, // -22px
                borderRadius: "50%",
                opacity: 0.5,
              }}
            >
              <img
                src="/uprade-bg.png"
                alt="upgrade-logo"
                style={{
                  position: "absolute",
                  bottom: "0.25rem", // 4px
                  left: "0.25rem", // 4px
                  opacity: 1,
                  width: "6rem",
                  height: "6rem",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <HelpSupportModal from="sidebar" />
          </Box>
        </Box>
      )}
    </>
  );
};

export default SidebarFooter;
