import React, { useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputProps } from "../../types/ui";

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type,
      placeholder,
      name,
      value,
      onChange,
      className,
      error,
      helperText,
      label,
      id,
      required,
      onBlur,
      onFocus,
      rows,
      sx,
      startAdornment,
      endAdornment,
      defualtValue,
      inputProps = null,
      fullWidth = false,
      readOnly = false,
      multiline = false,
      disabled = false,
      ...props
    },
    ref
  ) => {
    // props & state values
    const [showPassword, setShowPassword] = useState<boolean>(false);

    // callbacks & functions
    const handleClickShowPassword = () => {
      setShowPassword((prev) => !prev);
    };

    const handleMouseDownPassword = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      event.preventDefault();
    };
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.375rem",
          width: fullWidth ? "100%" : "auto",
        }}
      >
        {label && (
          <Typography
            sx={{
              // fontSize: "var(--neuro-font-size-medium-smallest)",
              fontSize: "var(--neuro-font-size-smaller-plus)",
              fontFamily: "var(--neuro-font-family-roboto-condensed)",
              color: "var(--neuro-black-text)",
              lineHeight: "120%",
              opacity: disabled ? 0.5 : 1
            }}
          >
            {label}
          </Typography>
        )}

        <TextField
          ref={ref}
          id={id}
          type={type === "password" && showPassword ? "text" : type}
          placeholder={placeholder}
          // minRows={rows || 1}
          rows={rows}
          name={name}
          value={value}
          onChange={onChange}
          className={className}
          variant="outlined"
          fullWidth={fullWidth}
          required={required}
          error={error}
          helperText={helperText}
          multiline={multiline}
          onBlur={onBlur}
          onFocus={onFocus}
          defaultValue={defualtValue}
          disabled={disabled}
          InputProps={{
            endAdornment:
              type === "password" ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ) : endAdornment ? (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              ) : undefined,

            startAdornment: startAdornment ? (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ) : undefined,
            readOnly: readOnly,
            ...(inputProps || {}),
          }}
          sx={{
            "& .MuiInputBase-input:-webkit-autofill": {
              WebkitBoxShadow:
                "0 0 0 6.25rem var(--neuro-input-bg-primary) inset",
              WebkitPaddingAfter: "inherit",
              WebkitPaddingBefore: "inherit",
            },
            opacity: disabled ? 0.5 : 1,
            ...sx,
          }}
          {...props}
        />
      </Box>
    );
  }
);

export default Input;
