import { Box, Typography } from "@mui/material";
import React, { MouseEvent } from "react";
import LoadingScreen from "../shared/LoadingScreen";
import ContentCenter from "../shared/ContentCenter";
import { AllFormsProps } from "../../types/patients";

const AllForms: React.FC<AllFormsProps> = ({
  setSelectedForm,
  selectedForm,
  formsList,
  loading,
}) => {
  return (
    <Box sx={{ width: "100%", height: "calc(100% - 4.75rem)", overflowY: "auto" }}>
      <>
        {loading && <LoadingScreen />}

        {!loading && formsList && formsList.length === 0 && (
          <ContentCenter>
            <Typography className="no-data">Forms not found</Typography>
          </ContentCenter>
        )}

        {!loading &&
          formsList &&
          formsList?.length > 0 &&
          formsList?.map((form, index) => (
            <Box
              key={index}
              onClick={(event: MouseEvent<HTMLDivElement>) => {
                event.stopPropagation();
                setSelectedForm(form)
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: "0.625rem",
                cursor: "pointer",
                width: "100%",
                height: "2.813rem",
                paddingLeft: "1.875rem",
                marginBottom: "1.438rem",
                fontSize: "var(--neuro-font-size-micro)",
                fontFamily: "var(--neuro-font-family-inter)",
                fontWeight:
                  form?.id === selectedForm?.id
                    ? "var(--neuro-font-weight-semibold)"
                    : "var(--neuro-font-weight-regular)",
                color: "var(--neuro-black-text)",
                border: "0.063rem solid var(--neuro-light-grey_border)",
                backgroundColor:
                  form?.id === selectedForm?.id
                    ? "var(--neuro-light-grey_border)"
                    : "var(--neuro-white-text)",
                "&:hover": {
                  backgroundColor: "var(--neuro-light-grey_border)",
                },
                "&:last-child": {
                  marginBottom: 0,
                },
              }}
            >
              {form?.name || ""}
            </Box>
          ))}
      </>
    </Box>
  );
};

export default AllForms;
