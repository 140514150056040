import api from "./apiService";

export const getPayments = (patientId: string, search?: string, status?: string) => {
    const params = {
        patientId,
        search: search || "",
        status: status || "ALL"
    }
    return api.get(`/api/payments/getall`, { params });
};

export const downloadPayment = (id: string) => {
    return api({
        method: "GET",
        url: `/api/downloadPdf/invoice/${id}`,
        params: { type: "PAYMENTS" },
        responseType: "blob",
    });
};