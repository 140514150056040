import React from "react";
import {
  AppointmentCardProps,
  TabAppointmentsProps,
} from "../../../types/appointments";
import LoadingScreen from "../../shared/LoadingScreen";
import ContentCenter from "../../shared/ContentCenter";
import { Box, Typography } from "@mui/material";
import AppointmentsTableView from "./AppointmentsTableView";
import NewAppointmentCard from "./NewAppointmentCard";
import { format, parseISO } from "date-fns";

type GroupedAppointments = Record<string, AppointmentCardProps[]>;

const TabAppointments: React.FC<TabAppointmentsProps> = ({
  loadingFetchAppointments,
  errorFetchAppointments,
  appointments,
  patientId,
  view = "table",
  from = "",
}) => {
  // props & state values
  console.log("appointments", appointments);
  const groupedAppointments =
    appointments && appointments.length > 0
      ? groupAppointmentsByDate(appointments)
      : [];

  // callbacks & functions
  function groupAppointmentsByDate(appointments: any[]): GroupedAppointments {
    return appointments.reduce((acc: any, appointment) => {
      const dateKey = format(
        parseISO(appointment.appointmentDate),
        "yyyy-MM-dd"
      );
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(appointment);
      return acc;
    }, {});
  }

  return (
    <Box
      sx={{
        height: from === "dashboard" ? "100%" : "calc(100% - 5rem)",
        overflow: "auto",
      }}
    >
      {loadingFetchAppointments && <LoadingScreen />}

      {errorFetchAppointments && (
        <ContentCenter>
          <Typography className="no-data">Appointments not found</Typography>
        </ContentCenter>
      )}

      {!loadingFetchAppointments &&
        !errorFetchAppointments &&
        appointments &&
        appointments.length === 0 && (
          <ContentCenter>
            <Typography className="no-data">Appointments not found</Typography>
          </ContentCenter>
        )}

      {!loadingFetchAppointments &&
        !errorFetchAppointments &&
        appointments &&
        appointments.length > 0 && (
          <>
            {view === "card" &&
              Object.entries(groupedAppointments).map(
                ([date, appointmentsForDate]) => (
                  <Box key={date} sx={{ mb: "0.625rem" }}>
                    <Typography
                      sx={{
                        fontSize: "var(--neuro-font-size-micro)",
                        fontWeight: "var(--neuro-font-weight-medium)",
                        fontFamily: "var(--neuro-font-family-roboto-condensed)",
                        lineHeight: "120%",
                        color: "var(--neuro-button-bg-primary)",
                        mb: "0.188rem",
                      }}
                    >
                      {format(parseISO(date), "dd-MM-yyyy")}
                    </Typography>
                    <>
                      {appointmentsForDate?.map((appointment: any) => (
                        <NewAppointmentCard
                          key={appointment.id}
                          {...appointment}
                          patientId={patientId}
                          usedIn={from}
                          from={from}
                        />
                      ))}
                    </>
                  </Box>
                )
              )}

            {view === "table" && (
              <AppointmentsTableView
                appointments={appointments}
                patientId={patientId}
                from={from}
              />
            )}
          </>
        )}
    </Box>
  );
};

export default TabAppointments;
