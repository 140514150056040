import { Box } from "@mui/material";
import React, { useState } from "react";

interface Question {
  type: string;
  name: string;
  title?: string;
  labelTrue?: string;
  labelFalse?: string;
  correctAnswer?: string[];
  isRequired?: boolean;
  isScoreBased?: boolean;
  score: { true: number; false: number };
}

interface DynamicBooleanSwitchGroupProps {
  question: Question;
  index: number;
  toggleRequired: (index: number) => void;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
  updateQuestion: (index: number, updatedQuestion: any) => void;
  isonclick?: boolean;
  showHighlighted: boolean;
}

const DynamicBooleanSwitchGroup: React.FC<DynamicBooleanSwitchGroupProps> = ({
  question,
  index,
  toggleRequired,
  handleDuplicateQuestion,
  handleRemoveQuestion,
  updateQuestion,
  showHighlighted = false,
  isonclick = false,
}) => {
  // props & state values
  const [localQuestion, setLocalQuestion] = useState<Question>(question);
  const isScoreBased = localStorage.getItem("isScoreBased") === "true";

  // callbacks & functions
  const updateLocalQuestion = (updatedQuestion: typeof question) => {
    setLocalQuestion(updatedQuestion);
    updateQuestion(index, updatedQuestion);
  };

  const handleSwitchChange = () => {
    const newSwitchState = !(localQuestion.score?.true === 1);
    const newScore = newSwitchState
      ? { ...localQuestion.score, true: 1, false: 0 }
      : { ...localQuestion.score, true: 0, false: 1 };
    updateLocalQuestion({ ...localQuestion, score: newScore });
  };

  const handleInputChange = (field: keyof Question) => (e: React.ChangeEvent<HTMLInputElement>) => {
    updateLocalQuestion({ ...localQuestion, [field]: e.target.value });
  };

  const handleToggleRequired = () => {
    toggleRequired(index);
  };

  return (
    <Box sx={{
      margin: 0,
      padding: "1rem",
      borderRadius: "0.5rem",
      marginBottom: "1rem",
      cursor: "pointer",
      transition: "border-color 0.3s ease",
      background: "var(--neuro-white-text)",
      height: "auto",
      border: isonclick || showHighlighted ? "1px solid var(--neuro-light_red_border) !important" : "1px solid transparent !important",
      "&:hover": {
        border: "1px solid var(--neuro-light_red_border_light) !important"
      }
    }}>
      <div className="question-header" style={{ marginBottom: "10px" }}>
        {localQuestion?.name}
        <input
          type="text"
          value={localQuestion.title || ""}
          onChange={handleInputChange("title")}
          placeholder="Enter title"
          className="question-name-input"
        />
        {localQuestion.isRequired && <span className="required">*</span>}
      </div>

      <div className="switch-container">
        <input
          type="text"
          value={localQuestion.labelTrue || "Yes"}
          onChange={handleInputChange("labelTrue")}
          placeholder="True Label"
          className="label-input"
        />
        <input
          type="text"
          value={localQuestion.labelFalse || "No"}
          onChange={handleInputChange("labelFalse")}
          placeholder="False Label"
          className="label-input"
        />

        <input
          type="checkbox"
          onChange={handleSwitchChange}
          checked={localQuestion?.score?.true === 1}
          className="switch-checkbox"
        />
      </div>

      {isScoreBased && (
        <div className="boolean-score-input-wrapper">
          <input
            type="number"
            placeholder="Score (True)"
            value={localQuestion.score?.true || 0}
            className="boolean-score-input"
            onChange={(e) =>
              updateLocalQuestion({
                ...localQuestion,
                score: {
                  ...localQuestion.score,
                  true: parseInt(e.target.value, 10) || 0,
                },
              })
            }
          />
          <input
            type="number"
            value={localQuestion.score?.false || 0}
            placeholder="Score (False)"
            className="boolean-score-input"
            onChange={(e) =>
              updateLocalQuestion({
                ...localQuestion,
                score: {
                  ...localQuestion.score,
                  false: parseInt(e.target.value, 10) || 0,
                },
              })
            }
          />
        </div>
      )}

      <div className="question-footer" style={{ marginTop: "10px" }}>
        <button
          onClick={() => handleDuplicateQuestion(index)}
          className="duplicate-button button-spacing blue-bg"
        >
          Duplicate
        </button>

        <button
          onClick={() => handleRemoveQuestion(index)}
          className="delete-button button-spacing red-bg required-button"
        >
          Delete
        </button>

        <button onClick={handleToggleRequired} className="required-button">
          {Boolean(localQuestion?.isRequired) ? "Required" : "Not Required"}
        </button>
      </div>
    </Box>
  );
};

export default DynamicBooleanSwitchGroup;
