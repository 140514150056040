import React, { useEffect, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Typography,
  Box,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Select,
} from "@mui/material";
import Input from "../ui/Input";
import Button from "../ui/Button";
import Spinner from "../ui/Spinner";
import { completeRegistration } from "../../services/authService";
import "react-datepicker/dist/react-datepicker.css"; // Import the CSS for the datepicker
import useAuthStore from "../../store/authStore";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import axios from "axios";
import useAppStore from "../../store/appStore";
import {
  SignUpFormInputs,
  SignUpPayloadDataType,
  SignupProps,
} from "../../types/auth";
import { signUpSchema } from "../../validations/authValidation";
import { isClinicAdminOrAdmin, isDoctor } from "../../utils/auth";
import useProfileStore from "../../store/profileStore";
import { countryCode } from "../../utils/common";

const Signup: React.FC<SignupProps> = ({ emailOrPhone, userId, onClose }) => {
  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<SignUpFormInputs>({
    resolver: yupResolver(signUpSchema),
    defaultValues: {
      mobile: isValidMobileNumber(emailOrPhone) ? emailOrPhone : "",
    },
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { setIsRedirectedFromSignUp, showSnackbar } = useAppStore();

  const [datePickerOpen, setDatePickerOpen] = useState(false); // New state for controlling DatePicker

  const { login, user, userRole } = useAuthStore();

  const { getProfileDetails } = useProfileStore();

  const [isSameMobileError, setIsSameMobileError] = useState(false);

  const age = watch("age");
  const dateOfBirth = watch("dateOfBirth");

  const doctorId = user?.doctorId;

  // callbacks & functions
  function isValidMobileNumber(mobile: string): boolean {
    const mobileNumberPattern = /^[0-9]{10}$/;
    return mobileNumberPattern.test(mobile);
  }

  useEffect(() => {
    if (dateOfBirth) {
      const birthDate = DateTime.fromJSDate(dateOfBirth);
      const calculatedAge = DateTime.now().diff(birthDate, "years").years;
      setValue("age", Math.floor(calculatedAge).toString());
    }
  }, [dateOfBirth, setValue]);

  useEffect(() => {
    if (age && !isNaN(Number(age))) {
      setDatePickerOpen(false); // Close DatePicker if age is entered
      const year = DateTime.now().year - Number(age);
      const newDateOfBirth = DateTime.fromObject({
        year,
        month: 1,
        day: 1,
      }).toJSDate();
      setValue("dateOfBirth", newDateOfBirth);
    }
  }, [age, setValue]);

  const handleAgeFocus = () => {
    setDatePickerOpen(false); // Close DatePicker if age is entered
  };

  const onSubmit: SubmitHandler<SignUpFormInputs> = async (data) => {
    setIsSameMobileError(false);
    setLoading(true);
    try {
      let payloadData: SignUpPayloadDataType;

      if (data.dateOfBirth && data.age) {
        payloadData = {
          adminUserId: userId,
          email: isValidMobileNumber(emailOrPhone) ? "" : emailOrPhone,
          fullName: data.fullName,
          // firstName: data.firstName,
          // lastName: data.lastName,
          age: data.age,
          gender: data.gender,
          dateOfBirth: data.dateOfBirth.toISOString().split("T")[0], // Convert to string or null
          // mobile: data.mobile,
          mobile: `+91${data?.mobile}`,
          reasonsForAppointments: data.reasonsForAppointments || "",
        };
      } else {
        // If neither dateOfBirth nor age is provided, handle it appropriately.
        throw new Error("Either Date of Birth or Age must be provided.");
      }

      const result = await completeRegistration(payloadData);

      login(result.accessToken, result.refreshToken);

      console.log("useEF 1");
      if (doctorId && isClinicAdminOrAdmin(userRole)) {
        getProfileDetails(doctorId, "ADMIN");
      } else if (doctorId && isDoctor(userRole)) {
        getProfileDetails(doctorId, "DOCTOR");
      } else {
        getProfileDetails();
      }

      if (onClose) {
        onClose();
      }

      // to show popup in dashboard
      setIsRedirectedFromSignUp(true);

      // for showing in login itself - will comment if showing in dashboard
      navigate("/dashboard");

      // Handle post-registration logic (e.g., redirect to dashboard or show success message)
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        if (error?.response?.data.message === "Mobile already exists") {
          setIsSameMobileError(true);
        } else {
          showSnackbar(
            error?.response?.data.message ||
              "Could not complete process, please try again",
            "error"
          );
        }
      } else {
        showSnackbar(
          error?.response?.data.message ||
            "Could not complete process, please try again",
          "error"
        );
        console.error("OTP request failed:", error);
      }
    }
    setLoading(false);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        marginBlock: "2rem",
        // maxWidth: 400,
        margin: "0 auto",
        // height: "500px",
        // overflow: "auto",
      }}
    >
      <Typography
        sx={{
          fontWeight: "var(--neuro-font-weight-bold)",
          fontSize: "var(--neuro-font-size-large-medium)",
          fontFamily: "var(--neuro-font-family-roboto-condensed)",
          lineHeight: "120%",
          marginBlock: "1rem",
        }}
        align="center"
      >
        Request Callback
        {/* Profile Info */}
      </Typography>

      <Box
        sx={{
          height: "23.75rem",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Grid container spacing={2.5}>
          {/* <Grid item xs={12} sm={6}>
            <Input
              type="text"
              // className="input-secondary"
              className="input-primary"
              sx={{
                "& >.MuiInputBase-root": {
                  height: "40px",
                },
              }}
              label="First name*"
              {...register("firstName")}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Input
              // className="input-secondary"
              className="input-primary"
              sx={{
                "& >.MuiInputBase-root": {
                  height: "40px",
                },
              }}
              type="text"
              label="Last name*"
              {...register("lastName")}
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
            />
          </Grid> */}

          <Grid item xs={12}>
            <Input
              type="text"
              // className="input-secondary"
              className="input-primary"
              sx={{
                "& >.MuiInputBase-root": {
                  height: "2.5rem",
                },
              }}
              label="Full name*"
              {...register("fullName")}
              error={!!errors.fullName}
              helperText={errors.fullName?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              sx={{
                mb: "0.5rem",
                fontSize: "var(--neuro-font-size-medium-smallest)",
                fontFamily: "var(--neuro-font-family-roboto-condensed)",
                color: "var(--neuro-black-text)",
                lineHeight: "120%",
              }}
            >
              Gender*
            </Typography>
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value="MALE"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="FEMALE"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="OTHER"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              )}
            />

            {errors.gender && (
              <Typography color="error" variant="body2">
                {errors.gender.message}
              </Typography>
            )}
          </Grid>

          <Grid item container spacing={2.5} alignItems="flex-end">
            <Grid item xs={12} sm={5.5}>
              <Typography
                sx={{
                  marginBottom: "0.375rem",
                  fontSize: "var(--neuro-font-size-medium-smallest)",
                  fontFamily: "var(--neuro-font-family-roboto-condensed)",
                  color: "var(--neuro-black-text)",
                  lineHeight: "120%",
                }}
              >
                Date of Birth Or Age*
              </Typography>
              <Controller
                name="dateOfBirth"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DatePicker
                      // value={field.value || undefined}
                      // onChange={(date) => field.onChange(date)}
                      open={datePickerOpen} // Control the open state
                      onOpen={() => setDatePickerOpen(true)}
                      onClose={() => setDatePickerOpen(false)}
                      value={
                        field.value ? DateTime.fromJSDate(field.value) : null
                      }
                      // disabled={isDobDisabled}
                      onChange={(date: DateTime | null) =>
                        field.onChange(date ? date.toJSDate() : null)
                      }
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          sx: {
                            "& .MuiOutlinedInput-root": {
                              height: "2.5rem",
                              borderRadius: "0.625rem",
                              backgroundColor:
                                "var(--neuro-bg-light-grey-secondary)",
                              borderColor:
                                "var(--neuro-bg-light-grey-secondary)",
                              "& >fieldset": {
                                borderColor:
                                  "var(--neuro-bg-light-grey-secondary)",
                              },
                              "& >fieldset:hover": {
                                borderColor:
                                  "var(--neuro-bg-light-grey-secondary)",
                              },
                              "& >fieldset:active": {
                                borderColor:
                                  "var(--neuro-bg-light-grey-secondary)",
                              },
                              "& >fieldset:focus": {
                                borderColor:
                                  "var(--neuro-bg-light-grey-secondary)",
                              },
                              "& >.Mui-focoused.MuiOutlinedInput-notchedOutline":
                                {
                                  border:
                                    "1px solid var(--neuro-bg-light-grey-secondary)",
                                },
                              "& .MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root>.MuiOutlinedInput-notchedOutline":
                                {
                                  border:
                                    "1px solid var(--neuro-bg-light-grey-secondary)",
                                },
                            },
                          },
                        },
                      }}
                      sx={{
                        "& .MuiFormControl-root": {
                          width: "100%",
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              />

              {errors.dateOfBirth && (
                <Typography color="error" variant="body2">
                  {errors.dateOfBirth.message}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={1}>
              <Typography
                sx={{
                  marginBottom: "0.375rem",
                  fontSize: "var(--neuro-font-size-medium-smallest)",
                  fontFamily: "var(--neuro-font-family-roboto-condensed)",
                  color: "var(--neuro-black-text)",
                  lineHeight: "120%",
                }}
              >
                Or
              </Typography>
            </Grid>

            <Grid item xs={12} sm={5.5}>
              <Input
                // className="input-secondary"
                className="input-primary"
                // disabled={isAgeDisabled}
                onFocus={handleAgeFocus}
                endAdornment={<Typography>Years</Typography>}
                sx={{
                  "& >.MuiInputBase-root": {
                    height: "2.5rem",
                  },
                }}
                type="text"
                {...register("age")}
                error={!!errors.age}
                helperText={errors.age?.message}
              />
              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #dcdcdc",
                  borderRadius: "10px",
                  gap: "6px",
                }}
              >
                <Input
                  // className="input-secondary"
                  className="input-primary"
                  disabled={isAgeDisabled}
                  sx={{
                    "& >.MuiInputBase-root": {
                      borderTopRightRadius: "0px !important",
                      borderBottomRightRadius: "0px !important",
                    },
                  }}
                  type="text"
                  // label="Date of birth/Age*"
                  {...register("age")}
                  error={!!errors.age}
                  helperText={errors.age?.message}
                />
                <Typography
                  sx={{
                    fontSize: "20px",
                    lineHeight: "24px",
                    fontFamily: "Roboto Condensed",
                    marginRight: "6px",
                  }}
                >
                  Years
                </Typography>
              </Box> */}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                gap: "0.5rem",
                alignItems: "baseline",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.375rem",
                  maxWidth: "6.25rem",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "var(--neuro-font-size-smaller-plus)",
                    fontFamily: "var(--neuro-font-family-roboto-condensed)",
                    color: "var(--neuro-black-text)",
                    lineHeight: "120%",
                  }}
                >
                  Code
                </Typography>
                <Select
                  sx={{
                    maxWidth: "6.25rem",
                    height: "2.625rem",
                    backgroundColor: "var(--neuro-input-bg-primary) !important",
                    borderRadius: "0.625rem !important",
                    "& .MuiInputBase-root": {
                      backgroundColor:
                        "var(--neuro-input-bg-primary) !important",
                      borderRadius: "0.625rem !important",
                      border: "none !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none !important",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none !important",
                    },
                  }}
                  name="countryCode"
                  value={"+91"}
                  // onChange={handleChange}
                  fullWidth
                >
                  {countryCode?.map((code) => (
                    <MenuItem key={code.id} value={code.value}>
                      {code.value}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Input
                type="text"
                fullWidth
                // className="input-secondary"
                // startAdornment={<Typography>+91</Typography>}
                className="input-primary"
                sx={{
                  "& >.MuiInputBase-root": {
                    height: "2.5rem",
                  },
                }}
                label="Phone*"
                {...register("mobile")}
                error={!!errors.mobile || isSameMobileError}
                helperText={
                  isSameMobileError
                    ? "Mobile already exists"
                    : errors.mobile?.message
                }
              />
            </Box>
          </Grid>

          {/* <Grid item xs={12}>
            <Input
              type="text"
              className="input-secondary"
              label="Pin code"
              {...register("pinCode")}
              error={!!errors.pinCode}
              helperText={errors.pinCode?.message}
            />
          </Grid> */}

          <Grid item xs={12}>
            <Input
              className="input-textarea"
              sx={{
                "& >.MuiInputBase-root": {
                  backgroundColor: "var(--neuro-input-bg-primary) !important",
                },
              }}
              multiline
              // rows={4}
              type="text"
              label="Reason for Appointment"
              {...register("reasonsForAppointments")}
              error={!!errors.reasonsForAppointments}
              helperText={errors.reasonsForAppointments?.message}
            />
          </Grid>

          {/* <Grid item xs={12}>
            <Input
              className="input-textarea"
              multiline
              rows={4}
              type="text"
              label="Address"
              {...register("address")}
              error={!!errors.address}
              helperText={errors.address?.message}
            />
          </Grid> */}
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: "2.75rem",
            mb: "3.5rem",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            className="success-button"
            color="success"
            disabled={loading}
          >
            {loading ? <Spinner /> : "Submit"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Signup;
