import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
  Box,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { availabilitySchema } from "../../validations/doctorValidation";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import Button from "../ui/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { erroMessageStyles, inputLabelStyles, selectStyles } from "../../utils/styles";
import Checkbox from "../ui/Checkbox";
import {
  addDoctorAvailability,
  updateDoctorAvailability,
} from "../../services/doctorService";
import useAppStore from "../../store/appStore";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { capitalizeFirstLetter, supportedServices } from "../../utils/common";
import CustomBreadcrumbs from "../shared/CustomBreadcrumbs";
import useAuthStore from "../../store/authStore";
import { isDoctor, isDoctorOrNetwork } from "../../utils/auth";
import useProfileStore from "../../store/profileStore";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { TimePicker } from '@mui/x-date-pickers';
import { DateTime } from "luxon";

// Sample days of the week for selection
const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const DoctorAvailabilityForm = ({
  onClose,
  id = "",
  doctor = null,
}: {
  onClose: any;
  id?: string;
  doctor?: any;
}) => {
  // props & state values
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(availabilitySchema),
    defaultValues: {
      slotDuration: doctor ? doctor?.availabilityJson?.slotDuration : 30,
      // doctorId: id,
      availabilityArray: doctor
        ? doctor?.availabilityJson?.availabilityArray
        : [
          {
            days: [],
            sessions: [
              {
                startTime: "",
                endTime: "",
                service: ["ONLINE"]
                // onlineConsultation: true,
              },
            ],
          },
        ],
    },
  });

  console.log("doctor prop in availability info", doctor);

  const {
    fields: groupFields,
    append: appendGroup,
    remove: removeGroup,
  } = useFieldArray({
    control,
    name: "availabilityArray",
  });

  const { showSnackbar, selectedDoctorInList, setSelectedDoctorInList } = useAppStore();
  const { getProfileDetails } = useProfileStore();

  const { userRole, user } = useAuthStore();

  const navigate = useNavigate();

  const breadcrumbItems = [
    ...(!isDoctorOrNetwork(userRole)
      ? [{ label: "Doctors", href: "/doctors" }]
      : []),
    {
      label: `${capitalizeFirstLetter(selectedDoctorInList?.firstName || selectedDoctorInList?.user?.firstName || "")}`,
    },
    { label: "Consultation Settings" },
  ];

  // callbacks & functions
  useEffect(() => {
    if (doctor) {
      console.log("doctor in availability form", doctor);
      const defaultValues = {
        slotDuration: doctor?.availabilityJson?.slotDuration || 30,
        availabilityArray: doctor?.availabilityJson?.availabilityArray || [
          {
            days: [],
            sessions: [
              {
                startTime: "",
                endTime: "",
                service: ["ONLINE"],
              },
            ],
          },
        ],
      };
      reset(defaultValues);
    }
  }, [doctor, reset]);

  const getProifileInfo = async () => {
    const doctorId = user?.doctorId;

    if (doctorId && isDoctor(userRole)) {
      await getProfileDetails(doctorId, "DOCTOR");
    }
    else {
      await getProfileDetails();
    }
  }

  const onSubmit = async (data: any) => {
    console.log("Form Data:", data);
    const values = {
      doctorId: id,
      ...data,
    };

    try {
      if (doctor) {
        console.log("doctor edit", doctor);

        try {
          const response = await updateDoctorAvailability(values);
          setSelectedDoctorInList({
            ...selectedDoctorInList,
            ...response?.data?.data
          });

          if (isDoctorOrNetwork(userRole)) {
            getProifileInfo();
          }

          showSnackbar("Consultation Settings Updated Successfully", "success");
        } catch (error: any) {
          showSnackbar(`${error.response?.data.message}`, "error");
          return;
        }
      } else {
        try {
          const response = await addDoctorAvailability(values);
          setSelectedDoctorInList({
            ...selectedDoctorInList,
            ...response?.data?.data
          });
          showSnackbar("Consultation Settings Added Successfully", "success");

          if (isDoctorOrNetwork(userRole)) {
            navigate(`/settings/${selectedDoctorInList?.id || selectedDoctorInList?.doctorId}/work-experience`);
          }
          else {
            navigate(`/doctors/${selectedDoctorInList?.id || selectedDoctorInList?.doctorId}/work-experience`);
          }
        } catch (error: any) {
          showSnackbar(`${error.response?.data.message}`, "error");
          return;
        }
      }
      // onClose();
    } catch (error) {
      const message = doctor
        ? "Could not update consultation settings, please try again"
        : "Could not add consultation settings, please try again";
      showSnackbar(message, "error");
      console.error("Error saving doctor:", error);
    }
  };

  const handleAddSession = (groupIndex: number) => {
    const currentSessions =
      watch(`availabilityArray.${groupIndex}.sessions`) || [];
    setValue(`availabilityArray.${groupIndex}.sessions`, [
      ...currentSessions,
      { startTime: "", endTime: "", service: ["ONLINE"] },
    ]);
  };

  const handleRemoveSession = (groupIndex: number, sessionIndex: number) => {
    const currentSessions =
      watch(`availabilityArray.${groupIndex}.sessions`) || [];
    const updatedSessions = currentSessions.filter(
      (_, index) => index !== sessionIndex
    );
    setValue(`availabilityArray.${groupIndex}.sessions`, updatedSessions);
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <CustomBreadcrumbs items={breadcrumbItems} />

          <Box
            sx={{
              display: "flex",
              gap: "1.25rem",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              className="outlined-secondary-button"
              onClick={onClose}
              sx={{
                height: "2.25rem",
              }}
            >
              Cancel
            </Button>
            <Button
              startIcon={<SaveOutlinedIcon />}
              variant="contained"
              className="success-button"
              type="submit"
              sx={{
                height: "2.25rem",
              }}
            >
              Save
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            p: "1.75rem",
            backgroundColor: "var(--neuro-white-text)",
            borderRadius: "0.625rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            maxHeight: "calc(100% - 3.25rem)",
            overflow: "auto",
          }}
        >

          <Grid container justifyContent="space-between" spacing={1.25} alignItems="center">
            {/* <Grid item xs={12} md={4}>
              <Typography
                sx={{
                  color: "var(--neuro-secondary-blue)",
                  lineHeight: "120%",
                  fontFamily: "var(--neuro-font-family-roboto-condensed)",
                  fontSize: "var(--neuro-font-size-medium-smallest)",
                }}
              >
                OPD Timings Schedule
              </Typography>
            </Grid> */}

            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "1.25rem", flexWrap: "wrap", justifyContent: "flex-start", width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <Typography className="inputLabelStyles" sx={{ minWidth: "max-content !important" }}>
                    Appointment Duration
                  </Typography>
                  <Controller
                    name="slotDuration"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.375rem",
                          width: "100%",
                          maxWidth: "10rem",
                        }}
                      >
                        <Select
                          {...field}
                          fullWidth
                          sx={{
                            maxWidth: "10rem",
                            ...selectStyles.withGreyBackground,
                          }}
                          margin="dense"
                          error={!!error}
                          defaultValue={
                            doctor
                              ? doctor?.availabilityJson?.slotDuration || 30
                              : 30
                          }
                        >
                          {[30, 60, 90].map((value) => (
                            <MenuItem key={value} value={value}>
                              {value} Minutes
                            </MenuItem>
                          ))}
                        </Select>
                        {error && (
                          <Typography sx={erroMessageStyles}>
                            {error?.message}
                          </Typography>
                        )}
                      </Box>
                    )}
                  />
                </Box>
              </Box>
            </Grid>

          </Grid>

          {groupFields &&
            groupFields.length > 0 &&
            groupFields.map((group, groupIndex) => (
              <Box
                key={group.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  width: "100%",
                  p: "1rem",
                  borderRadius: "0.313rem",
                  border: "1px solid var(--neuro-secondary_border)"
                }}
              >
                {/* Days */}
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    flexWrap: "wrap",
                    alignItems: "baseline",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.375rem",
                      flex: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        // gap: "1rem",
                        // padding: "0.2rem 1.125rem",
                        // backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                        // borderRadius: "0.313rem",
                        flex: 1,
                        flexWrap: "wrap",
                      }}
                    >
                      {daysOfWeek.map((day) => (
                        <Controller
                          key={day}
                          name={`availabilityArray.${groupIndex}.days`}
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              checked={field.value?.includes(day.toUpperCase())}
                              onChange={(e) => {
                                const updatedDays = e.target.checked
                                  ? [...(field.value || []), day.toUpperCase()]
                                  : field.value?.filter(
                                    (d: string) => d !== day.toUpperCase()
                                  );
                                field.onChange(updatedDays);
                              }}
                              label={
                                <Typography
                                  sx={inputLabelStyles}
                                >
                                  {day}
                                </Typography>
                              }
                            />
                          )}
                        />
                      ))}
                    </Box>
                    {errors?.availabilityArray?.[groupIndex]?.days && (
                      <Typography sx={erroMessageStyles}>
                        {errors?.availabilityArray[groupIndex]?.days?.message || ""}
                      </Typography>
                    )}
                  </Box>

                  <IconButton
                    onClick={() => removeGroup(groupIndex)}
                    sx={{
                      backgroundColor: "var(--neuro-primary-error)",
                      padding: "0.375rem",
                      width: "1.25rem",
                      height: "1.25rem",
                      borderRadius: "0.188rem",
                      "&:hover": {
                        backgroundColor: "var(--neuro-primary-error)",
                      },
                    }}
                  >
                    <CloseOutlinedIcon
                      sx={{ color: "var(--neuro-white-text)" }}
                    />
                  </IconButton>
                </Box>

                {/* Sessions */}
                <Box sx={{ width: "100%", display: "flex", alignItems: "flex-end", justifyContent: "space-between" }}>
                  <Grid
                    container
                    spacing={0}
                    justifyContent="space-between"
                    alignItems="baseline"
                    sx={{
                      width: "100%",
                      overflowY: "hidden",
                      overflowX: "auto",
                    }}
                  >
                    <Grid item xs={12}>
                      <Box sx={{ width: "100%", borderBottom: "1px solid var(--neuro-secondary_border)", p: "0.5rem" }}>
                        <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <Typography sx={{ flex: 0.4, ...inputLabelStyles }}>
                            Consultation Timings
                          </Typography>

                          <Typography sx={{ textAlign: "center", flex: 0.4, ...inputLabelStyles }}>
                            Service
                          </Typography>

                          <Typography sx={{ textAlign: "end", flex: 0.2, ...inputLabelStyles }}>
                            Actions
                          </Typography>

                        </Box>
                      </Box>
                    </Grid>

                    {watch(`availabilityArray.${groupIndex}.sessions`)?.map(
                      (_, sessionIndex) => (
                        <Grid item xs={12} key={sessionIndex}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "baseline",
                              width: "100%",
                              borderBottom: "1px solid var(--neuro-secondary_border)",
                              p: "0.5rem"
                            }}
                          >
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem", flex: 0.4 }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "2.25rem",
                                  // mt: "0.5rem",
                                }}
                              >

                                <Controller
                                  name={`availabilityArray.${groupIndex}.sessions.${sessionIndex}.startTime`}
                                  control={control}
                                  render={({ field, fieldState: { error } }) => (
                                    <Box sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "0.375rem",
                                    }}>
                                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                                        <TimePicker
                                          ampm={true}
                                          value={field?.value ? DateTime.fromFormat(field.value, "HH:mm") : null}
                                          onChange={(date) => {
                                            field.onChange(date?.toFormat("HH:mm"));
                                          }}
                                          // format="hh:mm"
                                          slotProps={{
                                            textField: {
                                              fullWidth: true,
                                              sx: {
                                                "& .MuiOutlinedInput-root": {
                                                  height: "2.5rem",
                                                  borderRadius: "0.625rem",
                                                  backgroundColor:
                                                    "var(--neuro-bg-light-grey-secondary)",
                                                  borderColor:
                                                    "var(--neuro-bg-light-grey-secondary)",
                                                  "& >fieldset": {
                                                    borderColor:
                                                      "var(--neuro-bg-light-grey-secondary)",
                                                  },
                                                  "& >fieldset:hover": {
                                                    borderColor:
                                                      "var(--neuro-bg-light-grey-secondary)",
                                                  },
                                                  "& >fieldset:active": {
                                                    borderColor:
                                                      "var(--neuro-bg-light-grey-secondary)",
                                                  },
                                                  "& >fieldset:focus": {
                                                    borderColor:
                                                      "var(--neuro-bg-light-grey-secondary)",
                                                  },
                                                  "& >.Mui-focoused.MuiOutlinedInput-notchedOutline":
                                                  {
                                                    border:
                                                      "1px solid var(--neuro-bg-light-grey-secondary)",
                                                  },
                                                  "& .MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root>.MuiOutlinedInput-notchedOutline":
                                                  {
                                                    border:
                                                      "1px solid var(--neuro-bg-light-grey-secondary)",
                                                  },
                                                },
                                              },
                                            },
                                          }}
                                          sx={{
                                            "& .MuiFormControl-root": {
                                              width: "100%",
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                      {error && (
                                        <Typography
                                          sx={erroMessageStyles}
                                        >
                                          {error.message}
                                        </Typography>
                                      )}
                                    </Box>
                                  )}
                                />


                                <Controller
                                  name={`availabilityArray.${groupIndex}.sessions.${sessionIndex}.endTime`}
                                  control={control}
                                  render={({ field, fieldState: { error } }) => (
                                    <Box sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "0.375rem",
                                    }}>
                                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                                        <TimePicker
                                          ampm={true}
                                          value={field?.value ? DateTime.fromFormat(field.value, "HH:mm") : null}
                                          onChange={(date) => {
                                            field.onChange(date?.toFormat("HH:mm"));
                                          }}
                                          // format="hh:mm"
                                          slotProps={{
                                            textField: {
                                              fullWidth: true,
                                              sx: {
                                                "& .MuiOutlinedInput-root": {
                                                  height: "2.5rem",
                                                  borderRadius: "0.625rem",
                                                  backgroundColor:
                                                    "var(--neuro-bg-light-grey-secondary)",
                                                  borderColor:
                                                    "var(--neuro-bg-light-grey-secondary)",
                                                  "& >fieldset": {
                                                    borderColor:
                                                      "var(--neuro-bg-light-grey-secondary)",
                                                  },
                                                  "& >fieldset:hover": {
                                                    borderColor:
                                                      "var(--neuro-bg-light-grey-secondary)",
                                                  },
                                                  "& >fieldset:active": {
                                                    borderColor:
                                                      "var(--neuro-bg-light-grey-secondary)",
                                                  },
                                                  "& >fieldset:focus": {
                                                    borderColor:
                                                      "var(--neuro-bg-light-grey-secondary)",
                                                  },
                                                  "& >.Mui-focoused.MuiOutlinedInput-notchedOutline":
                                                  {
                                                    border:
                                                      "1px solid var(--neuro-bg-light-grey-secondary)",
                                                  },
                                                  "& .MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root>.MuiOutlinedInput-notchedOutline":
                                                  {
                                                    border:
                                                      "1px solid var(--neuro-bg-light-grey-secondary)",
                                                  },
                                                },
                                              },
                                            },
                                          }}
                                          sx={{
                                            "& .MuiFormControl-root": {
                                              width: "100%",
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                      {error && (
                                        <Typography
                                          sx={erroMessageStyles}
                                        >
                                          {error.message}
                                        </Typography>
                                      )}
                                    </Box>
                                  )}
                                />
                              </Box>
                            </Box>


                            <Box sx={{
                              display: "flex", flexDirection: "column", gap: "0.5rem",
                              flex: 0.4
                            }}>
                              <Box sx={{ display: "flex", alignItems: "center", gap: "1rem", }}>
                                <Controller
                                  name={`availabilityArray.${groupIndex}.sessions.${sessionIndex}.service`}
                                  control={control}
                                  render={({ field, fieldState: { error } }) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "0.375rem",
                                        width: "100%",
                                      }}
                                    >
                                      <Select
                                        {...field}
                                        fullWidth
                                        multiple
                                        sx={selectStyles.withGreyBackground}
                                        margin="dense"
                                        error={!!error}
                                        defaultValue={
                                          doctor
                                            ? doctor?.specializations
                                              ? doctor?.specializations
                                              : []
                                            : []
                                        }
                                      >
                                        {supportedServices.map((supportedService) => (
                                          <MenuItem
                                            key={supportedService.value}
                                            value={supportedService.value}
                                          >
                                            {supportedService.label}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      {error && (
                                        <Typography sx={erroMessageStyles}>
                                          {error.message}
                                        </Typography>
                                      )}
                                    </Box>
                                  )}
                                />


                              </Box>


                            </Box>

                            <IconButton
                              onClick={() =>
                                handleRemoveSession(groupIndex, sessionIndex)
                              }
                              sx={{
                                backgroundColor: "var(--neuro-primary-error)",
                                padding: "0.375rem",
                                width: "1.25rem",
                                height: "1.25rem",
                                borderRadius: "0.188rem",
                                "&:hover": {
                                  backgroundColor:
                                    "var(--neuro-primary-error)",
                                },
                              }}
                            >
                              <CloseOutlinedIcon
                                sx={{ color: "var(--neuro-white-text)" }}
                              />
                            </IconButton>

                          </Box>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Box>

                {/* Add more session button */}
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                    alignItems: "center"
                  }}
                >
                  <Button
                    variant="outlined"
                    className="outlined-primary-button"
                    startIcon={
                      <AddCircleOutlineOutlinedIcon
                        sx={{ color: "var(--neuro-button-bg-primary)" }}
                      />
                    }
                    sx={{ height: "2.25rem", width: "max-content" }}
                    onClick={() => handleAddSession(groupIndex)}
                  >
                    Add New Session
                  </Button>
                </Box>
              </Box>
            ))}

          <Box sx={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center"
          }}
          >
            <Button
              variant="outlined"
              className="outlined-primary-button"
              startIcon={
                <AddCircleOutlineOutlinedIcon
                  sx={{ color: "var(--neuro-button-bg-primary)" }}
                />
              }
              sx={{ height: "2.25rem" }}
              onClick={() =>
                appendGroup({
                  days: [],
                  sessions: [
                    { startTime: "", endTime: "", service: ["ONLINE"] },
                  ],
                })
              }
            >
              Add New Schedule
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DoctorAvailabilityForm;
