import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import DynamicBooleanSwitchGroup from "../DynamicBooleanSwitchGroup";
import DynamicCheckboxGroup from "../DynamicCheckboxGroup";
import DynamicImagePickerNot from "../DynamicImagePickerNot";
import DynamicRadioGroup from "../DynamicRadioGroup/DynamicRadioGroup";
import DynamicRanking from "../DynamicRanking";
import DynamicRatingGroup from "../DynamicRatingGroup";
import DynamicSelect from "../DynamicSelect";
import DynamicSelectMulti from "../DynamicSelectMulti";
import DynamicTextField from "../DynamicTextField/DynamicTextField";
import FileInput from "../FileInput";
import { FormDesignerProps } from "../../../types/forms";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import Button from "../../ui/Button";
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import Tooltip from "../../ui/Tooltip";
import { fetchFormRequestsApiFormid } from "../../../services/formService";
import { capitalizeFirstLetter } from "../../../utils/common";
import LoadingScreen from "../../shared/LoadingScreen";
import useFormStore from "../../../store/formStore";
import DynamicHtml from "../DynamicHtml";

const FormDesigner: React.FC<FormDesignerProps> = ({ allData, handlePageChange, currentPage, isonclick, handleOnClickPage, duplicatePage, deletePage, handleInputChange, questionData, handleDuplicateQuestion, handleRemoveQuestion, updateQuestion, toggleRequired, addPage, updateQuestionName, loading = false }
) => {
    // props & state values
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const { selectedInput, setSelectedInput, selectedQuestionType, setSelectedQuestionType } = useFormStore();

    const [hoveredPage, setHoveredPage] = useState<any>(null);

    const id = localStorage.getItem("formid");

    const boxRef = useRef<HTMLDivElement | null>(null);

    // callbacks & functions
    useEffect(() => {
        if (id) {
            const fetchData = async () => {
                try {
                    const response = await fetchFormRequestsApiFormid(id || "");
                    console.log("response in ue in formid", response);
                    if (response?.data) {
                        localStorage.setItem("isScoreBased", response?.data?.data?.isScoreBased);
                        localStorage.setItem("autoSubmit", response?.data?.data?.isAutoSubmit);
                    }
                } catch (error) {
                    console.error("Error fetching form data:", error);
                }
            };

            fetchData();
        }
    }, [id]);

    const getQuestionType = (selectedInput: any) => {
        switch (selectedInput) {
            case "Checkboxes":
                return "checkbox";
            case "RatingScale":
                return "rating";
            case "MultiDropdown":
                return "tagbox";
            case "FileUpload":
                return "file";
            case "ImagePicker":
                return "imagepicker";
            case "RadioButtonGroup":
                return "radiogroup";
            case "SelectDropdown":
                return "dropdown";
            case "Ranking":
                return "ranking";
            case "Yes/No(Boolean)":
                return "boolean";
            case "TextFiled":
                return "text";
            case "Radiogroup":
                return "radiogroup";
            case "Html":
                return "html";
            default:
                console.warn("Unhandled radio value:", selectedInput);
        }
    }

    useEffect(() => {
        if (selectedInput) {
            const type = getQuestionType(selectedInput);
            setSelectedQuestionType(type);
        }
    }, [selectedInput])

    useLayoutEffect(() => {
        if (boxRef.current) {
            console.log("boxRef.current scrollHeight", boxRef?.current?.scrollHeight);
            console.log("boxRef.current scrollTop", boxRef?.current?.scrollTop);
            setTimeout(() => {
                const scrollPosition = selectedQuestionType === "imagepicker" ? boxRef.current!.scrollHeight - 540 : boxRef.current!.scrollHeight - 395;
                boxRef.current!.scrollTo({
                    top: scrollPosition,
                    behavior: "smooth",
                });
            }, 0);
        }
        setSelectedInput(null);
    }, [selectedInput])

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center" }}>
            {loading ? <LoadingScreen /> : <Box sx={{ width: "100%" }}>
                <Box sx={{
                    display: "flex",
                    // flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    gap: "50px",
                }}
                >
                    <Box sx={{
                        maxHeight: '100%',
                        paddingRight: "10px",
                        overflowY: "auto",
                        flex: 1,
                    }}
                        ref={boxRef}
                    >
                        <Box
                            sx={{
                                // width: '532px',
                                // height: '100%',
                                flexShrink: 0,
                                borderRadius: '0.75rem',
                                display: 'flex',
                                flexDirection: 'column',
                                // justifyContent: 'center',
                                padding: '0.75rem 1rem 1.5rem 1rem',
                                border: isonclick ? "1px solid var(--neuro-light_red_border)" : "1px solid transparent",
                                backgroundColor: isonclick ? "var(--neuro-light_red_background)" : "var(--neuro-bg-light-grey-secondary)",
                                cursor: "pointer",
                                flex: 1,
                                "&:hover": {
                                    border: isonclick ? "1px solid var(--neuro-light_red_border) !important" : "1px solid var(--neuro-light_red_border_light) !important",
                                    backgroundColor: "var(--neuro-light_red_background) !important"
                                }
                            }}
                            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                event.stopPropagation();
                                handleOnClickPage();
                                setSelectedQuestionType(null);
                            }} // Parent click event
                        >
                            <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end", mb: "6px" }}>
                                {allData && allData.pages && allData.pages.length >= 1 && (
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                                        <button
                                            className="delete-button button-spacing red-bg required-button"
                                            style={{
                                                backgroundColor: 'transparent',
                                                // position: 'relative',
                                                left: '10rem',
                                                top: '10px',
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent parent click
                                                duplicatePage(currentPage - 1);
                                                // Add logic for Duplicate button here
                                            }}
                                        >
                                            Duplicate
                                        </button>
                                        <button
                                            className="delete-button button-spacing red-bg required-button"
                                            style={{
                                                backgroundColor: 'transparent',
                                                // position: 'relative',
                                                left: '10rem',
                                                top: '10px',
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent parent click
                                                // deletePage(currentPage - 1);
                                                deletePage(`page${currentPage}`, currentPage);
                                                // Add logic for Delete button here
                                            }}
                                        >
                                            Delete
                                        </button>
                                    </Box>
                                )}
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "column", gap: "1px", mb: questionData?.length > 0 ? "1.5rem" : "1rem" }}>
                                <input
                                    type="text"
                                    value={capitalizeFirstLetter(allData?.pages[currentPage - 1]?.title || "")}
                                    placeholder={capitalizeFirstLetter(allData?.pages[currentPage - 1]?.name || "")}
                                    className="page-title-input"
                                    onChange={(e) => handleInputChange(e, 'title')}
                                    onClick={(e) => e.stopPropagation()} // Prevent click bubbling
                                />
                                <input
                                    type="text"
                                    value={allData?.pages[currentPage - 1]?.description || ""}
                                    placeholder="Description"
                                    className="Description"
                                    onChange={(e) => handleInputChange(e, 'description')}
                                    onClick={(e) => e.stopPropagation()} // Prevent click bubbling
                                />
                            </Box>

                            <Box>
                                {questionData?.map((question, index) => (
                                    <Box key={index} onClick={(e) => e.stopPropagation()}>
                                        {question.type === 'dropdown' && (
                                            <DynamicSelect
                                                key={index}
                                                question={question}
                                                index={index}
                                                handleDuplicateQuestion={handleDuplicateQuestion}
                                                handleRemoveQuestion={handleRemoveQuestion}
                                                updateQuestion={updateQuestion}
                                                isonclick={isonclick}
                                                toggleRequired={toggleRequired}
                                                showHighlighted={selectedQuestionType === "dropdown" && index === questionData.length - 1}
                                            />
                                        )}

                                        {question.type === 'tagbox' && (
                                            <DynamicSelectMulti
                                                key={index}
                                                question={question}
                                                toggleRequired={toggleRequired}
                                                index={index}
                                                handleDuplicateQuestion={handleDuplicateQuestion}
                                                handleRemoveQuestion={handleRemoveQuestion}
                                                updateQuestion={updateQuestion}
                                                isonclick={isonclick}
                                                showHighlighted={selectedQuestionType === "tagbox" && index === questionData.length - 1}
                                            />
                                        )}

                                        {question.type === 'imagepicker' && (
                                            <DynamicImagePickerNot
                                                key={index}
                                                initialQuestion={question}
                                                index={index}
                                                toggleRequired={toggleRequired}
                                                handleDuplicateQuestion={handleDuplicateQuestion}
                                                handleRemoveQuestion={handleRemoveQuestion}
                                                updateQuestion={updateQuestion}
                                                isonclick={isonclick}
                                                showHighlighted={selectedQuestionType === "imagepicker" && index === questionData.length - 1}
                                            />
                                        )}

                                        {question.type === 'boolean' && (
                                            <DynamicBooleanSwitchGroup
                                                key={index}
                                                question={question}
                                                index={index}
                                                handleDuplicateQuestion={handleDuplicateQuestion}
                                                handleRemoveQuestion={handleRemoveQuestion}
                                                updateQuestion={updateQuestion}
                                                toggleRequired={toggleRequired}
                                                isonclick={isonclick}
                                                showHighlighted={selectedQuestionType === "boolean" && index === questionData.length - 1}
                                            />
                                        )}

                                        {question.type === 'ranking' && (
                                            <DynamicRanking
                                                key={index}
                                                question={question}
                                                index={index}
                                                updateQuestion={updateQuestion}
                                                handleDuplicateQuestion={handleDuplicateQuestion}
                                                handleRemoveQuestion={handleRemoveQuestion}
                                                toggleRequired={toggleRequired}
                                                isonclick={isonclick}
                                                showHighlighted={selectedQuestionType === "ranking" && index === questionData.length - 1}
                                            />
                                        )}

                                        {question.type === 'file' && (
                                            <FileInput
                                                key={index}
                                                index={index}
                                                question={question}
                                                updateQuestion={updateQuestion}
                                                toggleRequired={toggleRequired}
                                                handleDuplicateQuestion={handleDuplicateQuestion}
                                                handleRemoveQuestion={handleRemoveQuestion}
                                                updateQuestionTitle={(updatedTitle: string) =>
                                                    updateQuestion(index, { ...question, title: updatedTitle })
                                                }
                                                isonclick={isonclick}
                                                showHighlighted={selectedQuestionType === "file" && index === questionData.length - 1}
                                            />
                                        )}

                                        {question.type === 'rating' && (
                                            <DynamicRatingGroup
                                                key={index}
                                                question={question}
                                                index={index}
                                                toggleRequired={toggleRequired}
                                                handleDuplicateQuestion={handleDuplicateQuestion}
                                                handleRemoveQuestion={handleRemoveQuestion}
                                                updateQuestionName={(updatedName) =>
                                                    updateQuestionName(index, updatedName)
                                                }
                                                updateQuestion={updateQuestion}
                                                isonclick={isonclick}
                                                showHighlighted={selectedQuestionType === "rating" && index === questionData.length - 1}
                                            />
                                        )}

                                        {question.type === 'checkbox' && (
                                            <DynamicCheckboxGroup
                                                key={index}
                                                question={question}
                                                index={index}
                                                updateQuestion={(index, updatedQuestion) =>
                                                    updateQuestion(index, updatedQuestion)
                                                }
                                                handleDuplicateQuestion={handleDuplicateQuestion}
                                                handleRemoveQuestion={handleRemoveQuestion}
                                                isonclick={isonclick}
                                                showHighlighted={selectedQuestionType === "checkbox" && index === questionData.length - 1}
                                            />
                                        )}

                                        {question.type === 'text' && (
                                            <DynamicTextField
                                                key={index}
                                                question={question}
                                                index={index}
                                                handleDuplicateQuestion={handleDuplicateQuestion}
                                                handleRemoveQuestion={handleRemoveQuestion}
                                                updateQuestion={updateQuestion}
                                                isonclick={isonclick}
                                                toggleRequired={toggleRequired}
                                                showHighlighted={selectedQuestionType === "text" && index === questionData.length - 1}
                                            />
                                        )}

                                        {question.type === 'html' && (
                                            <DynamicHtml
                                                key={index}
                                                question={question}
                                                index={index}
                                                handleDuplicateQuestion={handleDuplicateQuestion}
                                                handleRemoveQuestion={handleRemoveQuestion}
                                                updateQuestion={updateQuestion}
                                                isonclick={isonclick}
                                                showHighlighted={selectedQuestionType === "html" && index === questionData.length - 1}
                                            />
                                        )}

                                        {question.type === 'radiogroup' && (
                                            <DynamicRadioGroup
                                                key={index}
                                                question={question}
                                                index={index}
                                                handleDuplicateQuestion={handleDuplicateQuestion}
                                                handleRemoveQuestion={handleRemoveQuestion}
                                                updateQuestion={updateQuestion}
                                                isonclick={isonclick}
                                                toggleRequired={toggleRequired}
                                                showHighlighted={selectedQuestionType === "radiogroup" && index === questionData.length - 1}
                                            />
                                        )}
                                    </Box>
                                ))}
                            </Box>

                            <Button
                                variant="contained"
                                fullWidth
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                    e.stopPropagation(); // Prevent parent click
                                    addPage(); // Adds a new page
                                    handlePageChange(allData.pages.length + 1); // Navigates to the new page
                                }}
                                sx={{
                                    border: isonclick ? "1px solid var(--neuro-light_red_border) !important" : "1px solid transparent !important",
                                    height: '52px'
                                }}
                                className="white-bg-primary-button"
                            >
                                + Add Page
                            </Button>
                        </Box>
                    </Box>

                    <Box sx={{ marginTop: "50px" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "1.25rem", alignItems: "center", minWidth: "100px" }}>
                            <Button
                                variant="contained"
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                    e.stopPropagation();
                                    addPage();
                                    handlePageChange(allData.pages.length + 1);
                                }}
                                sx={{
                                    height: '2.25rem'
                                }}
                                className="white-bg-primary-button"
                            >
                                + Add Page
                            </Button>
                            <>
                                <Tooltip title="Select page" placement="right">
                                    <IconButton onClick={handleClick}>
                                        <LocationSearchingIcon />
                                    </IconButton>
                                </Tooltip>

                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "left", // Align menu to the left of the icon
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right", // Ensure proper positioning
                                    }}
                                    sx={{
                                        "& .MuiMenu-paper": {
                                            borderRadius: 2,
                                            minWidth: "120px",
                                            boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                                        },
                                    }}
                                >
                                    {allData?.pages?.map((page: any, index: number) => (
                                        <MenuItem key={index} sx={{
                                            backgroundColor: currentPage === index + 1 ? "var(--neuro-bg-primary-seagreen)" : "var(--neuro-white-text)",
                                            "&:hover": {
                                                backgroundColor: currentPage === index + 1 ? "var(--neuro-bg-primary-seagreen)" : "var(--neuro-bg-light-grey-secondary)",
                                            }
                                        }} onClick={() => {
                                            handlePageChange(index + 1);
                                            handleClose();
                                        }}>{page.name}</MenuItem>
                                    ))}
                                </Menu>
                            </>
                            <Box sx={{
                                display: "flex", flexDirection: "column", gap: "0.5rem", alignItems: "center"
                            }}>
                                {allData?.pages?.slice(0, 5)?.map((page: any, index: number) => (
                                    <>
                                        {currentPage !== index + 1 &&
                                            <Box
                                                onClick={() => handlePageChange(index + 1)}
                                                onMouseEnter={() => setHoveredPage(page)}
                                                onMouseLeave={() => setHoveredPage(null)}
                                                sx={{
                                                    cursor: "pointer",
                                                    height: "2.25rem",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    minWidth: "100px",
                                                    justifyContent: "center",
                                                    marginLeft: hoveredPage && hoveredPage?.name === page?.name ? "-26px !important" : "0px !important",
                                                }}>
                                                {hoveredPage && hoveredPage?.name === page.name ?
                                                    <button
                                                        key={index}
                                                        onClick={() => handlePageChange(index + 1)}
                                                        style={{
                                                            display: "flex",
                                                            padding: "10px",
                                                            backgroundColor: "var(--neuro-white-text)",
                                                            color: "var(--neuro-black-text)",
                                                            border: "1px solid var(--neuro-secondary_border)",
                                                            borderRadius: "20px",
                                                            cursor: "pointer",
                                                            alignItems: "center",
                                                            gap: "6px",
                                                            height: "2.25rem",
                                                            marginRight: "20px"
                                                        }}
                                                    >
                                                        <Typography>
                                                            {page?.name?.split(" ")?.map((word: any) => word?.charAt(0)?.toUpperCase() + word?.slice(1))?.join("  ")}
                                                        </Typography>
                                                        <Box sx={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "var(--neuro-bg-primary-seagreen)" }} />
                                                    </button>
                                                    : <Box
                                                        sx={{
                                                            width: "10px",
                                                            height: "10px",
                                                            borderRadius: "50%",
                                                            backgroundColor: "var(--neuro-darkgrey_border)",
                                                        }}
                                                    ></Box>}
                                            </Box>
                                        }
                                        {currentPage === index + 1 &&
                                            <Tooltip title={page?.name || ""} placement="right">
                                                <Box sx={{
                                                    height: "2.25rem",
                                                    width: "10px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center"
                                                }}>
                                                    <Box
                                                        sx={{
                                                            width: "10px",
                                                            height: "10px",
                                                            borderRadius: "50%",
                                                            backgroundColor: "var(--neuro-white-text)",
                                                            border: "2px solid var(--neuro-bg-primary-seagreen)",
                                                            cursor: "pointer",
                                                        }}></Box>
                                                </Box>
                                            </Tooltip>
                                        }
                                    </>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>}
        </Box>
    )
}

export default FormDesigner;