import { Box, Grid, Typography } from "@mui/material"
import ComponentHeader from "../shared/ComponentHeader"
import { PrescriptionsListProps } from "../../types/prescriptions"
import LoadingScreen from "../shared/LoadingScreen"
import ContentCenter from "../shared/ContentCenter"
import { PrescriptionCard } from "./PrescriptionCard"
import { useCallback, useEffect, useState } from "react"
import Button from "../ui/Button"
import useAppStore from "../../store/appStore"
import { downloadPrescription } from "../../services/documentsService"
import Spinner from "../ui/Spinner"
import ComponentFilter from "../shared/ComponentFilter"
import { debounce } from "lodash"
import SelectedPrescription from "./SelectedPrescription"

const PrescriptionsList: React.FC<PrescriptionsListProps> = ({
    prescriptions,
    loading,
    patientId,
    stateUpdaterForParent,
    loadPrescriptions,
    presciptionPatientId
}) => {
    // const props & state values
    const { showSnackbar, prescriptionsFilter, setFilter } = useAppStore();
    const [selectedPrescription, setSelectedPrescription] = useState<any>(null);

    const [downloadLoading, setDownloadLoading] = useState(false);

    // callbacks & functions
    useEffect(() => {
        if (prescriptions && prescriptions.length > 0) {
            setSelectedPrescription(prescriptions[0]);
            if (stateUpdaterForParent) {
                stateUpdaterForParent(prescriptions[0]);
            }
        }
        else {
            setSelectedPrescription(null);
            if (stateUpdaterForParent) {
                stateUpdaterForParent(null);
            }
        }
    }, [prescriptions])

    const handleCardClick = (prescription: any) => {
        setSelectedPrescription(prescription);
        if (stateUpdaterForParent) {
            stateUpdaterForParent(prescription);
        }
    }

    const downloadPrescriptionHandler = async () => {
        setDownloadLoading(true);
        showSnackbar("Prescription is downloading, please wait", "info");
        try {
            const response = await downloadPrescription(selectedPrescription?.appointmentId || "");
            const contentDisposition = response?.headers["content-disposition"];
            const blobData = response?.data;
            const fileName = contentDisposition;
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blobData);
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(link.href);
            showSnackbar("Prescription is downloaded", "success");
        } catch (error) {
            showSnackbar(
                "Could not download prescription, please try again",
                "error"
            );
        } finally {
            setDownloadLoading(false);
        }
    };

    const handleInputChange = (value: string) => {
        console.log(value);
        loadPrescriptions(presciptionPatientId || "", value);
    };

    const debouncedSearch = useCallback(
        debounce((value: string) => {
            // if (value.length >= config?.SEARCH_FILTER_LETTER) {
            //   handleInputChange(value, prescriptionsFilter);
            // }
            handleInputChange(value);
        }, 1000),
        []
    );


    const handleStoreSearchValueChange = (value: string) => {
        setFilter("prescriptionsFilter", {
            searchValue: value,
        });

        debouncedSearch(value);

        // if (value === "") {
        //   // If the input is cleared, fetch all appointments
        //   handleInputChange("", appointmentsFilter);
        // }

        // if (
        //   value.length >= config?.SEARCH_FILTER_LETTER
        //   // value.length % config?.SEARCH_FILTER_LETTER === 0
        // ) {
        //   debouncedSearch(value, appointmentsFilter);
        // }
    };


    return (
        <Box
            sx={{
                backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                height: "100%",
            }}
        >
            {!patientId && <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "1rem",
                }}
            >
                <ComponentHeader title="Prescriptions" />

                <Button
                    variant="outlined"
                    color="secondary"
                    className="outlined-secondary-button"
                    onClick={downloadPrescriptionHandler}
                    disabled={!selectedPrescription || downloadLoading}
                    sx={{ height: "2.25rem", width: "6rem" }}
                >
                    {downloadLoading ? <Spinner /> : "Download"}
                </Button>
            </Box>}

            <Box sx={{ marginBottom: "1rem" }}>
                <ComponentFilter
                    isEmpty
                    hideRangeFilter
                    hideStatusFilter
                    handleStoreSearchValueChange={handleStoreSearchValueChange}
                    searchValue={prescriptionsFilter?.searchValue || ""}
                    searchFilterPlaceholder="Medication, Doctor Name"
                    searchFilterWidth="16rem"
                />
            </Box>

            <Box sx={{ height: patientId ? "calc(100% - 5rem)" : "calc(100% - 8rem)", overflowY: "auto" }}>
                {loading ? (
                    <Box
                        sx={{
                            height: "100%",
                        }}
                    >
                        <LoadingScreen />
                    </Box>
                ) : prescriptions && prescriptions.length > 0 ? (
                    <Box sx={{ maxHeight: "100%", overflowY: "hidden" }}>
                        <Grid container spacing={2} sx={{ height: patientId ? "calc(100% + 1rem)" : "100%", overflowY: "hidden" }}>
                            <Grid item container spacing={2} xs={12} md={6.5}
                                sx={{
                                    height: "calc(100% + 1rem)",
                                    overflowY: "hidden",
                                }}
                            >
                                {prescriptions?.map((prescription) => (
                                    <Grid item xs={12} key={prescription.appointmentId}>
                                        <PrescriptionCard
                                            prescription={prescription}
                                            onClick={handleCardClick}
                                            selectedPrescription={selectedPrescription}
                                        />
                                    </Grid>
                                ))}
                            </Grid>

                            {selectedPrescription && <Grid item xs={12} md={5.5} sx={{
                                height: "100%",
                                overflowY: "hidden",
                            }}>
                                <SelectedPrescription selectedPrescription={selectedPrescription} />
                            </Grid>}

                        </Grid>
                    </Box>

                ) : (
                    <Box
                        sx={{
                            height: "100%",
                        }}
                    >
                        <ContentCenter>
                            <Typography className="no-data">No prescriptions found</Typography>
                        </ContentCenter>
                    </Box>
                )}
            </Box>

        </Box>
    )
}

export default PrescriptionsList;