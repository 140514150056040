import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import Logo from "../../shared/Logo";
import BigBorderIcon from "../../svg/BigBorderIcon";
import {
  getFullHospitalAddress,
  specializationMapping,
} from "../../../utils/appointments";
import { capitalizeFirstLetter } from "../../../utils/common";
import UserAvatar from "../../shared/UserAvatar";
import useAppointmentStore from "../../../store/appointmentsStore";

const SelectedDoctorCard = () => {
  // props & state values
  const isMobile = useMediaQuery("(max-width:37.5rem)");
  const isBelow400 = useMediaQuery("(max-width:25rem)");

  const { selectedDoctor } = useAppointmentStore();

  const styles = {
    containerStyles: {
      borderRadius: "0.75rem",
      backgroundColor: "var(--neuro-white-text)",
      display: "flex",
      paddingRight: isMobile
        ? isBelow400
          ? "0.625rem"
          : "0.875rem"
        : "1.188rem",
      paddingLeft: "0.688rem",
      gap: isMobile ? 0 : "1.25rem",
      flexWrap: "wrap",
      justifyContent: "space-between",
      border: "1px solid var(--neuro-secondary_border)",
      position: "relative",
      alignItems: "center",
      maxHeight: "5.75rem",
      minHeight: "5.75rem",
      marginBottom: "1rem",
    },
    detailsStyles: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      margin: "auto 0",
      paddingTop: "0.25rem",
      paddingBottom: "0.375rem",
    },
  };

  return (
    <Box sx={styles?.containerStyles}>
      <Box sx={{ position: "absolute", top: 0, left: 0, bottom: 0 }}>
        <BigBorderIcon />
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "0.875rem",
        }}
      >
        <UserAvatar
          src={
            selectedDoctor?.profileUrl ||
            selectedDoctor?.user?.profileImageUrl ||
            null
          }
          alt={
            `${selectedDoctor?.firstName ? capitalizeFirstLetter(selectedDoctor?.firstName || "") : selectedDoctor?.user?.firstName ? capitalizeFirstLetter(selectedDoctor?.user?.firstName || "") : "Doctor-profile"}` ||
            "Doctor-profile"
          }
        />

        <Box sx={styles?.detailsStyles}>
          <Typography
            sx={{
              fontFamily: "var(--neuro-font-family-roboto-condensed)",
              color: "var(--neuro-bg-darkblue-primary)",
              lineHeight: "120%",
            }}
          >
            Dr.{" "}
            {selectedDoctor?.firstName
              ? capitalizeFirstLetter(selectedDoctor?.firstName || "")
              : selectedDoctor?.user?.firstName
                ? capitalizeFirstLetter(selectedDoctor?.user?.firstName || "")
                : ""}{" "}
          </Typography>
          <Typography
            sx={{
              fontFamily: "var(--neuro-font-family-roboto-slab)",
              fontSize: "var(--neuro-font-size-tiniest)",
              color: "var(--neuro-button-bg-primary)",
              marginTop: "0.188rem",
            }}
          >
            {selectedDoctor?.specializations &&
            selectedDoctor?.specializations.length > 0
              ? selectedDoctor?.specializations
                  .map(
                    (specialization: string) =>
                      specializationMapping[specialization] || specialization
                  )
                  .join(", ")
              : ""}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ mt: "0.375rem" }}
          >
            <LocationOnIcon
              sx={{
                fontSize: "var(--neuro-font-size-extra-small)",
                color: "var(--neuro-bg-darkblue-primary)",
              }}
            />
            <Typography
              sx={{
                color: "var(--neuro-bg-darkblue-primary)",
                fontSize: "var(--neuro-font-size-smallest-x)",
                lineHeight: "120%",
              }}
            >
              {getFullHospitalAddress(
                selectedDoctor?.hospital || selectedDoctor?.defaultClinic
              ) || ""}
            </Typography>
          </Stack>
        </Box>
      </Box>
      <Logo height="3.938rem" width={isBelow400 ? "3.938rem" : "5.563rem"} />
    </Box>
  );
};

export default SelectedDoctorCard;
