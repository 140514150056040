import * as yup from "yup";

export const patientSignUpSchema = yup.object().shape({
  // firstName: yup.string().required("First name is required"),
  // lastName: yup.string().required("Last name is required"),
  fullName: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  gender: yup.string().required("Gender is required"),
  age: yup
    .string()
    .nullable()
    .matches(/^\d*$/, "Age must be a number")
    .test(
      "age-or-dob",
      "Either Date of Birth or Age is required",
      function (value) {
        const { dateOfBirth } = this.parent;
        return !!value || !!dateOfBirth;
      }
    ),
  dateOfBirth: yup
    .date()
    .nullable()
    .test(
      "dob-or-age",
      "Either Date of Birth or Age is required",
      function (value) {
        const { age } = this.parent;
        return !!value || !!age;
      }
    ),
  mobile: yup
    .string()
    .required("Phone number is required")
    .matches(
      /^(\+?\d{1,4}[\s-]?)?(?!0+$)\d{10}$/,
      "Phone number must be a valid 10-digit number"
    ),
  reasonsForAppointments: yup.string().optional(),
});

export const patientEmailSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
});
