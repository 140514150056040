import { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Grid,
  Select,
  MenuItem,
  useMediaQuery,
  TextField,
  IconButton,
} from "@mui/material";
// import Checkbox from "../../ui/Checkbox";
import Input from "../../ui/Input";
import { erroMessageStyles, inputLabelStyles, selectStyles } from "../../../utils/styles";
import {
  fetchFormRequestsApiFormid,
  getDisorders,
  submitFormResponseApisuryve,
  submitFormResponseApisuryveputcall,
} from "../../../services/formService";
import useAppStore from "../../../store/appStore";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useNavigate } from "react-router-dom";
import useFormStore from "../../../store/formStore";
import config from "../../../config";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from "../../ui/Tooltip";

const AddNewFormCompFormid = ({
  submitTrigger,
  setSubmitTrigger,
}: {
  submitTrigger: boolean;
  setSubmitTrigger: any;
}) => {
  // props & state values
  const id = localStorage.getItem("formid");
  const navigate = useNavigate();

  const [name, setName] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [formType, setFormType] = useState<string>("");
  const [filledBy, setFilledBy] = useState<string>("");
  const [disorderId, setDisorderId] = useState<string>("");
  const [isScoreBased, setIsScoreBased] = useState<boolean>(false);
  const [isPublic, setisPublic] = useState<boolean>(false);
  const [autoSubmit, setAutoSubmit] = useState<boolean>(false);
  const [notifyOnSubmit, setnotifyOnSubmit] = useState<boolean>(false);
  const [notificationEmail, setnotificationEmail] = useState<string>("");
  const [status, setStatus] = useState(true);
  const [disorders, setDisorders] = useState<any[]>([]);
  const [publicUrl, setPublicUrl] = useState<any>(null);

  console.log("name in form details", name);

  // Validation errors state
  const [errors, setErrors] = useState({
    name: "",
    zipCode: "",
    formType: "",
    filledBy: "",
    disorderId: "",
    notificationEmail: "",
  });

  console.log("errors in survey form details", errors);

  const {
    isMenuCollapsed,
    setDrawerOpen,
    setIsMenuCollapsed,
    showSnackbar,
    setShowFormSubmenus,
  } = useAppStore();

  const isBelow800 = useMediaQuery("(max-width:50rem)");

  const { setDisabledObj } = useFormStore();

  const [previousSidebarCollpased] = useState<boolean>(
    isMenuCollapsed ? true : false
  );

  // callbacks & functions
  const fetchDisorder = async () => {
    try {
      const response = await getDisorders();
      setDisorders(response?.data || []);
    }
    catch (error) {
      console.error("Error getting disorder", error);
    }
  }

  useEffect(() => {
    fetchDisorder();
  }, [])

  useEffect(() => {
    setShowFormSubmenus(true);
    if (isBelow800) {
      setDrawerOpen(false);
    } else {
      setIsMenuCollapsed(true);
    }

    return () => {
      setIsMenuCollapsed(previousSidebarCollpased);
      setDrawerOpen(!previousSidebarCollpased);
    };
  }, []);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const response = await fetchFormRequestsApiFormid(id || "");
          console.log("response in ue in formid", response);
          if (response?.data) {
            setName(response?.data?.data?.name || "");
            setZipCode(response?.data?.data?.description || "");
            setFormType(response?.data?.data?.type || "");
            setFilledBy(response?.data?.data?.filledBy || "");
            setDisorderId(response?.data?.data?.disorderId || "");
            setIsScoreBased(response?.data?.data?.isScoreBased || false);
            setStatus(response?.data?.data?.status || true);
            setisPublic(response?.data?.data?.isPublic || false);
            setAutoSubmit(response?.data?.data?.isAutoSubmit || false);
            setnotifyOnSubmit(response?.data?.data?.notifyOnSubmit || false);
            setnotificationEmail(response?.data?.data?.notificationEmail || "");
            setPublicUrl(response?.data?.data?.isPublic && !!response?.data?.data?.publicUrl ? response?.data?.data?.publicUrl : null);
            localStorage.setItem("isScoreBased", response?.data?.data?.isScoreBased);
            localStorage.setItem("formname", response.data.data.name);

            const newErrors: typeof errors = {
              name: response?.data?.data?.name?.trim()?.length > 0 ? "" : "Form Name is required.",
              formType: response?.data?.data?.type ? "" : "Form Type is required.",
              filledBy: response?.data?.data?.filledBy ? "" : "Form filled By is required.",
              disorderId: response?.data?.data?.disorderId ? "" : "Disorder is required.",
              notificationEmail: response?.data?.data?.notifyOnSubmit ? response?.data?.data?.notificationEmail.trim().length > 0
                ? ""
                : "Notification Email is required." : "",
              zipCode: "",
            };
            setErrors(newErrors);

          }
        } catch (error) {
          console.error("Error fetching form data:", error);
        }
      };

      fetchData();
    }
  }, [id]);

  const validateFields = () => {
    const newErrors: typeof errors = {
      name: name?.trim()?.length > 0 ? "" : "Form Name is required.",
      formType: formType ? "" : "Form Type is required.",
      filledBy: filledBy ? "" : "Form filled By is required.",
      disorderId: disorderId ? "" : "Disorder is required.",
      notificationEmail: notifyOnSubmit ? notificationEmail.trim()
        ? ""
        : "Notification Email is required." : "",
      zipCode: "",
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const UserId = localStorage.getItem("formid");

  const onSubmit = async () => {
    if (!validateFields()) return;

    const formData = {
      name,
      description: zipCode,
      type: formType,
      filledBy,
      disorderId,
      isScoreBased,
      status,
      isPublic,
      isAutoSubmit: autoSubmit,
      notifyOnSubmit,
      notificationEmail,
    };

    try {
      let response: any;
      if (id) {
        response = await submitFormResponseApisuryveputcall(
          id || "",
          formData
        )
      } else {
        response = await submitFormResponseApisuryve(formData)
      }
      if (id) {
        console.log("response EDIT", response);
        setDisabledObj((prev) => ({
          ...prev,
          FileUpload: Boolean(response?.data?.data?.isScoreBased), // Disables the FileUpload field
          Ranking: Boolean(response?.data?.data?.isScoreBased), // Disables the Ranking field
        }));
        localStorage.setItem("isScoreBased", response.data.data.isScoreBased);
        localStorage.setItem("autoSubmit", response?.data?.data?.isAutoSubmit);
        localStorage.setItem("formname", response.data.data.name);
        setisPublic(Boolean(response?.data?.data?.isPublic));
        setPublicUrl(response?.data?.data?.publicUrl || null)
        window.dispatchEvent(new Event("storage"));
        showSnackbar("Form details saved successfully", "success");
      }
      else {
        console.log("response ADD", response);

        setDisabledObj((prev) => ({
          ...prev,
          FileUpload: Boolean(response?.data?.data?.isScoreBased), // Disables the FileUpload field
          Ranking: Boolean(response?.data?.data?.isScoreBased), // Disables the Ranking field
        }));

        localStorage.setItem("isScoreBased", response.data.data.isScoreBased);
        localStorage.setItem("autoSubmit", response?.data?.data?.isAutoSubmit);
        localStorage.setItem("questions", response.data.data.questions);
        localStorage.setItem("formname", response.data.data.name);
        localStorage.setItem("formid", response.data.data.id);
        localStorage.setItem("isDisabled", "false");
        setisPublic(Boolean(response?.data?.data?.isPublic));
        setPublicUrl(response?.data?.data?.publicUrl || null)
        showSnackbar("Form details saved successfully", "success");
        // Trigger a synthetic storage change event to notify other components
        window.dispatchEvent(new Event("storage"));
        if (UserId && UserId.trim() !== "") {
          navigate("/forms");
        } else {
          // alert("Invalid or missing UserId11!");
        }
      }
    } catch (error) {
      console.error("Error submitting form", error);
      showSnackbar("Error submitting form", "error");
    }
  };

  useEffect(() => {
    if (submitTrigger) {
      if (id !== "") {
        onSubmit();
      }
      setSubmitTrigger(false);
    }
  }, [submitTrigger]);

  const handleRadioChange = (type: string, value: string) => {
    console.log("handleRadioChange", type, value);
    if (type === "Score") {
      setIsScoreBased(value === "Yes" ? true : false);
    }
    else if (type === "autoSubmit") {
      setAutoSubmit(value === "Yes" ? true : false);
    }
    else if (type === "Public") {
      setisPublic(value === "Yes" ? true : false);
    } else if (type === "Notify") {
      setnotifyOnSubmit(value === "Yes" ? true : false);
      if (value === "No") { setnotificationEmail("") }
    }
  };

  const handleCopy = async (textToCopy: string) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      showSnackbar("Url copied successfully", "success");
    } catch (err) {
      showSnackbar("Could not copy url, please try again", "error");
      console.error("Error copying url: ", err);
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "var(--neuro-bg-light-grey-secondary)",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.688rem",
            height: "100%",
            width: "100%",
          }}
        >
          <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <Box
              sx={{
                p: "1rem",
                backgroundColor: "var(--neuro-white-text)",
                borderRadius: "0.625rem",
                width: "100%",
                height: "100%",
              }}
            >
              <Grid container spacing={3.5}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 2 }}>
                    <Input
                      type="text"
                      className="input-primary"
                      label="Form Name *"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      error={!!errors.name}
                      helperText={errors.name}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 2 }}>
                    <Typography sx={inputLabelStyles}>Form Type *</Typography>
                    <Select
                      value={formType}
                      onChange={(e) => setFormType(e.target.value)}
                      fullWidth
                      sx={selectStyles.withGreyBackground}
                      error={!!errors.formType}
                    // helperText={errors.formType}
                    >
                      <MenuItem value="GENERIC">GENERIC</MenuItem>
                      <MenuItem value="SPECIFIC">SPECIFIC</MenuItem>
                      <MenuItem value="COMMON">COMMON</MenuItem>
                    </Select>
                    {!!errors?.formType && (
                      <Typography sx={erroMessageStyles}>
                        {errors?.formType || ""}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={3.5}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 2 }}>
                    <Typography sx={inputLabelStyles}>
                      Form filled By *
                    </Typography>
                    <Select
                      value={filledBy}
                      onChange={(e) => setFilledBy(e.target.value)}
                      fullWidth
                      sx={selectStyles.withGreyBackground}
                      error={!!errors.filledBy}
                    // helperText={errors.filledBy}
                    >
                      <MenuItem value="DOCTOR">Doctor</MenuItem>
                      <MenuItem value="PATIENT">Patient</MenuItem>
                      <MenuItem value="BOTH">Both</MenuItem>
                    </Select>
                    {!!errors?.filledBy && (
                      <Typography sx={erroMessageStyles}>
                        {errors?.filledBy || ""}
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 2 }}>
                    <Typography sx={inputLabelStyles}>Disorder *</Typography>
                    <Select
                      value={disorderId}
                      onChange={(e) => setDisorderId(e.target.value)}
                      fullWidth
                      sx={selectStyles.withGreyBackground}
                      error={!!errors.disorderId}
                    // helperText={errors.disorderId}
                    >
                      {disorders && disorders?.length > 0 && disorders.map((disorder) => (
                        <MenuItem value={disorder?.id} key={disorder?.id}>{disorder?.name || ""}</MenuItem>
                      ))}
                    </Select>
                    {!!errors?.disorderId && (
                      <Typography sx={erroMessageStyles}>
                        {errors?.disorderId || ""}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={3.5}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 2 }}>
                    <FormControl>
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        sx={{
                          color: "#000",
                          "&.Mui-focused": {
                            color: "#000",
                          },
                        }}
                      >
                        Notify On Submit
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(event) => {
                          handleRadioChange("Notify", event.target.value);
                        }}
                        value={notifyOnSubmit ? "Yes" : "No"}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": { color: "#000" },
                              }}
                            />
                          }
                          label="Yes"
                          sx={{ color: "#000" }}
                        />
                        <FormControlLabel
                          value="No"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": { color: "#000" },
                              }}
                            />
                          }
                          label="No"
                          sx={{ color: "#000" }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 2 }}>
                    <Input
                      type="text"
                      className="input-primary"
                      label="Notification Email *"
                      value={notificationEmail}
                      onChange={(e) => setnotificationEmail(e.target.value)}
                      error={notifyOnSubmit ? !!errors.notificationEmail : false}
                      helperText={errors.notificationEmail}
                      disabled={!notifyOnSubmit}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={3.5}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 2 }}>
                    <FormControl>
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        sx={{
                          color: "#000",
                          "&.Mui-focused": {
                            color: "#000",
                          },
                        }}
                      >
                        Is Score Based
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(event) => {
                          handleRadioChange("Score", event.target.value);
                        }}
                        value={isScoreBased ? "Yes" : "No"}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": { color: "#000" },
                              }}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": { color: "#000" },
                              }}
                            />
                          }
                          label="No"
                          sx={{ color: "#000" }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 2 }}>
                    <FormControl>
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        sx={{
                          color: "#000",
                          "&.Mui-focused": {
                            color: "#000",
                          },
                        }}
                      >
                        Auto Submit
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(event) => {
                          handleRadioChange("autoSubmit", event.target.value);
                        }}
                        value={autoSubmit ? "Yes" : "No"}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": { color: "#000" },
                              }}
                            />
                          }
                          sx={{ color: "#000" }}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": { color: "#000" },
                              }}
                            />
                          }
                          sx={{ color: "#000" }}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Grid>


              </Grid>


              <Grid item xs={12} md={6}>
                <Box sx={{ mt: 2 }}>
                  <FormControl>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      sx={{
                        color: "#000",
                        "&.Mui-focused": {
                          color: "#000",
                        },
                      }}
                    >
                      Is Public
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(event) => {
                        handleRadioChange("Public", event.target.value);
                      }}
                      value={isPublic ? "Yes" : "No"}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked": { color: "#000" },
                            }}
                          />
                        }
                        sx={{ color: "#000" }}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked": { color: "#000" },
                            }}
                          />
                        }
                        sx={{ color: "#000" }}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>


              {isPublic && publicUrl && publicUrl !== "" && <Grid item xs={12}>
                <Box sx={{ display: "flex", alignItems: "center", width: "100%", gap: "0.5rem" }}>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    sx={{
                      color: "#000",
                      "&.Mui-focused": {
                        color: "#000",
                      },
                    }}
                  >
                    Public Url :
                  </FormLabel>
                  <Typography sx={{ p: 0.5, color: "var(--neuro-white-text)", borderRadius: "30px", backgroundColor: "var(--neuro-button-bg-success)" }}>
                    {`${config.APP_BASE_URL}/public/${publicUrl}`}
                  </Typography>

                  <Tooltip title="Copy public url">
                    <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event.stopPropagation();
                      handleCopy(`${config.APP_BASE_URL}/public/${publicUrl}`);
                    }} sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "2rem", height: "2rem", borderRadius: "50%", p: 1, backgroundColor: "var(--neuro-button-bg-primary)", "&:hover": { backgroundColor: "var(--neuro-button-bg-primary)" } }}>
                      <ContentCopyIcon sx={{ color: "var(--neuro-white-text)", fontSize: "1rem" }} />
                    </IconButton>
                  </Tooltip>
                </Box>

              </Grid>}

              <Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 2 }}>
                    <TextField
                      label="Form Description"
                      value={zipCode}
                      onChange={(e) => setZipCode(e.target.value)}
                      multiline
                      rows={5} // Specifies the number of visible lines
                      fullWidth
                      variant="outlined" // Optional: Choose variant (outlined, filled, standard)
                      className="input-secondary-textarea" // Retains your custom styling
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddNewFormCompFormid;
