import { useEffect, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Typography,
  Box,
  Grid,
  //  Avatar,
  Select,
  MenuItem,
  // Avatar,
  // IconButton,
} from "@mui/material";
import Input from "../ui/Input";
// import CameraAltIcon from "@mui/icons-material/CameraAlt";
// import DeleteIcon from "@mui/icons-material/Delete";
import useAppStore from "../../store/appStore";
import { capitalizeFirstLetter, countryCode, departments, doctorPrefixes, supportedServices } from "../../utils/common";
import Button from "../ui/Button";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { doctorPersonalInfoFormValidationSchema } from "../../validations/doctorValidation";
import { addDoctorPersonalProfile, deleteDoctor, updateDoctorPersonalProfile } from "../../services/doctorService";
import Checkbox from "../ui/Checkbox";
import {
  erroMessageStyles,
  inputLabelStyles,
  selectStyles,
} from "../../utils/styles";
import { Hospital } from "../../types/common";
import { fetchHospitals } from "../../services/hospitalService";
import { DoctorPersonalInfoFormInputs } from "../../types/doctors";
import { useNavigate } from "react-router-dom";
import CustomBreadcrumbs from "../shared/CustomBreadcrumbs";
import useAuthStore from "../../store/authStore";
import { isClinicAdminOrAdmin, isDoctor, isDoctorOrNetwork } from "../../utils/auth";
import useProfileStore from "../../store/profileStore";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmModal from "../shared/ConfirmModal";

const DoctorPersonalInfoForm = ({
  // loadDoctors,
  onClose,
  doctor = null,
  hideBreadCrumbs = false,
}: {
  onClose: any;
  doctor?: any;
  loadDoctors?: any;
  hideBreadCrumbs?: boolean;
}) => {
  // props & state values
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<DoctorPersonalInfoFormInputs>({
    resolver: yupResolver(doctorPersonalInfoFormValidationSchema),
    defaultValues: {
      isNetwork: doctor ? doctor?.isNetwork || false : false,
      isClinicAdmin: doctor ? doctor?.isClinicAdmin || false : false,
      supportedServices: doctor ? doctor.supportedServices || [] : [],
      department: doctor ? departments.filter((department) => department.value === doctor?.department) ? doctor?.department : [] : [],
      designation: doctor ? doctor.designation || "" : "",
      hospitalId: doctor ? doctor?.hospitalId || doctor?.hospital?.id || doctor?.defaultClinicId : "",
      prefix: "Dr."
      // profileImage: undefined,
    },
  });

  console.log("doctor prop in personla info", doctor);
  console.log("errors in personla info", errors);

  // const [profileImagePreview, setProfileImagePreview] = useState<
  //   string | undefined
  // >();
  const { showSnackbar, setSelectedDoctorInList, selectedDoctorInList, setShowDoctorSubmenus } = useAppStore();
  const { userRole, user } = useAuthStore();

  const { getProfileDetails } = useProfileStore();

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const navigate = useNavigate();
  // const [profileImagePreview, setProfileImagePreview] = useState<
  //   string | undefined
  // >();

  const isNetworkValue = watch("isNetwork");
  const isClinicAdminValue = watch("isClinicAdmin");

  console.log("isNetworkValue", isNetworkValue);
  console.log("isClinicAdminValue", isClinicAdminValue);

  const [hospitals, setHospitals] = useState<Hospital[]>([]);

  const isNetworkChecked = watch("isNetwork");
  const isClinicAdminChecked = watch("isClinicAdmin");

  const breadcrumbItems = [
    { label: "Doctors", href: "/doctors" },
    {
      label: selectedDoctorInList ? `${capitalizeFirstLetter(selectedDoctorInList?.firstName || selectedDoctorInList?.user?.firstName || "")}` : "New",
    },
    { label: "Personal Profile" },
  ];

  // callbaks & functions
  useEffect(() => {
    const loadHospitals = async () => {
      try {
        const response = await fetchHospitals();
        setHospitals(response.data || []);
      } catch (error) {
        console.error("Error fetching hospitals:", error);
      }
    };
    loadHospitals();
  }, []);

  useEffect(() => {
    if (doctor) {
      console.log("doctor in form", doctor);
      const defaultValues = {
        firstName: doctor?.firstName || doctor?.user?.firstName || "",
        email: doctor?.email || doctor?.user?.email || "",
        mobile: doctor?.mobile ? doctor?.mobile?.replace(/^\+91/, "") : doctor?.user?.mobile ? doctor?.user?.mobile.replace(/^\+91/, "") : "",
        hospitalId: doctor?.hospitalId || doctor?.hospital?.id || doctor?.defaultClinicId || doctor?.defaultClinic?.id || "",
        isClinicAdmin: doctor?.isClinicAdmin || false,
        isNetwork: doctor.isNetwork || false,
        supportedServices: doctor.supportedServices ? doctor.supportedServices : [],
        department: doctor?.department || doctor?.departments || [],
        designation: doctor?.designation || "",
        // profileImage: doctor?.profileImage || undefined,
      };
      reset(defaultValues);
      if (doctor?.hospitalId || doctor?.hospital || doctor?.defaultClinicId) {
        console.log("in hospital condiation", doctor);
        setValue("hospitalId", doctor?.hospitalId || doctor?.hospital?.id || doctor?.defaultClinicId || "");
      }
      if (doctor?.supportedServices) {
        setValue("supportedServices", doctor.supportedServices || []);
      }
      if (doctor?.department || doctor?.departments) {
        setValue("department", doctor?.department || doctor?.departments || []);
      }
      // setProfileImagePreview(doctor?.profileImage || undefined);
      // initialValues.current = defaultValues;
    }
  }, [doctor, reset]);

  const getProifileInfo = async () => {
    const doctorId = user?.doctorId;

    if (doctorId && isDoctor(userRole)) {
      await getProfileDetails(doctorId, "DOCTOR");
    }
    else {
      await getProfileDetails();
    }
  }

  const onSubmit: SubmitHandler<DoctorPersonalInfoFormInputs> = async (
    values
  ) => {
    console.log("values on submit personal", values);
    try {
      if (doctor) {
        console.log("doctor edit", doctor);

        try {
          const data = {
            ...values,
            lastName: "",
            doctorId: doctor?.id || doctor?.doctorId || "",
            userId: doctor.userId,
            mobile:
              values?.mobile && values?.mobile !== ""
                ? values?.mobile?.includes("+")
                  ? values?.mobile
                  : `+91${values?.mobile}`
                : "",
          };
          const response = await updateDoctorPersonalProfile(data);
          setSelectedDoctorInList({
            ...selectedDoctorInList,
            ...response?.data?.data
          })

          if (isDoctorOrNetwork(userRole)) {
            getProifileInfo();
          }

          showSnackbar("Personal Profile Updated Successfully", "success");
        } catch (error: any) {
          showSnackbar(`${error.response?.data.message}`, "error");
          return;
        }
      } else {
        try {
          const data = {
            ...values,
            lastName: "",
            mobile:
              values?.mobile && values?.mobile !== ""
                ? values?.mobile?.includes("+")
                  ? values?.mobile
                  : `+91${values?.mobile}`
                : "",
          };
          const response = await addDoctorPersonalProfile(data);
          console.log("respone after personal info add", response);
          showSnackbar("Personal Profile Added Successfully", "success");
          setSelectedDoctorInList(response?.data?.data || null);
          if (response?.data?.data?.doctorId) {
            if (isDoctorOrNetwork(userRole)) {
              navigate(`/settings/${response?.data?.data?.doctorId}/professional-profile`);
            }
            else {
              navigate(`/doctors/${response?.data?.data?.doctorId}/professional-profile`);

            }
          }
        } catch (error: any) {
          showSnackbar(`${error.response?.data.message}`, "error");
          return;
        }
      }
      // onClose();
    } catch (error) {
      const message = doctor
        ? "Could not update personal profile, please try again"
        : "Could not add personal-profile, please try again";
      showSnackbar(message, "error");
      console.error("Error saving hospital:", error);
    }
  };

  // const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = e.target.files?.[0] as any;
  //   if (file) {
  //     setProfileImagePreview(URL.createObjectURL(file));
  //     const formData = new FormData();
  //     formData.append("profileUrl", file);
  //     formData.append("table", "USER");
  //     // formData.append("profileImageUrl", profileDetails.user.profileImageUrl);
  //     // updateProfile(profileDetails.user.id, formData);
  //   }
  // };

  // const handleRemoveImage = (
  //   event: React.MouseEvent<HTMLLabelElement, MouseEvent>
  // ) => {
  //   event.stopPropagation();
  //   setProfileImagePreview(undefined);
  //   setValue("profileImage", null);
  //   event.stopPropagation();
  // };

  const handleDeleteDoctor = async () => {
    try {
      await deleteDoctor(doctor?.id || doctor?.doctorId);
      showSnackbar("Doctor deleted successfully", "success");
      setSelectedDoctorInList(null);
      setShowDoctorSubmenus(false);
      navigate(`/doctors`);
    } catch (error) {
      showSnackbar("Could not delete doctor, please try again", "error");
      console.error("Error deleting doctor:", error);
    }
  }

  return (
    <Box
      sx={{
        height: hideBreadCrumbs ? "calc(100% - 3rem)" : "100%",
        width: "100%",
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: hideBreadCrumbs ? "flex-end" : "space-between",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          {!hideBreadCrumbs && <CustomBreadcrumbs items={breadcrumbItems} />}
          <Box
            sx={{
              display: "flex",
              gap: "1.25rem",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              className="outlined-secondary-button"
              // startIcon={<AddIcon />}
              onClick={onClose}
              sx={{
                height: "2.25rem",
              }}
            >
              Cancel
            </Button>

            <Button
              startIcon={<SaveOutlinedIcon />}
              variant="contained"
              className="success-button"
              type="submit"
              sx={{
                height: "2.25rem",
              }}
            >
              Save
            </Button>

            {selectedDoctorInList && isClinicAdminOrAdmin(userRole) && <Button
              startIcon={<DeleteIcon />}
              variant="contained"
              className="warning-button"
              sx={{
                height: "2.25rem",
              }}
              onClick={() => setOpenConfirmModal(true)}
            >
              Delete Doctor
            </Button>}

            <ConfirmModal
              open={openConfirmModal}
              description="Are you sure, you want to delete this doctor?"
              handleConfirm={handleDeleteDoctor}
              handleCancel={() => setOpenConfirmModal(false)}
            />
          </Box>
        </Box>
        <Box
          sx={{
            p: "1.75rem",
            backgroundColor: "var(--neuro-white-text)",
            borderRadius: "0.625rem",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            maxHeight: "calc(100% - 3.25rem)",
            overflow: "auto",
          }}
        >
          <Grid container spacing={2.5}>
            {/* <Grid item xs={12} md={4} sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  sx={{
                    position: "relative",
                    width: { xs: "9.375rem", xl: "11.875rem" },
                    height: { xs: "9.375rem", xl: "11.875rem" },
                    border: "0.125rem solid var(--neuro-button-bg-success)",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                    boxSizing: "content-box",
                    overflow: "hidden",
                  }}
                >
                  <Avatar
                    src={profileImagePreview || ""}
                    sx={{
                      width: { xs: "9.375rem", xl: "11.875rem" },
                      height: { xs: "9.375rem", xl: "11.875rem" },
                      border: "0.125rem solid var(--neuro-button-bg-success)",
                      backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                      overflow: "hidden",
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      width: "100%",
                      height: "30%",
                      backgroundColor: "var(--neuro-button-bg-success)",
                      borderBottomLeftRadius: "50%",
                      borderBottomRightRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      overflow: "hidden",
                    }}
                  >
                    {profileImagePreview ? (
                      <IconButton
                        component="label"
                        sx={{
                          overflow: "hidden",
                          width: "2.5rem",
                          height: "2.5rem",
                          backgroundColor: "var(--neuro-button-bg-success)",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "absolute",
                          bottom: "0.438rem",
                          "&:hover": {
                            backgroundColor: "var(--neuro-button-bg-success)",
                          },
                        }}
                      // onClick={handleRemoveImage}
                      >
                        <DeleteIcon
                          sx={{
                            fontSize: "var(--neuro-font-size-tiny-plus)",
                            color: "var(--neuro-white-text)",
                          }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        component="label"
                        sx={{
                          overflow: "hidden",
                          width: "2.5rem",
                          height: "2.5rem",
                          backgroundColor: "var(--neuro-button-bg-success)",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          // boxShadow: "0 0 0 0.125rem var(--neuro-button-bg-success)",
                          position: "absolute",
                          bottom: "0.438rem",
                          "&:hover": {
                            backgroundColor: "var(--neuro-button-bg-success)",
                          },
                        }}
                      >
                        <CameraAltIcon
                          sx={{
                            fontSize: "var(--neuro-font-size-tiny-plus)",
                            color: "var(--neuro-white-text)",
                          }}
                        />
                        <input
                          accept="image/*"
                          style={{
                            display: "none",
                          }}
                          id="profile-image-upload"
                          type="file"
                          {...register("profileImage")}
                          onChange={(e) => {
                            register("profileImage").onChange(e);
                            // handleImageChange(e);
                          }}
                        />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </Grid> */}

            <Grid item xs={12} md={4}>
              <Box sx={{
                display: "flex",
                gap: "0.5rem",
                width: "100%",
                flexDirection: "column"
              }}>
                <Typography sx={inputLabelStyles}>Full Name *</Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "baseline",
                    width: "100%",
                  }}
                >
                  <Controller
                    name="prefix"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Box sx={{
                        maxWidth: "6.25rem"
                      }}>
                        <Select
                          {...field}
                          fullWidth
                          sx={selectStyles.withGreyBackground}
                          margin="dense"
                          error={!!error}
                          defaultValue={field.value}
                        >
                          {doctorPrefixes?.map((prefix) => (
                            <MenuItem key={prefix?.value} value={prefix?.value}>
                              {prefix.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    )}
                  />
                  <Input
                    type="text"
                    className="input-primary"
                    {...register("firstName")}
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box sx={{
                display: "flex",
                gap: "0.5rem",
                width: "100%",
                flexDirection: "column"
              }}>
                <Typography sx={inputLabelStyles}>Mobile Number *</Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "baseline",
                    width: "100%",
                  }}
                >
                  <Box sx={{
                    maxWidth: "6.25rem"
                  }}>
                    <Select
                      sx={selectStyles.countryCode}
                      name="countryCode"
                      value={"+91"}
                      // onChange={handleChange}
                      fullWidth
                    >
                      {countryCode?.map((code) => (
                        <MenuItem key={code.id} value={code.value}>
                          {code.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Input
                    type="text"
                    className="input-primary"
                    fullWidth
                    {...register("mobile")}
                    error={!!errors?.mobile}
                    helperText={errors?.mobile?.message}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Input
                type="text"
                className="input-primary"
                label="Email *"
                {...register("email")}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="hospitalId"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.375rem",
                      width: "100%",
                    }}
                  >
                    <Typography sx={inputLabelStyles}>Clinic *</Typography>
                    <Select
                      {...field}
                      fullWidth
                      sx={selectStyles.withGreyBackground}
                      margin="dense"
                      error={!!error}
                      defaultValue={doctor ? doctor?.hospitalId || doctor?.hospital?.id || doctor?.defaultClinicId || "" : ""}
                    >
                      {hospitals?.map((hospital) => (
                        <MenuItem key={hospital?.id} value={hospital?.id}>
                          {hospital.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {error && (
                      <Typography sx={erroMessageStyles}>
                        {error.message}
                      </Typography>
                    )}
                  </Box>
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Input
                type="text"
                className="input-primary"
                label="Designation *"
                {...register("designation")}
                error={!!errors.designation}
                helperText={errors.designation?.message}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="department"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.375rem",
                      width: "100%",
                    }}
                  >
                    <Typography sx={inputLabelStyles}>
                      Department *
                    </Typography>
                    <Select
                      {...field}
                      fullWidth
                      multiple
                      sx={selectStyles.withGreyBackground}
                      margin="dense"
                      error={!!error}
                      defaultValue={
                        doctor
                          ? doctor?.department || doctor?.departments || []
                          : []
                      }
                    >
                      {departments.map((department) => (
                        <MenuItem
                          key={department.value}
                          value={department.value}
                        >
                          {department.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {error && (
                      <Typography sx={erroMessageStyles}>
                        {error.message}
                      </Typography>
                    )}
                  </Box>
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="supportedServices"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.375rem",
                      width: "100%",
                    }}
                  >
                    <Typography sx={inputLabelStyles}>
                      Supported Services *
                    </Typography>
                    <Select
                      {...field}
                      fullWidth
                      multiple
                      sx={selectStyles.withGreyBackground}
                      margin="dense"
                      error={!!error}
                      defaultValue={
                        doctor
                          ? doctor?.supportedServices
                            ? doctor?.supportedServices
                            : []
                          : []
                      }
                    >
                      {supportedServices.map((supportedService) => (
                        <MenuItem
                          key={supportedService.value}
                          value={supportedService.value}
                        >
                          {supportedService.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {error && (
                      <Typography sx={erroMessageStyles}>
                        {error.message}
                      </Typography>
                    )}
                  </Box>
                )}
              />
            </Grid>

            {isClinicAdminOrAdmin(userRole) && <Grid item container xs={12} md={6} sx={{ maxHeight: "fit-content" }}>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
              }}>
                <Typography sx={inputLabelStyles}>
                  Role
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  <Grid item xs={6}>
                    <Checkbox
                      label="Clinic Admin"
                      error={!!errors.isClinicAdmin}
                      helperText={errors?.isClinicAdmin?.message}
                      disabled={Boolean(isNetworkChecked)}
                      defaultChecked={
                        doctor ? doctor?.isClinicAdmin || false : false
                      }
                      {...register("isClinicAdmin")}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Checkbox
                      label="Network Doctor"
                      error={!!errors.isNetwork}
                      helperText={errors?.isNetwork?.message}
                      disabled={Boolean(isClinicAdminChecked)}
                      defaultChecked={doctor ? doctor?.isNetwork || false : false}
                      {...register("isNetwork")}
                    />
                  </Grid>
                </Box>
              </Box>
            </Grid>}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default DoctorPersonalInfoForm;
