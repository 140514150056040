import React from "react"; //   useState, //  useEffect,

// third-party imports
import {
  Box,
  //   Collapse,
  //   IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import useAppStore from "../../store/appStore";
import { SidebarMenuItemProps } from "../../types/ui";
import Tooltip from "./Tooltip";

const NewSidebarMenuItem: React.FC<SidebarMenuItemProps> = ({
  text,
  icon,
  route,
  selected = false,
  sx = {},
  hasSubmenus = false,
  //   submenus = [],
  isSubMenu = false,
  //   initiallyOpen = false,
  showIconsOnly = false,
  usedInSubMenu = false,
}) => {
  // props & state values
  const navigate = useNavigate();

  const { setDrawerOpen, drawerOpen, isMenuClosedBySystem, setIsMenuCollapsed, setIsMenuClosedBySystem } = useAppStore();

  const isMobile = useMediaQuery("(max-width:50rem)");

  //   const [open, setOpen] = useState(initiallyOpen);

  // callbacks & functions
  const handleListItemClick = (route: string) => {
    navigate(`/${route}`);

    if (route.startsWith("settings") && !isMenuClosedBySystem) {
      if (isMobile) {
        setDrawerOpen(false);
        setIsMenuClosedBySystem(true);
      } else {
        setIsMenuCollapsed(true);
        setIsMenuClosedBySystem(true);
      }
    }

    if (isMenuClosedBySystem) {
      if (isMobile) {
        setDrawerOpen(true);
        setIsMenuClosedBySystem(false);
      }
      else {
        setIsMenuCollapsed(false);
        setIsMenuClosedBySystem(false);
      }
    }

    if (isMobile) {
      setDrawerOpen(!drawerOpen);
    }
  };

  //   useEffect(() => {
  //     if (initiallyOpen && hasSubmenus) {
  //       setOpen(true);
  //     }
  //   }, [initiallyOpen, hasSubmenus]);

  return (
    <>
      <Tooltip title={showIconsOnly ? text || "" : ""} placement="right">
        <ListItem
          // button
          onClick={() => handleListItemClick(route)}
          sx={{
            color: selected
              ? "var(--neuro-white-text)"
              : "var(--neuro-black-text)",
            background: selected
              ? "linear-gradient(90deg, var(--neuro-bg-primary-seagreen) 0%, var(--neuro-button-bg-success) 100%)"
              : "transparent",
            borderRadius: "6.25rem",
            "&:hover": {
              backgroundColor: selected ? "#3CB371" : "#f0f0f0",
            },
            padding: showIconsOnly
              ? "0.313rem 0.375rem 0.313rem 0.3rem"
              : usedInSubMenu
                ? "0.1rem 0.2rem"
                : "0.313rem 0.375rem",
            width: "auto",
            marginBottom: usedInSubMenu ? "0.5rem" : "0.563rem",
            marginInline: "0.625rem",
            cursor: "pointer",
            position: "relative",
            borderTopRightRadius: selected && hasSubmenus ? 0 : "6.25rem",
            borderBottomRightRadius: selected && hasSubmenus ? 0 : "6.25rem",
            marginRight: selected && hasSubmenus ? 0 : "0.625rem",
            "&:first-of-type": {
              marginTop: isSubMenu ? 0 : usedInSubMenu ? "0.5rem" : "1rem",
            },
            ...sx,
          }}
        >
          <Box display="flex" alignItems="center">
            <ListItemIcon
              sx={{
                color: selected
                  ? "var(--neuro-black-text)"
                  : "var(--neuro-white-text)",
                minWidth: usedInSubMenu ? "1.5rem" : "1.875rem",
                height: usedInSubMenu ? "1.5rem" : "1.875rem",
                width: usedInSubMenu ? "1.5rem" : "1.875rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: selected
                  ? "var(--neuro-white-text)"
                  : "var(--neuro-bg-primary-seagreen)",
                borderRadius: "50%",
                padding: "0.5rem",
                marginRight: showIconsOnly
                  ? 0
                  : selected
                    ? usedInSubMenu
                      ? "0.5rem"
                      : "0.875rem"
                    : usedInSubMenu
                      ? "0.5rem"
                      : "0.75rem",
                "&>svg": {
                  width: usedInSubMenu ? "1rem" : "1.125rem",
                  height: usedInSubMenu ? "1rem" : "1.125rem",
                },
              }}
            >
              {icon}
            </ListItemIcon>

            {!showIconsOnly && (
              <ListItemText
                primary={text}
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: usedInSubMenu
                      ? "var(--neuro-font-size-extra-small)"
                      : "var(--neuro-font-size-smaller-plus)",
                  },
                }}
              />
            )}
          </Box>

          {/* {hasSubmenus && (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                if (hasSubmenus) {
                  setOpen(!open);
                }
              }}
              sx={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
                height: "1.875rem",
                width: "1.875rem",
                // "& >svg": {
                //   width: 20,
                //   height: 20,
                // },
              }}
            >
              {open ? (
                <KeyboardArrowDownIcon
                  sx={{
                    fontSize: "var(--neuro-font-size-tiny-plus)",
                    color: "var(--neuro-black-text)",
                  }}
                />
              ) : (
                <KeyboardArrowRightIcon
                  sx={{
                    fontSize: "var(--neuro-font-size-tiny-plus)",
                    color: "var(--neuro-black-text)",
                  }}
                />
              )}
            </IconButton>
          )} */}
        </ListItem>
      </Tooltip>

      {/* {hasSubmenus && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {submenus.map((submenu, subIndex) => (
            <NewSidebarMenuItem
              key={subIndex}
              text={submenu.text}
              icon={submenu.icon}
              route={submenu.route}
              selected={submenu.selected}
              sx={{ paddingLeft: "2rem" }}
              isSubMenu
            />
          ))}
        </Collapse>
      )} */}
    </>
  );
};

export default NewSidebarMenuItem;
