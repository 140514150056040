import React, { useState } from "react";

import { DocumentsProps } from "../../../types/documents";
import FullscreenModal from "../../shared/FullscreenModal";
import NewReportViewerComponent from "./NewReportViewerComponent";

const NewReportViewer: React.FC<DocumentsProps> = ({ patientId, from = "" }) => {
    // props & state values 
    const [fullScreenModalOpen] = useState(true);

    return (
        <FullscreenModal open={fullScreenModalOpen} >
            <NewReportViewerComponent patientId={patientId} from={from} />
        </FullscreenModal>
    );
};

export default NewReportViewer;
