import React, { useState } from "react";
import { capitalizeFirstLetter } from "../../../utils/common";
import { TableWrapper } from "../../shared/TableWrapper";
import { useNavigate } from "react-router-dom";
import useAppStore from "../../../store/appStore";
import { isClinicAdminOrAdmin, isDoctor, isDoctorOrAdminOrClinicAdmin, isPatient, isPatientOrClinicAdminOrAdmin } from "../../../utils/auth";
import useAuthStore from "../../../store/authStore";
import { AppointmentsTableViewProps } from "../../../types/appointments";
import {
  getAppointmentStatus,
  getFormattedDateTime,
  getTimeDiffereForOngoing,
} from "../../../utils/appointments";
import {
  CANCELLED,
  COMPLETED,
  IN_PERSON,
  NOT_HAPPENED,
  ONGOING,
  ONLINE,
  UPCOMING,
} from "../../../utils/constants";
import AppointmentTableActions from "./AppointmentTableActions";
import { Box } from "@mui/material";
import config from "../../../config";

const AppointmentsTableView: React.FC<AppointmentsTableViewProps> = ({
  // handleEdit,
  // handleDelete,
  appointments,
  patientId,
  from = "",
}) => {
  // props & state values
  const { appointmentIdForMeet, setAppointmentMeetId } = useAppStore();
  const { userRole, user } = useAuthStore();
  const navigate = useNavigate();
  //   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElByRow, setAnchorElByRow] = useState<{
    [key: string]: HTMLElement | null;
  }>({});

  // callbacks & functions
  const formattedDate = (availableSlot: any) => {
    if (!availableSlot?.startTime)
      return {
        date: "",
        time: "",
      };
    const DateTimeObject = getFormattedDateTime(availableSlot.startTime);

    if (DateTimeObject && typeof DateTimeObject === "object") {
      const { day, month, year, hours, minutes, amOrPm } = DateTimeObject;
      return {
        date: `${day} ${month} ${year}`,
        time: `${hours}:${minutes} ${amOrPm}`,
      };
    } else {
      return {
        date: "",
        time: "",
      };
    }
  };

  // reschedule appointment
  const isRebook = (status: string, slot: any) => {
    return status === CANCELLED || status === COMPLETED || getAppointmentStatus(slot?.startTime, slot?.endTime) === NOT_HAPPENED;
  }

  const isReSchedule = (status: string, slot: any) => {
    return status !== CANCELLED && status !== COMPLETED && getAppointmentStatus(slot?.startTime, slot?.endTime) !== NOT_HAPPENED && (getAppointmentStatus(slot?.startTime, slot?.endTime) === UPCOMING || (getAppointmentStatus(slot?.startTime, slot?.endTime) === ONGOING && getTimeDiffereForOngoing(slot?.startTime) >= config?.RESCHEDULE_TIME_LIMIT));
  }

  const showReScheduleButton = (status: string, slot: any, doctor: any) => {
    return (isPatientOrClinicAdminOrAdmin(userRole) && (isRebook(status, slot) || isReSchedule(status, slot))) || (isDoctor(userRole) && doctor?.id === user?.doctorId && isReSchedule(status, slot));
  }

  const showPrescriptionButton = (row?: any) =>
    row?.prescription && row?.prescription.length > 0;

  const showMeetButton = (mode: any, slot: any, status: any) => {
    const appointmentStatus = getAppointmentStatus(
      slot?.startTime,
      slot?.endTime
    );
    return (
      status !== CANCELLED &&
      status !== COMPLETED &&
      ((mode === IN_PERSON && isDoctor(userRole)) || mode === ONLINE) &&
      (appointmentStatus === UPCOMING || appointmentStatus === ONGOING)
    );
  };

  const showPayButton = (status: any, slot: any, paymentStatus: any) =>
    (isPatient(userRole) || isClinicAdminOrAdmin(userRole)) &&
    status !== COMPLETED &&
    status !== CANCELLED &&
    getAppointmentStatus(slot?.startTime, slot?.endTime) !== NOT_HAPPENED &&
    paymentStatus === "PENDING";

  const showPayOfflineButton = (status: any, slot: any, paymentStatus: any) =>
    isClinicAdminOrAdmin(userRole) &&
    status !== COMPLETED &&
    status !== CANCELLED &&
    getAppointmentStatus(slot?.startTime, slot?.endTime) !== NOT_HAPPENED &&
    paymentStatus === "PENDING";

  const showDirectionsButton = (mode: any, slot: any, status: any) =>
    mode === IN_PERSON &&
    status !== CANCELLED &&
    (getAppointmentStatus(slot?.startTime, slot?.endTime) === ONGOING ||
      getAppointmentStatus(slot?.startTime, slot?.endTime) === UPCOMING);

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    rowId: string
  ) => {
    // setAnchorEl(event.currentTarget);
    setAnchorElByRow((prev) => ({ ...prev, [rowId]: event.currentTarget }));
  };

  const handleMenuClose = (rowId: string) => {
    // setAnchorEl(null);
    setAnchorElByRow((prev) => ({ ...prev, [rowId]: null }));
  };

  // table columns
  const columns = [
    {
      id: "patient.user.firstName",
      label: "Patient Name",
      sortable: true,
      sortComparator: (a: any, b: any) => {
        const aName = a?.patient?.user?.firstName || "";
        const bName = b?.patient?.user?.firstName || "";

        // Check if names start with a number
        const aStartsWithNumber = /^\d/.test(aName);
        const bStartsWithNumber = /^\d/.test(bName);

        // Move names starting with a number to the end
        if (aStartsWithNumber && !bStartsWithNumber) return 1;
        if (bStartsWithNumber && !aStartsWithNumber) return -1;

        // Move empty or null values to the end
        if (aName === "") return 1;
        if (bName === "") return -1;

        // Normal locale comparison for non-empty, non-numeric values
        return aName.localeCompare(bName);
      },
      render: (_: any, row: any) =>
        `${capitalizeFirstLetter(row?.patient?.user?.firstName || "") || "-"}`,
    },
    {
      id: "doctor.user.firstName",
      label: "Doctor Name",
      sortable: true,
      sortComparator: (a: any, b: any) => {
        const aName = a?.doctor?.user?.firstName || "";
        const bName = b?.doctor?.user?.firstName || "";

        // Move empty or null values to the end
        if (aName === "") return 1;
        if (bName === "") return -1;

        // Normal locale comparison for non-empty values
        return aName.localeCompare(bName);
      },
      render: (_: any, row: any) =>
        `Dr. ${capitalizeFirstLetter(row?.doctor?.user?.firstName || "") || "-"}`,
    },
    {
      id: "availableSlot.startTime",
      label: "Date",
      sortable: true,
      sortComparator: (a: any, b: any) => {
        const aName = a?.availableSlot?.startTime || "";
        const bName = b?.availableSlot?.startTime || "";

        // Move empty or null values to the end
        if (aName === "") return 1;
        if (bName === "") return -1;

        // Normal locale comparison for non-empty values
        return aName.localeCompare(bName);
      },
      render: (_: any, row: any) =>
        `${formattedDate(row?.availableSlot)
          ? `${formattedDate(row?.availableSlot)?.date} (${formattedDate(row?.availableSlot)?.time})`
          : ""
        }`,
    },
    {
      id: "doctor.defaultClinic.name",
      label: "Clinic",
      sortable: true,
      sortComparator: (a: any, b: any) => {
        const aName = a?.doctor?.defaultClinic?.name || "";
        const bName = b?.doctor?.defaultClinic?.name || "";

        // Check if names start with a number
        const aStartsWithNumber = /^\d/.test(aName);
        const bStartsWithNumber = /^\d/.test(bName);

        // Move names starting with a number to the end
        if (aStartsWithNumber && !bStartsWithNumber) return 1;
        if (bStartsWithNumber && !aStartsWithNumber) return -1;

        // Move empty or null values to the end
        if (aName === "") return 1;
        if (bName === "") return -1;

        // Normal locale comparison for non-empty, non-numeric values
        return aName.localeCompare(bName);
      },
      render: (_: any, row: any) =>
        `${row?.doctor?.defaultClinic?.name || "-"}`,
    },
    {
      id: "appointmentMode",
      label: "Type",
      sortable: true,
      sortComparator: (a: any, b: any) => {
        const aName = a?.appointmentMode || "";
        const bName = b?.appointmentMode || "";

        // Move empty or null values to the end
        if (aName === "") return 1;
        if (bName === "") return -1;

        // Normal locale comparison for non-empty values
        return aName.localeCompare(bName);
      },
      render: (_: any, row: any) =>
        `${row?.appointmentMode
          ? row?.appointmentMode === ONLINE
            ? "Online Visit"
            : "Clinic Visit"
          : "-"
        }`,
    },
    {
      id: "status",
      label: "Status",
      sortable: true,
      sortComparator: (a: any, b: any) => {
        const getStatus = (row: any) => {
          if (row?.status === CANCELLED) return CANCELLED;
          if (row?.status === COMPLETED) return COMPLETED;
          return (
            getAppointmentStatus(
              row?.availableSlot?.startTime,
              row?.availableSlot?.endTime
            ) || ""
          );
        };

        const aStatus = getStatus(a);
        const bStatus = getStatus(b);

        // Locale comparison for status
        return aStatus.localeCompare(bStatus);
      },
      render: (_: any, row: any) =>
        `${row?.status === CANCELLED
          ? CANCELLED
          : row?.status === COMPLETED
            ? COMPLETED
            : getAppointmentStatus(
              row?.availableSlot?.startTime,
              row?.availableSlot?.endTime
            ) || ""
        }`,
    },
    {
      id: `actions`,
      label: "Actions",
      render: (_: any, row: any) => {
        const hasActions = [
          showMeetButton(row?.appointmentMode, row?.availableSlot, row?.status),
          showPayButton(row?.status, row?.availableSlot, row?.paymentStatus),
          showPayOfflineButton(
            row?.status,
            row?.availableSlot,
            row?.paymentStatus
          ),
          showDirectionsButton(
            row?.appointmentMode,
            row?.availableSlot,
            row?.status
          ),
          showReScheduleButton(row?.status, row?.availableSlot, row?.doctor),
          showPrescriptionButton(row),
        ].includes(true);

        return (
          <>
            {hasActions && (
              <AppointmentTableActions
                // handleMenuClose={handleMenuClose}
                // handleMenuOpen={handleMenuOpen}
                handleMenuClose={() => handleMenuClose(row.id)}
                handleMenuOpen={(event: any) => handleMenuOpen(event, row.id)}
                anchorEl={anchorElByRow[row.id] || null}
                row={row}
                patientId={patientId}
                key={row?.id}
                from={from}
              />
            )}

            {!hasActions && (
              <Box
                sx={{
                  width: "55%",
                  textAlign: "center",
                }}
              >
                -
              </Box>
            )}
          </>
        );
      },
    },
  ];

  const handleCardClick = (row: any) => {
    const path = patientId
      ? isDoctorOrAdminOrClinicAdmin(userRole)
        ? `/patients/${patientId}/appointments/${row?.id}/details`
        : `/appointment/${row?.id}`
      : `/appointment/${row?.id}`;
    if (from === "meet") {
      navigate(`/meet/${appointmentIdForMeet}/appointments/${appointmentIdForMeet}/details`);
      setAppointmentMeetId(row?.id);
    } else {
      navigate(path);
    }
  };

  return (
    <TableWrapper
      columns={columns}
      rows={appointments}
      stickyHeader={true}
      maxHeight="100%"
      onRowClick={handleCardClick}
      rowSx={{
        cursor: "pointer",
      }}
    />
  );
};

export default AppointmentsTableView;
