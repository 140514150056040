import React, { useState, useEffect } from "react";
import { fetchDoctors } from "../../services/doctorService";
import { Box } from "@mui/material";
import { Doctor } from "../../types/common";
import DoctorsList from "./DoctorsList";
import useAppStore from "../../store/appStore";

const Doctors: React.FC = () => {
  // propes & state values
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { doctorsFilter, setSelectedDoctorInList, setShowDoctorSubmenus } =
    useAppStore();

  console.log("doctors", doctors);

  // callbacks & functions
  const loadDoctors = async (
    type: string,
    start: string | null,
    end: string | null,
    search: string
  ) => {
    try {
      setLoading(true);
      const response = await fetchDoctors(type, start, end, search);
      setDoctors(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching doctors:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadDoctors(
      "DOCTORS",
      doctorsFilter.customStartDate,
      doctorsFilter.customEndDate,
      doctorsFilter.searchValue || ""
    );
  }, [doctorsFilter.customStartDate, doctorsFilter.customEndDate]);

  useEffect(() => {
    setSelectedDoctorInList(null);
    setShowDoctorSubmenus(false);
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "var(--neuro-bg-light-grey-secondary)",
          // minHeight: "100%",
          height: "100%",
          // paddingInline: "20px",
          paddingBlock: "1rem",
          paddingInline: "0.875rem",
        }}
      >
        <DoctorsList
          doctors={doctors}
          loading={loading}
          loadDoctors={loadDoctors}
        />
      </Box>
    </>
  );
};

export default Doctors;
