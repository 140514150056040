import React from "react";
import {
  Box,
  // IconButton
} from "@mui/material";
import DashboardFilter from "./DashboardFilter";
import SearchInput from "../ui/SearchInput";
import SearchIcon from "@mui/icons-material/Search";
import FilterByMobileDropdown from "./FilterByMobileDropdown";
// import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import SortByDocuments from "../documents/list/SortByDocuments";

const ComponentFilter = ({
  updateRange,
  updateDates,
  handleFilterByCategoryValue,
  handleFilterByCategoryClose,
  handleFilterByCategoryOpen,
  filterByCategoryOptions,
  sortOptions,
  viewComponent,
  setSelectedSortOption,
  handleStoreSearchValueChange,
  range = "",
  searchValue = "",
  filterByCategoryValue = "",
  isEmpty = false,
  filterByCategoryOpen = false,
  hideRangeFilter = false,
  hideStatusFilter = false,
  showSortFilter = false,
  selectedSortOption = "",
  sortDisabled = false,
  hideSearchFilter = false,
  searchFilterPlaceholder = "Search",
  searchFilterWidth = "18.75rem",
  wrap = false,
}: {
  updateRange?: any;
  updateDates?: any;
  range?: string;
  isEmpty?: boolean;
  searchValue?: string;
  filterByCategoryOpen?: boolean;
  filterByCategoryValue?: string;
  handleFilterByCategoryValue?: any;
  handleFilterByCategoryClose?: any;
  handleFilterByCategoryOpen?: any;
  filterByCategoryOptions?: any[];
  sortOptions?: any[];
  hideRangeFilter?: boolean;
  hideStatusFilter?: boolean;
  showSortFilter?: boolean;
  viewComponent?: React.ReactElement | React.ReactNode | JSX.Element;
  selectedSortOption?: any;
  setSelectedSortOption?: any;
  sortDisabled?: boolean;
  hideSearchFilter?: boolean;
  handleStoreSearchValueChange?: any;
  searchFilterPlaceholder?: string;
  searchFilterWidth?: string;
  wrap?: boolean;
}) => {
  // props & state values
  const styles = {
    flexChild: {
      display: "flex",
      alignItems: "center",
      gap: "0.875rem",
      flexWrap: wrap ? "wrap" : "nowrap",
    },
  };

  return (
    <Box
      sx={{
        paddingInline: "0.938rem",
        paddingBlock: "0.75rem",
        backgroundColor: "var(--neuro-white-text)",
        borderRadius: "0.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: wrap ? "wrap" : "nowrap",
        gap: "0.875rem",
      }}
    >
      <Box sx={styles.flexChild}>
        {!hideSearchFilter && (
          <SearchInput
            value={searchValue}
            onChange={(event) =>
              handleStoreSearchValueChange(event?.target?.value)
            }
            placeholder={searchFilterPlaceholder}
            endIcon={<SearchIcon />}
            width={searchFilterWidth}
          />
        )}

        {!hideRangeFilter && (
          <DashboardFilter
            updateRange={updateRange}
            updateDates={updateDates}
            range={range}
            isEmpty={isEmpty ? true : false}
          />
        )}

        {showSortFilter && (
          <SortByDocuments
            options={sortOptions || []}
            selectedOption={selectedSortOption}
            setSelectedOption={setSelectedSortOption}
            disabled={sortDisabled ? true : false}
          />
        )}

        {!hideStatusFilter && (
          <FilterByMobileDropdown
            open={filterByCategoryOpen}
            selectedValue={filterByCategoryValue}
            setSelectedValue={handleFilterByCategoryValue}
            handleClose={handleFilterByCategoryClose}
            handleOpen={handleFilterByCategoryOpen}
            options={filterByCategoryOptions || []}
          />
        )}
      </Box>

      <Box sx={styles.flexChild}>
        {viewComponent ? viewComponent : null}
        {/* <IconButton
          disabled
          sx={{
            width: "2.25rem",
            height: "2.25rem",
            "&.Mui-disabled > svg": {
              opacity: 0.5,
            },
          }}
        >
          <TuneOutlinedIcon sx={{ color: "var(--neuro-black-text)" }} />
        </IconButton> */}
      </Box>
    </Box>
  );
};

export default ComponentFilter;
