import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { capitalizeFirstLetter } from "../../utils/common";
import PersonalInfoPage from "../profile/patient/PersonalInfoPage";
import CustomBreadcrumbs from "../shared/CustomBreadcrumbs";
import Button from "../ui/Button";
import PersonIcon from "@mui/icons-material/Person";
import NewPatientDetails from "./NewPatientDetails";
import useAppStore from "../../store/appStore";
import useAuthStore from "../../store/authStore";
import { isDoctorOrAdminOrClinicAdmin } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
import PatientSignUp from "./PatientSignUp";

function AddNewPatientPage({ id = "", from = "", directionColumn = false }: { id?: string; from?: string; directionColumn?: boolean }) {
    // props & stat values
    const [showProfileEdit, setShowProfileEdit] = useState<boolean>(false);
    const { userRole } = useAuthStore();
    const navigate = useNavigate();

    const { selectedPatientInList, showPatientsSubmenus, setSelectedPatientInList, setShowPatientsSubmenus } = useAppStore();

    console.log("selectedPatientInList in patient click", selectedPatientInList);

    const breadcrumbItems = [
        { label: "Patients", href: "/patients" },
        {
            label: `${capitalizeFirstLetter(selectedPatientInList?.user?.firstName || "")}`,
        },
        { label: "Profile" },
    ];

    // callbacks & functions
    useEffect(() => {
        if (from === "meet") {
            setShowProfileEdit(false);
        }
        else {
            if (!selectedPatientInList && !showPatientsSubmenus) {
                if (isDoctorOrAdminOrClinicAdmin(userRole)) {
                    navigate("/patients");
                } else {
                    navigate("/appointments");
                }
            }
        }

    }, [selectedPatientInList, showPatientsSubmenus, from]);

    const handleProfileViewEdit = () => {
        setShowProfileEdit((prev) => !prev);
    };

    const handleAfterPatientAdd = (patient: any) => {
        setSelectedPatientInList(patient);
        setShowPatientsSubmenus(false);
    }

    return (
        <Box
            sx={{
                backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                height: from === "meet" ? "auto" : "100%",
                paddingBlock: "1rem",
                paddingInline: "0.875rem",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "100%",
            }}
        >
            {selectedPatientInList && <Box
                sx={{
                    display: "flex",
                    gap: "1.25rem",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    flexWrap: "wrap",
                }}
            >
                <CustomBreadcrumbs items={breadcrumbItems} />

                {from !== "meet" && <Button
                    variant="outlined"
                    color="secondary"
                    className="outlined-secondary-button"
                    onClick={handleProfileViewEdit}
                    startIcon={<PersonIcon />}
                    sx={{
                        flexShrink: 0,
                        height: "2.25rem",
                    }}
                >
                    {showProfileEdit ? "View Profile" : "Edit Profile"}
                </Button>}
            </Box>}

            {selectedPatientInList && !showPatientsSubmenus &&
                <>
                    {showProfileEdit ? (
                        <PersonalInfoPage
                            patientId={selectedPatientInList?.id}
                            from={from !== "" ? from : "patient-list"}
                        />
                    ) : (
                        <NewPatientDetails
                            id={id ? id : selectedPatientInList?.id || ""}
                            from={from !== "" ? from : "patient-list"}
                            directionColumn={directionColumn}
                        />
                    )}
                </>
            }

            {showPatientsSubmenus && !selectedPatientInList && <Box sx={{
                height: "100%",
                width: "100%",
                overflowY: "hidden",
            }}>
                <PatientSignUp afterAdd={handleAfterPatientAdd} />
            </Box>}
        </Box>
    );
}

export default AddNewPatientPage;
