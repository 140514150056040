import React from "react";
import { Box, Typography } from "@mui/material";
import Modal from "../ui/Modal";
import { LeaveSettingConfirmModalProps } from "../../types/doctors";

const LeaveSettingConfirmModal: React.FC<LeaveSettingConfirmModalProps> = ({
    open,
    width,
    closeIcon,
    description,
    handleCancel,
    children,
    ...props
}) => {
    // props & state values
    const headingStyles = {
        fontWeight: "var(--neuro-font-weight-bold)",
        fontSize: "var(--neuro-font-size-large-medium)",
        fontFamily: "var(--neuro-font-family-roboto-condensed)",
        lineHeight: "var(--neuro-line-height-medium)",
        textAlign: "center",
    };

    const descriptionStyles = {
        marginTop: "0.75rem",
        marginBottom: "1.5rem",
        textAlign: "center"
    };


    return (
        <Modal
            open={open}
            closeIcon={closeIcon}
            onClose={handleCancel}
            width={width ? width : "max-content"}
            {...props}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
                onClick={(event) => event.stopPropagation()}
            >
                <Typography sx={headingStyles}>Confirm</Typography>

                <Typography sx={descriptionStyles}>{description}</Typography>

                {children && <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {children}
                </Box>}
            </Box>
        </Modal>
    );
};

export default LeaveSettingConfirmModal;
