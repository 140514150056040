import React from 'react';
import { RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material';
import { inputLabelStyles } from '../../utils/styles';
import { CustomRadioGroupProps } from '../../types/ui';

const CustomRadioGroup: React.FC<CustomRadioGroupProps> = ({
    options,
    selectedValue,
    onChange,
}) => {
    return (
        <RadioGroup
            value={selectedValue}
            onChange={(e) => onChange(e.target.value)}
            sx={{
                display: "flex",
                flexDirection: 'row',
                flexWrap: "wrap",
                gap: 2, // Adds spacing between radio buttons
            }}
        >
            {options.map((option) => (
                <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={
                        <Radio
                            sx={{
                                '&.Mui-checked': {
                                    color: "var(--neuro-bg-darkblue-primary)", // Customize the color when checked
                                },
                            }}
                        />
                    }
                    label={
                        <Typography
                            sx={inputLabelStyles}
                        >
                            {option.label}
                        </Typography>
                    }
                    sx={{
                        alignItems: 'center',
                        gap: 1, // Spacing between radio button and label
                    }}
                />
            ))}
        </RadioGroup>
    );
};

export default CustomRadioGroup;
