import { Box, Grid } from "@mui/material";
import ComponentHeader from "../../shared/ComponentHeader";
import useAuthStore from "../../../store/authStore";
import AbhaCardDetails from "./AbhaCardDetails";

const NewAbhaDetailsPage = () => {
    // props & state values
    const { user } = useAuthStore();

    return (
        <Box
            sx={{
                paddingBlock: "1rem",
                paddingInline: "0.875rem",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "100%",
                height: "100%",
            }}
        >
            <ComponentHeader title="ABHA Details" />

            {/* Main Content */}
            <Grid container spacing={2} sx={{ height: "calc(100% - 60px)" }}>
                <Grid item xs={12} sx={{ height: "100%" }}>
                    <Box sx={{ width: "100%", height: "100%" }}>
                        <AbhaCardDetails userId={user?.userId || ""} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default NewAbhaDetailsPage;
