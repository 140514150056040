import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isDoctorOrAdminOrClinicAdmin } from "../../utils/auth";
import useAuthStore from "../../store/authStore";
import { Box } from "@mui/material";
import useAppStore from "../../store/appStore";
import CustomBreadcrumbs from "../shared/CustomBreadcrumbs";
import { capitalizeFirstLetter } from "../../utils/common";
import Prescriptions from "../prescriptions/Prescriptions";
import Button from "../ui/Button";
import { downloadPrescription } from "../../services/documentsService";
import Spinner from "../ui/Spinner";

const PatientPrescriptions = () => {
    // props & state values
    const navigate = useNavigate();
    const { userRole } = useAuthStore();
    const { selectedPatientInList, showSnackbar } = useAppStore();

    const [downloadLoading, setDownloadLoading] = useState(false);

    const [selectedPrescriptionInCard, setSelectedPrescriptionInCard] = useState<any>(null);
    const [prescriptionsCount, setPrescriptionsCount] = useState(0);

    const breadcrumbItems = [
        { label: "Patients", href: "/patients" },
        {
            label: `${capitalizeFirstLetter(selectedPatientInList?.user?.firstName || "")}`,
        },
        { label: "Prescriptions" },
    ];

    // callbacks & functions
    useEffect(() => {
        if (!selectedPatientInList) {
            if (isDoctorOrAdminOrClinicAdmin(userRole)) {
                navigate("/patients");
            } else {
                navigate("/appointments");
            }
        }
    }, [selectedPatientInList]);

    const downloadPrescriptionHandler = async () => {
        setDownloadLoading(true);
        showSnackbar("Prescription is downloading, please wait", "info");
        try {
            const response = await downloadPrescription(selectedPrescriptionInCard?.appointmentId || "");
            const contentDisposition = response?.headers["content-disposition"];
            const blobData = response?.data;
            const fileName = contentDisposition;
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blobData);
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(link.href);
            showSnackbar("Prescription is downloaded", "success");
        } catch (error) {
            showSnackbar(
                "Could not download prescription, please try again",
                "error"
            );
        } finally {
            setDownloadLoading(false);
        }
    };

    const stateUpdaterForParent = (prescription: any) => {
        setSelectedPrescriptionInCard(prescription)
    }

    const countUpdaterForParent = (count: number) => {
        setPrescriptionsCount(count);
    }

    return (
        <Box
            sx={{
                backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "100%",
                height: "100%",
                paddingBlock: selectedPatientInList ? "1rem" : 0,
                paddingInline: selectedPatientInList ? "0.875rem" : 0,
            }}
        >
            {selectedPatientInList &&
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <CustomBreadcrumbs items={breadcrumbItems} />

                    {prescriptionsCount > 0 && <Button
                        variant="outlined"
                        color="secondary"
                        className="outlined-secondary-button"
                        onClick={downloadPrescriptionHandler}
                        disabled={!selectedPrescriptionInCard || downloadLoading}
                        sx={{ height: "2.25rem", width: "6rem" }}
                    >
                        {downloadLoading ? <Spinner /> : "Download"}
                    </Button>}
                </Box>
            }
            <Prescriptions patientId={selectedPatientInList?.id || ""} stateUpdaterForParent={stateUpdaterForParent} countUpdaterForParent={countUpdaterForParent} />
        </Box>
    );
};

export default PatientPrescriptions;
