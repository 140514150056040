import { create } from "zustand";
import { submitFormResponseApi, assignFormApi } from "../services/formService";

interface FormsState {
  formSubmitted: any;
  formAssigned: any;
  submitLoading: boolean;
  assignLoading: boolean;
  submitError: string | null;
  assignError: string | null;
  submitFormResponse: (patientFormId: string, data: any) => Promise<void>;
  assignForm: (data: any) => Promise<void>;
  cleanUpFormSubmittedState: () => void;
  cleanUpFormAssignedState: () => void;

  // state for survey-form
  disabledObj: Record<string, boolean>; // All values must be strictly boolean
  setDisabledObj: (
    update: Partial<FormsState['disabledObj']> | ((prev: FormsState['disabledObj']) => Partial<FormsState['disabledObj']>)
  ) => void;
  allFormData: any;
  setAllFormData: (value: any) => void;
  selectedInput: any;
  setSelectedInput: (value: any) => void;
  selectedQuestionType: any;
  setSelectedQuestionType: (value: any) => void;

  resetStore: any;
}

const initialState = {
  formSubmitted: null,
  formAssigned: null,
  submitLoading: false,
  assignLoading: false,
  submitError: null,
  assignError: null,
  allFormData: {
    isScoreBased: false,
    pages: [
      {
        name: "page1",
        elements: [
          [],
        ],
      },
    ],
  },
  selectedInput: null,
  selectedQuestionType: null
}

const useFormStore = create<FormsState>((set) => ({
  // Initial states
  formSubmitted: null,
  formAssigned: null,
  submitLoading: false,
  assignLoading: false,
  submitError: null,
  assignError: null,

  // initial state for survey-form
  disabledObj: {
    "Yes/No(Boolean)": false,
    FileUpload: false,
    MultiDropdown: false,
    ImagePicker: false,
    Ranking: false,
    TextFiled: false,
    RadioButtonGroup: false,
    RatingScale: false,
    SelectDropdown: false,
  },
  allFormData: {
    isScoreBased: false,
    pages: [
      {
        name: "page1",
        elements: [
          [],
        ],
      },
    ],
  },
  selectedInput: null,
  setSelectedInput: (value: any) => set({ selectedInput: value }),
  selectedQuestionType: null,
  setSelectedQuestionType: (value: any) => set({ selectedQuestionType: value }),

  // Submit form response action
  submitFormResponse: async (patientFormId: string, data: any) => {
    set({ submitLoading: true, submitError: null });
    try {
      const response = await submitFormResponseApi(patientFormId, data);
      set({ formSubmitted: response.data, submitLoading: false });
    } catch (error) {
      set({
        submitError: "Failed to submit form response",
        submitLoading: false,
      });
    }
  },

  // Assign form action
  assignForm: async (data: any) => {
    set({ assignLoading: true, assignError: null });
    try {
      const response = await assignFormApi(data);
      set({ formAssigned: response.data, assignLoading: false });
    } catch (error) {
      set({
        assignError: "Failed to assign form",
        assignLoading: false,
      });
    }
  },

  //  update disable state for survey-form
  setDisabledObj: (update) => {
    set((state) => {
      const updatedDisabledObj =
        typeof update === 'function'
          ? update(state.disabledObj)
          : update;

      // Ensure no values in the updated object are undefined
      const sanitizedDisabledObj = Object.entries(updatedDisabledObj).reduce<Record<string, boolean>>((acc, [key, value]) => {
        acc[key] = value ?? false; // Default to `false` if `undefined`
        return acc;
      }, {});

      return {
        disabledObj: {
          ...state.disabledObj,
          ...sanitizedDisabledObj,
        },
      };
    });
  },
  setAllFormData: (value: any) => {
    set({ allFormData: value });
  },

  // Cleanup form submitted state
  cleanUpFormSubmittedState: () => {
    set({ formSubmitted: null, submitLoading: false, submitError: null });
  },

  // Cleanup form assigned state
  cleanUpFormAssignedState: () => {
    set({ formAssigned: null, assignLoading: false, assignError: null });
  },

  resetStore: () => {
    set({ ...initialState });
  }
}));

export default useFormStore;
