import { Box, Typography } from '@mui/material';
import Button from '../../ui/Button';
import Logo from '../../shared/Logo';
import { FormHeading } from './FormHeading';
import LoadingScreen from '../../shared/LoadingScreen';
import ContentCenter from '../../shared/ContentCenter';
import { useEffect, useState } from 'react';

export const PublicFormLanding = ({ setShowForm, loading, formDetails }: { setShowForm: any; loading: boolean; formDetails: any }) => {
    // props & state values
    const [questionsCount, setQuestionsCount] = useState<number>(0)
    // callbacks & functions
    useEffect(() => {
        if (formDetails && formDetails?.questions && formDetails?.questions?.pages && formDetails?.questions?.pages?.length > 0) {
            const totalQuestions = formDetails?.questions?.pages.reduce((total: number, page: any) => {
                return total + page?.elements?.length || 0;
            }, 0);

            if (totalQuestions > 0 && typeof totalQuestions === "number" && !isNaN(totalQuestions)) {
                setQuestionsCount(totalQuestions);
            }
        }
    }, [formDetails])

    return (
        <Box
            sx={{
                boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.10)",
                background: "linear-gradient(90deg, #62B55A 0%, #54B895 100%)",
                display: 'flex',
                width: '100vw',
                height: "100vh",
                alignItems: 'center',
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    borderRadius: '25px',
                    bgcolor: '#fff',
                    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    maxWidth: '100%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: '50px 80px',
                    margin: "10px",
                    '@media (max-width: 600px)': {
                        padding: '20px'
                    }
                }}
            >
                {loading && <LoadingScreen />}
                {
                    !loading && !formDetails && <ContentCenter><Typography className="no-data">No details found</Typography></ContentCenter>
                }
                {formDetails && <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        '@media (max-width: 991px)': {
                            maxWidth: '100%'
                        }
                    }}
                >
                    <Logo width="10.75rem" height="7.625rem" />
                    <Box
                        sx={{
                            bgcolor: '#dcdcdc',
                            alignSelf: 'stretch',
                            height: '1px',
                            marginTop: '25px',
                            '@media (max-width: 991px)': {
                                maxWidth: '100%'
                            }
                        }}
                    />
                    <FormHeading
                        title={formDetails?.name || ""}
                        subtitle="Hi there, please fill out and submit this form."
                        questionsCount={questionsCount || 0}
                    />
                    <Button
                        variant="contained"
                        className='error-button'
                        onClick={() => setShowForm(true)}
                        sx={{
                            height: "2.25rem",
                            mt: 6
                        }}
                    >
                        Let's Go
                    </Button>
                </Box>}

            </Box>
        </Box>
    );
};