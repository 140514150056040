import { useEffect } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Typography,
  Box,
  Grid,
  //  Avatar,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
} from "@mui/material";
import Input from "../ui/Input";
// import CameraAltIcon from "@mui/icons-material/CameraAlt";
// import DeleteIcon from "@mui/icons-material/Delete";

import useAppStore from "../../store/appStore";
import {
  countryCode,
  indianStates,
  reasonForReferrals,
  urgencyOfReferralOptions,
} from "../../utils/common";
import ComponentHeader from "../shared/ComponentHeader";
import Button from "../ui/Button";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { AddEditReferralFormInputs } from "../../types/referrals";
import { referralValidationSchema } from "../../validations/referralValidation";
import { DateTime } from "luxon";
import { addReferral, updateReferral } from "../../services/referralService";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const AddEditReferralPage = ({
  loadReferrals,
  onClose,
  referral = null,
}: {
  onClose: any;
  referral?: any;
  loadReferrals?: any;
}) => {
  // props & state values
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm<AddEditReferralFormInputs>({
    resolver: yupResolver(referralValidationSchema),
    defaultValues: {
      country: referral?.country || "India",
      urgencyOfReferral: referral?.urgencyOfReferral || "Routine",
      state: "",
    },
  });

  // const [profileImagePreview, setProfileImagePreview] = useState<
  //   string | undefined
  // >();
  const { showSnackbar } = useAppStore();

  console.log("errors in form", errors);

  const styles = {
    headerStyles: {
      fontWeight: "var(--neuro-font-weight-medium)",
      fontSize: "var(--neuro-font-size-medium-smallest)",
      lineHeight: "120%",
    },
  };

  // const { user } = useAuthStore();

  // callbaks & functions
  //   useEffect(() => {
  //     if (loadingUpdateProfile) {
  //       showSnackbar("Updating profile, please wait", "info");
  //     } else if (errorUpdateProfile) {
  //       showSnackbar("Could not update profile, please try again", "error");
  //       cleanUpUpdateProfileState();
  //     } else if (updatedProfileDetails) {
  //       showSnackbar("Profile updated", "success");
  //       if (patientId) {
  //         getProfileDetails(patientId, "PATIENT");
  //       } else if (!patientId) {
  //         getProfileDetails();
  //       }
  //       cleanUpUpdateProfileState();
  //     }
  //   }, [updatedProfileDetails, errorUpdateProfile, loadingUpdateProfile]);

  useEffect(() => {
    if (referral) {
      console.log("referral in form", referral);
      const dateOfBirth = referral.dateOfBirth
        ? DateTime.fromISO(referral.dateOfBirth)
        : undefined; // Handle null or invalid dates safely
      const defaultValues = {
        firstName: referral.firstName || "",
        email: referral.email || "",
        mobile: referral.mobile ? referral.mobile.replace(/^\+91/, "") : "",
        dateOfBirth: dateOfBirth,
        gender: referral.gender || "",
        streetAddress: referral.streetAddress || "",
        city: referral.city || "",
        state: referral.state || "",
        zipCode: referral.zipCode || "",
        country: referral.country || "India",
        // doctorId: referral.doctorId,
        symptoms: referral.symptoms || "",
        urgencyOfReferral: referral.urgencyOfReferral || "Routine",
        reasonForReferral: referral.reasonForReferral || "",
        currentMedications: referral.currentMedications || "",
        allergies: referral.allergies || "",
        relevantMedicalConditions: referral.relevantMedicalConditions || "",
        previousTreatments: referral.previousTreatments || "",
        referralDoctorFirstName: referral.referralDoctorFirstName || "",
        referralDoctorLastName: referral.referralDoctorLastName || "",
        referralDoctorMobile: referral.referralDoctorMobile
          ? referral.referralDoctorMobile.replace(/^\+91/, "")
          : "",
        referralDoctorEmail: referral.referralDoctorEmail || "",
        notes: referral.notes || "",
      };
      reset(defaultValues);
      if (referral?.state) {
        setValue("state", referral.state); // Update the state value
      }
      if (referral?.reasonForReferral) {
        setValue("reasonForReferral", referral.reasonForReferral);
      }
      // setProfileImagePreview(hospital.user.profileImageUrl);
      // initialValues.current = defaultValues;
    }
  }, [referral, reset]);

  const onSubmit: SubmitHandler<AddEditReferralFormInputs> = async (values) => {
    console.log("values", values);
    try {
      if (referral) {
        console.log("referral edit", referral);

        try {
          const referralId = referral.id;
          const data = {
            ...values,
            lastName: "",
            mobile:
              values?.mobile && values?.mobile !== ""
                ? values?.mobile?.includes("+")
                  ? values?.mobile
                  : `+91${values?.mobile}`
                : "",
            referralDoctorMobile:
              values?.referralDoctorMobile &&
              values?.referralDoctorMobile !== ""
                ? values?.referralDoctorMobile?.includes("+")
                  ? values?.referralDoctorMobile
                  : `+91${values?.referralDoctorMobile}`
                : "",
          };
          await updateReferral(referralId, data);
          loadReferrals();
          showSnackbar("Referral Updated Successfully", "success");
        } catch (error: any) {
          showSnackbar(`${error.response?.data.message}`, "error");
          return;
        }
      } else {
        // delete (values as any).id;
        try {
          const data = {
            ...values,
            lastName: "",
            mobile:
              values?.mobile && values?.mobile !== ""
                ? values?.mobile?.includes("+")
                  ? values?.mobile
                  : `+91${values?.mobile}`
                : "",
            referralDoctorMobile:
              values?.referralDoctorMobile &&
              values?.referralDoctorMobile !== ""
                ? values?.referralDoctorMobile?.includes("+")
                  ? values?.referralDoctorMobile
                  : `+91${values?.referralDoctorMobile}`
                : "",
          };
          await addReferral(data);
          loadReferrals();
          showSnackbar("New Referral Created Successfully", "success");
        } catch (error: any) {
          showSnackbar(`${error.response?.data.message}`, "error");
          return;
        }
      }
      onClose();
    } catch (error) {
      const message = referral
        ? "Could not update referral, please try again"
        : "Could not add referral, please try again";
      showSnackbar(message, "error");
      console.error("Error saving hospital:", error);
    }
  };

  // const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = e.target.files?.[0] as any;
  //   if (file && profileDetails) {
  //     setProfileImagePreview(URL.createObjectURL(file));
  //     const formData = new FormData();
  //     formData.append("profileUrl", file);
  //     formData.append("table", "USER");
  //     formData.append("profileImageUrl", profileDetails.user.profileImageUrl);
  //     // await updateProfile(profileDetails.user.id, formData);
  //     updateProfile(profileDetails.user.id, formData);
  //     // showSnackbar(`Your profile updated successfully.`, "success");
  //   }
  // };

  // const handleRemoveImage = (
  //   event: React.MouseEvent<HTMLLabelElement, MouseEvent>
  // ) => {
  //   event.stopPropagation();
  //   setProfileImagePreview(undefined);
  //   setValue("profileImage", null);
  //   event.stopPropagation();
  // };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <ComponentHeader title="Add New Referral / Edit Referral" />
          <Box
            sx={{
              display: "flex",
              gap: "1.25rem",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              className="outlined-secondary-button"
              // startIcon={<AddIcon />}
              onClick={onClose}
              sx={{
                height: "2.25rem",
              }}
            >
              Cancel
            </Button>
            <Button
              startIcon={<SaveOutlinedIcon />}
              variant="contained"
              className="success-button"
              type="submit"
              sx={{
                height: "2.25rem",
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            p: "1.75rem",
            backgroundColor: "var(--neuro-white-text)",
            borderRadius: "0.625rem",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            height: "calc(100% - 3.25rem)",
            overflow: "auto",
          }}
        >
          {/* Patient Details   */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Typography sx={styles.headerStyles}>
              Patient Information
            </Typography>
            <Grid container spacing={3.5}>
              <Grid item container xs={12} md={4}>
                {/* <Grid item xs={12}>
              <Box
                sx={{
                  position: "relative",
                  width: { xs: "9.375rem", xl: "11.875rem" },
                  height: { xs: "9.375rem", xl: "11.875rem" },
                  border: "0.125rem solid var(--neuro-button-bg-success)",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                  boxSizing: "content-box",
                  overflow: "hidden",
                  marginLeft: { xs: 0, md: "6.25rem" },
                }}
              >
                <Avatar
                  src={profileImagePreview || ""}
                  sx={{
                    width: { xs: "9.375rem", xl: "11.875rem" },
                    height: { xs: "9.375rem", xl: "11.875rem" },
                    border: "0.125rem solid var(--neuro-button-bg-success)",
                    backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                    overflow: "hidden",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    height: "30%",
                    backgroundColor: "var(--neuro-button-bg-success)",
                    borderBottomLeftRadius: "50%",
                    borderBottomRightRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                  }}
                >
                  {profileImagePreview ? (
                    <IconButton
                      component="label"
                      sx={{
                        overflow: "hidden",
                        width: "2.5rem",
                        height: "2.5rem",
                        backgroundColor: "var(--neuro-button-bg-success)",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // boxShadow: "0 0 0 0.125rem var(--neuro-button-bg-success)",
                        position: "absolute",
                        bottom: "0.438rem",
                        "&:hover": {
                          backgroundColor: "var(--neuro-button-bg-success)",
                        },
                      }}
                      onClick={handleRemoveImage}
                    >
                      <DeleteIcon
                        sx={{
                          fontSize: "var(--neuro-font-size-tiny-plus)",
                          color: "var(--neuro-white-text)",
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      component="label"
                      sx={{
                        overflow: "hidden",
                        width: "2.5rem",
                        height: "2.5rem",
                        backgroundColor: "var(--neuro-button-bg-success)",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // boxShadow: "0 0 0 0.125rem var(--neuro-button-bg-success)",
                        position: "absolute",
                        bottom: "0.438rem",
                        "&:hover": {
                          backgroundColor: "var(--neuro-button-bg-success)",
                        },
                      }}
                    >
                      <CameraAltIcon
                        sx={{
                          fontSize: "var(--neuro-font-size-tiny-plus)",
                          color: "var(--neuro-white-text)",
                        }}
                      />
                      <input
                        accept="image/*"
                        style={{
                          display: "none",
                        }}
                        id="profile-image-upload"
                        type="file"
                        {...register("profileImage")}
                        onChange={(e) => {
                          register("profileImage").onChange(e);
                          handleImageChange(e);
                        }}
                      />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Grid> */}
                <Grid item xs={12}>
                  <Input
                    type="text"
                    className="input-primary"
                    label="Full Name *"
                    {...register("firstName")}
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: "var(--neuro-font-size-smaller-plus)",
                      lineHeight: "120%",
                      fontFamily: "var(--neuro-font-family-roboto-condensed)",
                      marginBottom: "0.375rem",
                    }}
                  >
                    Gender *
                  </Typography>

                  <Controller
                    defaultValue="MALE"
                    name="gender"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup row {...field}>
                        <FormControlLabel
                          value="MALE"
                          control={<Radio />}
                          label="Male"
                        />
                        <FormControlLabel
                          value="FEMALE"
                          control={<Radio />}
                          label="Female"
                        />
                        <FormControlLabel
                          value="OTHER"
                          control={<Radio />}
                          label="Other"
                        />
                      </RadioGroup>
                    )}
                  />

                  {errors.gender && (
                    <Typography color="error" variant="body2">
                      {errors.gender.message}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="dateOfBirth"
                    control={control}
                    defaultValue={undefined}
                    render={({ field, fieldState: { error } }) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.375rem",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "var(--neuro-font-size-smaller-plus)",
                            lineHeight: "120%",
                            fontFamily:
                              "var(--neuro-font-family-roboto-condensed)",
                            marginBottom: "0.375rem",
                          }}
                        >
                          Date of Birth
                        </Typography>

                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                          <DatePicker
                            value={field.value || null} // Ensure value is not undefined
                            onChange={(date) => {
                              field.onChange(date);
                            }}
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                sx: {
                                  "& .MuiOutlinedInput-root": {
                                    height: "2.5rem",
                                    borderRadius: "0.625rem",
                                    backgroundColor:
                                      "var(--neuro-bg-light-grey-secondary)",
                                    borderColor:
                                      "var(--neuro-bg-light-grey-secondary)",
                                    "& >fieldset": {
                                      borderColor:
                                        "var(--neuro-bg-light-grey-secondary)",
                                    },
                                    "& >fieldset:hover": {
                                      borderColor:
                                        "var(--neuro-bg-light-grey-secondary)",
                                    },
                                    "& >fieldset:active": {
                                      borderColor:
                                        "var(--neuro-bg-light-grey-secondary)",
                                    },
                                    "& >fieldset:focus": {
                                      borderColor:
                                        "var(--neuro-bg-light-grey-secondary)",
                                    },
                                    "& >.Mui-focoused.MuiOutlinedInput-notchedOutline":
                                      {
                                        border:
                                          "1px solid var(--neuro-bg-light-grey-secondary)",
                                      },
                                    "& .MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root>.MuiOutlinedInput-notchedOutline":
                                      {
                                        border:
                                          "1px solid var(--neuro-bg-light-grey-secondary)",
                                      },
                                  },
                                },
                              },
                            }}
                            sx={{
                              "& .MuiFormControl-root": {
                                width: "100%",
                              },
                            }}
                          />
                        </LocalizationProvider>

                        {error && (
                          <Typography
                            sx={{
                              color: "#d32f2f",
                              fontSize: "var(--neuro-font-size-smallest-x)",
                              marginTop: "0.188rem",
                              marginLeft: "0.875rem",
                            }}
                          >
                            {error.message}
                          </Typography>
                        )}
                      </Box>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "baseline",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.375rem",
                        maxWidth: "6.25rem",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "var(--neuro-font-size-smaller-plus)",
                          fontFamily:
                            "var(--neuro-font-family-roboto-condensed)",
                          color: "var(--neuro-black-text)",
                          lineHeight: "120%",
                        }}
                      >
                        Code
                      </Typography>
                      <Select
                        sx={{
                          maxWidth: "6.25rem",
                          height: "2.625rem",
                          backgroundColor:
                            "var(--neuro-input-bg-primary) !important",
                          borderRadius: "0.625rem !important",
                          "& .MuiInputBase-root": {
                            backgroundColor:
                              "var(--neuro-input-bg-primary) !important",
                            borderRadius: "0.625rem !important",
                            border: "none !important",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none !important",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none !important",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none !important",
                          },
                        }}
                        name="countryCode"
                        value={"+91"}
                        // onChange={handleChange}
                        fullWidth
                      >
                        {countryCode?.map((code) => (
                          <MenuItem key={code.id} value={code.value}>
                            {code.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                    <Input
                      // startAdornment={<Typography>+91</Typography>}
                      type="text"
                      className="input-primary"
                      label="Mobile Number *"
                      fullWidth
                      {...register("mobile")}
                      error={!!errors?.mobile}
                      helperText={errors?.mobile?.message}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Input
                    type="text"
                    className="input-primary"
                    label="Email"
                    {...register("email")}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Input
                    type="text"
                    className="input-primary"
                    label="City"
                    {...register("city")}
                    error={!!errors.city}
                    helperText={errors?.city?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="state"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.375rem",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "var(--neuro-font-size-smaller-plus)",
                            fontFamily:
                              "var(--neuro-font-family-roboto-condensed)",
                            color: "var(--neuro-black-text)",
                            lineHeight: "120%",
                          }}
                        >
                          State *
                        </Typography>
                        <Select
                          {...field}
                          fullWidth
                          sx={{
                            height: "2.625rem",
                            backgroundColor:
                              "var(--neuro-input-bg-primary) !important",
                            borderRadius: "0.625rem !important",
                            "& .MuiInputBase-root": {
                              backgroundColor:
                                "var(--neuro-input-bg-primary) !important",
                              borderRadius: "0.625rem !important",
                              border: "none !important",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none !important",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: "none !important",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: "none !important",
                            },
                          }}
                          margin="dense"
                          error={!!error}
                          defaultValue={referral ? referral?.state : ""}
                        >
                          {indianStates?.map((state) => (
                            <MenuItem key={state} value={state}>
                              {state}
                            </MenuItem>
                          ))}
                        </Select>
                        {error && (
                          <Typography
                            sx={{
                              color: "#d32f2f",
                              fontSize: "var(--neuro-font-size-smallest-x)",
                              marginTop: "0.188rem",
                              marginLeft: "0.875rem",
                            }}
                          >
                            {error.message}
                          </Typography>
                        )}
                      </Box>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid item container xs={12} md={4}>
                <Grid item xs={12}>
                  <Input
                    type="number"
                    className="input-primary"
                    label="Zip Code"
                    {...register("zipCode")}
                    error={!!errors.zipCode}
                    helperText={errors.zipCode?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Input
                    type="text"
                    className="input-secondary-textarea"
                    rows={3}
                    multiline
                    sx={{
                      "& textarea": {
                        minHeight: "30px",
                      },
                    }}
                    label="Street Address"
                    {...register("streetAddress")}
                    error={!!errors.streetAddress}
                    helperText={errors.streetAddress?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="country"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.375rem",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "var(--neuro-font-size-smaller-plus)",
                            fontFamily:
                              "var(--neuro-font-family-roboto-condensed)",
                            color: "var(--neuro-black-text)",
                            lineHeight: "120%",
                          }}
                        >
                          Country
                        </Typography>
                        <Select
                          {...field}
                          fullWidth
                          required
                          margin="dense"
                          error={!!error}
                          sx={{
                            height: "2.625rem",
                            backgroundColor:
                              "var(--neuro-input-bg-primary) !important",
                            borderRadius: "0.625rem !important",
                            "& .MuiInputBase-root": {
                              backgroundColor:
                                "var(--neuro-input-bg-primary) !important",
                              borderRadius: "0.625rem !important",
                              border: "none !important",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none !important",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: "none !important",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: "none !important",
                            },
                          }}
                        >
                          <MenuItem value="India">India</MenuItem>
                        </Select>
                      </Box>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Input
                    type="text"
                    className="input-secondary-textarea"
                    rows={3}
                    multiline
                    sx={{
                      "& textarea": {
                        minHeight: "30px",
                      },
                    }}
                    label="Symptoms"
                    {...register("symptoms")}
                    error={!!errors.symptoms}
                    helperText={errors.symptoms?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Input
                    type="text"
                    className="input-secondary-textarea"
                    rows={3}
                    multiline
                    sx={{
                      "& textarea": {
                        minHeight: "30px",
                      },
                    }}
                    label="Relevant Medical Conditions"
                    {...register("relevantMedicalConditions")}
                    error={!!errors.relevantMedicalConditions}
                    helperText={errors.relevantMedicalConditions?.message}
                  />
                </Grid>
              </Grid>

              <Grid item container xs={12} md={4}>
                <Grid item xs={12}>
                  <Input
                    type="text"
                    className="input-secondary-textarea"
                    rows={3}
                    multiline
                    sx={{
                      "& textarea": {
                        minHeight: "30px",
                      },
                    }}
                    label="Current Medications"
                    {...register("currentMedications")}
                    error={!!errors.currentMedications}
                    helperText={errors.currentMedications?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Input
                    type="text"
                    className="input-secondary-textarea"
                    rows={3}
                    multiline
                    sx={{
                      "& textarea": {
                        minHeight: "30px",
                      },
                    }}
                    label="Previous Treatments"
                    {...register("previousTreatments")}
                    error={!!errors.previousTreatments}
                    helperText={errors.previousTreatments?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Input
                    type="text"
                    className="input-secondary-textarea"
                    rows={3}
                    multiline
                    sx={{
                      "& textarea": {
                        minHeight: "30px",
                      },
                    }}
                    label="Allergies"
                    {...register("allergies")}
                    error={!!errors.allergies}
                    helperText={errors.allergies?.message}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Divider
            sx={{
              color: "var(--neuro-secondary_border)",
              backgroundColor: "var(--neuro-secondary_border)",
              height: "1px",
            }}
          />

          {/* Doctor Details */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Typography sx={styles.headerStyles}>Doctor Information</Typography>

            <Grid container spacing={3.5}>
              <Grid item container xs={12} md={4}>
                <Grid item xs={12}>
                  <Input
                    type="text"
                    className="input-primary"
                    label="First Name"
                    {...register("referralDoctorFirstName")}
                    error={!!errors.referralDoctorFirstName}
                    helperText={errors.referralDoctorFirstName?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Input
                    type="text"
                    className="input-primary"
                    label="Last Name"
                    {...register("referralDoctorLastName")}
                    error={!!errors.referralDoctorLastName}
                    helperText={errors.referralDoctorLastName?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "baseline",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.375rem",
                        maxWidth: "6.25rem",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "var(--neuro-font-size-smaller-plus)",
                          fontFamily:
                            "var(--neuro-font-family-roboto-condensed)",
                          color: "var(--neuro-black-text)",
                          lineHeight: "120%",
                        }}
                      >
                        Code
                      </Typography>
                      <Select
                        sx={{
                          maxWidth: "6.25rem",
                          height: "2.625rem",
                          backgroundColor:
                            "var(--neuro-input-bg-primary) !important",
                          borderRadius: "0.625rem !important",
                          "& .MuiInputBase-root": {
                            backgroundColor:
                              "var(--neuro-input-bg-primary) !important",
                            borderRadius: "0.625rem !important",
                            border: "none !important",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none !important",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none !important",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none !important",
                          },
                        }}
                        name="countryCode"
                        value={"+91"}
                        // onChange={handleChange}
                        fullWidth
                      >
                        {countryCode?.map((code) => (
                          <MenuItem key={code.id} value={code.value}>
                            {code.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                    <Input
                      // startAdornment={<Typography>+91</Typography>}
                      type="text"
                      className="input-primary"
                      label="Mobile Number"
                      fullWidth
                      {...register("referralDoctorMobile")}
                      error={!!errors?.referralDoctorMobile}
                      helperText={errors?.referralDoctorMobile?.message}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid item container xs={12} md={4}>
                <Grid item xs={12}>
                  <Input
                    type="text"
                    className="input-primary"
                    label="Email"
                    {...register("referralDoctorEmail")}
                    error={!!errors.referralDoctorEmail}
                    helperText={errors.referralDoctorEmail?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="reasonForReferral"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.375rem",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "var(--neuro-font-size-smaller-plus)",
                            fontFamily:
                              "var(--neuro-font-family-roboto-condensed)",
                            color: "var(--neuro-black-text)",
                            lineHeight: "120%",
                          }}
                        >
                          Reason for Referral *
                        </Typography>
                        <Select
                          {...field}
                          fullWidth
                          sx={{
                            height: "2.625rem",
                            backgroundColor:
                              "var(--neuro-input-bg-primary) !important",
                            borderRadius: "0.625rem !important",
                            "& .MuiInputBase-root": {
                              backgroundColor:
                                "var(--neuro-input-bg-primary) !important",
                              borderRadius: "0.625rem !important",
                              border: "none !important",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none !important",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: "none !important",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: "none !important",
                            },
                          }}
                          margin="dense"
                          error={!!error}
                          defaultValue={
                            referral ? referral?.reasonForReferral : ""
                          }
                        >
                          {reasonForReferrals?.map((reason) => (
                            <MenuItem key={reason?.value} value={reason?.value}>
                              {reason?.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {error && (
                          <Typography
                            sx={{
                              color: "#d32f2f",
                              fontSize: "var(--neuro-font-size-smallest-x)",
                              marginTop: "0.188rem",
                              marginLeft: "0.875rem",
                            }}
                          >
                            {error.message}
                          </Typography>
                        )}
                      </Box>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="urgencyOfReferral"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.375rem",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "var(--neuro-font-size-smaller-plus)",
                            fontFamily:
                              "var(--neuro-font-family-roboto-condensed)",
                            color: "var(--neuro-black-text)",
                            lineHeight: "120%",
                          }}
                        >
                          Urgency Of Referral
                        </Typography>
                        <Select
                          {...field}
                          fullWidth
                          sx={{
                            height: "2.625rem",
                            backgroundColor:
                              "var(--neuro-input-bg-primary) !important",
                            borderRadius: "0.625rem !important",
                            "& .MuiInputBase-root": {
                              backgroundColor:
                                "var(--neuro-input-bg-primary) !important",
                              borderRadius: "0.625rem !important",
                              border: "none !important",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none !important",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: "none !important",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: "none !important",
                            },
                          }}
                          margin="dense"
                          error={!!error}
                          defaultValue={
                            referral ? referral?.urgencyOfReferral : "Routine"
                          }
                        >
                          {urgencyOfReferralOptions?.map((urgency) => (
                            <MenuItem
                              key={urgency?.value}
                              value={urgency?.value}
                            >
                              {urgency?.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {error && (
                          <Typography
                            sx={{
                              color: "#d32f2f",
                              fontSize: "var(--neuro-font-size-smallest-x)",
                              marginTop: "0.188rem",
                              marginLeft: "0.875rem",
                            }}
                          >
                            {error.message}
                          </Typography>
                        )}
                      </Box>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid item container xs={12} md={4}>
                <Grid item xs={12}>
                  <Input
                    type="text"
                    className="input-secondary-textarea"
                    rows={3}
                    multiline
                    sx={{
                      "& textarea": {
                        minHeight: "30px",
                      },
                    }}
                    label="Notes"
                    {...register("notes")}
                    error={!!errors.notes}
                    helperText={errors.notes?.message}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddEditReferralPage;
