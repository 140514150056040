import { Switch as MuiSwitch } from "@mui/material";
import React from "react";
import { SwitchProps } from "../../types/ui";

const Switch: React.FC<SwitchProps> = ({ checked, onChange, sx, disabled = false }) => {
  return (
    <MuiSwitch
      sx={{
        "& .MuiSwitch-switchBase.Mui-checked": {
          color: "var(--neuro-button-bg-primary)",
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
          backgroundColor: "var(--neuro-button-bg-primary)",
        },
        ...sx,
      }}
      checked={checked}
      onChange={onChange}
      inputProps={{ "aria-label": "controlled" }}
      disabled={disabled}
    />
  );
};

export default Switch;
