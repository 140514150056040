import { Box, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface Validator {
  type: string;
  text: string;
  minValue: number;
  maxValue: number;
}

interface Question {
  type: string;
  name: string;
  title: string;
  inputType: string;
  placeholder: string;
  value: string | number;
  isRequired: boolean;
  validators: Validator[];
  showScore: boolean;
}

interface DynamicTextFieldProps {
  question: Question;
  index: number;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
  updateQuestion: (index: number, updatedQuestion: Question) => void;
  isonclick?: boolean;
  showHighlighted: boolean;
  toggleRequired: (index: number) => void;
}

const DynamicTextField: React.FC<DynamicTextFieldProps> = ({
  question,
  index,
  handleDuplicateQuestion,
  handleRemoveQuestion,
  updateQuestion,
  toggleRequired,
  showHighlighted = false,
  isonclick = false,
}) => {
  // props & state values
  const [localQuestion, setLocalQuestion] = useState<Question>(question);
  const [errorMessage, setErrorMessage] = useState("");

  const inputTypes = ["text", "number", "email", "date", "datetime-local"];

  console.log("localQuestion in textfield", localQuestion);

  // callbacks & functions
  // Sync local state with parent prop changes
  useEffect(() => {
    setLocalQuestion(question);
  }, [question]);

  const updateLocalQuestion = (updatedQuestion: typeof question) => {
    setLocalQuestion(updatedQuestion);
    updateQuestion(index, updatedQuestion);
  };

  // Debounce updates to parent state
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     updateQuestion(index, { ...localQuestion });
  //   }, 300);
  //   return () => clearTimeout(timeout);
  // }, [localQuestion, index, updateQuestion]);

  // Handle title change
  // const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const newTitle = e.target.value;
  //   setLocalQuestion((prev) => ({ ...prev, title: newTitle }));
  // };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    updateLocalQuestion({ ...localQuestion, title: newTitle });
  };

  // Handle input value change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const numericValue = parseFloat(newValue);
    if (
      localQuestion.validators[0]?.type === "numeric" &&
      (isNaN(numericValue) ||
        numericValue < localQuestion.validators[0].minValue ||
        numericValue > localQuestion.validators[0].maxValue)
    ) {
      setErrorMessage(localQuestion.validators[0]?.text || "Invalid input.");
      updateLocalQuestion({ ...localQuestion, value: newValue });
    } else {
      setErrorMessage("");
      updateLocalQuestion({ ...localQuestion, value: newValue });
      // setLocalQuestion((prev) => ({
      //   ...prev,
      //   value: value,
      // }));
    }
  };

  // Handle validator changes
  const handleValidatorChange = (
    field: "minValue" | "maxValue",
    value: string
  ) => {
    const numericValue = parseFloat(value);

    updateLocalQuestion({
      ...localQuestion, validators: localQuestion?.validators?.map((validator) =>
        validator.type === "numeric"
          ? {
            ...validator,
            [field]: isNaN(numericValue) ? 0 : numericValue,
            text: field === "minValue" ? `The value must be between ${Number(isNaN(numericValue) ? 0 : numericValue)} and ${Number(localQuestion.validators[0]?.maxValue || 0)}.`
              : `The value must be between ${Number(localQuestion.validators[0]?.minValue || 0)} and ${Number(isNaN(numericValue) ? 0 : numericValue)}.`
          }
          : validator
      ),
    });

    // setLocalQuestion((prev) => ({
    //   ...prev,
    //   validators: prev.validators.map((validator) =>
    //     validator.type === "numeric"
    //       ? {
    //         ...validator,
    //         [field]: isNaN(numericValue) ? 0 : numericValue,
    //       }
    //       : validator
    //   ),
    // }));
  };

  // Toggle required field
  // const handleToggleRequired = () => {
  //   setLocalQuestion((prev) => ({ ...prev, isRequired: !prev.isRequired }));
  // };
  const handleToggleRequired = () => {
    toggleRequired(index);
  };

  return (
    <Box sx={{
      margin: 0,
      padding: "1rem",
      borderRadius: "0.5rem",
      marginBottom: "1rem",
      cursor: "pointer",
      transition: "border-color 0.3s ease",
      background: "var(--neuro-white-text)",
      height: "auto",
      border: isonclick || showHighlighted ? "1px solid var(--neuro-light_red_border) !important" : "1px solid transparent !important",
      "&:hover": {
        border: "1px solid var(--neuro-light_red_border_light) !important"
      }
    }}>
      {/* Title Input */}
      <div className="question-header">
        {localQuestion.name}
        <input
          type="text"
          value={localQuestion.title}
          onChange={handleTitleChange}
          className="question-name-input survey-js-gobal-input"
        />
        {localQuestion.isRequired && <span className="required">*</span>}
      </div>

      {/* Numeric Input Field */}
      <div className="numeric-input-group">
        <input
          type={localQuestion.inputType}
          name={localQuestion.name}
          value={localQuestion.inputType === "number" ? Number(localQuestion?.value || 0) : localQuestion.value}
          onChange={handleInputChange}
          placeholder={localQuestion.placeholder}
          className={`numeric-input survey-js-gobal-input ${errorMessage ? "input-error" : ""}`}
        />
        {errorMessage && <span className="error-text">{errorMessage}</span>}
      </div>

      {/* Min and Max Value Fields (Conditional) */}
      {localQuestion.inputType === "number" && (
        <Box sx={{ mt: "1rem" }}>
          <div className="validator-field">
            <label className="validator-field-label" htmlFor={`minValue-${index}`}>Min Value:</label>
            <input
              id={`minValue-${index}`}
              type="number"
              value={localQuestion?.isRequired ? localQuestion?.validators?.find((validator) => {
                return validator.type === "numeric" || null;
              }) ? Number(localQuestion?.validators?.find((validator) => {
                return validator.type === "numeric";
              })?.minValue) : 0 : ""}
              onChange={(e) =>
                handleValidatorChange("minValue", e.target.value)
              }
              placeholder="Min"
              className="edit-score-input survey-js-gobal-input"
            />
          </div>
          <div className="validator-field">
            <label className="validator-field-label" htmlFor={`maxValue-${index}`}>Max Value:</label>
            <input
              id={`maxValue-${index}`}
              type="number"
              value={localQuestion?.isRequired ? localQuestion?.validators?.find((validator) => {
                return validator.type === "numeric" || null;
              }) ? Number(localQuestion?.validators?.find((validator) => {
                return validator.type === "numeric";
              })?.maxValue) : 0 : ""}
              onChange={(e) =>
                handleValidatorChange("maxValue", e.target.value)
              }
              placeholder="Max"
              className="edit-score-input survey-js-gobal-input"
            />
          </div>
        </Box>
      )}

      {/* Control Buttons */}
      <Box sx={{
        display: "flex",
        // gap: "8px",
        marginTop: "15px",
        // margin: "10px",
        backgroundColor: "transparent",
        // padding: "10px",
        borderRadius: "5px",
        alignItems: "center",
        justifyContent: "space-between"
      }}>
        <Select
          value={localQuestion.inputType}
          onChange={(e) => {
            const updatedValidators =
              e.target.value === "number"
                ? [
                  {
                    type: "numeric",
                    text: `The value must be between 0 and 90.`,
                    minValue: 0,
                    maxValue: 90,
                  },
                ]
                : [];
            updateLocalQuestion({
              ...localQuestion,
              inputType: e.target.value as string,
              validators: updatedValidators,
            });
            // setLocalQuestion((prev) => ({
            //   ...prev,
            //   inputType: e.target.value as string,
            //   validators: updatedValidators,
            // }))
          }
          }
          IconComponent={KeyboardArrowDownIcon}
          sx={{
            height: "2.25rem",
            minWidth: "10rem",
            backgroundColor: "var(--neuro-white-text)",
            borderRadius: "1.875rem",
            border: "1px solid var(--neuro-secondary_border)",
            boxShadow: "none !important",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent", // No border by default
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent", // Light border on hover
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent", // No extra border on focus
            },
            "&.Mui-focused .MuiOutlinedInput-root": {
              borderColor: "transparent", // Override focus state
            },
            ".MuiSelect-select": {
              padding: "0.5rem 1rem",
              display: "flex",
              alignItems: "center",
            },
            ".MuiSvgIcon-root": {
              color: "var(--neuro-black-text)",
            },
          }}
        >
          {inputTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>

        <Box sx={{
          display: "flex",
          gap: "8px",
          backgroundColor: "transparent",
          alignItems: "center",
        }}>
          <button
            className="duplicate-button button-spacing blue-bg"
            onClick={() => handleDuplicateQuestion(index)}
          >
            Duplicate
          </button>

          <button
            className="delete-button button-spacing red-bg"
            onClick={() => handleRemoveQuestion(index)}
          >
            Delete
          </button>

          <button
            className={`required-button ${localQuestion.isRequired ? "active1" : ""
              }`}
            onClick={handleToggleRequired}
            aria-pressed={localQuestion.isRequired}
          >
            {localQuestion.isRequired ? "Required" : "Not Required"}
          </button>
        </Box>
      </Box>
    </Box >
  );
};

export default DynamicTextField;
