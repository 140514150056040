import React from "react";

import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import Button from "../ui/Button";
import useAppStore from "../../store/appStore";
import config from "../../config";

interface FormsTableActionsProps {
    handleMenuOpen: any;
    anchorEl: any;
    handleMenuClose: any;
    row: any;
}

const FormsTableActions: React.FC<FormsTableActionsProps> = ({
    handleMenuOpen,
    anchorEl,
    handleMenuClose,
    row,
}) => {
    // props & state values
    const { showSnackbar } = useAppStore();

    // callbacks & functions
    const handleCopy = async (textToCopy: string) => {
        try {
            await navigator.clipboard.writeText(`${config.APP_BASE_URL}/public/${textToCopy}`);
            showSnackbar("Url copied successfully", "success");
        } catch (err) {
            showSnackbar("Could not copy url, please try again", "error");
            console.error("Error copying url: ", err);
        }
    };

    return (
        <div>
            <IconButton
                onClick={(event) => {
                    event.stopPropagation();
                    handleMenuOpen(event);
                }}
            >
                <MoreVertIcon sx={{ color: "var(--neuro-black-text)" }} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={(event: any) => {
                    event.stopPropagation();
                    handleMenuClose();
                }}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                {Boolean(row?.isPublic) && Boolean(row?.publicUrl) && (
                    <MenuItem
                        onClick={(event) => {
                            event.stopPropagation();
                            handleCopy(row?.publicUrl);
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className="primary-button "
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                event.stopPropagation();
                                handleCopy(row?.publicUrl);
                            }
                            }
                            sx={{
                                height: "2.25rem",
                            }}
                        >
                            Copy Public Url
                        </Button>
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
};

export default FormsTableActions;
