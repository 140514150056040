import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  //  Tabs, Tab,
  Grid,
  useMediaQuery,
} from "@mui/material";
// import CustomTabPanel from "../documents/view/CustomTabPanel";
import { getPatientProfileApi } from "../../services/profileService";
import LoadingScreen from "../shared/LoadingScreen";
import ContentCenter from "../shared/ContentCenter";
import { capitalizeFirstLetter } from "../../utils/common";

const GreenBorderBox = ({
  children,
}: {
  children: React.ReactElement | React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        borderLeft: "0.375rem solid var(--neuro-button-bg-success)",
        minHeight: "1.625rem",
        paddingLeft: "0.438rem",
        display: "flex",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  );
};

function PatientDetails({ id = "", from = "" }: { id?: string; from?: string }) {
  // props & stat values
  const isMobile = useMediaQuery("(max-width:37.5rem)");

  const styles = {
    textStyles: {
      fontFamily: "var(--neuro-font-family-roboto)",
      lineHeight: "120%",
      width: "100%",
      wordWrap: "break-word",
    },
  };

  const [details, setDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  console.log("profile details", details);

  // const [value, setValue] = useState(0);

  // callbacks & functions
  const getProfileDetails = async (id: string) => {
    try {
      const response = await getPatientProfileApi(id);
      setDetails(response.data || null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getProfileDetails(id);
    }
  }, [id]);

  function calculateAge(birthDateString: string): number {
    const birthDate = new Date(birthDateString);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  }

  function calculateBirthYearFromAge(age: number): string {
    const currentYear = new Date().getFullYear();
    const birthYear = currentYear - age;
    return `01/01/${birthYear}`; // Assuming birthdate as January 1st for display
  }

  function formatDOB(dateString: string): string {
    const date = new Date(dateString);
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getUTCDate()).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${month}/${day}/${year}`;
  }

  const getGender = (gender: string) => {
    switch (gender) {
      case "MALE":
        return "Male";
      case "FEMALE":
        return "Female";
      default:
        return "Other";
    }
  };

  if (!id) {
    return null;
  }

  return (
    <Box
      sx={{
        background: "var(--neuro-bg-light-grey-secondary)",
        width: "100%",
        height: "100%",
      }}
    >
      {loading && <LoadingScreen />}

      {!loading && !details && (
        <ContentCenter>
          <Typography className="no-data">No details found</Typography>
        </ContentCenter>
      )}

      {!loading && details && (
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            backgroundColor: "var(--neuro-white-text)",
            borderRadius: "0.313rem",
            padding: "1.25rem",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: isMobile
                ? 0
                : from === "meet"
                  ? "1.25rem"
                  : from === "admin"
                    ? "5.938rem"
                    : "5.938rem",
              gap: "1.813rem",
              alignItems: "center",
              marginBottom: isMobile ? "1.5rem" : 0,
            }}
          >
            <Avatar
              sx={{
                width:
                  from === "meet"
                    ? "6.25rem"
                    : from === "admin"
                      ? "11.875rem"
                      : "11.875rem",
                height:
                  from === "meet"
                    ? "6.25rem"
                    : from === "admin"
                      ? "11.875rem"
                      : "11.875rem",
                border: "0.125rem solid var(--neuro-button-bg-success)",
                backgroundColor: "var(--neuro-secondary_border)",
              }}
              src={details?.user?.profileImageUrl || undefined}
            />
            <Box>
              <Typography
                sx={{
                  fontSize: "var(--neuro-font-size-micro-small)",
                  fontWeight: "var(--neuro-font-weight-medium)",
                  color: "var(--neuro-dark-grey-primary)",
                  fontFamily: "var(--neuro-font-family-inter)",
                }}
              >
                {details?.dateOfBirth && details?.dateOfBirth !== ""
                  ? `${calculateAge(details?.dateOfBirth)}yrs,` || ""
                  : details.age && details.age !== ""
                    ? `${details.age}yrs,` || ""
                    : ""}{" "}
                {details?.gender && details?.gender !== ""
                  ? getGender(details?.gender)
                  : ""}
              </Typography>
            </Box>
          </Box>

          <Grid container spacing={3}>
            <Grid item container spacing={3.5} xs={12} sm={6}>
              <Grid item xs={12}>
                <GreenBorderBox>
                  <Typography sx={styles.textStyles}>
                    <span className="bold-text">Full Name:</span>{" "}
                    {capitalizeFirstLetter(details?.user?.firstName || "")}
                  </Typography>
                </GreenBorderBox>
              </Grid>

              <Grid item xs={12}>
                <GreenBorderBox>
                  <Typography sx={styles.textStyles}>
                    <span className="bold-text">Email:</span>{" "}
                    {details?.user?.email || "-"}
                  </Typography>
                </GreenBorderBox>
              </Grid>

              <Grid item xs={12}>
                <GreenBorderBox>
                  <Typography sx={styles.textStyles}>
                    <span className="bold-text">Height:</span>{" "}
                    {details?.height || "-"}
                  </Typography>
                </GreenBorderBox>
              </Grid>

              <Grid item xs={12}>
                <GreenBorderBox>
                  <Typography sx={styles.textStyles}>
                    <span className="bold-text">City:</span>{" "}
                    {details?.city || "-"}
                  </Typography>
                </GreenBorderBox>
              </Grid>

              <Grid item xs={12}>
                <GreenBorderBox>
                  <Typography sx={styles.textStyles}>
                    <span className="bold-text">Zip code:</span>{" "}
                    {details?.zipCode || "-"}
                  </Typography>
                </GreenBorderBox>
              </Grid>

              <Grid item xs={12}>
                <GreenBorderBox>
                  <Typography sx={styles.textStyles}>
                    <span className="bold-text">Reason for appointment:</span>{" "}
                    {details?.reasonsForAppointments || "-"}
                  </Typography>
                </GreenBorderBox>
              </Grid>
            </Grid>

            <Grid item container spacing={3.5} xs={12} sm={6}>
              <Grid item xs={12}>
                <GreenBorderBox>
                  <Typography sx={styles.textStyles}>
                    <span className="bold-text">Mobile No:</span>{" "}
                    {details?.user?.mobile || "-"}
                  </Typography>
                </GreenBorderBox>
              </Grid>

              <Grid item xs={12}>
                <GreenBorderBox>
                  <Typography sx={styles.textStyles}>
                    <span className="bold-text">Date of Birth:</span>{" "}
                    {details?.dateOfBirth && details?.dateOfBirth !== ""
                      ? formatDOB(details?.dateOfBirth) || ""
                      : details?.age && details?.age !== ""
                        ? calculateBirthYearFromAge(Number(details?.age)) || ""
                        : ""}
                  </Typography>
                </GreenBorderBox>
              </Grid>

              <Grid item xs={12}>
                <GreenBorderBox>
                  <Typography sx={styles.textStyles}>
                    <span className="bold-text">Weight:</span>{" "}
                    {details?.weight || "-"}
                  </Typography>
                </GreenBorderBox>
              </Grid>

              <Grid item xs={12}>
                <GreenBorderBox>
                  <Typography sx={styles.textStyles}>
                    <span className="bold-text">State:</span>{" "}
                    {details?.state || "-"}
                  </Typography>
                </GreenBorderBox>
              </Grid>

              <Grid item xs={12}>
                <GreenBorderBox>
                  <Typography sx={styles.textStyles}>
                    <span className="bold-text">Street address:</span>{" "}
                    {details?.streetAddress || "-"}
                  </Typography>
                </GreenBorderBox>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default PatientDetails;
