import React, { useMemo } from "react";
import { Box, Typography, Avatar, Divider } from "@mui/material";
import { calculateAgeInYearsOrMonths } from "../../utils/appointments";
import { capitalizeFirstLetter } from "../../utils/common";
import { format } from "date-fns";
import { isClinicAdminOrAdmin } from "../../utils/auth";
import useAuthStore from "../../store/authStore";
import Button from "../ui/Button";
import { CardViewReferralCardProps } from "../../types/referrals";
import { getFormattedDate } from "../../utils/common";
import { isNetwork } from "../../utils/auth";

const ReferralCard: React.FC<CardViewReferralCardProps> = ({
  referral,
  handleEdit,
  handleDelete,
  handleAccept,
  handleCancel,
}) => {
  // props & state values
  const { userRole } = useAuthStore();

  const styles = {
    wrapperStyles: {
      display: "flex",
      alignItems: "center",
      gap: "0.2rem",
    },
    headingStyles: {
      fontWeight: "var(--neuro-font-weight-semibold)",
      color: "var(--neuro-button-bg-primary)",
      fontSize: "var(--neuro-font-size-smallest-x)",
      lineHeight: "120%",
    },
    subHeadingStyles: {
      fontWeight: "var(--neuro-font-weight-regular)",
      color: "var(--neuro-black-text)",
      fontSize: "var(--neuro-font-size-smallest-x)",
      lineHeight: "120%",
    },
  };

  const patientAge = `${`${calculateAgeInYearsOrMonths(referral?.dateOfBirth || "")}` || ""} ${
    `(${formatDateString(referral?.dateOfBirth || "")})` || ""
  }`;

  const patientGender = referral?.gender
    ? referral?.gender?.charAt(0).toUpperCase() +
      referral?.gender.slice(1).toLowerCase()
    : "";

  const cardStyles = useMemo(() => {
    const background =
      referral?.status === "Rejected"
        ? "var(--neuro-bg-light-grey-secondary)"
        : "var(--neuro-white-text)";
    const borderLeft =
      referral?.status === "Rejected"
        ? "1px solid var(--neuro-secondary_border)"
        : referral?.status === "Pending"
          ? "4px solid var(--neuro-button-bg-primary)"
          : referral?.status === "Accepted"
            ? "4px solid var(--neuro-button-bg-success)"
            : "1px solid var(--neuro-secondary_border)";

    return {
      background,
      borderLeft,
      opacity: referral?.status === "Rejected" ? 0.9 : 1,
    };
  }, [referral]);

  // callbacks & functions
  function formatDateString(dateString: string): string {
    if (!dateString || dateString === "") {
      return "";
    }

    // const date = parseISO(dateString);
    return format(dateString, "dd-MM-yyyy") || "";
  }

  const fullAddess = (city: any, state: any, country: any): string => {
    // `${hospital.city} ${hospital.state} ${hospital.country}`
    let address = "";

    if (city && city !== "") {
      address = `${city},`;
    }

    if (state && state !== "") {
      address = `${address} ${state},`;
    }

    if (country && country !== "") {
      address = `${address} ${country}`;
    }

    return address || "";
  };

  return (
    <Box
      sx={{
        borderRadius: "0.75rem",
        backgroundColor: cardStyles.background,
        display: "flex",
        gap: "0.75rem",
        padding: "0.625rem 1.188rem 0.625rem 1.188rem",
        marginBottom: "1rem",
        border: "0.063rem solid var(--neuro-secondary_border)",
        borderLeft: cardStyles.borderLeft,
      }}
    >
      <Box
        sx={{
          zIndex: 10,
          display: "flex",
          flexDirection: "column",
          lineHeight: "120%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: "1.25rem",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              gap: "0.438rem",
            }}
          >
            <Avatar
              sx={{
                width: "2.875rem",
                height: "2.875rem",
                bgcolor: "var(--neuro-light-grey_border)",
                mt: "0.188rem",
              }}
              src={undefined}
            />
            <Box
              sx={{
                display: "flex",
                marginTop: "0.563rem",
                flexDirection: "column",
                alignItems: "start",
                flexGrow: 1,
                flexBasis: 0,
                width: "fit-content",
              }}
            >
              <Typography
                sx={{
                  color: "var(--neuro-bg-primary-seagreen)",
                  fontWeight: "var(--neuro-font-weight-bold)",
                  lineHeight: "120%",
                  mb: "0.125rem",
                }}
              >
                {`${capitalizeFirstLetter(referral?.firstName || "") || "-"}`}
              </Typography>

              <Typography
                sx={{
                  color: "var(--neuro-bg-darkblue-primary)",
                  fontSize: "var(--neuro-font-size-smallest-x)",
                  lineHeight: "120%",
                  fontFamily: "var(--neuro-font-family-roboto-slab)",
                  mb: "0.375rem",
                }}
              >
                {`${patientAge || ""}, ${patientGender || ""}`}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "var(--neuro-font-family-roboto-slab)",
                  color: "var(--neuro-black-text)",
                  fontSize: "var(--neuro-font-size-smallest-x)",
                  lineHeight: "120%",
                  justifyContent: "space-between",
                  gap: "1.25rem",
                  flexWrap: "wrap",
                }}
              >
                <Box sx={styles.wrapperStyles}>
                  <Typography sx={styles.headingStyles}>
                    Mobile Number:
                  </Typography>
                  <Typography sx={styles.subHeadingStyles}>
                    {referral?.mobile || "-"}
                  </Typography>
                </Box>
                <Box sx={styles.wrapperStyles}>
                  <Typography sx={styles.headingStyles}>Urgency:</Typography>
                  <Typography sx={styles.subHeadingStyles}>
                    {referral?.urgencyOfReferral || "-"}
                  </Typography>
                </Box>
                <Box sx={styles.wrapperStyles}>
                  <Typography sx={styles.headingStyles}>
                    Referred To:
                  </Typography>
                  <Typography sx={styles.subHeadingStyles}>
                    {capitalizeFirstLetter(
                      referral?.referralDoctorFirstName || ""
                    ) || "-"}
                  </Typography>
                </Box>
                <Box sx={styles.wrapperStyles}>
                  <Typography sx={styles.headingStyles}>
                    Referred By:
                  </Typography>
                  <Typography sx={styles.subHeadingStyles}>
                    {capitalizeFirstLetter(
                      referral?.doctor?.user?.firstName || ""
                    ) || "-"}
                  </Typography>
                </Box>
                <Box sx={styles.wrapperStyles}>
                  <Typography sx={styles.headingStyles}>Reg. Date:</Typography>
                  <Typography sx={styles.subHeadingStyles}>
                    {`${getFormattedDate(referral?.createdAt || "") || "-"}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mt: "0.75rem" }} />
        <Box
          sx={{
            alignSelf: "center",
            display: "flex",
            marginTop: "0.75rem",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: "100%",
            flexWrap: "wrap",
            gap: "1.5rem",
            paddingLeft: "3.3rem",
          }}
        >
          <Box sx={styles.wrapperStyles}>
            <Typography sx={styles.headingStyles}>Address:</Typography>
            <Typography sx={styles.subHeadingStyles}>
              {`${fullAddess(referral?.city, referral?.state, referral?.country) || "-"}`}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            {isClinicAdminOrAdmin(userRole) &&
              referral.status === "Pending" && (
                <Button
                  variant="contained"
                  className="secondary-button"
                  onClick={() => handleCancel(referral)}
                  sx={{
                    height: "2.25rem",
                  }}
                >
                  Cancel
                </Button>
              )}

            {isClinicAdminOrAdmin(userRole) &&
              referral.status === "Pending" && (
                <Button
                  variant="contained"
                  color="success"
                  className="success-button"
                  onClick={() => handleAccept(referral)}
                  sx={{
                    height: "2.25rem",
                  }}
                >
                  Accept
                </Button>
              )}

            {isNetwork(userRole) && referral.status === "Pending" && (
              <Button
                variant="contained"
                className="warning-button"
                onClick={() => handleDelete(referral?.id)}
                sx={{
                  height: "2.25rem",
                }}
              >
                Delete
              </Button>
            )}

            {isNetwork(userRole) && referral.status === "Pending" && (
              <Button
                variant="contained"
                color="success"
                className="success-button"
                onClick={() => handleEdit(referral)}
                sx={{
                  height: "2.25rem",
                }}
              >
                Edit
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReferralCard;
