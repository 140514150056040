import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import ContentModal from "../../shared/FullscreenModal";
import FormSidebarcomp from "../FormSidebarcomp";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../ui/Button";
import useAppStore from "../../../store/appStore";
import config from "../../../config";
// import ToggleFormSidebarIcon from "../../svg/ToggleFormSidebarIcon";
import NewFormpagesEdit from "./NewFormpagesEdit";
import { deleteForm } from "../../../services/formService";
import useAuthStore from "../../../store/authStore";
import { isClinicAdminOrAdmin } from "../../../utils/auth";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmModal from "../../shared/ConfirmModal";

const NewFormViewFullScreenEdit = () => {
    // props & state values
    const [fullScreenModalOpen] = useState(true);

    const { id } = useParams();

    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

    console.log("id in formsView full screen", id);

    const { highlightDiscard, setHighlightDiscard, showSnackbar } = useAppStore();

    const { userRole } = useAuthStore();

    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState<string>("Form Details");

    const [submitTrigger, setSubmitTrigger] = useState(false);
    const [submitTriggerQuestion, setSubmitTriggerQuestion] = useState(false);
    // const [isOpen, setIsOpen] = useState(true);

    const [isDisabled, setIsDisabled] = useState(localStorage.getItem("isDisabled") === "true");
    const [_, setIsScoreBasedData] = useState(localStorage.getItem("isScoreBased") === "true");

    const tabs = [
        "Form Details",
        "Form Designer",
        "Preview",
        // "Themes",
        // "Logic",
        "JSON Editor",
    ];

    // callbacks & functions
    useEffect(() => {
        setHighlightDiscard(false);
    }, [])

    useEffect(() => {
        let intervalId: ReturnType<typeof setTimeout>;

        if (highlightDiscard) {
            intervalId = setTimeout(() => {
                setHighlightDiscard(false);
            }, config.HIGHLIGHT_DURATION || 3000);
        }

        // Cleanup function to clear the interval
        return () => {
            clearInterval(intervalId);
        };
    }, [highlightDiscard])

    const handleParentSubmit = () => {
        console.log("Tab clicked:", "handleParentSubmit");
        setSubmitTrigger(true); // Toggle submitTrigger
        setSubmitTriggerQuestion(false);
    };

    const handleQuestionFormSubmit = () => {
        console.log("Tab clicked:", "handleQuestionFormSubmit");
        setSubmitTriggerQuestion(true); // Toggle submitTrigger
        setSubmitTrigger(false);
    };

    useEffect(() => {
        // Listen for changes to localStorage
        const handleStorageChange = () => {
            // Check if 'isDisabled' or 'isScoreBased' has changed
            const updatedIsDisabled = localStorage.getItem("isDisabled") === "true";
            const updatedIsScoreBased = localStorage.getItem("isScoreBased") === "true";

            // Update respective states
            setIsDisabled(updatedIsDisabled);
            setIsScoreBasedData(updatedIsScoreBased);
        };

        // Add event listener
        window.addEventListener("storage", handleStorageChange);

        // Cleanup listener on unmount
        return () => window.removeEventListener("storage", handleStorageChange);
    }, []);

    const handleTabClick = (tab: string) => {
        if (!isDisabled) {
            setActiveTab(tab);
        }
    };

    useEffect(() => {
        if (isDisabled) {
            setActiveTab("Form Details");
        } else {
            setActiveTab("Form Designer");
        }
    }, [isDisabled]);

    const handleCancel = () => {
        navigate(`/forms`);
    }

    const handleDeleteForm = async () => {
        // delete form api handler
        try {
            const response = await deleteForm(id || "");
            if (response) {
                showSnackbar("Form deleted successfully", "success");
                setOpenConfirmModal(false);
                navigate(`/forms`);
            }
        }
        catch (error) {
            showSnackbar("Could not delete form, please try again", "error");
        }
    }

    // const toggleSidebar = () => {
    //     setIsOpen(!isOpen);
    // };

    return (
        <ContentModal open={fullScreenModalOpen}>
            <Box
                sx={{
                    backgroundColor: "var(--neuro-white-text)",
                    height: "100%",
                    padding: "0rem",
                    paddingBottom: 0,
                }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%"
                }}>
                    {/* Primary Header */}
                    <Box sx={{
                        backgroundColor: "var(--neuro-white-text)",
                        padding: "10px 22px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                        height: "56px",
                    }}>
                        <Typography sx={{
                            display: "flex",
                            width: "340px",
                            height: "22px",
                            flexDirection: "column",
                            justifyContent: "center",
                            flexShrink: 0,
                            color: "var(--neuro-darkblue-text)",
                            fontFamily: "var(--neuro-font-family-roboto-condensed)",
                            fontSize: "var(--neuro-font-size-medium-smallest)",
                            fontWeight: "var(--neuro-font-weight-bold)",
                            lineHeight: "120%"
                        }}>
                            Create New Form
                        </Typography>

                        <Box sx={{
                            display: "flex",
                            gap: "10px"
                        }}>
                            <Button variant="contained" className="success-button" onClick={activeTab === "Form Details" ? handleParentSubmit : handleQuestionFormSubmit} sx={{
                                height: "2.25rem"
                            }}>Save</Button>

                            <Button variant="outlined" className="outlined-secondary-button" onClick={handleCancel} sx={{
                                borderColor: highlightDiscard ? "var(--neuro-secondary-light-error) !important" : "var(--neuro-secondary_border) !important",
                                boxShadow: highlightDiscard ? "0 4px 8px rgba(255, 0, 0, 0.3) !important" : "none !important",
                                height: "2.25rem"
                            }}>Discard</Button>

                            {isClinicAdminOrAdmin(userRole) && id && <Button startIcon={<DeleteIcon />} variant="contained" className="warning-button" onClick={() => setOpenConfirmModal(true)} sx={{
                                height: "2.25rem"
                            }}>Delete</Button>}

                            <ConfirmModal
                                open={openConfirmModal}
                                description="Are you sure, you want to delete this form?"
                                handleConfirm={handleDeleteForm}
                                handleCancel={() => setOpenConfirmModal(false)}
                            />
                        </Box>

                    </Box>

                    {/* Secondary Header */}
                    <Box sx={{
                        backgroundColor: "var(--neuro-white-text)",
                        padding: "10px 22px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                        borderTop: "1px solid #DCDCDC",
                        borderBottom: "1px solid #DCDCDC",
                        width: "100%",
                        height: "46px",
                        flexShrink: 0,
                        overflowY: "hidden",
                        overflowX: "auto",
                        paddingLeft: "200px"
                    }}>
                        <Box>
                            <Box sx={{
                                display: "flex",
                                margin: 0,
                                padding: "10px 0",
                            }}>
                                {tabs.map((tab) => (
                                    <Box
                                        key={tab}
                                        sx={{
                                            margin: "0 10px",
                                            padding: "8px 16px",
                                            cursor: isDisabled && tab !== "Form Details" ? "not-allowed" : "pointer",
                                            pointerEvents: isDisabled && tab !== "Form Details" ? "none" : "auto",
                                            borderRadius: "0px",
                                            fontSize: "var(--neuro-font-size-extra-small)",
                                            transition: "all 0.3s ease-in-out",
                                            color: activeTab === tab ? "var(--neuro-black-text)" : "var(--neuro-darkgrey_border)",
                                            lineHeight: "250%",
                                            opacity: isDisabled && tab !== "Form Details" ? 0.7 : 1,
                                            height: "46px",
                                            width: "max-content",
                                            borderBottom: activeTab === tab ? "2px solid var(--neuro-button-bg-success)" : "none",
                                            "&:hover": {
                                                backgroundColor: "transparent",
                                            },
                                        }}
                                        onClick={() => handleTabClick(tab)}
                                    >
                                        {tab}
                                    </Box>
                                ))}
                            </Box>
                        </Box>

                        {/* <Box
                            onClick={toggleSidebar}
                            sx={{
                                cursor: isDisabled ? "not-allowed" : "pointer",
                                pointerEvents: isDisabled ? "none" : "auto",
                                opacity: isDisabled ? 0.7 : 1
                            }}
                        >
                            <ToggleFormSidebarIcon />
                        </Box> */}
                    </Box>

                    <Box sx={{
                        display: "flex",
                        flex: 1,
                        overflowY: "hidden",
                        maxHeight: "calc(100% - 104px)"
                    }}>
                        <FormSidebarcomp disabled={Boolean(activeTab !== "Form Designer")} />
                        {/* Main Content */}
                        <Box sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            // background-color: #f8f9fa;
                            backgroundColor: "var(--neuro-bg-light-grey)",
                            height: "100%",
                            overflowY: "auto",
                            // width: isOpen ? "calc(100% - 400px)" : "calc(100% - 200px)",
                            width: "calc(100% - 200px)",
                            transition: "width 0.3s ease-in-out",
                        }}>
                            <Box sx={{
                                flex: 1,
                                /* padding: 20px; */
                                padding: "0px",
                                background: "var(--neuro-white-text)",
                                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                                borderRadius: "8px",
                                height: "100%"
                            }}>
                                <NewFormpagesEdit navlinks={activeTab} setSubmitTrigger={setSubmitTrigger} submitTrigger={submitTrigger} submitTriggerQuestion={submitTriggerQuestion} setSubmitTriggerQuestion={setSubmitTriggerQuestion} />
                            </Box>
                        </Box>

                        {/* Right Sidebar */}
                        {/* <Box sx={{
                            width: isOpen ? "200px" : 0,
                            backgroundColor: "white",
                            padding: "10px",
                            borderRight: "1px solid var(--neuro-secondary_border)",
                            height: "100%",
                            overflowY: "auto",
                            overflowX: "hidden",
                            transition: "width 0.3s ease-in-out",
                        }}>


                        </Box> */}
                    </Box>
                </Box>
            </Box>
        </ContentModal >
    );
};

export default NewFormViewFullScreenEdit;
