import api from "./apiService";

export const fetchForms = () => {
  return api.get(`/api/forms`);
};

export const assignFormApi = (data: any) => {
  // data - patientId, formId, assignedById;
  return api.post(`/api/patients/forms`, data);
};

export const submitFormResponseApi = (patientFormId: string, data: any) => {
  // data - responses, score
  return api.put(`/api/patients/forms/${patientFormId}`, data);
};

export const fetchFormRequestsApi = (patientId: string, search?: string) => {
  const params = {
    responderId: patientId,
    search: search || ""
  };
  return api.get(`/api/patients/forms`, {
    params,
  });
};

export const submitFormResponseApisuryve = (data: any) => {
  return api.post(`/api/forms`, data);
};

export const submitFormResponseApisuryveputcall = (formId: string, data: any) => {
  return api.put(`/api/forms/${formId}`, data);
};

export const submitFormResponseApisuryvegetcall = (formId: string) => {
  return api.get(`/api/forms/${formId}`);
};

export const getFormResponseApisuryve = () => {
  return api.get(`/api/forms`);
};

export const fetchFormRequestsApiFormid = (formId: string) => {
  return api.get(`/api/forms/${formId}`);
};

export const getDisorders = () => {
  return api.get(`/api/disorders`);
};

// to delete a form
export const deleteForm = (id: string) => {
  return api.delete(`/api/forms/${id}`);
}

// to get public form details
// formid, publicUrl, qrCodeUrl
export const getPublicFormDetails = (params: any) => {
  return api.get(`/api/publicresponseform`, {
    params
  });
};

// to post public form response
export const postPublicFormResponse = (data: any) => {
  return api.post(`/api/publicresponseform`, data);
};