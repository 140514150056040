import React, { useEffect } from "react";
import {
    Box,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from "@mui/material";
import useDocumentStore from "../../../store/documentsStore";
import ViewerReportCard from "./ViewerReportCard";
import { DocumentsProps } from "../../../types/documents";
import useAppStore from "../../../store/appStore";
import ContentCenter from "../../shared/ContentCenter";
import LoadingScreen from "../../shared/LoadingScreen";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CardsDropdown: React.FC<DocumentsProps> = ({
    patientId,
    from = "",
}) => {
    const {
        documentDeleted,
        documentDetails,
        loadingDeleteDocument,
        errorDeleteDocument,
        cleanUpDeleteDocumentState,
        documents,
        setDocuments,
        loadingDocuments,
        errorDocuments,
        fetchDocuments,
        setSelectedDocumentId,
        selectedDocumentId,
    } = useDocumentStore();

    const { showSnackbar, documentsFilter } = useAppStore();

    console.log("documents in CardsDropdown", documents);

    // callbacks & functions
    useEffect(() => {
        if (loadingDeleteDocument) {
            showSnackbar("Deleting document, please wait", "info");
        } else if (errorDeleteDocument) {
            showSnackbar("Could not delete document, please try again", "error");
            cleanUpDeleteDocumentState();
        } else if (documentDeleted) {
            showSnackbar("Document deleted", "success");
            if (patientId) {
                fetchDocuments(
                    patientId,
                    documentsFilter.customStartDate,
                    documentsFilter.customEndDate,
                    documentsFilter.searchValue || ""
                );
            } else {
                fetchDocuments(
                    "",
                    documentsFilter.customStartDate,
                    documentsFilter.customEndDate,
                    documentsFilter.searchValue || ""
                );
            }
            cleanUpDeleteDocumentState();
        }
    }, [
        loadingDeleteDocument,
        errorDeleteDocument,
        documentDeleted,
        documentsFilter.customStartDate,
        documentsFilter.customEndDate,
    ]);

    const updateDocumentsAfterSummaryGenerated = (id: any) => {
        if (documents && documents?.length > 0) {
            const index = documents.findIndex((item) => item.id === id);
            if (index === -1) return documents; // Item not found
            const updatedItems = [...documents];
            updatedItems[index] = { ...updatedItems[index], isSummaryGenerated: true };
            if (updatedItems && updatedItems.length > 0) {
                setDocuments(updatedItems);
            }
        }
    };

    useEffect(() => {
        if (documentDetails && Boolean(documentDetails?.isSummaryGenerated)) {
            updateDocumentsAfterSummaryGenerated(documentDetails?.id)
        }
    }, [documentDetails?.isSummaryGenerated])

    const handleChange = (event: SelectChangeEvent<string>) => {
        console.log("evnt on card change", event)
        setSelectedDocumentId(event.target.value);
    };

    return (
        <Box sx={{ width: "25rem", height: "3.75rem" }}>
            {loadingDocuments && <LoadingScreen />}

            {errorDocuments && (
                <ContentCenter>
                    <Typography className="no-data">
                        Documents not found
                    </Typography>
                </ContentCenter>
            )}

            {!loadingDocuments &&
                !errorDocuments &&
                documents &&
                documents.length === 0 && (
                    <ContentCenter>
                        <Typography className="no-data">
                            Documents not found
                        </Typography>
                    </ContentCenter>
                )}

            {!loadingDocuments &&
                !errorDocuments &&
                documents &&
                documents.length > 0 &&
                <Select
                    value={selectedDocumentId || undefined}
                    onChange={handleChange}
                    displayEmpty
                    fullWidth
                    IconComponent={KeyboardArrowDownIcon}
                    renderValue={(selected) => {
                        if (!selected) {
                            return <Typography color="text.secondary">Select a Document</Typography>;
                        }
                        const doc = documents.find((d) => d.id === selected);
                        return doc ? (
                            <ViewerReportCard
                                {...doc}
                                setSelectedDocumentId={setSelectedDocumentId}
                                patientId={patientId}
                                noBorder
                                isFromMeet={from === "meet" ? true : false}
                                backgroundTransparent
                                showDatesInline
                                noPadding
                            />
                        ) : null;
                    }}
                    sx={{
                        height: "3.75rem",
                        backgroundColor: "var(--neuro-white-text)",
                        borderRadius: "0.75rem",
                        border: "1px solid var(--neuro-secondary_border)",
                        boxShadow: "none !important",
                        "& .MuiSelect-icon": {
                            color: "var(--neuro-black-text)",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent", // No border by default
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent", // Light border on hover
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent", // No extra border on focus
                        },
                        "&.Mui-focused .MuiOutlinedInput-root": {
                            borderColor: "transparent", // Override focus state
                        },
                        ".MuiSelect-select": {
                            padding: "1rem 1rem",
                            display: "flex",
                            alignItems: "center",
                        },
                    }}
                >
                    {documents.map((doc) => (
                        <MenuItem
                            key={doc.id}
                            value={doc.id}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1.5,
                                border: "1px solid var(--neuro-secondary_border)",
                                borderRadius: "0.5rem",
                                margin: "0.5rem 0.75rem",
                                "&:hover": {
                                    backgroundColor: "#f0f0f0",
                                },
                            }}
                        >
                            <ViewerReportCard
                                {...doc}
                                setSelectedDocumentId={setSelectedDocumentId}
                                patientId={patientId}
                                isFromMeet={from === "meet" ? true : false}
                                noBorder
                                backgroundTransparent
                                showDatesInline
                                noPadding
                            />
                        </MenuItem>
                    ))}
                </Select>}
        </Box>
    );
};

export default CardsDropdown;
