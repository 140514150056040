import React from "react";
import { Box, List } from "@mui/material";
import { SidebarMenuProps } from "../../types/common";
import NewSidebarMenuItem from "../ui/NewSidebarMenuItem";

const NewSidebarMenu: React.FC<SidebarMenuProps> = ({
  menuItems,
  showIconsOnly = false,
  usedInSubMenu = false,
}) => {
  return (
    <List
      sx={{
        padding: 0,
        height: "100%",
        overflow: "auto",
      }}
    >
      {menuItems.map((item, index) => (
        <Box key={index}>
          <NewSidebarMenuItem
            text={item.text}
            icon={item.icon}
            route={item.route}
            selected={item.selected}
            hasSubmenus={!!item.submenus && item.submenus.length > 0}
            submenus={item.submenus}
            initiallyOpen
            showIconsOnly={showIconsOnly}
            usedInSubMenu={usedInSubMenu}
          />
        </Box>
      ))}
    </List>
  );
};

export default NewSidebarMenu;
