import React, { useEffect, useState } from "react";

// third-party imports
import { Box, Grid } from "@mui/material";

// project imports
import { loadDoctorDashboard } from "../../services/dashboardService";
import RefferralsIcon from "../svg/RefferralsIcon";
import DashboardCard from "./DashboardCard";
import { getStartAndEndDateTimesForRange } from "../../utils/common";
import useAppStore from "../../store/appStore";

interface NetworkDashboardProps {
  range: string;
}

interface Statistics {
  [key: string]: number;
  patientsCount: number;
  clinicsCount: number;
  referralsCount: number;
  bookingAppointmentsCount: number;
  onGoingAppointmentsCount: number;
}

const NetworkDashboard: React.FC<NetworkDashboardProps> = ({ range }) => {
  // props & state values
  const [statistics, setStatistics] = useState<Statistics>({
    doctorsCount: 0,
    patientsCount: 0,
    clinicsCount: 0,
    referralsCount: 0,
    bookingAppointmentsCount: 0,
    onGoingAppointmentsCount: 0,
  });

  const { dashboardFilter, setFilter } = useAppStore();

  const cardsData = [
    {
      name: "newReferralsCount",
      icon: <RefferralsIcon />,
      text: "New Referrals",
      navigateTo: `/referrals`,
      action: () => {
        if (range === "TODAY") {
          const { start, end } = getStartAndEndDateTimesForRange(range);
          setFilter("referralsFilter", {
            status: "Pending",
            range: dashboardFilter.range,
            customStartDate: start,
            customEndDate: end,
          });
        } else {
          setFilter("referralsFilter", {
            status: "Pending",
            range: dashboardFilter.range,
            customStartDate: dashboardFilter.customStartDate,
            customEndDate: dashboardFilter.customEndDate,
          });
        }
      },
    },
    {
      name: "acceptedReferralsCount",
      icon: <RefferralsIcon />,
      text: "Accepted Referrals",
      navigateTo: `/referrals`,
      action: () => {
        if (range === "TODAY") {
          const { start, end } = getStartAndEndDateTimesForRange(range);
          setFilter("referralsFilter", {
            status: "Accepted",
            range: dashboardFilter.range,
            customStartDate: start,
            customEndDate: end,
          });
        } else {
          setFilter("referralsFilter", {
            status: "Accepted",
            range: dashboardFilter.range,
            customStartDate: dashboardFilter.customStartDate,
            customEndDate: dashboardFilter.customEndDate,
          });
        }
      },
    },
    {
      name: "rejectedReferralsCount",
      icon: <RefferralsIcon />,
      text: "Rejected Referrals",
      navigateTo: `/referrals`,
      action: () => {
        if (range === "TODAY") {
          const { start, end } = getStartAndEndDateTimesForRange(range);
          setFilter("referralsFilter", {
            status: "Rejected",
            range: dashboardFilter.range,
            customStartDate: start,
            customEndDate: end,
          });
        } else {
          setFilter("referralsFilter", {
            status: "Rejected",
            range: dashboardFilter.range,
            customStartDate: dashboardFilter.customStartDate,
            customEndDate: dashboardFilter.customEndDate,
          });
        }
      },
    },
  ];

  // callbacks & functions
  useEffect(() => {
    const fetchDashboard = async () => {
      try {
        if (dashboardFilter.customStartDate && dashboardFilter.customEndDate) {
          const response = await loadDoctorDashboard(
            dashboardFilter.customStartDate,
            dashboardFilter.customEndDate
          );
          const data = response.data;
          setStatistics(data);
        } else {
          if (range === "TODAY") {
            const { start, end } = getStartAndEndDateTimesForRange(range);
            const response = await loadDoctorDashboard(start, end);
            const data = response.data;
            setStatistics(data);
          }
        }
      } catch (error) {
        console.error("error in dashboard api", error);
      }
    };

    fetchDashboard();
  }, [range, dashboardFilter.customStartDate, dashboardFilter.customEndDate]);

  return (
    <>
      <Box
        sx={{
          maxHeight: "calc(100% - 7.5rem)",
          width: "100%",
          overflowY: "auto"
        }}
      >
        <Grid container spacing={2.75}>
          <Grid item xs={12}>
            <Grid container spacing={2.5}>
              {cardsData?.map((data, i) => (
                <Grid item xs={12} md={4} key={i}>
                  <DashboardCard
                    icon={data?.icon}
                    count={statistics ? statistics[data?.name] || 0 : 0}
                    total={0}
                    text={data?.text}
                    navigateTo={data?.navigateTo}
                    action={data?.action}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default NetworkDashboard;
