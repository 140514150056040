import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

interface DropdownGroupProps {
  question: {
    type: string;
    name: string;
    title: string;
    choices: { value: string; text: string; score?: number }[]; // `score` is optional
    isRequired: boolean;
    showScore: boolean; // New property to control score display
  };
  index: number;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
  updateQuestion: (index: number, updatedQuestion: any) => void;
  isonclick?: boolean;
  toggleRequired: (index: number) => void;
  showHighlighted: boolean;
}

const DynamicSelect: React.FC<DropdownGroupProps> = ({
  question,
  index,
  handleDuplicateQuestion,
  handleRemoveQuestion,
  updateQuestion,
  toggleRequired,
  showHighlighted = false,
  isonclick = false,
}) => {
  // props & state values
  const [localQuestion, setLocalQuestion] = useState(question);

  //callbacks & functions
  // Sync local state with parent prop changes
  useEffect(() => {
    setLocalQuestion(question);
  }, [question])

  const updateLocalQuestion = (updatedQuestion: typeof question) => {
    setLocalQuestion(updatedQuestion);
    updateQuestion(index, updatedQuestion);
  };

  // Handle title change
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    updateLocalQuestion({ ...localQuestion, title: newTitle });
  };

  // Toggle "required" field
  const handleToggleRequired = () => {
    toggleRequired(index);
  };

  // Add a new choice
  const handleAddChoice = () => {
    const number = parseInt(localQuestion?.choices[localQuestion.choices?.length - 1]?.value.replace("feature", ""), 10);

    const newChoice = {
      value: `feature${number ? number + 1 : localQuestion.choices.length + 1}`,
      text: `Feature ${number ? number + 1 : localQuestion.choices.length + 1}`,
      score: 0,
    };

    updateLocalQuestion({
      ...localQuestion,
      choices: [...localQuestion.choices, newChoice],
    });
  };

  // Remove a choice
  const handleRemoveChoice = (choiceIndex: number) => {
    const updatedChoices = localQuestion.choices.filter((_, idx) => idx !== choiceIndex);
    updateLocalQuestion({ ...localQuestion, choices: updatedChoices });
  };

  // Edit a choice
  const handleEditChoice = (choiceIndex: number, newText: string) => {
    const updatedChoices = [...localQuestion.choices];
    updatedChoices[choiceIndex].text = newText;
    updateLocalQuestion({ ...localQuestion, choices: updatedChoices });
  };

  const handleEditScore = (choiceIndex: number, newScore: number) => {
    const updatedChoices = [...localQuestion.choices];
    updatedChoices[choiceIndex] = {
      ...updatedChoices[choiceIndex],
      score: newScore,
      value: `${newScore}`, // Update `value` to match the `score` as a string
    };
    updateLocalQuestion({ ...localQuestion, choices: updatedChoices });
  };

  return (
    <Box sx={{
      margin: 0,
      padding: "1rem",
      borderRadius: "0.5rem",
      marginBottom: "1rem",
      cursor: "pointer",
      transition: "border-color 0.3s ease",
      background: "var(--neuro-white-text)",
      height: "auto",
      border: isonclick || showHighlighted ? "1px solid var(--neuro-light_red_border) !important" : "1px solid transparent !important",
      "&:hover": {
        border: "1px solid var(--neuro-light_red_border_light) !important"
      }
    }}>
      {/* Question Title */}
      <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px"
      }}>
        {localQuestion.name}
        <input
          type="text"
          value={localQuestion.title}
          onChange={handleTitleChange}
          className="question-name-input survey-js-gobal-input"
        />
        {localQuestion.isRequired && <span className="required">*</span>}
      </Box>

      {/* Dropdown Choices */}
      <Box>
        <select
          name={localQuestion.name}
          className="dropdown-select"
          style={{ height: "30px", borderRadius: "6px", padding: 0, paddingLeft: "6px !important", overflow: "hidden", cursor: "default" }}
          disabled
          defaultValue=""
        >
          <option value="" disabled style={{ padding: "6px" }}>Select...</option>
        </select>

        {/* Editable Choices */}
        {localQuestion.choices.map((choice, choiceIndex) => (
          <Box sx={{
            display: "flex",
            alignItems: "center",
            marginRight: "10px",
            gap: "0.8rem",
            mb: "1rem"
          }}
            key={choiceIndex}
          >
            <button
              className="remove-button"
              onClick={() => handleRemoveChoice(choiceIndex)}
            >
              -
            </button>
            <Box sx={{ display: "flex", alignItems: "center", gap: "0.8rem" }}>
              {/* Editable Text */}
              <input
                type="text"
                value={choice.text}
                onChange={(e) => handleEditChoice(choiceIndex, e.target.value)}
                autoFocus
                className="edit-label-input survey-js-gobal-input"
              />
              {/* Editable Score (only if showScore is true) */}
              {localQuestion.showScore && (
                <input
                  type="number"
                  value={choice.score || 0} // Default to 0 if score is undefined
                  onChange={(e) => handleEditScore(choiceIndex, Number(e.target.value))}
                  autoFocus
                  className="edit-score-input survey-js-gobal-input"
                />
              )}
            </Box>
          </Box>
        ))}
      </Box>

      {/* Add Choice Button */}
      <Box>
        <button className="add-button" onClick={handleAddChoice}>
          +
        </button>
      </Box>

      {/* Control Buttons */}
      <Box sx={{
        display: "flex",
        gap: "0.5rem",
        margin: "0.625rem",
        backgroundColor: "transparent",
        padding: "0.625rem",
        borderRadius: "5px",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <button
          className="duplicate-button button-spacing blue-bg"
          onClick={() => handleDuplicateQuestion(index)}
        >
          Duplicate
        </button>
        <button
          className="delete-button button-spacing red-bg"
          onClick={() => handleRemoveQuestion(index)}
        >
          Delete
        </button>
        <button
          className={`required-button ${localQuestion.isRequired ? "active" : ""}`}
          onClick={handleToggleRequired}
        >
          {localQuestion.isRequired ? "Required" : "Not Required"}
        </button>
      </Box>
    </Box >
  );
};

export default DynamicSelect;

