import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface RatingGroupProps {
  question: {
    type: string;
    name: string;
    rateValues: { value: string; text: string; score: number }[];
    required: boolean;
    title: string;
    showScore: boolean;
  };
  index: number;
  toggleRequired: (index: number) => void;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
  updateQuestionName: (updatedName: string) => void;
  updateQuestion: (index: number, updatedQuestion: any) => void;
  isonclick?: boolean;
  showHighlighted: boolean;
}

const DynamicRatingGroup: React.FC<RatingGroupProps> = ({
  question,
  index,
  toggleRequired,
  handleDuplicateQuestion,
  handleRemoveQuestion,
  updateQuestion,
  showHighlighted = false,
  isonclick = false,
}) => {
  // props & state values
  const [localQuestion, setLocalQuestion] = useState(question);

  // callbacks & functions
  useEffect(() => {
    setLocalQuestion(question);
  }, [question])

  const updateLocalQuestion = (updatedQuestion: typeof question) => {
    setLocalQuestion(updatedQuestion);
    updateQuestion(index, updatedQuestion);
  };

  const handleRemoveRating = () => {
    if (localQuestion?.rateValues?.length > 1) {
      const updatedChoices = localQuestion?.rateValues?.slice(0, -1);
      updateLocalQuestion({ ...localQuestion, rateValues: updatedChoices });
    }
  };

  const handleAddRating = () => {
    const newRateValues = { value: `${localQuestion.rateValues.length + 1}`, text: `${localQuestion.rateValues.length + 1}`, score: 0 };
    updateLocalQuestion({
      ...localQuestion,
      rateValues: [...localQuestion.rateValues, newRateValues],
    });
  };

  const handleToggleRequired = () => {
    toggleRequired(index);
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    updateLocalQuestion({ ...localQuestion, title: newTitle });
  };

  const handleEditRating = (rateIndex: number, newText: string) => {
    const updatedRateValues = [...localQuestion.rateValues];
    updatedRateValues[rateIndex].text = newText;
    updatedRateValues[rateIndex].value = newText;
    updateLocalQuestion({ ...localQuestion, rateValues: updatedRateValues });
  };

  const handleEditScore = (rateIndex: number, newScore: number) => {
    const updatedRateValues = [...localQuestion.rateValues];
    updatedRateValues[rateIndex] = {
      ...updatedRateValues[rateIndex],
      score: newScore,
      value: `${newScore}`, // Update `value` to match the `score` as a string
    };
    updateLocalQuestion({ ...localQuestion, rateValues: updatedRateValues });
  };

  return (
    <Box sx={{
      margin: 0,
      padding: "1rem",
      borderRadius: "0.5rem",
      marginBottom: "1rem",
      cursor: "pointer",
      transition: "border-color 0.3s ease",
      background: "var(--neuro-white-text)",
      height: "auto",
      border: isonclick || showHighlighted ? "1px solid var(--neuro-light_red_border) !important" : "1px solid transparent !important",
      "&:hover": {
        border: "1px solid var(--neuro-light_red_border_light) !important"
      }
    }}>
      <div className="question-header">
        {localQuestion.name}
        <input
          type="text"
          value={localQuestion.title}
          onChange={handleTitleChange}  // Handle name change
          className="question-name-input survey-js-gobal-input"
        />
        {localQuestion.required && <span className="required">*</span>}
      </div>

      <div className="rating-group">
        <div className="add-remove-controls">
          <button className="remove-button" onClick={handleRemoveRating}>
            -
          </button>
          <button className="add-button" onClick={handleAddRating}>
            +
          </button>
        </div>

        <Box sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap"
        }}>
          {localQuestion?.rateValues && localQuestion?.rateValues?.length > 0 && localQuestion?.rateValues?.map((rateValue, rateIndex) => (
            <Box key={rateIndex} sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "10px"
            }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", alignItems: "center" }}>
                <input
                  type="number"
                  value={typeof rateValue === 'number' ? rateValue : rateValue.value}
                  onChange={(e) => handleEditRating(rateIndex, e.target.value)} // Handle editable rating
                  className="rating-input"
                />
                {localQuestion.showScore && (
                  <input
                    type="number"
                    value={rateValue.score}
                    onChange={(e) => handleEditScore(rateIndex, parseInt(e.target.value, 10))}
                    style={{ width: "60px" }}
                  />
                )}
              </Box>

              {/* {typeof rateValue !== 'number' && rateValue.text && (
                <span className="rating-text">{rateValue.text}</span>
              )} */}
            </Box>
          ))}
        </Box>
      </div>

      <div className="question-controls">
        <button
          className="duplicate-button button-spacing blue-bg duplicate-button button-spacing blue-bg"
          onClick={() => handleDuplicateQuestion(index)}
        >
          Duplicate
        </button>
        <button
          className="delete-button button-spacing red-bg required-button"
          onClick={() => handleRemoveQuestion(index)}
        >
          Delete
        </button>
        <button
          className={`required-button  ${localQuestion?.required ? 'active' : ''}`}
          onClick={handleToggleRequired}
        >
          {localQuestion?.required ? 'Required' : 'Optional'}
        </button>
      </div>
    </Box>
  );
};

export default DynamicRatingGroup;
