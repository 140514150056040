import React, { useEffect } from "react";
import { Box, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { postConsultationSummary } from "../../../services/appointmentsService";
import useAppointmentStore from "../../../store/appointmentsStore";
import { FollowUpProps } from "../../../types/appointments";
import { followUpByOptions } from "../../../utils/common";

const FollowUpBy: React.FC<FollowUpProps> = ({
    appointmentId,
    followUpByData,
}) => {
    // props & state values
    const { followUpBy, setFollowUpBy } = useAppointmentStore();

    // callbacks & functions
    useEffect(() => {
        console.log("followUpByData in ue", followUpByData);
        setFollowUpBy(followUpByData || "");
    }, []);


    const handleInput = async (event: SelectChangeEvent<string>) => {
        const selectedValue = event.target.value as string;
        setFollowUpBy(selectedValue);
        const data = {
            appointmentId: appointmentId,
            followUpBy: selectedValue || [],
        };
        const params = {
            saveType: "FOLLOWUPBY",
        };
        try {
            await postConsultationSummary(data, params);
        }
        catch (error) {
            console.log("Error in follow up", error);
        }
    };

    return (
        <Box
            sx={{
                paddingBottom: "1.563rem",
                marginBottom: "1.188rem",
                borderBottom: "1px dashed var(--neuro-light-grey_border)",
            }}
        >
            <Typography
                sx={{
                    mb: "1rem",
                    fontSize: "var(--neuro-font-size-tiny)",
                    lineHeight: "var(--neuro-line-height-micro)",
                    fontFamily: "var(--neuro-font-family-roboto-condensed)",
                    color: "var(--neuro-button-bg-primary)",
                }}
            >
                Follow Up
            </Typography>

            <Select
                value={followUpBy}
                onChange={handleInput}
                displayEmpty
                sx={{
                    height: "2.25rem",
                    width: "10rem",
                    backgroundColor: "var(--neuro-white-text)",
                    borderRadius: "1.875rem",
                    border: "1px solid var(--neuro-secondary_border)",
                    boxShadow: "none !important",
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent", // No border by default
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent", // Light border on hover
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent", // No extra border on focus
                    },
                    "&.Mui-focused .MuiOutlinedInput-root": {
                        borderColor: "transparent", // Override focus state
                    },
                    ".MuiSelect-select": {
                        padding: "0.5rem 1rem",
                        display: "flex",
                        alignItems: "center",
                    },
                    ".MuiSvgIcon-root": {
                        color: "var(--neuro-black-text)",
                    },
                }}

            >
                <MenuItem value="" disabled>
                    Select follow by
                </MenuItem>
                {followUpByOptions.map((followUp) => (
                    <MenuItem key={followUp.value} value={followUp.value}
                        sx={{
                            backgroundColor:
                                followUpBy === followUp?.value
                                    ? "var(--neuro-button-bg-success) !important"
                                    : "inherit",
                            color:
                                followUpBy === followUp?.value
                                    ? "var(--neuro-white-text) !important"
                                    : "inherit",
                        }}
                    >
                        {followUp.label}
                    </MenuItem>
                ))}
            </Select>

        </Box>
    );
};

export default FollowUpBy;
