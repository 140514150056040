import React, { useMemo, useState } from "react";
import { Box, Typography, Avatar, Divider } from "@mui/material";
import { capitalizeFirstLetter } from "../../utils/common";
import { PaymentTypes } from "../../types/payments";
import { COMPLETED } from "../../utils/constants";
import PaymentsIcon from '@mui/icons-material/Payments';
import { getFormattedDateTime } from "../../utils/appointments";
import Button from "../ui/Button";
import { downloadPayment } from "../../services/paymentService";
import useAppStore from "../../store/appStore";
import Spinner from "../ui/Spinner";
import CurrencyIcon from "../shared/CurrencyIcon";

const NewPaymentCard: React.FC<PaymentTypes> = ({
    payment
}) => {
    // props & state values
    const {
        amount,
        appointment,
        appointmentId,
        createdAt,
        // createdBy,
        currency,
        // errorMessage,
        // errorMetadata,
        id,
        method,
        // patientPackageId,
        razorpayOrderId,
        razorpayPaymentId,
        // retryCount,
        status,
        // statusUpdatedAt,
        // transactionId,
        // updatedAt,
        // updatedBy,
        // webhookVerified,
    } = payment;

    const { showSnackbar } = useAppStore();

    const styles = {
        wrapperStyles: {
            display: "flex",
            alignItems: "center",
            gap: "0.2rem",
        },
        headingStyles: {
            fontWeight: "var(--neuro-font-weight-semibold)",
            color: "var(--neuro-button-bg-primary)",
            fontSize: "var(--neuro-font-size-smallest-x)",
            lineHeight: "120%",
        },
        subHeadingStyles: {
            fontWeight: "var(--neuro-font-weight-regular)",
            color: "var(--neuro-black-text)",
            fontSize: "var(--neuro-font-size-smallest-x)",
            lineHeight: "120%",
        },
    };

    const [loading, setLoading] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState<any>(null);

    // callbacks & functions
    const formattedDate = useMemo(() => {
        if (!createdAt) return null;
        const DateTimeObject = getFormattedDateTime(createdAt);

        if (DateTimeObject && typeof DateTimeObject === "object") {
            const { day, month, year, hours, minutes, amOrPm } = DateTimeObject;
            return {
                date: `${day} ${month} ${year}`,
                time: `${hours}:${minutes}${amOrPm}`,
            };
        } else return "";
    }, []);

    const formatteAppoitmentDate = useMemo(() => {
        if (!appointment?.appointmentDate) return null;
        const DateTimeObject = getFormattedDateTime(appointment?.appointmentDate);

        if (DateTimeObject && typeof DateTimeObject === "object") {
            const { day, month, year, hours, minutes, amOrPm } = DateTimeObject;
            return {
                date: `${day} ${month} ${year}`,
                time: `${hours}:${minutes}${amOrPm}`,
            };
        } else return "";
    }, []);

    const cardStyles = useMemo(() => {
        const background = "var(--neuro-white-text)";
        const borderLeft = status && status === COMPLETED ? "4px solid var(--neuro-button-bg-success)" : status && status === "FAILED" ? "4px solid var(--neuro-secondary-light-error)" : "4px solid var(--neuro-button-bg-primary)"

        return {
            background,
            borderLeft,
            opacity: 1,
        };
    }, [payment]);

    const downloadPrescriptionHandler = async (id: string) => {
        if (!id || id === "") {
            showSnackbar(
                "Could not download invoice, please try again",
                "error"
            );
            return;
        }
        setLoading(true);
        showSnackbar("Invoice is downloading, please wait", "info");
        try {
            const response = await downloadPayment(id);
            const contentDisposition = response?.headers["content-disposition"];
            const blobData = response?.data;
            const fileName = contentDisposition;
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blobData);
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(link.href);
            showSnackbar("Invoice is downloaded", "success");
        } catch (error) {
            showSnackbar(
                "Could not download invoice, please try again",
                "error"
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box
            sx={{
                borderRadius: "0.75rem",
                backgroundColor: cardStyles.background,
                display: "flex",
                gap: "0.75rem",
                padding: "0.625rem 1.188rem 0.625rem 1.188rem",
                border: "0.063rem solid var(--neuro-secondary_border)",
                borderLeft: cardStyles.borderLeft,
            }}
        >
            <Box
                sx={{
                    zIndex: 10,
                    display: "flex",
                    flexDirection: "column",
                    lineHeight: "120%",
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        gap: "1.25rem",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "start",
                            gap: "0.438rem",
                        }}
                    >
                        <Avatar
                            sx={{
                                width: "2.875rem",
                                height: "2.875rem",
                                bgcolor: "var(--neuro-white-text)",
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "1px solid",
                                mt: "0.188rem",
                                borderColor: status && status === COMPLETED ? "var(--neuro-button-bg-success)" : "var(--neuro-secondary_border)"
                            }}
                        >
                            <PaymentsIcon sx={{ color: status && status === COMPLETED ? "var(--neuro-button-bg-success)" : "var(--neuro-black-text)" }} />
                        </Avatar>
                        <Box
                            sx={{
                                display: "flex",
                                marginTop: "0.563rem",
                                flexDirection: "column",
                                alignItems: "start",
                                flexGrow: 1,
                                flexBasis: 0,
                                width: "fit-content",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "max-content",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <CurrencyIcon color="var(--neuro-bg-primary-seagreen)" currency={currency} />
                                <Typography
                                    sx={{
                                        color: "var(--neuro-bg-primary-seagreen)",
                                        fontWeight: "var(--neuro-font-weight-bold)",
                                        lineHeight: "120%",
                                        mb: "0.125rem",
                                    }}
                                >
                                    {amount || ""}
                                </Typography>
                            </Box>


                            <Typography
                                sx={{
                                    color: "var(--neuro-bg-darkblue-primary)",
                                    fontSize: "var(--neuro-font-size-smallest-x)",
                                    lineHeight: "120%",
                                    fontFamily: "var(--neuro-font-family-roboto-slab)",
                                    mb: "0.375rem",
                                }}
                            >
                                {method && method !== "" ? method.charAt(0).toUpperCase() + method.slice(1).toLowerCase() : ""} {method && method !== "" ? "|" : ""} {formattedDate ? formattedDate.date : ""} - {formattedDate ? formattedDate.time : ""}
                            </Typography>

                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontFamily: "var(--neuro-font-family-roboto-slab)",
                                    color: "var(--neuro-black-text)",
                                    fontSize: "var(--neuro-font-size-smallest-x)",
                                    lineHeight: "120%",
                                    justifyContent: "space-between",
                                    gap: "1.25rem",
                                    flexWrap: "wrap",
                                }}
                            >
                                <Box sx={styles.wrapperStyles}>
                                    <Typography sx={styles.headingStyles}>Payment ID:</Typography>
                                    <Typography sx={styles.subHeadingStyles}>
                                        {razorpayPaymentId || id || "-"}
                                    </Typography>
                                </Box>
                                <Box sx={styles.wrapperStyles}>
                                    <Typography sx={styles.headingStyles}>Order ID:</Typography>
                                    <Typography sx={styles.subHeadingStyles}>
                                        {razorpayOrderId || "-"}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Divider sx={{ mt: "0.75rem" }} />
                <Box
                    sx={{
                        alignSelf: "center",
                        display: "flex",
                        marginTop: "0.75rem",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        maxWidth: "100%",
                        flexWrap: "wrap",
                        gap: "1.5rem",
                        paddingLeft: "3.3rem",
                    }}
                >
                    <Box sx={styles.wrapperStyles}>
                        <Typography sx={styles.subHeadingStyles}>
                            {`With ${appointment?.doctor?.user?.firstName && appointment?.doctor?.user?.firstName !== "" ? "Dr." : ""} ${capitalizeFirstLetter(appointment?.doctor?.user?.firstName || "")}`} - {formatteAppoitmentDate ? formatteAppoitmentDate.date : ""} - {formatteAppoitmentDate ? formatteAppoitmentDate.time : ""}
                        </Typography>
                    </Box>

                    <Button
                        variant="contained"
                        color="success"
                        className="success-button"
                        onClick={() => {
                            setSelectedPayment(payment);
                            downloadPrescriptionHandler(appointmentId || "");
                        }}
                        sx={{
                            height: "2.25rem",
                        }}
                        disabled={status !== COMPLETED || (loading && selectedPayment && selectedPayment?.appointmentId === appointmentId)}
                    >
                        {loading && selectedPayment && selectedPayment?.appointmentId === appointmentId ? <Spinner /> : "Download"}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default NewPaymentCard;
