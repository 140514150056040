import React, { useEffect, useState } from "react";
import { Box, Chip, TextField, Typography } from "@mui/material";
import { postConsultationSummary } from "../../../services/appointmentsService";
import useAppointmentStore from "../../../store/appointmentsStore";
import { SymptompsProps } from "../../../types/appointments";

const Symptomps: React.FC<SymptompsProps> = ({
  appointmentId,
  symptompsData,
}) => {
  // props & state values
  const { symptomps, setSymptomps } = useAppointmentStore();
  const [newSymptomp, setNewSymptomp] = useState("");

  // callbacks & functions
  useEffect(() => {
    if (symptomps && symptomps.length === 0) {
      setSymptomps(symptompsData);
    }
  }, []);

  const handleDelete = (noteToDelete: string) => {
    setSymptomps(symptomps.filter((symptomp) => symptomp !== noteToDelete));
    const symptompsArray = symptomps.filter(
      (symptomp) => symptomp !== noteToDelete
    );
    const data = {
      appointmentId: appointmentId,
      symptoms: symptompsArray || [],
    };
    const params = {
      saveType: "SYMPTOMS",
    };
    postConsultationSummary(data, params);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && newSymptomp.trim() !== "") {
      setSymptomps([...symptomps, newSymptomp.trim()]);
      const data = {
        appointmentId: appointmentId,
        symptoms: [...symptomps, newSymptomp.trim()],
      };
      const params = {
        saveType: "SYMPTOMS",
      };
      postConsultationSummary(data, params);
      setNewSymptomp("");
    }
  };

  const handleInput = (event: any) => {
    setNewSymptomp(event.target.value || "");
  };

  return (
    <Box
      sx={{
        paddingBottom: "1.563rem",
        marginBottom: "1.188rem",
        borderBottom: "1px dashed var(--neuro-light-grey_border)",
      }}
    >
      <Typography
        sx={{
          mb: "1rem",
          fontSize: "var(--neuro-font-size-tiny)",
          lineHeight: "var(--neuro-line-height-micro)",
          fontFamily: "var(--neuro-font-family-roboto-condensed)",
          color: "var(--neuro-button-bg-primary)",
        }}
      >
        Symptoms
      </Typography>

      <Box
        sx={{
          bgcolor: "var(--neuro-white-text)",
          border: "1px solid var(--neuro-light-grey_border)",
          paddingBlock: "1.313rem",
          paddingInline: "1.438rem",
          borderRadius: "1.25rem",
          minHeight: "6.25rem",
          display: "flex",
          flexDirection: "column",
          gap: "0.625rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "0.5rem",
          }}
        >
          {symptomps &&
            symptomps?.length > 0 &&
            symptomps.map((complaint) => (
              <Chip
                key={complaint}
                label={complaint}
                onDelete={() => handleDelete(complaint)}
                sx={{
                  bgcolor: "var(--neuro-white-text)",
                  borderRadius: "0.625rem",
                  paddingBlock: "0.75rem",
                  paddingInline: "0.875rem",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.875rem",
                  border: "1px solid var(--neuro-light-grey_border)",
                  "& .MuiChip-label": {
                    paddingLeft: 0,
                  },
                }}
              />
            ))}
        </Box>
        <TextField
          onChange={handleInput}
          onKeyDown={handleKeyPress}
          value={newSymptomp}
          placeholder="Type here..."
          sx={{
            // minWidth: "100px",
            minHeight: "6.25rem",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            alignItems: "flex-start",
            height: "6.25rem",
            width: "100%",
            "& .MuiInputBase-root": {
              alignItems: "flex-start",
              borderRadius: "3.125rem",
              height: "6.25rem",
              width: "100%",
            },
            "& .MuiInputBase-input": {
              alignItems: "flex-start",
              padding: "0rem !important",
              height: "6.25rem !important",
              width: "100%",
            },
            "& .MuiOutlinedInput-root": {
              alignItems: "flex-start",
              width: "100%",
              backgroundColor: "transparent",
              "& fieldset": {
                borderColor: "transparent", // default
              },
              "&:hover fieldset": {
                borderColor: "transparent", // hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "transparent", // focus
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default Symptomps;
