import React, { useMemo } from "react";
import { Box, Typography, Divider } from "@mui/material";
import { capitalizeFirstLetter } from "../../utils/common";
import Button from "../ui/Button";
import { getFormattedDate } from "../../utils/common";
import { DoctorWorkExperienceCardProps } from "../../types/doctors";

const WorkExperienceCard: React.FC<DoctorWorkExperienceCardProps> = ({
    expereicne,
    handleEdit,
    handleDelete,
}) => {
    // props & state values
    const styles = {
        wrapperStyles: {
            display: "flex",
            alignItems: "center",
            gap: "0.2rem",
        },
        headingStyles: {
            fontWeight: "var(--neuro-font-weight-semibold)",
            color: "var(--neuro-button-bg-primary)",
            fontSize: "var(--neuro-font-size-smallest-x)",
            lineHeight: "120%",
        },
        subHeadingStyles: {
            fontWeight: "var(--neuro-font-weight-regular)",
            color: "var(--neuro-black-text)",
            fontSize: "var(--neuro-font-size-smallest-x)",
            lineHeight: "120%",
        },
    };

    const cardStyles = useMemo(() => {
        const background = "var(--neuro-white-text)";
        const borderLeft = "4px solid var(--neuro-button-bg-success)";

        return {
            background,
            borderLeft,
            opacity: 1,
        };
    }, [expereicne]);

    return (
        <Box
            sx={{
                borderRadius: "0.75rem",
                backgroundColor: cardStyles.background,
                display: "flex",
                gap: "0.75rem",
                padding: "0.625rem 1.188rem 0.625rem 1.188rem",
                marginBottom: "1rem",
                border: "0.063rem solid var(--neuro-secondary_border)",
                borderLeft: cardStyles.borderLeft,
                cursor: "pointer"
            }}
            onClick={() => handleEdit(expereicne)}
        >
            <Box
                sx={{
                    zIndex: 10,
                    display: "flex",
                    flexDirection: "column",
                    lineHeight: "120%",
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        gap: "1.25rem",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "start",
                            gap: "0.438rem",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                marginTop: "0.563rem",
                                flexDirection: "column",
                                alignItems: "start",
                                flexGrow: 1,
                                flexBasis: 0,
                                width: "fit-content",
                            }}
                        >
                            <Typography
                                sx={{
                                    color: "var(--neuro-bg-primary-seagreen)",
                                    fontWeight: "var(--neuro-font-weight-bold)",
                                    lineHeight: "120%",
                                    mb: "0.125rem",
                                }}
                            >
                                {`${capitalizeFirstLetter(expereicne?.clinicName || "") || "-"}`}
                            </Typography>

                            <Typography
                                sx={{
                                    color: "var(--neuro-bg-darkblue-primary)",
                                    fontSize: "var(--neuro-font-size-smallest-x)",
                                    lineHeight: "120%",
                                    fontFamily: "var(--neuro-font-family-roboto-slab)",
                                    mb: "0.375rem",
                                    overflowWrap: "anywhere",
                                }}
                            >
                                {`${expereicne?.specialization || ""} (${getFormattedDate(expereicne?.startDate)} - ${expereicne?.endDate ? getFormattedDate(expereicne?.endDate) : "Present"})`}
                            </Typography>

                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontFamily: "var(--neuro-font-family-roboto-slab)",
                                    color: "var(--neuro-black-text)",
                                    fontSize: "var(--neuro-font-size-smallest-x)",
                                    lineHeight: "120%",
                                    justifyContent: "space-between",
                                    gap: "1.25rem",
                                    flexWrap: "wrap",
                                }}
                            >
                                <Box sx={styles.wrapperStyles}>
                                    <Typography sx={styles.headingStyles}>Designation:</Typography>
                                    <Typography sx={styles.subHeadingStyles}>
                                        {expereicne?.designation || "-"}
                                    </Typography>
                                </Box>
                                <Box sx={styles.wrapperStyles}>
                                    <Typography sx={styles.headingStyles}>Location:</Typography>
                                    <Typography sx={styles.subHeadingStyles}>
                                        {expereicne?.location || "-"}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Divider sx={{ mt: "0.75rem" }} />
                <Box
                    sx={{
                        alignSelf: "center",
                        display: "flex",
                        marginTop: "0.75rem",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        maxWidth: "100%",
                        flexWrap: "wrap",
                        gap: "1.5rem",
                        // paddingLeft: "3.3rem",
                    }}
                >
                    <Box sx={styles.wrapperStyles}>
                        <Typography sx={styles.headingStyles}>Clinic Details:</Typography>
                        <Typography sx={styles.subHeadingStyles}>
                            {expereicne?.clinicDetails || "-"}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                        }}
                    >
                        <Button
                            variant="contained"
                            className="warning-button"
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                event.stopPropagation();
                                handleDelete(expereicne);
                            }
                            }
                            sx={{
                                height: "2.25rem",
                            }}
                        >
                            Delete
                        </Button>

                        <Button
                            variant="contained"
                            color="success"
                            className="success-button"
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                event.stopPropagation();
                                handleEdit(expereicne)
                            }
                            }
                            sx={{
                                height: "2.25rem",
                            }}
                        >
                            Edit
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default WorkExperienceCard;
