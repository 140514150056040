import { Box, Grid } from "@mui/material";
import { useState } from "react";
import ComponentHeader from "../../shared/ComponentHeader";
import PersonalInfoPage from "./PersonalInfoPage";
import useAuthStore from "../../../store/authStore";
import DoctorPersonalInfoPage from "../doctor/DoctorPersonalInfoPage";
import AdminPersonalInfoPage from "../admin/AdminPersonalInfoPage";
import Button from "../../ui/Button";
import { updateData } from "../../../services/patientService";
import {
    isAdmin,
    isClinicAdmin,
    isDoctor,
    isNetwork,
} from "../../../utils/auth";
import NewPatientDetails from "../../patients/NewPatientDetails";

const NewPersonalInfoPage = () => {
    // props & state values
    const { user, userRole } = useAuthStore();

    const [showProfileEdit, setShowProfileEdit] = useState<boolean>(false);

    // callbacks & functions

    const handleProfileViewEdit = () => {
        setShowProfileEdit((prev) => !prev);
    };

    const handleUpdateData = async () => {
        try {
            await updateData();
        } catch (error) {
            console.error("Could not update data", error);
        }
    };

    return (
        <Box
            sx={{
                paddingBlock: "1rem",
                paddingInline: "0.875rem",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "100%",
                height: "100%",
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <ComponentHeader title="Personal Info" />

                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1.25rem",
                    }}
                >
                    {user?.email === "demo+a@racchabanda.com" && (
                        <Button
                            variant="outlined"
                            color="secondary"
                            className="outlined-secondary-button"
                            onClick={handleUpdateData}
                        >
                            Update Data By IT Admin
                        </Button>
                    )}

                    <Button
                        variant="outlined"
                        color="secondary"
                        className="outlined-secondary-button"
                        onClick={handleProfileViewEdit}
                    >
                        {showProfileEdit ? "View Profile" : "Edit Profile"}
                    </Button>
                </Box>
            </Box>


            {/* Main Content */}
            <Grid container spacing={2} sx={{ maxHeight: "calc(100% - 2rem)", overflowY: "auto" }}>
                <Grid item xs={12} sx={{ height: "100%" }}>
                    {
                        (isDoctor(userRole) ||
                            isNetwork(userRole) ||
                            isClinicAdmin(userRole) ? (
                            showProfileEdit ? (
                                <DoctorPersonalInfoPage />
                            ) : (
                                <NewPatientDetails
                                    id={user?.doctorId || ""}
                                    from="personal-info"
                                    role="doctor"
                                />
                            )
                        ) : isAdmin(userRole) ? (
                            showProfileEdit ? (
                                <AdminPersonalInfoPage />
                            ) : (
                                <NewPatientDetails
                                    id={user?.userId || ""}
                                    from="personal-info"
                                    role="admin"
                                />
                            )
                        ) : showProfileEdit ? (
                            <PersonalInfoPage />
                        ) : (
                            // <PatientDetails id={user?.patientId || ""} />
                            <NewPatientDetails
                                id={user?.patientId || ""}
                                from="personal-info"
                            />
                        ))}
                </Grid>
            </Grid>
        </Box>
    );
};

export default NewPersonalInfoPage;
