import { useParams } from "react-router-dom"
import NotFound from "../../routing/NotFound";
import { useEffect, useState } from "react";
import { getPublicFormDetails, postPublicFormResponse } from "../../../services/formService";
import MainLayout from "../../layouts/MainLayout";
import { Box, Typography, useMediaQuery } from "@mui/material";
import LoadingScreen from "../../shared/LoadingScreen";
import ContentCenter from "../../shared/ContentCenter";
import NewSurveyComponent from "../SurveyComponents/NewSurveyComponent";
import useAppStore from "../../../store/appStore";
import { PublicFormLanding } from "./PublicFormLanding";

const PublicForm = () => {
    // props & state values
    const { id } = useParams();
    const { showSnackbar } = useAppStore();

    const isBelowLg = useMediaQuery("(max-width:75rem)");

    const [loading, setLoading] = useState<boolean>(true);
    const [formDetails, setFormDetails] = useState<any>(null);
    const [showForm, setShowForm] = useState<boolean>(false);

    console.log("formDetails", formDetails);

    // callbacks & functions
    const getFormDetails = async (id: string) => {
        try {
            const response = await getPublicFormDetails({ publicUrl: id });
            setFormDetails(response?.data || null);
            console.log("response in getPublicFormDetails", response);
            setLoading(false);
        }
        catch (error) {
            console.error("Error in getting form details", error);
            setFormDetails(null);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (id) {
            getFormDetails(id);
        }
    }, [id])

    const onSubmitForm = async (responses: any, score: number) => {
        const data = {
            responses,
            score,
            formId: formDetails?.id || ""
        };

        try {
            const response = await postPublicFormResponse(data);
            console.log("public form post response", response);
            if (response) {
                showSnackbar("Form submitted successfully", "success");
            }
        }
        catch (error) {
            console.error("Error submitting form:", error);
            // showSnackbar("Could not submit form, please try again", "error");
        }
    };

    if (!id) {
        return <NotFound />
    }

    return (
        <>
            {showForm ?
                <MainLayout hideSidebarNav showOnlyLogoInHeader>
                    <Box
                        sx={{
                            backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                            height: "100%",
                            paddingBlock: "1rem",
                            paddingInline: "0.875rem",
                        }}
                    >
                        {
                            loading && <LoadingScreen />
                        }
                        {
                            !loading && !formDetails && <ContentCenter><Typography className="no-data">No details found</Typography></ContentCenter>
                        }
                        {
                            !loading && formDetails &&
                            <>{
                                formDetails?.questions && formDetails?.questions?.pages && formDetails?.questions?.pages?.length > 0 ? (
                                    <>
                                        <NewSurveyComponent
                                            questionData={{
                                                isScoreBased: Boolean(formDetails?.isScoreBased),
                                                pages: [...formDetails?.questions?.pages],
                                            }}
                                            onFinish={onSubmitForm}
                                            from="public-form"
                                            showTooltip={isBelowLg ? false : true} />
                                    </>
                                ) : (
                                    <p>Loading or no data available...</p>
                                )
                            }
                            </>
                        }
                    </Box>
                </MainLayout>
                :
                <PublicFormLanding setShowForm={setShowForm} loading={loading} formDetails={formDetails} />
            }
        </>
    )
}

export default PublicForm;