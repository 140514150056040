import React from "react";
import { Box } from "@mui/material";
import FormListEdit from "./FormListEdit";


const FormCreator: React.FC = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "var(--neuro-bg-light-grey-secondary)",
          height: "100%",
          paddingBlock: "1rem",
          paddingInline: "0.875rem",
          overflow: "auto",
        }}
      >
        <FormListEdit />
      </Box>
    </>
  );
};

export default FormCreator;
