import { useEffect } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Typography,
  Box,
  Grid,
  //  Avatar,
  Select,
  MenuItem,
} from "@mui/material";
import Input from "../ui/Input";
// import CameraAltIcon from "@mui/icons-material/CameraAlt";
// import DeleteIcon from "@mui/icons-material/Delete";

import useAppStore from "../../store/appStore";
import { countryCode, indianStates } from "../../utils/common";
import { AddEditHospitalFormInputs } from "../../types/hospitals";
import { hospitalValidationSchema } from "../../validations/hospitalValidation";
import { addHospital, updateHospital } from "../../services/hospitalService";
import ComponentHeader from "../shared/ComponentHeader";
import Button from "../ui/Button";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

const AddEditHospitalPage = ({
  loadHospitals,
  onClose,
  hospital = null,
}: {
  onClose: any;
  hospital?: any;
  loadHospitals?: any;
}) => {
  // props & state values
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm<AddEditHospitalFormInputs>({
    resolver: yupResolver(hospitalValidationSchema),
    defaultValues: {
      country: hospital?.country || "India",
      latitude: hospital?.latitude || 0,
      longitude: hospital?.longitude || 0,
      timezone: hospital?.timezone || "Asia/Kolkata",
      currency: hospital?.currency || "INR",
      state: "",
    },
  });

  // const [profileImagePreview, setProfileImagePreview] = useState<
  //   string | undefined
  // >();
  const { showSnackbar } = useAppStore();

  console.log("errors in form", errors);
  // const { user } = useAuthStore();

  // callbaks & functions
  //   useEffect(() => {
  //     if (loadingUpdateProfile) {
  //       showSnackbar("Updating profile, please wait", "info");
  //     } else if (errorUpdateProfile) {
  //       showSnackbar("Could not update profile, please try again", "error");
  //       cleanUpUpdateProfileState();
  //     } else if (updatedProfileDetails) {
  //       showSnackbar("Profile updated", "success");
  //       if (patientId) {
  //         getProfileDetails(patientId, "PATIENT");
  //       } else if (!patientId) {
  //         getProfileDetails();
  //       }
  //       cleanUpUpdateProfileState();
  //     }
  //   }, [updatedProfileDetails, errorUpdateProfile, loadingUpdateProfile]);

  useEffect(() => {
    if (hospital) {
      const defaultValues = {
        name: hospital?.name || "",
        contactNumber: hospital.contactNumber
          ? hospital.contactNumber.replace(/^\+91/, "")
          : "",
        email: hospital?.email || "",
        city: hospital?.city || "",
        state: hospital?.state || "",
        zipCode: hospital?.zipCode || "",
        country: hospital?.country || "India",
        streetAddress: hospital?.streetAddress || "",
        operatingHours: hospital?.operatingHours || "",
        latitude: hospital?.latitude || 0,
        longitude: hospital?.longitude || 0,
        timezone: hospital?.timezone || "Asia/Kolkata",
        currency: hospital?.currency || "INR",
        website: hospital?.website || "",
      };
      reset(defaultValues);
      if (hospital?.state) {
        setValue("state", hospital.state); // Update the state value
      }
      // setProfileImagePreview(hospital.user.profileImageUrl);
      // initialValues.current = defaultValues;
    }
  }, [hospital, reset]);

  const onSubmit: SubmitHandler<AddEditHospitalFormInputs> = async (values) => {
    console.log("values", values);
    try {
      if (hospital) {
        console.log("hospital edit", hospital);
        const hospitalId = hospital.id;

        try {
          const data = {
            ...values,
            contactNumber:
              values?.contactNumber && values?.contactNumber !== ""
                ? values?.contactNumber?.includes("+")
                  ? values?.contactNumber
                  : `+91${values?.contactNumber}`
                : "",
            id: hospitalId,
          };
          await updateHospital(hospitalId, data);
          loadHospitals();
          showSnackbar("Clinic Updated Successfully", "success");
        } catch (error: any) {
          showSnackbar(`${error.response?.data.message}`, "error");
          return;
        }
      } else {
        // delete (values as any).id;
        try {
          const data = {
            ...values,
            contactNumber:
              values?.contactNumber && values?.contactNumber !== ""
                ? values?.contactNumber?.includes("+")
                  ? values?.contactNumber
                  : `+91${values?.contactNumber}`
                : "",
          };
          await addHospital(data);
          loadHospitals();
          showSnackbar("New Clinic Created Successfully", "success");
        } catch (error: any) {
          showSnackbar(`${error.response?.data.message}`, "error");
          return;
        }
      }
      onClose();
    } catch (error) {
      const message = hospital
        ? "Could not update clinic, please try again"
        : "Could not add clinic, please try again";
      showSnackbar(message, "error");
      console.error("Error saving hospital:", error);
    }
  };

  // const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = e.target.files?.[0] as any;
  //   if (file && profileDetails) {
  //     setProfileImagePreview(URL.createObjectURL(file));
  //     const formData = new FormData();
  //     formData.append("profileUrl", file);
  //     formData.append("table", "USER");
  //     formData.append("profileImageUrl", profileDetails.user.profileImageUrl);
  //     // await updateProfile(profileDetails.user.id, formData);
  //     updateProfile(profileDetails.user.id, formData);
  //     // showSnackbar(`Your profile updated successfully.`, "success");
  //   }
  // };

  // const handleRemoveImage = (
  //   event: React.MouseEvent<HTMLLabelElement, MouseEvent>
  // ) => {
  //   event.stopPropagation();
  //   setProfileImagePreview(undefined);
  //   setValue("profileImage", null);
  //   event.stopPropagation();
  // };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.688rem",
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1.25rem",
            alignItems: "center",
          }}
        >
          <ComponentHeader title="Add New Clinic / Edit Clinic" />
          <Box
            sx={{
              display: "flex",
              gap: "1.25rem",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              className="outlined-secondary-button"
              // startIcon={<AddIcon />}
              onClick={onClose}
              sx={{
                height: "2.25rem",
              }}
            >
              Cancel
            </Button>
            <Button
              startIcon={<SaveOutlinedIcon />}
              variant="contained"
              className="success-button"
              type="submit"
              sx={{
                height: "2.25rem",
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            p: "1.75rem",
            backgroundColor: "var(--neuro-white-text)",
            borderRadius: "0.625rem",
          }}
        >
          <Grid container spacing={3.5}>
            <Grid item container xs={12} md={4}>
              {/* <Grid item xs={12}>
              <Box
                sx={{
                  position: "relative",
                  width: { xs: "9.375rem", xl: "11.875rem" },
                  height: { xs: "9.375rem", xl: "11.875rem" },
                  border: "0.125rem solid var(--neuro-button-bg-success)",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                  boxSizing: "content-box",
                  overflow: "hidden",
                  marginLeft: { xs: 0, md: "6.25rem" },
                }}
              >
                <Avatar
                  src={profileImagePreview || ""}
                  sx={{
                    width: { xs: "9.375rem", xl: "11.875rem" },
                    height: { xs: "9.375rem", xl: "11.875rem" },
                    border: "0.125rem solid var(--neuro-button-bg-success)",
                    backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                    overflow: "hidden",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    height: "30%",
                    backgroundColor: "var(--neuro-button-bg-success)",
                    borderBottomLeftRadius: "50%",
                    borderBottomRightRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                  }}
                >
                  {profileImagePreview ? (
                    <IconButton
                      component="label"
                      sx={{
                        overflow: "hidden",
                        width: "2.5rem",
                        height: "2.5rem",
                        backgroundColor: "var(--neuro-button-bg-success)",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // boxShadow: "0 0 0 0.125rem var(--neuro-button-bg-success)",
                        position: "absolute",
                        bottom: "0.438rem",
                        "&:hover": {
                          backgroundColor: "var(--neuro-button-bg-success)",
                        },
                      }}
                      onClick={handleRemoveImage}
                    >
                      <DeleteIcon
                        sx={{
                          fontSize: "var(--neuro-font-size-tiny-plus)",
                          color: "var(--neuro-white-text)",
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      component="label"
                      sx={{
                        overflow: "hidden",
                        width: "2.5rem",
                        height: "2.5rem",
                        backgroundColor: "var(--neuro-button-bg-success)",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // boxShadow: "0 0 0 0.125rem var(--neuro-button-bg-success)",
                        position: "absolute",
                        bottom: "0.438rem",
                        "&:hover": {
                          backgroundColor: "var(--neuro-button-bg-success)",
                        },
                      }}
                    >
                      <CameraAltIcon
                        sx={{
                          fontSize: "var(--neuro-font-size-tiny-plus)",
                          color: "var(--neuro-white-text)",
                        }}
                      />
                      <input
                        accept="image/*"
                        style={{
                          display: "none",
                        }}
                        id="profile-image-upload"
                        type="file"
                        {...register("profileImage")}
                        onChange={(e) => {
                          register("profileImage").onChange(e);
                          handleImageChange(e);
                        }}
                      />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Grid> */}
              <Grid item xs={12}>
                <Input
                  type="text"
                  className="input-primary"
                  label="Clinic Name *"
                  {...register("name")}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "baseline",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.375rem",
                      maxWidth: "6.25rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "var(--neuro-font-size-smaller-plus)",
                        fontFamily: "var(--neuro-font-family-roboto-condensed)",
                        color: "var(--neuro-black-text)",
                        lineHeight: "120%",
                      }}
                    >
                      Code
                    </Typography>
                    <Select
                      sx={{
                        maxWidth: "6.25rem",
                        height: "2.625rem",
                        backgroundColor:
                          "var(--neuro-input-bg-primary) !important",
                        borderRadius: "0.625rem !important",
                        "& .MuiInputBase-root": {
                          backgroundColor:
                            "var(--neuro-input-bg-primary) !important",
                          borderRadius: "0.625rem !important",
                          border: "none !important",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none !important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none !important",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none !important",
                        },
                      }}
                      name="countryCode"
                      value={"+91"}
                      // onChange={handleChange}
                      fullWidth
                    >
                      {countryCode?.map((code) => (
                        <MenuItem key={code.id} value={code.value}>
                          {code.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Input
                    type="text"
                    className="input-primary"
                    fullWidth
                    label="Contact Number *"
                    {...register("contactNumber")}
                    error={!!errors.contactNumber}
                    helperText={errors.contactNumber?.message}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Input
                  type="email"
                  className="input-primary"
                  label="Email *"
                  {...register("email")}
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <Input
                  type="text"
                  className="input-primary"
                  label="City *"
                  {...register("city")}
                  error={!!errors.city}
                  helperText={errors.city?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="state"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.375rem",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "var(--neuro-font-size-smaller-plus)",
                          fontFamily:
                            "var(--neuro-font-family-roboto-condensed)",
                          color: "var(--neuro-black-text)",
                          lineHeight: "120%",
                        }}
                      >
                        State *
                      </Typography>
                      <Select
                        {...field}
                        fullWidth
                        sx={{
                          height: "2.625rem",
                          backgroundColor:
                            "var(--neuro-input-bg-primary) !important",
                          borderRadius: "0.625rem !important",
                          "& .MuiInputBase-root": {
                            backgroundColor:
                              "var(--neuro-input-bg-primary) !important",
                            borderRadius: "0.625rem !important",
                            border: "none !important",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none !important",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none !important",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none !important",
                          },
                        }}
                        margin="dense"
                        error={!!error}
                      >
                        {indianStates?.map((state) => (
                          <MenuItem key={state} value={state}>
                            {state}
                          </MenuItem>
                        ))}
                      </Select>
                      {error && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "var(--neuro-font-size-smallest-x)",
                            marginTop: "0.188rem",
                            marginLeft: "0.875rem",
                          }}
                        >
                          {error.message}
                        </Typography>
                      )}
                    </Box>
                  )}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} md={4}>
              <Grid item xs={12}>
                <Input
                  type="number"
                  className="input-primary"
                  label="Zip Code *"
                  {...register("zipCode")}
                  error={!!errors.zipCode}
                  helperText={errors.zipCode?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <Input
                  type="text"
                  // className="input-primary"
                  label="Street Address *"
                  {...register("streetAddress")}
                  error={!!errors.streetAddress}
                  helperText={errors.streetAddress?.message}
                  className="input-secondary-textarea"
                  rows={3}
                  multiline
                  sx={{
                    "& textarea": {
                      minHeight: "30px",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="country"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.375rem",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "var(--neuro-font-size-smaller-plus)",
                          fontFamily:
                            "var(--neuro-font-family-roboto-condensed)",
                          color: "var(--neuro-black-text)",
                          lineHeight: "120%",
                        }}
                      >
                        Country *
                      </Typography>
                      <Select
                        {...field}
                        fullWidth
                        required
                        margin="dense"
                        error={!!error}
                        sx={{
                          height: "2.625rem",
                          backgroundColor:
                            "var(--neuro-input-bg-primary) !important",
                          borderRadius: "0.625rem !important",
                          "& .MuiInputBase-root": {
                            backgroundColor:
                              "var(--neuro-input-bg-primary) !important",
                            borderRadius: "0.625rem !important",
                            border: "none !important",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none !important",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none !important",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none !important",
                          },
                        }}
                      >
                        <MenuItem value="India">India</MenuItem>
                      </Select>
                    </Box>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Input
                  type="number"
                  className="input-primary"
                  label="Latitude *"
                  {...register("latitude")}
                  error={!!errors.latitude}
                  helperText={errors.latitude?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <Input
                  type="number"
                  className="input-primary"
                  label="Longitude *"
                  {...register("longitude")}
                  error={!!errors.longitude}
                  helperText={errors.longitude?.message}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} md={4}>
              <Grid item xs={12}>
                <Input
                  type="text"
                  className="input-primary"
                  label="Timezone *"
                  {...register("timezone")}
                  error={!!errors.timezone}
                  helperText={errors.timezone?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <Input
                  type="text"
                  className="input-primary"
                  label="Currency *"
                  {...register("currency")}
                  error={!!errors.currency}
                  helperText={errors?.currency?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <Input
                  type="text"
                  className="input-primary"
                  label="Operating Hours"
                  {...register("operatingHours")}
                  error={!!errors.operatingHours}
                  helperText={errors?.operatingHours?.message}
                />
              </Grid>

              <Grid item xs={12}>
                <Input
                  type="text"
                  className="input-primary"
                  label="Website"
                  {...register("website")}
                  error={!!errors.website}
                  helperText={errors.website?.message}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default AddEditHospitalPage;
