import React, { useEffect, useState } from "react";
import { Box, Chip, TextField, Typography } from "@mui/material";
import { postConsultationSummary } from "../../../services/appointmentsService";
import useAppointmentStore from "../../../store/appointmentsStore";
import { DoctorAdvicesProps } from "../../../types/appointments";

const DoctorAdvices: React.FC<DoctorAdvicesProps> = ({
  appointmentId,
  doctorAdvices,
}) => {
  // props & state values
  const { advices, setAdvices } = useAppointmentStore();
  const [newAdvice, setNewAdvice] = useState("");

  // callbacks & functions
  useEffect(() => {
    if (advices && advices.length === 0) {
      setAdvices(doctorAdvices);
    }
  }, []);

  const handleDelete = (noteToDelete: string) => {
    setAdvices(advices.filter((advice) => advice !== noteToDelete));
    const advicesArray = advices?.filter((advice) => advice !== noteToDelete);
    const data = {
      appointmentId: appointmentId,
      Advices: advicesArray || [],
    };
    const params = {
      saveType: "ADVICES",
    };
    postConsultationSummary(data, params);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && newAdvice.trim() !== "") {
      setAdvices([...advices, newAdvice.trim()]);
      const data = {
        appointmentId: appointmentId,
        Advices: [...advices, newAdvice.trim()],
      };
      const params = {
        saveType: "ADVICES",
      };
      postConsultationSummary(data, params);
      setNewAdvice("");
    }
  };

  const handleInput = (event: any) => {
    setNewAdvice(event.target.value || "");
  };

  return (
    <Box
      sx={{
        paddingBottom: "1.563rem",
        marginBottom: "1.188rem",
        borderBottom: "1px dashed var(--neuro-light-grey_border)",
      }}
    >
      <Typography
        sx={{
          mb: "1rem",
          fontSize: "var(--neuro-font-size-tiny)",
          lineHeight: "var(--neuro-line-height-micro)",
          fontFamily: "var(--neuro-font-family-roboto-condensed)",
          color: "var(--neuro-button-bg-primary)",
        }}
      >
        Doctor Advices
      </Typography>

      <Box
        sx={{
          bgcolor: "var(--neuro-white-text)",
          border: "1px solid var(--neuro-light-grey_border)",
          paddingBlock: "1.313rem",
          paddingInline: "1.438rem",
          borderRadius: "1.25rem",
          minHeight: "6.25rem",
          display: "flex",
          flexDirection: "column",
          gap: "0.625rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "0.5rem",
          }}
        >
          {advices &&
            advices?.length > 0 &&
            advices.map((advice) => (
              <Chip
                key={advice}
                label={advice}
                onDelete={() => handleDelete(advice)}
                sx={{
                  bgcolor: "var(--neuro-white-text)",
                  borderRadius: "0.625rem",
                  paddingBlock: "0.75rem",
                  paddingInline: "0.875rem",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.875rem",
                  border: "1px solid var(--neuro-light-grey_border)",
                  "& .MuiChip-label": {
                    paddingLeft: 0,
                  },
                }}
              />
            ))}
        </Box>
        <TextField
          onChange={handleInput}
          // disabled={
          //   createSummaryLoading && createSummaryLoading.includes("notes")
          //     ? true
          //     : false
          // }
          onKeyDown={handleKeyPress}
          value={newAdvice}
          placeholder="Type here..."
          sx={{
            // minWidth: "100px",
            minHeight: "6.25rem",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            alignItems: "flex-start",
            height: "6.25rem",
            width: "100%",
            "& .MuiInputBase-root": {
              //   paddingRight: "12px",
              alignItems: "flex-start",
              borderRadius: "3.125rem",
              height: "6.25rem",
              width: "100%",
            },
            "& .MuiInputBase-input": {
              alignItems: "flex-start",
              padding: "0rem !important",
              height: "6.25rem !important",
              width: "100%",
            },
            "& .MuiOutlinedInput-root": {
              alignItems: "flex-start",
              width: "100%",
              backgroundColor: "transparent",
              "& fieldset": {
                borderColor: "transparent", // default
              },
              "&:hover fieldset": {
                borderColor: "transparent", // hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "transparent", // focus
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default DoctorAdvices;
