import { useEffect, useState } from "react";
import { Box, Typography, Grid, Divider } from "@mui/material";
import { getAdminProfileApi, getDoctorProfileApi, getPatientProfileApi } from "../../services/profileService";
import LoadingScreen from "../shared/LoadingScreen";
import ContentCenter from "../shared/ContentCenter";
import PatientInfoCard from "../dashboard/PatientInfoCard";
import { appointmentTypeMapping, departmentsMapping, specializationMapping } from "../../utils/appointments";

function NewPatientDetails({ id, from = "", directionColumn = false, role = "" }: { id: string; from?: string; directionColumn?: boolean; role?: string }) {
  // props & stat values
  const styles = {
    textStyles: {
      fontFamily: "var(--neuro-font-family-roboto)",
      lineHeight: "120%",
      width: "100%",
      wordWrap: "break-word",
    },
  };

  const [details, setDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  // callbacks & functions
  const getProfileDetails = async (id: string) => {
    try {
      let response;
      if (role === "admin") {
        response = await getAdminProfileApi(id);
      }
      else if (role === "doctor") {
        response = await getDoctorProfileApi(id);
      }
      else {
        response = await getPatientProfileApi(id);
      }
      setDetails(response.data || null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getProfileDetails(id);
    }
  }, [id]);

  function calculateAge(birthDateString: string): number {
    const birthDate = new Date(birthDateString);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  }

  function calculateBirthYearFromAge(age: number): string {
    const currentYear = new Date().getFullYear();
    const birthYear = currentYear - age;
    return `01-01-${birthYear}`; // Assuming birthdate as January 1st for display
  }

  function formatDOB(dateString: string): string {
    const date = new Date(dateString);
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getUTCDate()).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${month}-${day}-${year}`;
  }

  const getGender = (gender: string) => {
    switch (gender) {
      case "MALE":
        return "Male";
      case "FEMALE":
        return "Female";
      default:
        return "Other";
    }
  };
  const fullAddess = (city: any, state: any, country: any): string => {
    // `${hospital.city} ${hospital.state} ${hospital.country}`
    let address = "";

    if (city && city !== "") {
      address = `${city},`;
    }

    if (state && state !== "") {
      address = `${address} ${state},`;
    }

    if (country && country !== "") {
      address = `${address} ${country}`;
    }

    return address || "";
  };

  if (!id) {
    return null;
  }

  return (
    <Box
      sx={{
        background: "var(--neuro-bg-light-grey-secondary)",
        width: "100%",
        height: from === "patient-list" ? "calc(100% - 3.25rem)" : "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1.25rem",
      }}
    >
      <PatientInfoCard
        profileDetails={details}
        userProfile={details?.user?.profileImageUrl || undefined}
        directionColumn={directionColumn}
        from={from}
        role={role}
      />

      {role !== "admin" && <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.25rem",
          width: "85%",
          alignSelf: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "var(--neuro-font-size-medium-smallest)",
            fontFamily: "var(--neuro-font-family-roboto-condensed)",
            lineHeight: "120%",
            color: "var(--neuro-black-text)",
          }}
        >
          Personal Info
        </Typography>

        <Divider
          sx={{
            height: "0.063rem",
            background: "var(--neuro-secondary_border)",
            color: "var(--neuro-secondary_border)",
            width: "100%",
          }}
        />

        {loading && <LoadingScreen />}

        {!loading && !details && (
          <ContentCenter>
            <Typography className="no-data">No details found</Typography>
          </ContentCenter>
        )}

        {!loading && details && (
          <Grid container spacing={3}>
            <Grid item container spacing={2} xs={12} sm={6}>

              {role !== "admin" && role !== "doctor" &&
                <Grid item xs={12}>
                  <Typography sx={styles.textStyles}>
                    <span className="bold-text">Age:</span>{" "}
                    {details?.dateOfBirth && details?.dateOfBirth !== ""
                      ? `${calculateAge(details?.dateOfBirth)}yrs` || ""
                      : details.age && details.age !== ""
                        ? `${details.age}yrs` || ""
                        : ""}{" "}
                    {details?.dateOfBirth && details?.dateOfBirth !== ""
                      ? `(${formatDOB(details?.dateOfBirth) || ""})`
                      : details?.age && details?.age !== ""
                        ? `(${calculateBirthYearFromAge(Number(details?.age)) || ""})`
                        : ""}
                  </Typography>
                </Grid>
              }

              {role === "doctor" &&
                <Grid item xs={12}>
                  <Typography sx={styles.textStyles}>
                    <span className="bold-text">Specializations:</span>{" "}
                    {details?.specializations && details?.specializations.length > 0
                      ? details?.specializations.map(
                        (specialization: string) =>
                          specializationMapping[specialization] || specialization
                      )
                        .join(", ")
                      : "-"}
                  </Typography>
                </Grid>
              }

              {role === "doctor" &&
                <Grid item xs={12}>
                  <Typography sx={styles.textStyles}>
                    <span className="bold-text">Departments:</span>{" "}
                    {details?.departments && details?.departments.length > 0
                      ? details?.departments.map(
                        (department: string) =>
                          departmentsMapping[department] || department
                      )
                        .join(", ")
                      : "-"}

                  </Typography>
                </Grid>
              }

              {role === "doctor" &&
                <Grid item xs={12}>
                  <Typography sx={styles.textStyles}>
                    <span className="bold-text">Supported Services:</span>{" "}
                    {details?.supportedServices && details?.supportedServices.length > 0
                      ? details?.supportedServices.map(
                        (supportedService: string) =>
                          appointmentTypeMapping[supportedService] || supportedService
                      )
                        .join(", ")
                      : "-"}
                  </Typography>
                </Grid>
              }

              {role === "doctor" &&
                <Grid item xs={12}>
                  <Typography sx={styles.textStyles}>
                    <span className="bold-text">About:</span>{" "}
                    {details?.about || "-"}
                  </Typography>
                </Grid>
              }

              {role !== "admin" && role !== "doctor" && <Grid item xs={12}>
                <Typography sx={styles.textStyles}>
                  <span className="bold-text">Sex:</span>{" "}
                  {details?.gender && details?.gender !== ""
                    ? getGender(details?.gender)
                    : "-"}
                </Typography>
              </Grid>}

              {role !== "admin" && role !== "doctor" && <Grid item xs={12}>
                <Typography sx={styles.textStyles}>
                  <span className="bold-text">Reason for appointment:</span>{" "}
                  {details?.reasonsForAppointments || "-"}
                </Typography>
              </Grid>}

              {role !== "admin" && role !== "doctor" && <Grid item xs={12}>
                <Typography sx={styles.textStyles}>
                  <span className="bold-text">Address:</span>
                  {fullAddess(details?.city, details?.state, details?.country)}
                </Typography>
              </Grid>}

              {role === "admin" && <Grid item xs={12}>
                <Typography sx={styles.textStyles}>
                  <span className="bold-text">Mobile No:</span>{" "}
                  {details?.user?.mobile || "-"}
                </Typography>
              </Grid>}
            </Grid>

            <Grid item container spacing={2} xs={12} sm={6}>
              {role !== "admin" && <Grid item xs={12}>
                <Typography sx={styles.textStyles}>
                  <span className="bold-text">Mobile No:</span>{" "}
                  {details?.user?.mobile || "-"}
                </Typography>
              </Grid>}

              <Grid item xs={12}>
                <Typography sx={styles.textStyles}>
                  <span className="bold-text">Email:</span>{" "}
                  {details?.user?.email || "-"}
                </Typography>
              </Grid>

              {role !== "admin" && role !== "doctor" && <Grid item xs={12}>
                <Typography sx={styles.textStyles}>
                  <span className="bold-text">Height:</span>{" "}
                  {details?.height || "-"}
                </Typography>
              </Grid>}

              {role !== "admin" && role !== "doctor" && <Grid item xs={12}>
                <Typography sx={styles.textStyles}>
                  <span className="bold-text">Weight:</span>{" "}
                  {details?.weight || "-"}
                </Typography>
              </Grid>}

              {role === "doctor" &&
                <Grid item xs={12}>
                  <Typography sx={styles.textStyles}>
                    <span className="bold-text">Online Doctor Fee:</span>{" "}
                    {details?.onlineConsultationFee || "-"}
                  </Typography>
                </Grid>
              }

              {role === "doctor" &&
                <Grid item xs={12}>
                  <Typography sx={styles.textStyles}>
                    <span className="bold-text">In Person Doctor Fee:</span>{" "}
                    {details?.inPersonConsultationFee || "-"}
                  </Typography>
                </Grid>
              }
            </Grid>
          </Grid>
        )}
      </Box>}
    </Box>
  );
}

export default NewPatientDetails;
