import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";

interface Question {
    type: string;
    name: string;
    // title: string;
    // inputType: string;
    // placeholder: string;
    // value: string | number;
    isRequired: boolean;
    html: string;
    showScore: boolean;
}

interface DynamicHtmlProps {
    question: Question;
    index: number;
    handleDuplicateQuestion: (index: number) => void;
    handleRemoveQuestion: (index: number) => void;
    updateQuestion: (index: number, updatedQuestion: Question) => void;
    isonclick?: boolean;
    showHighlighted: boolean;
}

const DynamicHtml: React.FC<DynamicHtmlProps> = ({
    question,
    index,
    handleDuplicateQuestion,
    handleRemoveQuestion,
    updateQuestion,
    showHighlighted = false,
    isonclick = false,
}) => {
    // props & state values
    const [localQuestion, setLocalQuestion] = useState<Question>(question);

    // callbacks & functions
    // Sync local state with parent prop changes
    useEffect(() => {
        setLocalQuestion(question);
    }, [question]);

    const updateLocalQuestion = (updatedQuestion: typeof question) => {
        setLocalQuestion(updatedQuestion);
        updateQuestion(index, updatedQuestion);
    };

    // Handle title change
    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newTitle = e.target.value;
        updateLocalQuestion({ ...localQuestion, html: `<p>${newTitle}</p>` });
    };

    // const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const newTitle = e.target.value;
    //     setLocalQuestion((prev) => ({ ...prev, title: newTitle }));
    // };

    const getText = (htmlString: string) => {
        const match = htmlString.match(/<p>(.*?)<\/p>/);

        if (match) {
            const text = match[1];
            return text;
        } else {
            return ""
        }
    }

    return (
        <Box sx={{
            margin: 0,
            padding: "1rem",
            borderRadius: "0.5rem",
            marginBottom: "1rem",
            cursor: "pointer",
            transition: "border-color 0.3s ease",
            background: "var(--neuro-white-text)",
            height: "auto",
            border: isonclick || showHighlighted ? "1px solid var(--neuro-light_red_border) !important" : "1px solid transparent !important",
            "&:hover": {
                border: "1px solid var(--neuro-light_red_border_light) !important"
            }
        }}>
            {/* Title Input */}
            <div className="question-header">
                {localQuestion.name}
                <input
                    type="text"
                    value={getText(localQuestion.html)}
                    onChange={handleTitleChange}
                    className="question-name-input survey-js-gobal-input"
                />
            </div>


            {/* Control Buttons */}
            <Box sx={{
                display: "flex",
                // gap: "8px",
                marginTop: "15px",
                // margin: "10px",
                backgroundColor: "transparent",
                // padding: "10px",
                borderRadius: "5px",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Box sx={{
                    display: "flex",
                    gap: "8px",
                    backgroundColor: "transparent",
                    alignItems: "center",
                }}>
                    <button
                        className="duplicate-button button-spacing blue-bg"
                        onClick={() => handleDuplicateQuestion(index)}
                    >
                        Duplicate
                    </button>

                    <button
                        className="delete-button button-spacing red-bg"
                        onClick={() => handleRemoveQuestion(index)}
                    >
                        Delete
                    </button>
                </Box>
            </Box>
        </Box >
    );
};

export default DynamicHtml;
