// authStore.ts
import { create } from "zustand";
import useAuthStore from "./authStore";
import useAppointmentStore from "./appointmentsStore";
import useAppStore from "./appStore";
import useDocumentStore from "./documentsStore";
import useFormStore from "./formStore";
import useProfileStore from "./profileStore";

const useResetStore = create(() => ({
    resetAllStores: () => {
        useAuthStore.getState().logout();
        useAppointmentStore.getState().resetStore();
        useAppStore.getState().resetStore();
        useDocumentStore.getState().resetStore();
        useFormStore.getState().resetStore();
        useProfileStore.getState().resetStore();
    },
}));


export default useResetStore;
