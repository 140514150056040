import { Typography, useMediaQuery } from "@mui/material";
import { isClinicAdminOrAdmin, isDoctor } from "../../../utils/auth";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../../store/authStore";
import useAppStore from "../../../store/appStore";

const PatientName = ({
  id,
  name,
  patient,
}: {
  id: string;
  name: string;
  patient: any;
}) => {
  // props & state value
  const navigate = useNavigate();
  const { userRole } = useAuthStore();
  const { setSelectedPatientInList, setDrawerOpen, setIsMenuCollapsed } = useAppStore();

  const isMobile = useMediaQuery("(max-width:50rem)");

  return (
    <Typography
      sx={{
        color: "var(--neuro-bg-primary-seagreen)",
        fontWeight: "var(--neuro-font-weight-bold)",
        lineHeight: "120%",
        mb: "0.125rem",
        "&:hover": {
          color: isDoctor(userRole) || isClinicAdminOrAdmin(userRole) ? "var(--neuro-button-bg-primary)" : "var(--neuro-bg-primary-seagreen)",
        },
      }}
      onClick={(event) => {
        if (isDoctor(userRole) || isClinicAdminOrAdmin(userRole)) {
          event.stopPropagation();
          setSelectedPatientInList(patient || null);
          if (isMobile) {
            setDrawerOpen(false);
          } else {
            setIsMenuCollapsed(true);
          }
          navigate(`/patients/${id}/profile`);
        }
      }}
    >
      {name}
    </Typography>
  );
};

export default PatientName;
