import React, { useEffect, useRef, useState } from "react";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import { Box } from "@mui/material";
import FormFilledDetails from "../../forms/FormFilledDetails";
import Button from "../../ui/Button";
import { CustomWidgetCollection } from "survey-core";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css'; // Import Flatpickr CSS
import { isPatient } from "../../../utils/auth";
import useAuthStore from "../../../store/authStore";

interface QuestionData {
    isScoreBased: boolean;
    pages: any[];
    isAutoSubmit?: boolean;
    formName?: string;
}

interface SurveyComponentProps {
    questionData: QuestionData;
    from?: string;
    onFinish?: any;
    readonly?: boolean;
    scoreForReadOnly?: any;
    onClose?: any;
    patientName?: any;
    showTooltip?: boolean;
}

const NewSurveyComponent: React.FC<SurveyComponentProps> = ({ questionData, onFinish, patientName, onClose, from = "", readonly = false, scoreForReadOnly = null, showTooltip = true }) => {
    // props & state values
    const [score, setScore] = useState<number | null>(null);
    const [formFilled, setFormFilled] = useState<boolean>(false);
    console.log("questionData in survey component", questionData);
    const [_, setTotalQuestions] = useState<number>(0);
    const [maxScore, setMaxScore] = useState<number>(10 * questionData?.pages?.flatMap(page => page.elements).length || 0);
    const [surveyData, setSurveyData] = useState({});

    const { userRole } = useAuthStore();

    const [showScoreButton, setShowScoreButton] = useState(false);

    // const [addTooltipText, setAddTooltipText] = useState<boolean>(true);

    console.log("surveyData", surveyData);

    const savedData = localStorage.getItem("questionData");
    console.log("savedData in survey component", savedData);

    console.log("score", score);

    // Flattening the page elements
    const flattenElements = (pages: any[]) => {
        return pages.map((page) => ({
            ...page,
            elements: page.elements.flat() // Flatten the nested array of elements
        }));
    };

    // callbacks & functions
    CustomWidgetCollection.Instance.addCustomWidget({
        name: "customDateTimePicker",
        title: "Custom Date-Time Picker",
        isFit: (question: any) => question.inputType === "datetime-local",
        htmlTemplate: `<input type="text" class="custom-datetime-picker" />`,
        afterRender: (question: any, element: any) => {
            const input = element.querySelector(".custom-datetime-picker");

            // Initialize Flatpickr
            const datePicker = flatpickr(input, {
                enableTime: true,
                dateFormat: "Y-m-d H:i",
                defaultDate: question.value || null, // Set default date if value exists
                onChange: (_: any, dateStr: any) => {
                    question.value = dateStr; // Update the survey question value
                },
            });

            // Update Flatpickr value when SurveyJS value changes
            question.valueChangedCallback = () => {
                if (question.value) {
                    datePicker.setDate(question.value, false);
                }
            };
        },
        willUnmount: (_: any, element: any) => {
            const input = element.querySelector(".custom-datetime-picker");
            if (input && input._flatpickr) {
                input._flatpickr.destroy(); // Clean up Flatpickr instance
            }
        },
    });

    useEffect(() => {
        // Calculate the maximum score dynamically
        const calculateMaxScore = () => {
            let totalMaxScore = 0;

            questionData?.pages?.forEach((page: any) => {
                page?.elements?.forEach((element: any) => {
                    if (element.type === "tagbox") {
                        // For tagbox, sum up the scores of all choices
                        const tagboxMaxScore = element?.choices?.reduce((sum: number, choice: any) => sum + (choice.score || 0), 0);
                        totalMaxScore += tagboxMaxScore || 0;
                    } else if (element.type === "boolean") {
                        // For boolean, calculate the maximum score based on true/false scores
                        const booleanMaxScore = Math.max(
                            element?.score?.true || 0,
                            element?.score?.false || 0
                        );
                        totalMaxScore += booleanMaxScore;
                    } else if (element.type === "text" && element.inputType === 'number') {
                        // For boolean, calculate the maximum score based on true/false scores
                        const booleanMaxScore = Number(element?.validators[0]?.maxValue || 0);
                        totalMaxScore += booleanMaxScore;
                    }
                    else if (element.type === "rating") {
                        // For rating, calculate the maximum score based on rateValues
                        const ratingMaxScore = Math.max(...(element.rateValues?.map((rate: any) => rate.score || 0) || []));
                        totalMaxScore += ratingMaxScore || 0;
                    } else if (element.choices) {
                        // For multiple-choice, take the maximum score among choices
                        const maxChoiceScore = Math.max(...element.choices.map((choice: any) => choice.score || 0));
                        totalMaxScore += maxChoiceScore || 0;
                    } else if (element.score) {
                        // For other types with a single score value
                        totalMaxScore += element.score || 0;
                    }
                });
            });

            setMaxScore(totalMaxScore);
        };

        if (Boolean(questionData.isScoreBased)) {
            calculateMaxScore();
        }

        calculateMaxScore();
    }, [questionData]);

    useEffect(() => {
        const style = document.createElement("style");
        style.innerHTML = `
        .sd-completedpage { display: initial !important; }
        .sd-title.sd-container-modern__title { display: flex !important; }
        .sd-root-modern.sd-root-modern--full-container { display: initial !important; }
    `;
        document.head.appendChild(style);
        setShowScoreButton(false);

        setFormFilled(false);
    }, []);

    useEffect(() => {
        if (readonly && showScoreButton && scoreForReadOnly && from !== "form-design") {
            const isLastPage = questionData?.pages?.length === 1;
            if (isLastPage) {
                const style = document.createElement("style");
                style.innerHTML = `.sd-btn { margin-bottom: 0px !important; }`;
                document.head.appendChild(style);
                setShowScoreButton(true);
            }
        }
    }, [readonly, showScoreButton, scoreForReadOnly, from, questionData])

    // Function to add a child element to the .sd-row class for range value
    // const addChildToRow = (dataname: string, value: any) => {
    //     // Find the first element with the class .sd-row (You may need to be more specific depending on your structure)
    //     const sdRowElement = document.querySelector(`[data-name=${dataname}]`);
    //     if (sdRowElement) {
    //         // Create a new element, for example a span
    //         const newChild = document.createElement('h6');
    //         newChild.textContent = `Value: ${value}`; // Display the range value

    //         // Append this new child to the .sd-row element
    //         sdRowElement.appendChild(newChild);
    //     }
    // };

    const json = {
        title: questionData?.formName || "",
        pages: flattenElements([
            ...questionData.pages,
        ]),
    };

    console.log("json", json);

    const handleValueChanged = (survey: any) => {
        // console.log("survey>data====> handleValueChanged", survey?.data);
        // let foundRangeInput = false;
        // const currentPage = survey.currentPage;

        // currentPage?.questions?.forEach((element: any) => {
        //     console.log("currentPage?.questions?.forEach", element?.jsonObj?.inputType === "range");
        //     if (element && element?.jsonObj && element?.jsonObj?.inputType === "range") {
        //         foundRangeInput = true;
        //     }
        // })

        // console.log("foundRangeInput, currentPage ====> handleValueChanged", foundRangeInput, currentPage);

        if (Boolean(questionData?.isAutoSubmit)) {
            const currentPage = survey.currentPage;

            // Check if all questions in the current page are completed
            const isPageCompleted = currentPage?.questions?.every((question: any) => question.isAnswered);

            if (currentPage && Boolean(isPageCompleted) && !Boolean(survey?.isLastPage)) {
                survey.nextPage();
            }
        }
    };

    const handleComplete = (survey: any) => {
        const style = document.createElement("style");
        style.innerHTML = `
        .sd-completedpage { display: none !important; }
        .sd-title.sd-container-modern__title { display: none !important; }
        .sd-root-modern.sd-root-modern--full-container { display: none !important; }
    `;
        document.head.appendChild(style);

        setFormFilled(true);

        let totalScore = 0;

        console.log("survey?.pages", survey?.pages);

        setSurveyData((prevData) => ({
            ...prevData,
            ...survey?.data
        }));

        let updatedQuestionDataForPatientForms = questionData;

        // if this component is used in patient-forms
        if (from === "patient-forms") {
            const newArray = updatedQuestionDataForPatientForms?.pages?.map((page: any) => ({
                ...page,
                elements: page?.elements?.map((question: any) => {
                    switch (question.type) {
                        case "radiogroup":
                        case "dropdown":
                            // Single-choice questions
                            return {
                                ...question,
                                defaultValue: survey.data[question.name] || null,
                                selectedAnswer: survey.data[question.name] || null,
                            };

                        case "checkbox":
                            // Multi-select questions
                            return {
                                ...question,
                                defaultValue: survey.data[question.name] || [],
                                selectedAnswer: survey.data[question.name] || null,
                            };

                        case "text":
                            // Text or number input
                            return {
                                ...question,
                                defaultValue: survey.data[question.name] || "",
                                selectedAnswer: survey.data[question.name] || null,
                            };

                        case "rating":
                            // Rating question
                            return {
                                ...question,
                                defaultValue: survey.data[question.name] || null,
                                selectedAnswer: survey.data[question.name] || null,
                            };

                        case "tagbox":
                            // Multi-select tagbox
                            return {
                                ...question,
                                defaultValue: survey.data[question.name] || [],
                                selectedAnswer: survey.data[question.name] || null,
                            };

                        default:
                            return {
                                ...question,
                                selectedAnswer: survey.data[question.name] || null,
                            }; // For other question types, keep as is
                    }
                }),
            }));
            updatedQuestionDataForPatientForms = {
                ...updatedQuestionDataForPatientForms,
                pages: newArray
            };

            // setDefaultValuesForQuestions(updatedQuestionDataForPatientForms);
        }

        // score calculation if form is score-based
        if (questionData.isScoreBased) {
            // Iterate through all pages to manually extract scores
            survey?.pages?.forEach((page: any) => {
                console.log("page in survey?.pages?.forEach", page);
                const pageName = page.name;
                const elements = page?.jsonObj?.elements
                elements?.forEach((element: any) => {
                    console.log("element in page?.elements?.forEach", element);
                    const answer = survey.data[element.name]; // Retrieve the latest answer for this question name
                    console.log("answer", answer);

                    if (element.type === "boolean") {
                        // Handle boolean-type elements
                        const booleanScore = element?.score[answer] || 0; // Adjust based on your scoring logic
                        console.log("booleanScore", booleanScore);
                        console.log(`Score for ${pageName}.${element.name}:`, booleanScore);
                        totalScore += booleanScore;
                    }
                    else if (element.type === "tagbox") {
                        // Handle tagbox-type elements
                        if (Array.isArray(answer)) {
                            answer.forEach((selectedValue: any) => {
                                const selectedChoice = element.choices.find(
                                    (choice: any) => choice.value === selectedValue
                                );
                                if (selectedChoice?.score) {
                                    console.log(`Score for ${pageName}.${element.name} [${selectedValue}]:`, selectedChoice.score);
                                    totalScore += selectedChoice.score;
                                }
                            });
                        }
                    }
                    else if (element.type === "rating") {
                        const selectedChoice = element?.rateValues?.find(
                            (choice: any) => choice.value === answer
                        );
                        console.log("selectedChoice", selectedChoice);

                        if (selectedChoice?.score) {
                            console.log(`Score for ${pageName}.${element.name}:`, selectedChoice.score);
                            totalScore += selectedChoice.score;
                        }
                    }
                    else if (element.type === "text" && element.inputType === 'number' && answer !== undefined) {
                        const numericScore = parseInt(answer, 10);
                        if (!isNaN(numericScore)) {
                            console.log(`Score for ${pageName}.${element.name}:`, numericScore);
                            // setMaxScore((prev) => prev + (numericScore || 0));
                            totalScore += numericScore; // Add to total score
                        }
                    }
                    else {
                        const selectedChoice = element?.choices?.find(
                            (choice: any) => choice.value === answer
                        );
                        console.log("selectedChoice", selectedChoice);

                        if (selectedChoice?.score) {
                            console.log(`Score for ${pageName}.${element.name}:`, selectedChoice.score);
                            totalScore += selectedChoice.score;
                        }
                    }
                });
            });

            setScore(totalScore);
        }

        if (onFinish) {
            const reponse = {
                pages: from === "patient-forms" ? updatedQuestionDataForPatientForms?.pages : questionData?.pages
            };
            onFinish(reponse, totalScore || 0);
        }

        setTotalQuestions(questionData?.pages?.flatMap(page => page.elements).length);
        console.log("Total Score:", totalScore);
    };

    const handleSurveyNavigation = (survey: any) => {
        // Check if the current page is the last page
        const isLastPage = survey.currentPageNo === survey.pages.length - 1;
        if (isLastPage) {
            const style = document.createElement("style");
            style.innerHTML = `
        .sd-btn { margin-bottom: 0px !important; }
    `;
            document.head.appendChild(style);
        }

        setShowScoreButton(isLastPage);
    };


    const handleScoreButtonClick = () => {
        // Logic to calculate and display the score
        const style = document.createElement("style");
        style.innerHTML = `
        .sd-completedpage { display: none !important; }
        .sd-title.sd-container-modern__title { display: none !important; }
        .sd-root-modern.sd-root-modern--full-container { display: none !important; }
    `;
        document.head.appendChild(style);
        setFormFilled(true);
        setShowScoreButton(false);
    };

    const surveyRef = useRef<any>(null);

    const applyTooltips = (survey: any, showTooltip: boolean) => {
        // Select all progress bar buttons
        const progressButtons = document.querySelectorAll(
            ".sd-progress-buttons--no-titles .sd-progress-buttons__list .sd-progress-buttons__button"
        );

        console.log("progressButtons", progressButtons);

        if (progressButtons.length === 0) return;

        progressButtons[0].classList.add('first-list');
        progressButtons[progressButtons.length - 1].classList.add('last-list');

        // Update tooltips for each progress button
        progressButtons.forEach((button, index) => {
            const page = survey.pages[index];
            if (page) {
                const questionCount = page.questions.length;

                // Tooltip logic: Page title or first question title
                const tooltip =
                    questionCount > 1
                        ? page.title || `Page ${index + 1}`
                        : page.questions[0]?.title || `Page ${index + 1}`;

                if (Boolean(showTooltip)) {
                    button.setAttribute("title", ""); // Apply tooltip
                    button.setAttribute("data-tooltip", tooltip);
                }
                else {
                    if (button instanceof HTMLDivElement) {
                        button.removeAttribute("title"); // Removes the title attribute
                        button.removeAttribute("data-tooltip"); // Removes the data-tooltip attribute
                    }
                }
            }
        });
    };

    useEffect(() => {
        const surveyInstance = surveyRef.current?.survey;

        if (surveyInstance) {
            // Observe DOM changes
            const observer = new MutationObserver(() => {
                applyTooltips(surveyInstance, showTooltip);
            });

            // Target the container with the progress buttons
            const targetNode = document.querySelector(".sd-progress-buttons__list");
            if (targetNode) {
                observer.observe(targetNode, { childList: true, subtree: true });
            }

            // Initial tooltip setup
            applyTooltips(surveyInstance, showTooltip);

            // Cleanup observer on unmount
            return () => observer.disconnect();
        }
    }, [showTooltip]);

    return (
        <Box sx={{
            height: from === "public-form" && !readonly && score !== null && Boolean(questionData.isScoreBased) && formFilled ? "100%" : "auto",
            width: "100%"
        }}>
            <Survey
                ref={surveyRef}
                showProgressBar="bottom"
                json={json}
                onComplete={handleComplete}
                onValueChanged={handleValueChanged}
                mode={readonly ? "display" : "edit"}
                onCurrentPageChanged={handleSurveyNavigation} // Track page changes
            />

            {readonly && showScoreButton && scoreForReadOnly && from !== "form-design" && !isPatient(userRole) && (
                <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        className="primary-button "
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            event.stopPropagation();
                            handleScoreButtonClick();
                        }
                        }
                        sx={{
                            height: "2.25rem",
                            marginBlock: "1rem"
                        }}
                    >
                        Show Score
                    </Button>
                </Box>
            )}

            {from === "form-design" && formFilled &&
                <FormFilledDetails
                    score={
                        Boolean(questionData.isScoreBased) &&
                            score !== null &&
                            score !== undefined
                            ? score
                            : null
                    }
                    maxScore={
                        Boolean(questionData.isScoreBased) &&
                            maxScore !== null &&
                            maxScore !== undefined
                            ? maxScore
                            : null
                    }
                    from="form-design"
                />
            }

            {from !== "form-design" && scoreForReadOnly && readonly && Boolean(questionData.isScoreBased) && formFilled &&
                <FormFilledDetails
                    score={
                        Boolean(questionData.isScoreBased) &&
                            scoreForReadOnly !== null &&
                            scoreForReadOnly !== undefined
                            ? scoreForReadOnly
                            : null
                    }
                    patientName={patientName}
                    onClose={() => {
                        if (onClose) {
                            onClose();
                        }
                        setFormFilled(false);
                    }}
                    maxScore={
                        Boolean(questionData.isScoreBased) &&
                            maxScore !== null &&
                            maxScore !== undefined
                            ? maxScore
                            : null
                    }
                />
            }

            {from === "public-form" && !readonly && score !== null && Boolean(questionData.isScoreBased) && formFilled && (
                <FormFilledDetails
                    from="public-form"
                    score={
                        Boolean(questionData.isScoreBased) &&
                            score !== null &&
                            score !== undefined
                            ? score
                            : null
                    }
                    maxScore={
                        Boolean(questionData.isScoreBased) &&
                            maxScore !== null &&
                            maxScore !== undefined
                            ? maxScore
                            : null
                    }
                />
            )}

            {from !== "form-design" && from !== "public-form" && !readonly && score !== null && Boolean(questionData.isScoreBased) && formFilled && (
                <FormFilledDetails
                    score={
                        Boolean(questionData.isScoreBased) &&
                            score !== null &&
                            score !== undefined
                            ? score
                            : null
                    }
                    patientName={patientName}
                    onClose={onClose}
                    maxScore={
                        Boolean(questionData.isScoreBased) &&
                            maxScore !== null &&
                            maxScore !== undefined
                            ? maxScore
                            : null
                    }
                />
            )}
        </Box>
    );
};

export default NewSurveyComponent;