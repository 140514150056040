import React, { useCallback, useState } from "react";

// third-party imports
import { Box } from "@mui/material";

// project imports
import { NewAppointmentListProps, Option } from "../../../types/appointments";
import ComponentHeader from "../../shared/ComponentHeader";
import TabAppointments from "./TabAppointments";
import useAppStore from "../../../store/appStore";
import CardTableToggle from "../../shared/CardTableToggle";
import ComponentFilter from "../../shared/ComponentFilter";
import useAppointmentStore from "../../../store/appointmentsStore";
import { debounce } from "lodash";
import config from "../../../config";
// import { isClinicAdminOrAdmin } from "../../../utils/auth";
// import Button from "../../ui/Button";
// import useAuthStore from "../../../store/authStore";
// import { useNavigate } from "react-router-dom";
// import config from "../../../config";

const NewAppointmentList: React.FC<NewAppointmentListProps> = ({
  appointments,
  loadingFetchAppointments,
  errorFetchAppointments,
  patientId,
  from = "",
}) => {
  // props & state values
  // Initialize view from localStorage or default to "table"
  const [view, setView] = useState(
    () => localStorage.getItem("appointmentsView") || config.DEFAULT_APPOINTMENTS_VIEW
  );

  // const navigate = useNavigate();

  const options: Option[] = [
    { value: "All", label: "All" },
    { value: "Ongoing", label: "Ongoing" },
    { value: "Upcoming", label: "Upcoming" },
    { value: "Booked", label: "Booked" },
    { value: "Completed", label: "Completed" },
    { value: "Cancelled", label: "Cancelled" },
  ];

  const [open, setOpen] = useState(false);
  const { appointmentsFilter, setFilter } = useAppStore();
  const { fetchAppointments } = useAppointmentStore();
  // const { userRole } = useAuthStore();

  console.log("appointmentsFilter in newappointment list", appointmentsFilter);

  // callbacks & functions
  // search filter handlers start
  const handleInputChange = (value: string, filter: any) => {
    if (patientId) {
      if (from === "dashboard") {
        fetchAppointments(
          filter.customStartDate,
          filter.customEndDate,
          filter.status || "",
          patientId,
          "DASHBOARD",
          value
        );
      } else {
        fetchAppointments(
          filter.customStartDate,
          filter.customEndDate,
          filter.status || "",
          patientId,
          "",
          value
        );
      }
    } else {
      if (from === "dashboard") {
        fetchAppointments(
          filter.customStartDate,
          filter.customEndDate,
          filter.status || "",
          "",
          "DASHBOARD",
          value
        );
      } else {
        fetchAppointments(
          filter.customStartDate,
          filter.customEndDate,
          filter.status || "",
          "",
          "",
          value
        );
      }
    }
  };

  const debouncedSearch = useCallback(
    debounce((value: string, appointmentsFilter: any) => {
      // if (value.length >= config?.SEARCH_FILTER_LETTER) {
      //   handleInputChange(value, appointmentsFilter);
      // }
      handleInputChange(value, appointmentsFilter);
    }, 1000),
    []
  );

  const handleStoreSearchValueChange = (value: string) => {
    setFilter("appointmentsFilter", {
      searchValue: value,
    });

    debouncedSearch(value, appointmentsFilter);

    // if (value === "") {
    //   // If the input is cleared, fetch all appointments
    //   handleInputChange("", appointmentsFilter);
    // }

    // if (
    //   value.length >= config?.SEARCH_FILTER_LETTER
    //   // value.length % config?.SEARCH_FILTER_LETTER === 0
    // ) {
    //   debouncedSearch(value, appointmentsFilter);
    // }
  };
  // search filter handlers end

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const updateAppointmentStausFilter = (value: string) => {
    setFilter("appointmentsFilter", {
      status: value,
    });
  };

  const updateAppointmentRangeFilter = (value: string) => {
    setFilter("appointmentsFilter", {
      range: value,
    });
  };

  const updateAppointmentCustomDateFilter = (start: string, end: string) => {
    setFilter("appointmentsFilter", {
      customStartDate: start,
      customEndDate: end,
    });
  };

  const handleToggleView = useCallback((newView: string) => {
    setView((prevView) => {
      const updatedView = prevView !== newView ? newView : prevView;
      localStorage.setItem("appointmentsView", updatedView); // Store updated view in localStorage
      return updatedView;
    });
  }, []);

  // appointment booking handler for admin
  // const handleBookAppointment = () => {
  //   navigate(`/appointments/book`);
  // }

  return (
    <Box
      sx={{
        paddingBlock: from === "dashboard" ? 0 : "1rem",
        paddingInline: from === "dashboard" ? 0 : "0.875rem",
        height: from === "patient-list" ? "calc(100% - 3.25rem)" : "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!patientId && (
        <Box
          sx={{
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ComponentHeader
            title={from === "dashboard" ? "My Appointments" : "Appointments"}
          />

          {/* {isClinicAdminOrAdmin(userRole) && (
            <Button
              color="primary"
              variant="contained"
              className="primary-button"
              onClick={handleBookAppointment}
            >
              Book Appointment
            </Button>
          )} */}
        </Box>
      )}

      <Box sx={{ marginBottom: "1rem" }}>
        <ComponentFilter
          filterByCategoryOpen={open}
          filterByCategoryValue={appointmentsFilter.status || ""}
          handleFilterByCategoryValue={updateAppointmentStausFilter}
          handleFilterByCategoryClose={handleClose}
          handleFilterByCategoryOpen={handleOpen}
          filterByCategoryOptions={options}
          updateRange={updateAppointmentRangeFilter}
          updateDates={updateAppointmentCustomDateFilter}
          range={appointmentsFilter.range}
          isEmpty
          viewComponent={
            <CardTableToggle view={view} setView={handleToggleView} />
          }
          handleStoreSearchValueChange={handleStoreSearchValueChange}
          searchValue={appointmentsFilter?.searchValue || ""}
          searchFilterPlaceholder="MRN, Patient/Doctor Name, Email"
          searchFilterWidth="19.2rem"
          wrap={from === "meet" ? true : false}
        />
      </Box>

      {/* tabs content */}
      <TabAppointments
        appointments={appointments}
        loadingFetchAppointments={loadingFetchAppointments}
        errorFetchAppointments={errorFetchAppointments}
        from={from}
        patientId={patientId}
        view={view}
      />
    </Box>
  );
};

export default NewAppointmentList;
