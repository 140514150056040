import { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import ComponentHeader from "../shared/ComponentHeader";
import Button from "../ui/Button";
import Drawer from "../shared/Drawer";
import PatientFormsList from "./PatientFormsList";
import AllFormsList from "./AllFormsList";
import { useNavigate } from "react-router-dom";
import assignFormStore from "../../store/formStore";
import useAppStore from "../../store/appStore";
import { isDoctorOrAdminOrClinicAdmin } from "../../utils/auth";
import useAuthStore from "../../store/authStore";
import { capitalizeFirstLetter } from "../../utils/common";
import CustomBreadcrumbs from "../shared/CustomBreadcrumbs";
import NewSurveyComponent from "../FormsCreator/SurveyComponents/NewSurveyComponent";

const NewPatinetForms = () => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedForm, setSelectedForm] = useState<any>(null);

  console.log("selectedForm", selectedForm);
  const { showSnackbar, selectedPatientInList } = useAppStore();
  const { userRole, user } = useAuthStore();

  console.log("user in patient forms", user);

  const isMobile = useMediaQuery("(max-width:37.5rem)");

  const newPatinetName = `${capitalizeFirstLetter(selectedPatientInList?.user?.firstName || "")}`;

  const [showFormList, setShowFormList] = useState<boolean>(true);

  const navigate = useNavigate();

  const {
    formSubmitted,
    formAssigned,
    assignForm,
    // assignError,
    submitLoading,
    submitError,
    submitFormResponse,
    cleanUpFormSubmittedState,
  } = assignFormStore();

  const breadcrumbItems = [
    { label: "Patients", href: "/patients" },
    {
      label: `${capitalizeFirstLetter(selectedPatientInList?.user?.firstName || "")}`,
    },
    { label: "Forms" },
  ];

  // callbacks & functions
  const onSubmitForm = (responses: any, score: number) => {
    const data = {
      responses,
      score,
    };
    submitFormResponse(formAssigned?.id, data);
  };

  useEffect(() => {
    return () => {
      setSelectedForm(null);
      cleanUpFormSubmittedState();
    };
  }, []);

  useEffect(() => {
    if (!selectedPatientInList) {
      if (isDoctorOrAdminOrClinicAdmin(userRole)) {
        navigate("/patients");
      } else {
        navigate("/appointments");
      }
    }
  }, [selectedPatientInList]);

  useEffect(() => {
    if (submitError) {
      showSnackbar("Could not submit form, please try again", "error");
      cleanUpFormSubmittedState();
    }
    if (submitLoading) {
      showSnackbar("Form is submitting, please try again", "info");
    }

    if (formSubmitted) {
      showSnackbar("Form is submittied", "success");
      setDrawerOpen(false);
      setSelectedForm(null);
      setShowFormList(true);
    }
  }, [formSubmitted, submitLoading, submitError]);

  // const onCloseDrawer = () => {
  //   setDrawerOpen(false);
  //   setSelectedForm(null);
  //   setShowFormList(true);
  //   cleanUpFormSubmittedState();
  // };

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        width: "100%",
        height: "100%",
        paddingBlock: selectedPatientInList ? "1rem" : 0,
        paddingInline: selectedPatientInList ? "0.875rem" : 0,
      }}
    >
      {selectedPatientInList ? (
        <Box
          sx={{
            display: "flex",
            gap: "1.25rem",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          <CustomBreadcrumbs items={breadcrumbItems} />
          <Button
            color="primary"
            variant="contained"
            className="primary-button"
            onClick={() => setDrawerOpen(true)}
            sx={{ height: "2.25rem" }}
          >
            Add Form
          </Button>
        </Box>
      ) : (
        <ComponentHeader title="">
          <Button
            color="primary"
            variant="contained"
            className="primary-button"
            onClick={() => setDrawerOpen(true)}
          >
            Add Form
          </Button>
        </ComponentHeader>
      )}

      <PatientFormsList
        patientId={selectedPatientInList?.id || null}
        patientName={newPatinetName || ""}
        from="patient-list"
      />

      <Drawer
        open={drawerOpen}
        width={isMobile ? "100vw" : "45vw"}
        headerText={newPatinetName || ""}
        onClose={() => {
          setDrawerOpen(false);
          setSelectedForm(null);
          setShowFormList(true);
          cleanUpFormSubmittedState();
        }}
      >
        <Box
          sx={{
            paddingInline: "1rem",
            paddingTop: "1.25rem",
            height: "calc(100% - 3.75rem)",
            overflowY: showFormList ? "hidden" : "auto",
          }}
        >
          {showFormList && (
            <Box sx={{ height: "100%" }}>
              <AllFormsList
                selectedForm={selectedForm}
                setSelectedForm={setSelectedForm}
              />
              <Box
                sx={{
                  marginBlock: "1.25rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  color="success"
                  variant="contained"
                  className="success-button"
                  disabled={!selectedForm}
                  sx={{ marginRight: "0.625rem", height: "2.25rem" }}
                  onClick={() => {
                    const data = {
                      patientId: selectedPatientInList?.id,
                      formId: selectedForm?.id,
                      assignedById: user?.userId,
                    };
                    assignForm(data);
                    setDrawerOpen(false);
                    setSelectedForm(null);
                    setShowFormList(true);
                  }}
                >
                  Assign Form
                </Button>
                <Button
                  color="success"
                  variant="contained"
                  className="success-button"
                  disabled={!selectedForm}
                  sx={{
                    height: "2.25rem"
                  }}
                  onClick={() => {
                    const data = {
                      patientId: selectedPatientInList?.id,
                      formId: selectedForm?.id,
                      assignedById: user?.userId,
                    };
                    assignForm(data);
                    setShowFormList(false);
                  }}
                >
                  Assign & Fill Form
                </Button>
              </Box>
            </Box>
          )}

          {!showFormList && selectedForm && (
            <>
              {selectedForm?.questions && selectedForm?.questions?.pages && selectedForm?.questions?.pages?.length > 0 ? (
                <>
                  <NewSurveyComponent
                    questionData={{
                      isAutoSubmit: Boolean(selectedForm?.isAutoSubmit),
                      isScoreBased: Boolean(selectedForm?.isScoreBased),
                      formName: selectedForm?.name || "",
                      pages: selectedForm?.responses ? [...selectedForm?.responses?.pages] : [...selectedForm?.questions?.pages],
                    }}
                    from="patient-forms"
                    onFinish={onSubmitForm}
                    showTooltip={false}
                  />
                </>
              ) : (
                <p>Loading or no data available...</p>
              )}
            </>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default NewPatinetForms;
