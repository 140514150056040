import { useEffect } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, MenuItem, Select, Typography } from "@mui/material";
import Input from "../ui/Input";

import useAppStore from "../../store/appStore";
import Button from "../ui/Button";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { doctorAcademicQualificationsFormValidationSchema } from "../../validations/doctorValidation";
import { addDoctoracademicQualification, updateDoctoracademicQualification } from "../../services/doctorService";
import { DoctorAcademicQualificationsFormInputs } from "../../types/doctors";
import { capitalizeFirstLetter, typeOfGraduation } from "../../utils/common";
import CustomBreadcrumbs from "../shared/CustomBreadcrumbs";
import { erroMessageStyles, inputLabelStyles, selectStyles } from "../../utils/styles";
import useAuthStore from "../../store/authStore";
import { isDoctorOrNetwork } from "../../utils/auth";

const DoctorAcademicQualificationsForm = ({
    onClose,
    handleAfterChanges,
    id = "",
    selectedQualification = null,
}: {
    onClose: any;
    id?: string;
    selectedQualification?: any;
    handleAfterChanges?: any
}) => {
    // props & state values
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
        control,
    } = useForm<DoctorAcademicQualificationsFormInputs>({
        resolver: yupResolver(doctorAcademicQualificationsFormValidationSchema),
        defaultValues: {
            typeOfGraduation: "",
            qualification: "",
            yearOfCompletion: "",
            collegeOrUniversity: "",
        },
    });

    const { showSnackbar, selectedDoctorInList } = useAppStore();
    const { userRole } = useAuthStore();

    const breadcrumbItems = [
        ...(!isDoctorOrNetwork(userRole)
            ? [{ label: "Doctors", href: "/doctors" }]
            : []),
        {
            label: `${capitalizeFirstLetter(selectedDoctorInList?.firstName || selectedDoctorInList?.user?.firstName || "")}`,
        },
        { label: "Academic Qualifications" },
    ];

    const newBreadcrumbItem =
        !selectedQualification
            ? { label: "New" }
            : null;

    const updatedBreadcrumbItems = [
        ...breadcrumbItems.slice(0, 2), // get the first 2 items
        newBreadcrumbItem, // add the new item
        ...breadcrumbItems.slice(2), // get the rest of the items
    ].filter((item) => item !== null);

    // callbacks & functions
    useEffect(() => {
        if (selectedQualification) {
            console.log("selectedQualification in form", selectedQualification);
            const defaultValues = {
                typeOfGraduation: selectedQualification?.typeOfGraduation ? selectedQualification.typeOfGraduation : "",
                qualification: selectedQualification.qualification || "",
                yearOfCompletion: String(selectedQualification.yearOfCompletion) || "",
                collegeOrUniversity: selectedQualification.collegeOrUniversity || "",
            };
            reset(defaultValues);
        }
    }, [selectedQualification, reset]);

    const onSubmit: SubmitHandler<DoctorAcademicQualificationsFormInputs> = async (
        values
    ) => {
        console.log("values on submit personal", values);
        const data = {
            doctorId: id,
            ...values,
            yearOfCompletion: Number(values?.yearOfCompletion),
        };

        try {
            if (selectedQualification) {
                console.log("selectedQualification edit", selectedQualification);
                try {
                    await updateDoctoracademicQualification(selectedQualification?.id, data);
                    showSnackbar("Academic Qualifications Updated Successfully", "success");
                    if (handleAfterChanges) {
                        handleAfterChanges()
                    }
                } catch (error: any) {
                    showSnackbar(`${error.response?.data.message}`, "error");
                    return;
                }
            } else {
                try {
                    await addDoctoracademicQualification(data);
                    showSnackbar("Academic Qualifications Added Successfully", "success");
                    if (handleAfterChanges) {
                        handleAfterChanges()
                    }
                } catch (error: any) {
                    showSnackbar(`${error.response?.data.message}`, "error");
                    return;
                }
            }
        } catch (error) {
            const message = selectedQualification
                ? "Could not update academic qualification, please try again"
                : "Could not add academic qualification, please try again";
            showSnackbar(message, "error");
            console.error("Error saving hospital:", error);
        }
    };

    return (
        <Box
            sx={{
                height: "100%",
                width: "100%",
            }}
        >
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    height: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "1.25rem",
                        alignItems: "center",
                    }}
                >
                    <CustomBreadcrumbs items={updatedBreadcrumbItems} />
                    <Box
                        sx={{
                            display: "flex",
                            gap: "1.25rem",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="secondary"
                            className="outlined-secondary-button"
                            // startIcon={<AddIcon />}
                            onClick={onClose}
                            sx={{
                                height: "2.25rem",
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            startIcon={<SaveOutlinedIcon />}
                            variant="contained"
                            className="success-button"
                            type="submit"
                            sx={{
                                height: "2.25rem",
                            }}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        p: "1.75rem",
                        backgroundColor: "var(--neuro-white-text)",
                        borderRadius: "0.625rem",
                        display: "flex",
                        flexDirection: "column",
                        gap: "2rem",
                        maxHeight: "calc(100% - 3.25rem)",
                        overflow: "auto",
                    }}
                >
                    <Grid container spacing={2.5}>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="typeOfGraduation"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "0.375rem",
                                            width: "100%",
                                        }}
                                    >
                                        <Typography sx={inputLabelStyles}>
                                            Type Of Graduation *
                                        </Typography>
                                        <Select
                                            {...field}
                                            fullWidth
                                            sx={selectStyles.withGreyBackground}
                                            margin="dense"
                                            error={!!error}
                                            defaultValue={
                                                selectedQualification
                                                    ? selectedQualification?.typeOfGraduation
                                                        ? selectedQualification?.typeOfGraduation
                                                        : ""
                                                    : ""
                                            }
                                        >
                                            {typeOfGraduation.map((graduation) => (
                                                <MenuItem
                                                    key={graduation.value}
                                                    value={graduation.value}
                                                >
                                                    {graduation.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {error && (
                                            <Typography sx={erroMessageStyles}>
                                                {error.message}
                                            </Typography>
                                        )}
                                    </Box>
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Input
                                type="text"
                                className="input-primary"
                                label="Qualification *"
                                {...register("qualification")}
                                error={!!errors.qualification}
                                helperText={errors.qualification?.message}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Input
                                type="text"
                                className="input-primary"
                                label="Year Of Completion *"
                                {...register("yearOfCompletion")}
                                error={!!errors.yearOfCompletion}
                                helperText={errors.yearOfCompletion?.message}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Input
                                type="text"
                                className="input-primary"
                                label="College/Board/University *"
                                {...register("collegeOrUniversity")}
                                error={!!errors.collegeOrUniversity}
                                helperText={errors.collegeOrUniversity?.message}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default DoctorAcademicQualificationsForm;
