import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  getAppointmentStatus,
  getFullHospitalAddress,
  getTimeDiffereForOngoing,
  handleOpenMap,
} from "../../../utils/appointments";
import {
  CANCELLED,
  COMPLETED,
  IN_PERSON,
  NOT_HAPPENED,
  ONGOING,
  ONLINE,
  UPCOMING,
} from "../../../utils/constants";
import useAppointmentStore from "../../../store/appointmentsStore";
import useAuthStore from "../../../store/authStore";
import { useNavigate } from "react-router-dom";
import { isClinicAdminOrAdmin, isDoctor, isPatient, isPatientOrClinicAdminOrAdmin } from "../../../utils/auth";
import useAppStore from "../../../store/appStore";
import Tooltip from "../../ui/Tooltip";
import Button from "../../ui/Button";
import VideoCallIcon from "../../svg/VideoCallIcon";
import {
  capitalizeFirstLetter,
  formatMinutesToHHMM,
} from "../../../utils/common";
import config from "../../../config";
import PaymentIcon from "@mui/icons-material/Payment";
import api from "../../../services/apiService";
import PaidIcon from "@mui/icons-material/Paid";
import { offlinePayment } from "../../../services/appointmentsService";
import Spinner from "../../ui/Spinner";
import LocationIcon from "../../svg/LocationIcon";
import ReScheduleAppointmentButton from "./ReScheduleAppointmentButton";
import AppointmentDrawer from "../AppointmentBooking/AppointmentDrawer";
import PrescriptionButton from "./PrescriptionButton";

interface AppointmentTableActionsProps {
  handleMenuOpen: any;
  anchorEl: any;
  handleMenuClose: any;
  row: any;
  patientId: any;
  from?: string;
}

const AppointmentTableActions: React.FC<AppointmentTableActionsProps> = ({
  handleMenuOpen,
  anchorEl,
  handleMenuClose,
  row,
  patientId,
  from = "",
}) => {
  // props & state values
  const styles = {
    meetButtonStyles: {
      fontSize: "var(--neuro-font-size-smaller-plus)",
      textTransform: "none",
      height: "2.25rem",
      fontFamily: "var(--neuro-font-family-roboto)",
    },
  };
  const { showSnackbar, appointmentsFilter } = useAppStore();

  const argument = from === "dashboard" ? "DASHBOARD" : "";

  const {
    fetchAppointments,
    setSelectedMeetingType,
    selectedDoctor,
    setSelectedDoctor,
    setAppointmentDrawer,
    setAppointmentId,
    setPaymentCompleted,
    setIsRescheduleAppointment,
    setSelectedConsultationType,
    setReasonForAppointment,
  } = useAppointmentStore();

  const { userRole, user } = useAuthStore();
  const navigate = useNavigate();

  const [payOfflineLoading, setPayOfflineLoading] = useState<boolean>(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState<any>(null);
  const [rescheduleLoading, setRescheduleLoading] = useState<boolean>(false);

  const meetButtonText = isDoctor(userRole)
    ? "Meet with patient"
    : "Meet with doctor";

  const buttonRefs = useRef<{
    [key: string]: { handleButtonClick: any };
  }>({});

  // callbacks & functions
  useEffect(() => {
    console.log("row", row);
  }, [row?.id]);

  const charges = (appointmentMode: any, doctor: any) => {
    return appointmentMode === ONLINE
      ? doctor?.onlineConsultationFee || null
      : doctor?.inPersonConsultationFee || null;
  };

  const calculateTotalAmount = (appointmentMode: any, doctor: any) => {
    return doctor?.taxes?.reduce((total: any, tax: any) => {
      if (tax.type === "PERCENTAGE") {
        return (
          total +
          (charges(appointmentMode, doctor) * parseFloat(tax.value)) / 100 ||
          null
        );
      } else if (tax.type === "FIXED") {
        return total + parseFloat(tax.value) || null;
      }
      return total || null;
    }, charges);
  };

  const totalAmount = (appointmentMode: any, doctor: any) => {
    return charges
      ? doctor?.taxes && doctor?.taxes?.length > 0
        ? calculateTotalAmount(appointmentMode, doctor)
        : charges
      : null;
  };

  // reschedule appointment
  const isRebook = (status: string, slot: any) => {
    return status === CANCELLED || status === COMPLETED || getAppointmentStatus(slot?.startTime, slot?.endTime) === NOT_HAPPENED;
  }

  const isReSchedule = (status: string, slot: any) => {
    return status !== CANCELLED && status !== COMPLETED && getAppointmentStatus(slot?.startTime, slot?.endTime) !== NOT_HAPPENED && (getAppointmentStatus(slot?.startTime, slot?.endTime) === UPCOMING || (getAppointmentStatus(slot?.startTime, slot?.endTime) === ONGOING && getTimeDiffereForOngoing(slot?.startTime) >= config?.RESCHEDULE_TIME_LIMIT)) || false;
  }

  const showReScheduleButton = (status: string, slot: any, doctor: any) => {
    const result =
      (isPatientOrClinicAdminOrAdmin(userRole) &&
        (isRebook(status, slot) || isReSchedule(status, slot))) ||
      (isDoctor(userRole) && doctor?.id === user?.doctorId && isReSchedule(status, slot));
    return result;
  }

  const handleReschedule = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, id: string, doctor: any, appointmentType: string) => {
      event.stopPropagation();

      if (isReSchedule(row?.status, row?.availableSlotId)) {
        setIsRescheduleAppointment(true);
        setSelectedConsultationType(row?.consultationType);
        setReasonForAppointment(row?.reasonForAppointment);
        setSelectedAppointmentId(id);
        setRescheduleLoading(true);
        setSelectedDoctor(doctor);
        setSelectedMeetingType(appointmentType);
        setAppointmentId(id);
        row?.paymentStatus === "COMPLETED" ? setPaymentCompleted(true) : setPaymentCompleted(false);
        setAppointmentDrawer(true);
        setRescheduleLoading(false);
      }
      else {
        setIsRescheduleAppointment(false);
        setRescheduleLoading(true);
        setSelectedAppointmentId(id);
        setSelectedDoctor(doctor);
        setSelectedMeetingType("ONLINE");
        setPaymentCompleted(false);
        setAppointmentDrawer(true);
        setRescheduleLoading(false);
      }
    },
    [row?.doctor, setSelectedDoctor, setSelectedMeetingType, setAppointmentDrawer]
  );

  const handleAppointmentDrawerClose = () => {
    setSelectedAppointmentId(null);
    setSelectedDoctor(null);
  };

  // offline payment by admin
  const handleOfflinePayment = async (id: string) => {
    try {
      setPayOfflineLoading(true);
      const result = await offlinePayment(id);
      if (result && result?.data) {
        setPayOfflineLoading(false);
        showSnackbar("Paid offline, successufully", "success");
        // fetchAppointments(
        //   appointmentsFilter.customStartDate,
        //   appointmentsFilter.customEndDate,
        //   appointmentsFilter.status || "",
        //   patientId || ""
        // );
        fetchAppointments(
          appointmentsFilter.customStartDate,
          appointmentsFilter.customEndDate,
          appointmentsFilter.status || "",
          patientId || "",
          argument,
          appointmentsFilter?.searchValue || ""
        );
      }
    } catch (error) {
      setPayOfflineLoading(false);
      showSnackbar("Could not complete pay offline, please try again", "error");
    }
  };

  const showMeetButton = (mode: any, slot: any, status: any) => {
    const appointmentStatus = getAppointmentStatus(
      slot?.startTime,
      slot?.endTime
    );
    return (
      status !== CANCELLED &&
      status !== COMPLETED &&
      ((mode === IN_PERSON && isDoctor(userRole)) || mode === ONLINE) &&
      (appointmentStatus === UPCOMING || appointmentStatus === ONGOING)
    );
  };

  const showPayButton = (status: any, slot: any, paymentStatus: any) =>
    (isPatient(userRole) || isClinicAdminOrAdmin(userRole)) &&
    status !== COMPLETED &&
    status !== CANCELLED &&
    getAppointmentStatus(slot?.startTime, slot?.endTime) !== NOT_HAPPENED &&
    paymentStatus === "PENDING";

  const showPayOfflineButton = (status: any, slot: any, paymentStatus: any) =>
    isClinicAdminOrAdmin(userRole) &&
    status !== COMPLETED &&
    status !== CANCELLED &&
    getAppointmentStatus(slot?.startTime, slot?.endTime) !== NOT_HAPPENED &&
    paymentStatus === "PENDING";

  const showDirectionsButton = (mode: any, slot: any, status: any) =>
    mode === IN_PERSON &&
    status !== CANCELLED &&
    (getAppointmentStatus(slot?.startTime, slot?.endTime) === ONGOING ||
      getAppointmentStatus(slot?.startTime, slot?.endTime) === UPCOMING);

  const isOngoingSoon = (dateString: any) =>
    getTimeDiffereForOngoing(dateString) <= config?.MEETING_ENABLE_TIME;

  const handleMeetingJoin = useCallback(
    (
      event:
        | React.MouseEvent<HTMLButtonElement>
        | React.MouseEvent<HTMLElement>,
      id: string,
      appointmentMode: any
    ) => {
      event.stopPropagation();
      navigate(`/meet/${id}`, {
        state: { appointmentMode: appointmentMode },
      });
    },
    [navigate]
  );

  const handlePayment = useCallback(
    async (appointmentId: string, amount: number | null, patient: any) => {
      try {
        const params = { paymentType: "PAYLATER" };

        const { data } = await api.post(
          `/api/payments/createOrder`,
          { appointmentId, amount },
          { params }
        );
        const { id, currency } = data;

        const options = {
          key: config.RAZORPAY_KEY_ID,
          amount,
          currency,
          name: config.APP_NAME,
          description: config.RAZORPAY_DESCRIPTION,
          order_id: id,
          handler: async (response: any) => {
            try {
              const result = await api.post(`/api/payments/verifySignature`, {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                appointmentId,
              });
              if (result.data.status === "success") {
                showSnackbar("Payment successful", "success");
                handleMenuClose();

                // fetchAppointments(
                //   appointmentsFilter.customStartDate,
                //   appointmentsFilter.customEndDate,
                //   appointmentsFilter.status || "",
                //   patientId || ""
                // );
                fetchAppointments(
                  appointmentsFilter.customStartDate,
                  appointmentsFilter.customEndDate,
                  appointmentsFilter.status || "",
                  patientId || "",
                  argument,
                  appointmentsFilter?.searchValue || ""
                );
              } else {
                showSnackbar(
                  "Payment not completed, please try again",
                  "error"
                );
              }
            } catch {
              showSnackbar("Payment not completed, please try again", "error");
            }
          },
          prefill: {
            // name:
            //   `${patient?.user?.firstName} ${patient?.user?.lastName}` || "",
            name: `${patient?.user?.firstName}` || "",
            email: patient?.user?.email || "",
            contact: patient?.user?.mobile || "",
          },
          theme: { color: "#3399cc" },
          modal: {
            ondismiss: () =>
              showSnackbar("Payment was cancelled by the user", "info"),
          },
        };

        const rzp1 = new (window as any).Razorpay(options);
        rzp1.on("payment.failed", (response: any) =>
          showSnackbar(
            `Payment failed. Reason: ${response.error.description}`,
            "error"
          )
        );
        rzp1.open();
      } catch (error) {
        showSnackbar("Payment not completed, please try again", "error");
        console.error(error);
      }
    },
    [showSnackbar, fetchAppointments, patientId, user]
  );

  const handleViewMap = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, doctor: any) => {
      event.stopPropagation();
      handleOpenMap(getFullHospitalAddress(doctor?.defaultClinic));
    },
    []
  );

  const handlePrescriptionMenuItemClick = (id: string) => {
    // Trigger the specific child's click handler
    buttonRefs.current[id]?.handleButtonClick();
  };

  return (
    <div>
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          handleMenuOpen(event);
        }}
      // disableRipple
      // size="small"
      // sx={{ p: 0, backgroundColor: "transparent" }}
      >
        <MoreVertIcon sx={{ color: "var(--neuro-black-text)" }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(event: any) => {
          event.stopPropagation();
          handleMenuClose();
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {showMeetButton(
          row?.appointmentMode,
          row?.availableSlot,
          row?.status
        ) && (
            <MenuItem
              onClick={(event: any) => {
                event?.stopPropagation();
                handleMenuClose();
              }}
            >
              {!isOngoingSoon(row?.availableSlot?.startTime) ? (
                row?.paymentStatus === "PENDING" ? (
                  <Tooltip
                    title={
                      isDoctor(userRole) && row?.doctor?.id === user?.doctorId
                        ? "Payment not completed by patient"
                        : isClinicAdminOrAdmin(userRole) ||
                          (isDoctor(userRole) &&
                            row?.doctor?.id !== user?.doctorId)
                          ? "You are not a participant in this meet"
                          : "Please complete the payment to meet with doctor"
                    }
                  >
                    <Box
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <span
                        style={{ width: 0 }}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      ></span>
                      <Button
                        startIcon={<VideoCallIcon />}
                        disabled={true}
                        color="secondary"
                        variant="outlined"
                        className="outlined-secondary-button"
                        sx={styles.meetButtonStyles}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                          event.stopPropagation();
                        }}
                      >
                        {meetButtonText}
                      </Button>
                    </Box>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={
                      isDoctor(userRole) && row?.doctor?.id === user?.doctorId
                        ? `You can join meet before ${formatMinutesToHHMM(config?.MEETING_ENABLE_TIME)} hours to meet time`
                        : isClinicAdminOrAdmin(userRole) ||
                          (isDoctor(userRole) &&
                            row?.doctor?.id !== user?.doctorId)
                          ? "You are not a participant in this meet"
                          : `You can join meet before ${formatMinutesToHHMM(config?.MEETING_ENABLE_TIME)} hours to meet time`
                    }
                  >
                    <Box
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <span
                        style={{ width: 0 }}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      ></span>
                      <Button
                        startIcon={<VideoCallIcon />}
                        disabled={true}
                        color="secondary"
                        variant="outlined"
                        className="outlined-secondary-button"
                        sx={styles.meetButtonStyles}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                          event.stopPropagation();
                        }}
                      >
                        {meetButtonText}
                      </Button>
                    </Box>
                  </Tooltip>
                )
              ) : row?.paymentStatus === "PENDING" ? (
                <Tooltip
                  title={
                    isDoctor(userRole) && row?.doctor?.id === user?.doctorId
                      ? "Payment not completed by patient"
                      : isClinicAdminOrAdmin(userRole) ||
                        (isDoctor(userRole) &&
                          row?.doctor?.id !== user?.doctorId)
                        ? "You are not a participant in this meet"
                        : "Please complete the payment to meet with doctor"
                  }
                >
                  <Box
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <span
                      style={{ width: 0 }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    ></span>
                    <Button
                      startIcon={<VideoCallIcon />}
                      disabled={true}
                      color="secondary"
                      variant="outlined"
                      className="outlined-secondary-button"
                      sx={styles.meetButtonStyles}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation();
                      }}
                    >
                      {meetButtonText}
                    </Button>
                  </Box>
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    isClinicAdminOrAdmin(userRole) ||
                      (isDoctor(userRole) && row?.doctor?.id !== user?.doctorId)
                      ? "You are not a participant in this meet"
                      : ""
                  }
                >
                  <Box
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      if (
                        isClinicAdminOrAdmin(userRole) ||
                        (isDoctor(userRole) && row?.doctor?.id !== user?.doctorId)
                      ) {
                        event.stopPropagation();
                      } else {
                        handleMeetingJoin(
                          event,
                          row?.appointmentCode,
                          row?.appointmentMode
                        );
                      }
                    }}
                  >
                    {(isClinicAdminOrAdmin(userRole) ||
                      (isDoctor(userRole) &&
                        row?.doctor?.id !== user?.doctorId)) && (
                        <span
                          style={{ width: 0 }}
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                        ></span>
                      )}
                    <Button
                      startIcon={<VideoCallIcon />}
                      disabled={
                        isClinicAdminOrAdmin(userRole) ||
                        (isDoctor(userRole) && row?.doctor?.id !== user?.doctorId)
                      }
                      color="secondary"
                      variant="outlined"
                      className="outlined-secondary-button"
                      sx={styles.meetButtonStyles}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        if (
                          isClinicAdminOrAdmin(userRole) ||
                          (isDoctor(userRole) &&
                            row?.doctor?.id !== user?.doctorId)
                        ) {
                          event.stopPropagation();
                        } else {
                          handleMeetingJoin(
                            event,
                            row?.appointmentCode,
                            row?.appointmentMode
                          );
                        }
                      }}
                    >
                      {meetButtonText}
                    </Button>
                  </Box>
                </Tooltip>
              )}
            </MenuItem>
          )}

        {showPayButton(row?.status, row?.availableSlot, row?.paymentStatus) && (
          <MenuItem
            onClick={(event: any) => {
              event?.stopPropagation();
              handlePayment(
                row?.id,
                totalAmount(row?.appointmentMode, row?.doctor),
                row?.patient
              );
            }}
            disabled={!totalAmount(row?.appointmentMode, row?.doctor)}
          >
            <Button
              variant="contained"
              className="success-gradient-button"
              startIcon={
                <PaymentIcon
                  sx={{
                    fontSize: "var(--neuro-font-size-tiny-plus)",
                    color: "var(--neuro-white-text)",
                  }}
                />
              }
              sx={{
                textTransform: "none",
                height: "2.25rem",
              }}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                handlePayment(
                  row?.id,
                  totalAmount(row?.appointmentMode, row?.doctor),
                  row?.patient
                );
              }}
              disabled={!totalAmount(row?.appointmentMode, row?.doctor)}
            >
              Pay
            </Button>
          </MenuItem>
        )}

        {showPayOfflineButton(
          row?.status,
          row?.availableSlot,
          row?.paymentStatus
        ) && (
            <MenuItem
              onClick={(event: any) => {
                event?.stopPropagation();
                handleOfflinePayment(row?.id);
                handleMenuClose();
              }}
              disabled={!totalAmount || payOfflineLoading}
            >
              <Button
                className="primary-button"
                variant="contained"
                startIcon={
                  <PaidIcon
                    sx={{
                      fontSize: "var(--neuro-font-size-tiny-plus)",
                      color: "var(--neuro-white-text)",
                    }}
                  />
                }
                sx={{
                  textTransform: "none",
                  height: "2.25rem",
                }}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation();
                  handleOfflinePayment(row?.id);
                  handleMenuClose();
                }}
                disabled={!totalAmount || payOfflineLoading}
              >
                {payOfflineLoading ? <Spinner /> : "Pay Offline"}
              </Button>
            </MenuItem>
          )}

        {showDirectionsButton(
          row?.appointmentMode,
          row?.availableSlot,
          row?.status
        ) && (
            <MenuItem
              onClick={(event: any) => {
                event?.stopPropagation();
                handleViewMap(event, row?.doctor);
                handleMenuClose();
              }}
            >
              <Button
                startIcon={<LocationIcon />}
                color="secondary"
                variant="outlined"
                className="outlined-secondary-button"
                sx={{
                  fontSize: "var(--neuro-font-size-smaller-plus)",
                  textTransform: "none",
                  // width: 130,
                  height: "2.25rem",
                  fontFamily: "var(--neuro-font-family-roboto)",
                }}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event?.stopPropagation();
                  handleViewMap(event, row?.doctor);
                  handleMenuClose();
                }}
              >
                Directions
              </Button>
            </MenuItem>
          )}

        {/* Prescription Button */}
        {row?.prescription && row?.prescription.length > 0 && (
          <MenuItem
            onClick={(event: any) => {
              event?.stopPropagation();
              handlePrescriptionMenuItemClick(row?.id);
            }}
          >
            <PrescriptionButton
              key={row?.id}
              ref={(el) => {
                if (el) buttonRefs.current[row.id] = el;
              }}
              id={row?.id}
              prescription={row?.prescription}
            />
          </MenuItem>
        )}

        {showReScheduleButton(row?.status, row?.availableSlot, row?.doctor) && (
          <MenuItem
            onClick={(event: any) => {
              event.stopPropagation();
              handleReschedule(event, row?.id, row?.doctor, row?.appointmentMode);
              handleMenuClose();
            }}
          >
            <ReScheduleAppointmentButton
              id={row?.id}
              onClick={handleReschedule}
              loading={rescheduleLoading}
              doctor={row?.doctor}
              appointmentType={row?.appointmentMode}
            />
          </MenuItem>
        )}
      </Menu>

      {selectedAppointmentId === row?.id && selectedDoctor && (
        <AppointmentDrawer
          headerText="Schedule Appointment"
          patientId={row?.patient?.id}
          patientName={`${capitalizeFirstLetter(row?.patient?.user?.firstName || "")}`}
          patientEmail={row?.patient?.user?.email || ""}
          patientContact={row?.patient?.user?.mobile || ""}
          from={from === "patient-list" ? "patient-list" : "appointment-cards"}
          onClose={handleAppointmentDrawerClose}
        />
      )}
    </div>
  );
};

export default AppointmentTableActions;
