import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { loadAdminDashboard } from "../../services/dashboardService";
// import DoctorsTeamIcon from "../svg/DoctorsTeamIcon";
// import PatientsIcon from "../svg/PatientsIcon";
import HospitalsIcon from "../svg/HospitalsIcon";
import RefferralsIcon from "../svg/RefferralsIcon";
import AppointmentsIcon from "../svg/AppointmentsIcon";
import { getStartAndEndDateTimesForRange } from "../../utils/common";
import useAppStore from "../../store/appStore";

// import AppointmentCard from "./AppointmentCard";
import DashboardCardcopy from "./DashboardCardcopy";
// import ReferralStats from "./ReferralStats";
// import HospitalsIconcrad from "../svg/HospitalsIconcrad";
import Doctorscard from "../svg/Doctorscard";
import Empolyee from "../svg/Empolyee";
import DashboardCardcopy1 from "./DashboardCardcopy1";

interface AdminDashboardProps {
  range: string;
}

interface Statistics {
  [key: string]: number;
  patientsCount: number;
  clinicsCount: number;
  referralsCount: number;
  bookingAppointmentsCount: number;
  appointmentsTotalCount: number;
  onGoingAppointmentsCount: number;
  clinicsCountTotal: number;
  doctorsCountTotal: number;
  patientsCountTotal: number;
}

const AdminDashboardcopy: React.FC<AdminDashboardProps> = ({ range }) => {
  const [statistics, setStatistics] = useState<Statistics>({
    doctorsCount: 0,
    patientsCount: 0,
    clinicsCount: 0,
    referralsCount: 0,
    bookingAppointmentsCount: 0,
    onGoingAppointmentsCount: 0,
    clinicsCountTotal: 0,
    doctorsCountTotal: 0,
    patientsCountTotal: 0,
    appointmentsTotalCount: 0,
  });

  const { dashboardFilter, setFilter } = useAppStore();

  // const totalAppointments = 0;
  // const appointments = [
  //   { type: "Booking    Appointments", count: statistics.bookingAppointmentsCount },
  //   { type: "Ongoing Appointments", count: statistics.completedAppointmentsCount },
  //   // { type: "View all appointments", count: "" },
  // ];

  // const cardsData = [
  //   {
  //     name: "doctorsCount",
  //     icon: <DoctorsTeamIcon />,
  //     text: "Doctors",
  //     navigateTo: `/doctors`,
  //     action: () => setFilter("doctorsFilter", { range: dashboardFilter.range }),
  //   },
  //   {
  //     name: "patientsCount",
  //     icon: <PatientsIcon />,
  //     text: "Patients",
  //     subtype: true,
  //     subtext: "New Clinic 7",
  //     subtext2: "",
  //     navigateTo: `/patients`,
  //     action: () => setFilter("patientsFilter", { range: dashboardFilter.range }),
  //   },
  //   {
  //     name: "clinicsCount",
  //     icon: <HospitalsIcon />,
  //     text: "Clinics",
  //     subtype: true,
  //     subtext: "New Doctors",
  //     subtext2: "",
  //     navigateTo: `/clinics`,
  //     action: () => setFilter("clinicsFilter", { range: dashboardFilter.range }),
  //   },
  //   {
  //     name: "newReferralsCount",
  //     icon: <RefferralsIcon />,
  //     text: "New Referrals",
  //     navigateTo: `/referrals`,
  //     action: () => setFilter("referralsFilter", { status: "Pending", range: dashboardFilter.range }),
  //   },
  //   {
  //     name: "bookingAppointmentsCount",
  //     icon: <AppointmentsIcon />,
  //     text: "Booked Appointments",
  //     navigateTo: `/appointments`,
  //     action: () => setFilter("appointmentsFilter", { status: "Booked", range: dashboardFilter.range }),
  //   },
  // ];

  const cardsData = [
    // {
    //   name: "newReferralsCount",
    //   icon: <RefferralsIcon />,
    //   text: "Total Referrals",
    //   navigateTo: `/referrals`,
    //   subtext:"Accepted -",
    //   subtext2:"Rejected -",
    //   subnavigateTo:"Rejected -",
    //   subnavigateTo2:"Accepted -",
    //   subtextcount:statistics.acceptedReferralsCount,
    //   subtextcount2:statistics.rejectedReferralsCount,
    //   action: () => {
    //     if (range === "TODAY") {
    //       const { start, end } = getStartAndEndDateTimesForRange(range);
    //       setFilter("referralsFilter", {
    //         status: "Accepted",
    //         range: dashboardFilter.range,
    //         customStartDate: start,
    //         customEndDate: end,
    //       });
    //     } else {
    //       setFilter("referralsFilter", {
    //         status: "Accepted",
    //         range: dashboardFilter.range,
    //         customStartDate: dashboardFilter.customStartDate,
    //         customEndDate: dashboardFilter.customEndDate,
    //       });
    //     }
    //   },
    // },
    {
      name: "newReferralsCount",
      icon: <RefferralsIcon color="var(--neuro-darkgrey_border)" />,
      text: "Total Referrals",
      navigateTo: `/referrals`,
      subtext: "Accepted -",
      subtext2: "Rejected -",
      subnavigateTo: "Rejected -",
      subnavigateTo2: "Accepted -",
      subtextcount: statistics.acceptedReferralsCount,
      subtextcount2: statistics.rejectedReferralsCount,
      newReferralsCount: statistics.newReferralsCount,
      action: (status: "Accepted" | "Rejected") => {
        const { start, end } =
          range === "TODAY"
            ? getStartAndEndDateTimesForRange(range)
            : {
              start: dashboardFilter.customStartDate,
              end: dashboardFilter.customEndDate,
            };

        setFilter("referralsFilter", {
          status,
          range: dashboardFilter.range,
          customStartDate: start,
          customEndDate: end,
        });
      },
    },
    {
      name: "clinicsCount",
      // icon: <HospitalsIconcrad />,
      icon: <HospitalsIcon color="var(--neuro-darkgrey_border)" />,
      text: "Total Clinics",
      navigateTo: `/clinics`,
      subtext: "New Clinics -",
      subtext2: "",
      subtextcount: statistics.clinicsCount,
      subtextcount2: "",
      action: () => {
        if (range === "TODAY") {
          const { start, end } = getStartAndEndDateTimesForRange(range);
          setFilter("clinicsFilter", {
            range: dashboardFilter.range,
            customStartDate: start,
            customEndDate: end,
          });
        } else {
          setFilter("clinicsFilter", {
            range: dashboardFilter.range,
            customStartDate: dashboardFilter.customStartDate,
            customEndDate: dashboardFilter.customEndDate,
          });
        }
      },
    },
    {
      name: "doctorsCount",
      icon: <Doctorscard />,
      text: "Doctors",
      navigateTo: `/doctors`,
      subtext: "New Doctors - ",
      subtext2: "",
      subtextcount: statistics.doctorsCount,
      subtextcount2: "",
      action: () => {
        if (range === "TODAY") {
          const { start, end } = getStartAndEndDateTimesForRange(range);
          setFilter("doctorsFilter", {
            range: dashboardFilter.range,
            customStartDate: start,
            customEndDate: end,
          });
        } else {
          setFilter("doctorsFilter", {
            range: dashboardFilter.range,
            customStartDate: dashboardFilter.customStartDate,
            customEndDate: dashboardFilter.customEndDate,
          });
        }
      },
    },
    {
      name: "patientsCount",
      icon: <Empolyee />,
      text: "Patients",
      navigateTo: `/patients`,
      subtext: "New patients - ",
      subtext2: "",
      subtextcount: statistics.patientsCount,
      subtextcount2: "",
      action: () => {
        if (range === "TODAY") {
          const { start, end } = getStartAndEndDateTimesForRange(range);
          setFilter("patientsFilter", {
            range: dashboardFilter.range,
            customStartDate: start,
            customEndDate: end,
          });
        } else {
          setFilter("patientsFilter", {
            range: dashboardFilter.range,
            customStartDate: dashboardFilter.customStartDate,
            customEndDate: dashboardFilter.customEndDate,
          });
        }
      },
    },

    // {
    //   name: "acceptedReferralsCount",
    //   icon: <RefferralsIcon />,
    //   text: "Accepted Referrals",
    //   navigateTo: `/referrals`,
    //   action: () => {
    //     if (range === "TODAY") {
    //       const { start, end } = getStartAndEndDateTimesForRange(range);
    //       setFilter("referralsFilter", {
    //         status: "Accepted",
    //         range: dashboardFilter.range,
    //         customStartDate: start,
    //         customEndDate: end,
    //       });
    //     } else {
    //       setFilter("referralsFilter", {
    //         status: "Accepted",
    //         range: dashboardFilter.range,
    //         customStartDate: dashboardFilter.customStartDate,
    //         customEndDate: dashboardFilter.customEndDate,
    //       });
    //     }
    //   },
    // },
    // {
    //   name: "rejectedReferralsCount",
    //   icon: <RefferralsIcon />,
    //   text: "Rejected Referrals",
    //   navigateTo: `/referrals`,
    //   action: () => {
    //     if (range === "TODAY") {
    //       const { start, end } = getStartAndEndDateTimesForRange(range);
    //       setFilter("referralsFilter", {
    //         status: "Rejected",
    //         range: dashboardFilter.range,
    //         customStartDate: start,
    //         customEndDate: end,
    //       });
    //     } else {
    //       setFilter("referralsFilter", {
    //         status: "Rejected",
    //         range: dashboardFilter.range,
    //         customStartDate: dashboardFilter.customStartDate,
    //         customEndDate: dashboardFilter.customEndDate,
    //       });
    //     }
    //   },
    // },
    {
      name: "bookingAppointmentsCount",
      icon: <AppointmentsIcon />,
      text: "Booked Appointments",
      navigateTo: `/appointments`,
      action: () => {
        if (range === "TODAY") {
          const { start, end } = getStartAndEndDateTimesForRange(range);
          setFilter("appointmentsFilter", {
            status: "Booked",
            range: dashboardFilter.range,
            customStartDate: start,
            customEndDate: end,
          });
        } else {
          setFilter("appointmentsFilter", {
            status: "Booked",
            range: dashboardFilter.range,
            customStartDate: dashboardFilter.customStartDate,
            customEndDate: dashboardFilter.customEndDate,
          });
        }
      },
    },
    {
      name: "completedAppointmentsCount",
      icon: <AppointmentsIcon />,
      text: "Completed Appointments",
      navigateTo: `/appointments`,
      action: () => {
        if (range === "TODAY") {
          const { start, end } = getStartAndEndDateTimesForRange(range);
          setFilter("appointmentsFilter", {
            status: "Completed",
            range: dashboardFilter.range,
            customStartDate: start,
            customEndDate: end,
          });
        } else {
          setFilter("appointmentsFilter", {
            status: "Completed",
            range: dashboardFilter.range,
            customStartDate: dashboardFilter.customStartDate,
            customEndDate: dashboardFilter.customEndDate,
          });
        }
      },
    },
    {
      name: "cancelledAppointmentsCount",
      icon: <AppointmentsIcon />,
      text: "Cancelled Appointments",
      navigateTo: `/appointments`,
      action: () => {
        if (range === "TODAY") {
          const { start, end } = getStartAndEndDateTimesForRange(range);
          setFilter("appointmentsFilter", {
            status: "Cancelled",
            range: dashboardFilter.range,
            customStartDate: start,
            customEndDate: end,
          });
        } else {
          setFilter("appointmentsFilter", {
            status: "Cancelled",
            range: dashboardFilter.range,
            customStartDate: dashboardFilter.customStartDate,
            customEndDate: dashboardFilter.customEndDate,
          });
        }
      },
    },
  ];

  const appointmentData = [
    {
      name: "bookingAppointmentsCount",
      icon: <AppointmentsIcon />,
      count: statistics.bookingAppointmentsCount,
      text: "Booked Appointments",
      navigateTo: `/appointments`,
      action: () => {
        // Set filter for Booked Appointments
        if (range === "TODAY") {
          const { start, end } = getStartAndEndDateTimesForRange(range);
          setFilter("appointmentsFilter", {
            status: "Booked",
            range: dashboardFilter.range,
            customStartDate: start,
            customEndDate: end,
          });
        } else {
          setFilter("appointmentsFilter", {
            status: "Booked",
            range: dashboardFilter.range,
            customStartDate: dashboardFilter.customStartDate,
            customEndDate: dashboardFilter.customEndDate,
          });
        }
      },
    },
    {
      name: "completedAppointmentsCount",
      icon: <AppointmentsIcon />,
      count: statistics.completedAppointmentsCount,
      text: "Completed Appointments",
      navigateTo: `/appointments`,
      action: () => {
        // Set filter for Completed Appointments
        if (range === "TODAY") {
          const { start, end } = getStartAndEndDateTimesForRange(range);
          setFilter("appointmentsFilter", {
            status: "Completed",
            range: dashboardFilter.range,
            customStartDate: start,
            customEndDate: end,
          });
        } else {
          setFilter("appointmentsFilter", {
            status: "Completed",
            range: dashboardFilter.range,
            customStartDate: dashboardFilter.customStartDate,
            customEndDate: dashboardFilter.customEndDate,
          });
        }
      },
    },
    {
      name: "cancelledAppointmentsCount",
      icon: <AppointmentsIcon />,
      text: "Cancelled Appointments",
      count: statistics.cancelledAppointmentsCount,
      navigateTo: `/appointments`,
      action: () => {
        // Set filter for Cancelled Appointments
        if (range === "TODAY") {
          const { start, end } = getStartAndEndDateTimesForRange(range);
          setFilter("appointmentsFilter", {
            status: "Cancelled",
            range: dashboardFilter.range,
            customStartDate: start,
            customEndDate: end,
          });
        } else {
          setFilter("appointmentsFilter", {
            status: "Cancelled",
            range: dashboardFilter.range,
            customStartDate: dashboardFilter.customStartDate,
            customEndDate: dashboardFilter.customEndDate,
          });
        }
      },
    },
    {
      name: "bookingAppointmentsCount",
      icon: <AppointmentsIcon />,
      appointmentsTotalCount: statistics.appointmentsTotalCount,

      navigateTo: `/appointments`,
      action: () => {
        // Set filter for Booked Appointments
        if (range === "TODAY") {
          const { start, end } = getStartAndEndDateTimesForRange(range);
          setFilter("appointmentsFilter", {
            status: "All",
            range: dashboardFilter.range,
            customStartDate: start,
            customEndDate: end,
          });
        } else {
          setFilter("appointmentsFilter", {
            status: "All",
            range: dashboardFilter.range,
            customStartDate: dashboardFilter.customStartDate,
            customEndDate: dashboardFilter.customEndDate,
          });
        }
      },
    },
  ];

  useEffect(() => {
    const fetchDashboard = async () => {
      try {
        if (dashboardFilter.customStartDate && dashboardFilter.customEndDate) {
          const response = await loadAdminDashboard(
            dashboardFilter.customStartDate,
            dashboardFilter.customEndDate
          );
          const data = response.data;
          setStatistics(data);
        } else {
          if (range === "TODAY") {
            const { start, end } = getStartAndEndDateTimesForRange(range);
            const response = await loadAdminDashboard(start, end);
            const data = response.data;
            setStatistics(data);
          }
        }
      } catch (error) {
        console.error("error in dashboard api", error);
      }
    };

    fetchDashboard();
  }, [range, dashboardFilter.customStartDate, dashboardFilter.customEndDate]);

  return (
    // <Box
    //   sx={{
    //     display: "grid",
    //     gridTemplateColumns: { xs: "1fr", md: "65% 35%" },
    //     gap: "1rem",
    //     height: "100%",
    //     width: "100%",
    //   }}
    // >
    //   <Box sx={{ height: "100%", width: "100%" }}>
    //     {/* <Grid container spacing={2.5}>
    //   {cardsData.map((data, i) => (
    //     <Grid item xs={12} md={6} key={i}>
    //       <DashboardCardcopy
    //         icon={data.icon}
    //         text={data.text}
    //         count={statistics[data.name] || 0}
    //         total={statistics[data.name + "Total"] || 0}
    //         navigateTo={data.navigateTo}
    //         action={data.action}
    //         subtype={data.subtype}
    //         subtext={data.subtext}
    //         subtext2={data.subtext2}
    //         subtextcount={data.subtextcount}
    //         subtextcount2={data.subtextcount2}
    //       />
    //     </Grid>
    //   ))}
    // </Grid> */}

    //     <Grid container spacing={2.5}>
    //       {cardsData
    //         .filter(
    //           (data) =>
    //             ![
    //               "bookingAppointmentsCount",
    //               "completedAppointmentsCount",
    //               "cancelledAppointmentsCount",
    //             ].includes(data.name)
    //         ) // Filter out the specified names
    //         .map((data, i) => (
    //           <Grid item xs={12} md={6} key={i}>
    //             <DashboardCardcopy
    //               icon={data.icon}
    //               text={data.text}
    //               count={statistics[data.name] || 0}
    //               total={statistics[data.name + "Total"] || 0}
    //               navigateTo={data.navigateTo}
    //               action={data.action}
    //               // subtype={data.subtype}
    //               subtext={data.subtext || ""}
    //               subtext2={data.subtext2 || ""}
    //               subtextcount={data.subtextcount || ""}
    //               subtextcount2={data.subtextcount2 || ""}
    //             />
    //           </Grid>
    //         ))}
    //     </Grid>
    //   </Box>
    //   {/* <AppointmentCard totalAppointments={totalAppointments} appointments={appointments} /> */}

    //   <DashboardCardcopy1 appointmentData={appointmentData} />
    // </Box>
    <Box
      sx={{
        // display: "grid",
        // gridTemplateColumns: { xs: "1fr", md: "65% 35%" },
        // gap: "1rem",
        maxHeight: "calc(100% - 7.5rem)",
        width: "100%",
        overflowY: "auto"
      }}
    >
      <Box sx={{ height: "100%", width: "100%" }}>
        {/* <Grid container spacing={2.5}>
      {cardsData.map((data, i) => (
        <Grid item xs={12} md={6} key={i}>
          <DashboardCardcopy
            icon={data.icon}
            text={data.text}
            count={statistics[data.name] || 0}
            total={statistics[data.name + "Total"] || 0}
            navigateTo={data.navigateTo}
            action={data.action}
            subtype={data.subtype}
            subtext={data.subtext}
            subtext2={data.subtext2}
            subtextcount={data.subtextcount}
            subtextcount2={data.subtextcount2}
          />
        </Grid>
      ))}
    </Grid> */}

        <Grid container spacing={2}>
          <Grid item container xs={12} md={8} spacing={2}>
            {cardsData
              .filter(
                (data) =>
                  ![
                    "bookingAppointmentsCount",
                    "completedAppointmentsCount",
                    "cancelledAppointmentsCount",
                  ].includes(data.name)
              ) // Filter out the specified names
              .map((data, i) => (
                <Grid item xs={12} md={6} key={i}>
                  <DashboardCardcopy
                    icon={data.icon}
                    text={data.text}
                    count={statistics[data.name] || 0}
                    total={statistics[data.name + "Total"] || 0}
                    navigateTo={data.navigateTo}
                    action={data.action}
                    // subtype={data.subtype}
                    subtext={data.subtext || ""}
                    subtext2={data.subtext2 || ""}
                    subtextcount={data.subtextcount || ""}
                    subtextcount2={data.subtextcount2 || ""}
                  />
                </Grid>
              ))}
          </Grid>

          <Grid item xs={12} md={4}>
            <DashboardCardcopy1 appointmentData={appointmentData} />
          </Grid>
        </Grid>
      </Box>
      {/* <AppointmentCard totalAppointments={totalAppointments} appointments={appointments} /> */}
    </Box>
  );
};

export default AdminDashboardcopy;
