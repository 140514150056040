import React from "react";
import {
  Checkbox as MUICheckbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { CheckboxProps } from "../../types/ui";
import { erroMessageStyles } from "../../utils/styles";

const Checkbox = React.forwardRef<HTMLButtonElement, CheckboxProps>(
  (
    {
      onChange,
      label,
      name,
      sx,
      className,
      id,
      checked,
      helperText,
      defaultChecked = false,
      disabled = false,
      error = false,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <FormControlLabel
          control={
            <MUICheckbox
              ref={ref}
              checked={checked}
              onChange={onChange}
              id={id}
              name={name}
              className={className}
              disabled={disabled}
              defaultChecked={defaultChecked}
              sx={{
                "& .MuiFormControlLabel-root": {
                  marginRight: "0 !important"
                },
              }}
              {...props}
            />
          }
          label={label}
          sx={{
            "& .MuiTypography-root": {
              fontSize: "var(--neuro-font-size-medium-smallest)",
              fontFamily: "var(--neuro-font-family-roboto-condensed)",
              color: "var(--neuro-black-text)",
              lineHeight: "120%",
            },
            ...sx,
          }}
        />
        {error && helperText && (
          <Typography sx={erroMessageStyles}>{helperText}</Typography>
        )}
      </>
    );
  }
);

export default Checkbox;
