import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface DynamicImagePickerProps {
  initialQuestion: {
    type: string;
    name: string;
    title: string;
    choices: { value: string; imageLink: string; score: number }[];
    imageFit: 'cover' | 'contain';
    isRequired: boolean;
    showScore: boolean;
  };
  index: number;
  toggleRequired: (index: number) => void;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
  updateQuestion: (index: number, updatedQuestion: any) => void;
  isonclick?: boolean;
  showHighlighted: boolean;
}

const DynamicImagePickerNot: React.FC<DynamicImagePickerProps> = ({
  initialQuestion,
  index,
  toggleRequired,
  handleDuplicateQuestion,
  handleRemoveQuestion,
  updateQuestion,
  showHighlighted = false,
  isonclick = false,
}) => {
  const [localQuestion, setLocalQuestion] = useState(initialQuestion);

  console.log("localQuestion", localQuestion);

  // callbacks & functions
  useEffect(() => {
    setLocalQuestion(initialQuestion);
  }, [initialQuestion])

  const updateLocalQuestion = (updatedQuestion: typeof initialQuestion) => {
    setLocalQuestion(updatedQuestion);
    updateQuestion(index, updatedQuestion);
  };

  // Handle Title Change
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    updateLocalQuestion({ ...localQuestion, title: newTitle });
  };

  // Handle Score Change (Update both value and score)
  const handleScoreChange = (idx: number, newScore: number) => {
    const updatedChoices = localQuestion.choices.map((choice, choiceIdx) =>
      choiceIdx === idx ? { ...choice, score: newScore, value: `${newScore}` } : choice
    );
    updateLocalQuestion({ ...localQuestion, choices: updatedChoices });
  };

  // Handle Image Delete
  const handleImageDelete = (idx: number) => {
    setTimeout(() => {
      const updatedChoices = localQuestion.choices.filter((_, choiceIdx) => choiceIdx !== idx);
      updateLocalQuestion({ ...localQuestion, choices: updatedChoices });
    }, 300); // Simulate a delay (300ms here, you can adjust this time)
  };

  // Handle Image Upload
  const handleImageUpload = (idx: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const newImageLink = URL.createObjectURL(file);
      const updatedChoices = localQuestion.choices.map((choice, choiceIdx) =>
        choiceIdx === idx ? { ...choice, imageLink: newImageLink } : choice
      );
      updateLocalQuestion({ ...localQuestion, choices: updatedChoices });
    }
  };

  return (
    <Box sx={{
      margin: 0,
      padding: "1rem",
      borderRadius: "0.5rem",
      marginBottom: "1rem",
      cursor: "pointer",
      transition: "border-color 0.3s ease",
      background: "var(--neuro-white-text)",
      height: "auto",
      border: isonclick || showHighlighted ? "1px solid var(--neuro-light_red_border) !important" : "1px solid transparent !important",
      "&:hover": {
        border: "1px solid var(--neuro-light_red_border_light) !important"
      }
    }}>
      <div style={{ marginBottom: '0.8rem' }} className="question-header">
        {/* Editable Question Title */}
        {localQuestion.name}{" "}
        <input
          type="text"
          value={localQuestion.title}
          onChange={handleTitleChange}
          placeholder="Enter Question Title"
          className="question-name-input survey-js-gobal-input"
        />
        {localQuestion.isRequired && <span className="required">*</span>}
      </div>
      <div className="image-picker-container" style={{ width: '80%' }}>
        {/* Image Picker Choices */}
        <div className="image-picker-grid">
          {localQuestion.choices.map((choice, idx) => (
            <div
              key={idx}
              className="image-picker-item"
              style={{
                backgroundImage: `url(${choice.imageLink})`,
                backgroundSize: localQuestion.imageFit,
              }}
            >
              <span className="image-picker-label">{choice.value}</span>
              <div className="image-picker-buttons">
                {localQuestion.showScore && (
                  <>
                    {/* Image Upload Trigger */}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleImageUpload(idx, e)}
                      style={{ display: 'none' }}
                      id={`file-upload-${idx}`}
                    />

                  </>
                )}


                <label className="circle-button upload-button">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageUpload(idx, e)}
                  />
                  📤
                </label>
                <button
                  className="circle-button delete-button"
                  onClick={() => handleImageDelete(idx)} // Delayed delete
                >
                  ❌
                </button>
                {/* Score Input */}
                {Boolean(localQuestion?.showScore) && <input
                  type="number"
                  value={choice.score}
                  style={{ width: '3rem' }}
                  onChange={(e) => handleScoreChange(idx, parseInt(e.target.value, 10))}
                  min="0"
                  className="edit-score-input survey-js-gobal-input"
                />}
              </div>
            </div>
          ))}
        </div>

        {/* Controls */}
        <div className="question-controls">
          <button
            className="duplicate-button button-spacing blue-bg"
            onClick={() => handleDuplicateQuestion(index)}
          >
            Duplicate
          </button>

          <button
            className="delete-button button-spacing red-bg"
            onClick={() => handleRemoveQuestion(index)}
          >
            Delete
          </button>

          <button
            className={`required-button ${localQuestion.isRequired ? 'active' : ''}`}
            onClick={() => toggleRequired(index)}
          >
            {localQuestion.isRequired ? 'Required' : 'Optional'}
          </button>
        </div>
      </div>
    </Box>
  );
};

export default DynamicImagePickerNot;
