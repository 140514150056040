import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Typography, Button, FormControlLabel,Checkbox , Link } from "@mui/material";
import Input from "../ui/Input";
import Spinner from "../ui/Spinner";

// Define validation schema for Yup
const schema = yup.object().shape({
	aadharNumber: yup.string().required("Aadhaar number is required"),
  agreeToTerms: yup.boolean().oneOf([true], "Please provide consent by clicking on 'I agree'"),
});

interface FormInputs {
	aadharNumber: string;
  agreeToTerms?: boolean;
}

const AadharModal: React.FC<{
	sendOtp: (data: any) => void;
	loader: boolean;
}> = ({ sendOtp ,loader}) => {
	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<FormInputs>({
		resolver: yupResolver(schema),
	});

	const onSubmit: SubmitHandler<FormInputs> = async (data) => {
		sendOtp(data)
	};
	return (
		<>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Typography
						sx={{
							mb: "2rem",
							fontWeight: "var(--neuro-font-weight-bold)",
							fontSize: "var(--neuro-font-size-large-medium)",
							fontFamily:
								"var(--neuro-font-family-roboto-condensed)",
							lineHeight: "120%",
						}}
					>
						Aadhaar
					</Typography>

					<Box
						component="form"
						onSubmit={handleSubmit(onSubmit)}
						sx={{ width: "100%" }}
					>
						<Input
							type="text"
							// className="input-secondary"
							className="input-primary"
							// placeholder="XXXX - XXXX - XXXX"
							sx={{
								"& >.MuiInputBase-root": {
									height: "2.5rem",
								},
							}}
							label="Enter Aadhaar Number*"
							{...register("aadharNumber")}
							error={!!errors.aadharNumber}
							helperText={errors.aadharNumber?.message}
						/>

						<FormControlLabel
							control={
								<Checkbox
									{...register("agreeToTerms")}
									color="primary"
								/>
							}
							label={
								<Typography variant="body2">
									I agree to{" "}
									<Link
										href="/abha-terms-and-conditons"
										target="_blank"
										rel="noopener"
									>
									abha terms and conditions
									</Link>
								</Typography>
							}
						/>
						{errors.agreeToTerms && (
							<Typography variant="body2" color="error">
								{errors.agreeToTerms.message}
							</Typography>
						)}

						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								mt: "2rem",
								mb: "1rem",
							}}
						>
							<Button
								type="submit"
								variant="contained"
								className="success-button"
								color="success"
								disabled={loader}
								sx={{
									opacity:loader ? 0.7:1,
									minWidth: "9.375rem",
									height: "2.5rem",
									textTransform: "none",
								}}
							>
								{loader ? <Spinner size={"1.25rem"} /> : "Get OTP"}
							</Button>
						</Box>
					</Box>
				</Box>
		</>
	);
};

export default AadharModal;
