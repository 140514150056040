import React, { useState, useRef, ChangeEvent, DragEvent } from "react";
import { Box, Typography, TextField, Autocomplete } from "@mui/material";
import Modal from "../../ui/Modal";
import Button from "../../ui/Button";
import Spinner from "../../ui/Spinner";
import UploadIcon from "../../svg/UploadIcon";
import UploadDocumentIcon from "../../svg/UploadDocumentIcon";
import useDocumentStore from "../../../store/documentsStore";
import { Category, UploadFileModalProps } from "../../../types/documents";
import { uploadFiles } from "../../../services/documentsService";
import useAppStore from "../../../store/appStore";
// import MobileDocumentScanner from "./MobileDocumentScanner";

const UploadFileModal: React.FC<UploadFileModalProps> = ({
  open,
  onClose,
  width,
  closeIcon,
  categories,
  patientId,
  from = "",
  ...props
}) => {
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );

  // const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { uploadDocuments, loadingUploadDocuments } = useDocumentStore();

  const { showSnackbar } = useAppStore();

  const [dragging, setDragging] = useState(false); // Track dragging state


  // callbacks & functions
  const resetFileInput = () => {
    if (fileInputRef.current) fileInputRef.current.value = "";
    setSelectedCategory(null);
    if (onClose) onClose();
  };

  const uploadDocumentsApiCall = async (formdata: any, id: string) => {
    try {
      const response = await uploadFiles(formdata, id);
      if (response.data.createdDocuments) {
        showSnackbar("Document uploaded successfully", "success");
        resetFileInput();
      }
    } catch (error) {
      showSnackbar("Could not upload document, please try again", "error");
    }
  }

  const uploadFilesHandler = (files: File[]) => {
    console.log("files", files);
    const formData = new FormData();
    files.forEach((file) => formData.append("files", file));
    const value = selectedCategory?.value;
    formData.append("documentCategory", value as string);
    if (from === "patient-table") {
      uploadDocumentsApiCall(formData, patientId || "");
    }
    else {
      if (patientId) {
        uploadDocuments(formData, patientId);
      }
      if (!patientId) {
        uploadDocuments(formData);
      }
      resetFileInput();
    }
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files) {
      const selectedFiles = Array.from(event.target.files || []);
      uploadFilesHandler(selectedFiles);

      if (!selectedCategory) return;
    }
  };


  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    if (event.dataTransfer.files) {
      event.preventDefault();
      setDragging(false);
      const files = Array.from(event.dataTransfer.files);
      if (!selectedCategory) {
        showSnackbar("Please select a category before uploading files.", "error");
        return;
      }

      if (files.length > 0) {
        uploadFilesHandler(files);
      }
      if (files.length === 0) {
        showSnackbar("Please select a file", "error");
      }
    }
    else {
      showSnackbar("Please select a file", "error");
    }

  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeIcon={closeIcon}
      width={width ? width : "33.75rem"}
      {...props}
    >
      <Box
        sx={{
          // width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingInline: "2.5rem",
        }}
        onClick={(event) => event.stopPropagation()}
      >
        <Typography
          className="form-heading"
          sx={{
            textAlign: "center",
            fontWeight: "var(--neuro-font-weight-semibold)",
            fontSize: "var(--neuro-font-size-tiny)",
          }}
        >
          Upload Document
        </Typography>
        <Box sx={{ mt: "2rem" }}>
          <UploadDocumentIcon />
        </Box>

        <Box sx={{ width: "100%", mt: "1rem" }}>
          <Autocomplete
            options={categories}
            getOptionLabel={(option) => option.name}
            value={selectedCategory}
            onChange={(_, newValue) => {
              setSelectedCategory(newValue);

              // Blur the input element after an option is selected
              inputRef.current?.blur();
            }}
            renderInput={(params) => {
              const combinedRef = (input: HTMLInputElement | null) => {
                // Handle the ref from params.InputProps.ref
                if (typeof params.InputProps.ref === "function") {
                  params.InputProps.ref(input);
                } else if (params.InputProps.ref) {
                  (params.InputProps.ref as React.MutableRefObject<HTMLInputElement | null>).current = input;
                }

                // Set your custom ref
                inputRef.current = input;
              };

              return (
                <TextField
                  {...params}
                  placeholder="Select Category"
                  variant="outlined"
                  fullWidth
                  inputRef={combinedRef}
                  sx={{
                    "& .MuiInputBase-input": {
                      textAlign: "center", // Center align the placeholder text
                    },
                    "& .MuiInputBase-root": {
                      height: "2.625rem !important",
                    },
                    "& .MuiInputLabel-root": {
                      textAlign: "center", // Center align the label text
                    },
                  }}
                />
              );
            }}
          // renderInput={(params) => (
          //   <TextField
          //     {...params}
          //     placeholder="Select Category"
          //     variant="outlined"
          //     fullWidth
          //     sx={{
          //       "& .MuiInputBase-input": {
          //         textAlign: "center", // Center align the placeholder text
          //       },
          //       "& .MuiInputBase-root": {
          //         height: "2.625rem !important",
          //       },
          //       "& .MuiInputLabel-root": {
          //         textAlign: "center", // Center align the label text
          //       },
          //     }}
          //   />
          // )}
          />
        </Box>

        {/* Drag-and-Drop Zone */}
        <Box
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          sx={{
            width: "100%",
            mt: "1.5rem",
            padding: "2rem",
            border: "2px dashed #ccc",
            borderRadius: "8px",
            textAlign: "center",
            color: selectedCategory ? "#333" : dragging ? "white" : "#aaa",
            backgroundColor: dragging ? selectedCategory ? "var(--neuro-bg-primary-seagreen)" : "var(--neuro-primary-error)" : "var(--neuro-bg-light-grey-secondary)",
            cursor: selectedCategory ? "pointer" : "not-allowed",
            opacity: selectedCategory ? 1 : 0.8,
          }}
        >
          <Typography sx={{ color: selectedCategory ? "#333" : dragging ? "white" : "#333", opacity: selectedCategory ? 1 : 0.8 }}>
            {selectedCategory
              ? dragging ? "Drop here" : "Drag and drop files here, or click Upload"
              : dragging ? "Category is not selected, please select a category" : "Please select a category to enable file upload"}
          </Typography>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            mt: "1.5rem",
          }}
        >
          {/* {isMobileDevice && <MobileDocumentScanner />} */}
          <input
            type="file"
            id="file-upload"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileChange}
            accept=".jpeg,.jpg,.png,.pdf"
            disabled={!selectedCategory || loadingUploadDocuments}
          />
          <label htmlFor="file-upload">
            <Button
              variant="outlined"
              className="outlined-secondary-button"
              startIcon={<UploadIcon />}
              disabled={!selectedCategory || loadingUploadDocuments}
              onClick={() => fileInputRef.current?.click()}
            >
              {loadingUploadDocuments ? <Spinner /> : "Upload"}
            </Button>
          </label>
        </Box>
      </Box>
    </Modal>
  );
};

export default UploadFileModal;
